import { useEffect, useState } from "react";
import { FILE } from "src/interfaces/auth.interface";
import { useFilePicker } from "use-file-picker";
import { onUploadImage } from "src/services/auth.service";
import { ImageCropper } from "./ImageCropper";
import Image from "./Image";
import { FileContent } from "use-file-picker/dist/interfaces";
import { MainImageViewer } from "./MainImageViewer";

interface Props {
  accepted: string[];
  path: string;
  imagePath: string;
  images: FILE[];
  uploadedImage?: (file: FILE) => void;
}

export default function UploadFile(props: Props) {
  const [uploadedFiles, setUploadedFiles] = useState<FILE[]>([]);
  const { openFilePicker, filesContent, loading, clear } = useFilePicker({
    accept: props.accepted,
    multiple: false,
    readAs: "DataURL",
  });
  const [showCropImage, setShowCropImage] = useState<boolean>(false);
  const [imageCrop, setImageCrop] = useState<FileContent<string> | null>(null);
  const [uploading, setUploading] = useState(false);

  const [openViewImage, setOpenViewImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<FILE>(props.images[0]);

  useEffect(() => {
    setUploadedFiles(props.images);
  }, [props.images]);

  useEffect(() => {
    filesContent.map((value) => {
      setImageCrop(value);
      setShowCropImage(true);
    });
  }, [filesContent]);

  const uploadImage = async (base64: string) => {
    setUploading(true);
    var newImage = await onUploadImage(props.path, {
      id: imageCrop?.name,
      base64: base64,
    });
    if (!newImage) {
      setUploading(false);
      setShowCropImage(false);
      return;
    }
    props.uploadedImage && props.uploadedImage(newImage.data);
    setUploadedFiles(
      uploadedFiles
        ? uploadedFiles.concat(newImage?.data)
        : [].concat(newImage?.data)
    );
    setUploading(false);
    setShowCropImage(false);
  };

  const handleClickImage = (image: FILE) => {
    setSelectedImage(image);
    setOpenViewImage(true);
  };

  return (
    <>
      <MainImageViewer
        open={openViewImage}
        onClose={() => {
          setOpenViewImage(false);
        }}
        selectedImage={selectedImage}
        images={props.images}
      />
      <ImageCropper
        uploading={uploading}
        onUpload={uploadImage}
        onOpen={() => setShowCropImage(false)}
        image={imageCrop?.content ?? ""}
        open={showCropImage}
      />
      <div
        onClick={() => openFilePicker()}
        className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0"
      >
        <div className="relative w-full">
          <div className="items-center justify-center max-w-xl mx-auto">
            <label
              className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
              id="drop"
            >
              <span className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <span className="font-medium text-gray-600">
                  Drop files to Attach, or
                  <span className="text-blue-600 underline ml-[4px]">
                    browse
                  </span>
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap">
        {uploadedFiles.map((m) => (
          <div
            onClick={() => {
              // handleClickImage(m);
            }}
            style={{
              width: 97,
              height: 97,
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Image url={m.url ?? ""} />
          </div>
        ))}
      </div>
    </>
  );
}
