import { IoAddCircle } from "react-icons/io5";
import MainButton from "src/components/MainButton";
import SearchInput from "src/components/SearchInput";
import { STEP } from "src/components/Stepper";

interface Props {
  onAdd?: () => void;
  onChange: (text: string) => void;
  addLabel?: string;
  buttons?: STEP[];
  onButton?: (doc: STEP) => void;
  active?: STEP;
  totalActive:number;
  totalInactive:number;
  className?:string
}

export default function TableHeader(props: Props) {
  return (
    <div className={`w-full h-12 flex gap-2 ${props.className ? props.className : ""}`}>
      <div className="w-96">
        <SearchInput onChange={(e) => props.onChange(e.target.value)} />
      </div>
    
      <div className="flex flex-1" />
      {props.onAdd ? (
        <div className="w-45">
          <MainButton
           variant={"outline"}
            icon={IoAddCircle}
            onClick={() => props.onAdd && props.onAdd()}
            type="button"
          />
        </div>
      ) : null}
      <div className="flex">
        {props.buttons?.map((m) => {
          const active = m.id === props.active?.id
          return (
            <div 
            key={m.id}
            className="w-45 mr-2">
              <MainButton
                variant={active ? "default" : "outline"}
                onClick={() => props.onButton && props.onButton(m)}
                label={`${m.name} (${m.id === 1 ? props.totalActive : m.id === 2 ? props.totalInactive : props.totalInactive + props.totalActive})`}
                type="button"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
