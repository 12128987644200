import { META_DATA } from "src/interfaces/proposal.interface";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import { ContentTitle } from "../ContentTitle";
import { COMPONENT_TYPE } from "src/constants";
import UploadFileForProposal from "../UploadProposalFile";

interface Props {
  parent: META_DATA;
  onChange: (child: META_DATA) => void;
}

export const ProjectScheduleSection = (props: Props) => {
  return (
    <div className="mb-8">
      <ContentTitle title={`${props.parent.label}`} />
      {props.parent.type === "upload" && props.parent.children.length === 0 ? (
        <UploadFileForProposal
          isProjectSchedule
          key={props.parent.id}
          parent={props.parent}
          child={props.parent.children[0]}
          images={[]}
        />
      ) : null}
      {props.parent.children.map((child) => {
        const isImage = child.type === COMPONENT_TYPE.image.key;
        if (isImage) {
          return (
            <UploadFileForProposal
              isProjectSchedule
              key={child.id}
              parent={props.parent}
              child={child}
              images={[{ id: child.id, base64: null, url: child.value }]}
            />
          );
        }
        return (
          <DynamicInput
            key={child.id}
            meta={child}
            onChange={(v) => {
              const data = {
                ...child,
                value: v,
              };
              props.onChange(data);
            }}
          />
        );
      })}
    </div>
  );
};
