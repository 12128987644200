import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";
import ActivityIndicator from "src/components/ActivityIndicator";
import { Checkbox } from "src/components/CheckBox";
import {
  PERMISSION,
  PERMISSION_CLASSIFICATION,
  ROLE,
} from "src/interfaces/init.interface";
import { orderByAsc } from "src/utilities/formatter.utilities";

interface Props {
  enable?: boolean;
  role: ROLE | null;
  processing: boolean;
  permissionClassifications: PERMISSION_CLASSIFICATION[];
  permissions: PERMISSION[];
  onPermission: (v: PERMISSION_CLASSIFICATION, permission: PERMISSION) => void;
}

function PermissionSetup(props: Props) {
  return (
    <div className="mt-8">
      <div className="flex items-center gap-4">
        <span className="text-lg font-bold">Permissions</span>
        {props.processing ? <ActivityIndicator /> : null}
      </div>
      <div className="flex gap-2 mt-4 flex-col">
        {props.permissionClassifications.map((item) => {
          const _permissions = orderByAsc(item.permissions ?? [], "sequence");
          return (
            <div
              style={{
                padding: LAYOUTS.padding_big,
                display: "flex",
                backgroundColor: COLORS.background_primary,
                marginBottom: LAYOUTS.margin,
                borderRadius: LAYOUTS.radius,
                flex: 1,
                flexDirection: "column",
              }}
            >
              <h4>{item?.name}</h4>
              <div>
                {_permissions.map((m) => {
                  return (
                    <div className="mt-2">
                      <Checkbox
                        disabled={
                          props.enable ? false : !props.role || props.processing
                        }
                        label={m.name}
                        value={
                          props.permissions.find((e) => e?.id === m.id)
                            ? true
                            : false
                        }
                        onChange={(v) => {
                          props.onPermission(item, m);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PermissionSetup;
