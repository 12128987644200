import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { inject, observer } from "mobx-react";
import TableHeader from "./TableHeader";
import {  staticSearch } from "src/utilities/mapping.utilities";
import AuthStore from "src/stores/auth.store";
import { USER } from "src/interfaces/init.interface";
import { STEP } from "src/components/Stepper";
import MemberStore from "./MemberStore";
import { LAYOUTS } from "src/assets/layouts";
import RenderColumnRow, { getSize, saveSize } from "src/components/tableComponents/RenderColumnRow";
import { ColumnSizingState } from "@tanstack/react-table";

interface Props {
  entityCode: string;
  auth?: AuthStore;
  onClick: (row: any) => void;
  height: number;
  onMainButton?: () => void;
  mainButtonLabel?: string;
  member?: MemberStore;
  data: USER[];
  headerButtons: STEP[];
  active: STEP;
  onHeaderButton: (step: STEP) => void;
  totalInactive: number;
  totalActive: number;
}

const MemberTable = (props: Props) => {
  const { entities } = props.auth ?? {};
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [search, setSearch] = useState("");
  const [data, setData] = useState<USER[]>([]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(columnSizing).length !== 0) {
      saveSize(props.entityCode ?? "", columnSizing);
    } else {
      const size = getSize(props.entityCode ?? "");
      if(!size) return;
      setColumnSizing(size);
    }
  }, [columnSizing]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    const entity = entities?.find((m) => m.code === props.entityCode);
    if (!entity) return [];
    return RenderColumnRow({ entity: entity, auth: props.auth });
  }, [entities]);



  useEffect(() => {
    if (search) {
      const result = staticSearch(props.data, search);
      setData(result);
    } else {
      setData(props.data);
    }
  }, [search, props.data]);

  const table = useMaterialReactTable({
    onColumnSizingChange: setColumnSizing,
    columns,
    data: data,
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 250, //default size is usually 180
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#f8fafc",
      },
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: "#f8fafc",
      },
    },

    muiTableContainerProps: {
      sx: {
        height: props.height - 160,
        borderWidth: 1,
        borderRadius: LAYOUTS.radius,
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
    state:{
      columnSizing: columnSizing
    },
    enableSorting: false,
    enableBottomToolbar: false,
    enablePagination: false,
    manualSorting: true,
    enableMultiSort: false,
    enableColumnActions: false,
    manualPagination: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    enableStickyHeader: true,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: "pointer",
        opacity: row.original?.enable === false ? 0.5 : 1,
      },
      onClick: (event) => {
        props.onClick(row.original);
      },
    }),

    renderTopToolbar: () => {
      return (
        <TableHeader
          totalActive={props.totalActive}
          totalInactive={props.totalInactive}
          onButton={(step) => props.onHeaderButton(step)}
          active={props.active}
          buttons={props.headerButtons}
          addLabel={props.mainButtonLabel}
          onAdd={props.onMainButton}
          onChange={(e) => setSearch(e)}
        />
      );
    },
  });
  if (columns.length === 0) return <div />;
  return <MaterialReactTable table={table} />;
};

export default inject("auth", "member")(observer(MemberTable));
