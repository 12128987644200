import { META_DATA } from "src/interfaces/proposal.interface";
import { ContentTitle } from "../ContentTitle";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import UploadFileForProposal from "../UploadProposalFile";

interface Props {
  sectionId: number;
  parent: META_DATA;
  onChange: (child: META_DATA) => void;
}

export const ReferenceImageSection = (props: Props) => {
  return (
    <div className="mb-8">
      <ContentTitle title={props.parent?.label} />
      <div className="rounded-sm bg-gray-50 border-[0.8px] p-4 pt-0 mt-3">
        {props.parent.children.map((child) => {
          if (child.type === "upload") {
            return (
              <div className="mt-4">
                <UploadFileForProposal
                  isReferenceImage
                  key={child.id}
                  parent={props.parent}
                  child={child}
                  images={child.children.map((m) => ({
                    id: m.id,
                    base64: null,
                    url: m.value,
                  }))}
                />
              </div>
            );
          }
          return (
            <DynamicInput
              key={child.id}
              onChange={(text) => {
                const data = {
                  ...child,
                  value: text,
                };
                props.onChange(data);
              }}
              meta={child}
            />
          );
        })}
      </div>
    </div>
  );
};
