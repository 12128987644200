import { useEffect, useRef, useState } from "react";
import { Textarea } from "./ui/textarea";

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
  onChange?: (t: string) => void;
  disabled?: boolean;
  onClick?: (e: any) => void;
  active?: boolean;
  hideBorder?: boolean;
  id: string;
}

export default function MainTextArea(props: Props) {
  const [text, setText] = useState("");
  const [isFocused, setFocus] = useState(false);
  const [height, setHeight] = useState(0);
  const [displayText,setDisplayText] = useState("");

  const ref = useRef<any>(null);
  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value]);

  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        ref?.current?.select();
      },100)
    }
  }, [isFocused]);

  useEffect(() => {
    const rows = text.split(/\r|\r\n|\n/).length;
    setHeight(rows * 20);
    setDisplayText(text.replace(/\n/g, ' ') )
  }, [text]);

  return (
    <>
   
      <Textarea
        ref={ref}
        id={`${props.id}_editor`}
        style={{
          marginTop: 6,
          height: isFocused ? height : 32,
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        disabled={
          (props.onClick ? true : false) || !props.onChange || props.disabled
        }
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={(e) => {
          setFocus(false);
          props.onChange && props.onChange(text);
        }}
        onChange={(e) => setText(e.target.value)}
        value={ !isFocused ? displayText : text}
        className={`resize-none bg-white  ${
          props.onClick && !props.disabled
            ? "disabled:text-muted-foreground border border-gray-400"
            : ""
        } ${
          props.active
            ? "border border-secondary"
            : props.hideBorder
            ? "shadow-transparent border-transparent"
            : ""
        }`}
        placeholder={props.placeholder}
      />
    </>
  );
}
