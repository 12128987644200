import ReactDOM from "react-dom/client";
import "./index.css";
import "react-international-phone/style.css";
import 'react-quill/dist/quill.snow.css';
import '@nosferatu500/react-sortable-tree/style.css';

import App from "./App";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
