import { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { STEP } from "src/components/Stepper";
import React from "react";
import TeamListScreen from "./TeamListScreen";
import TeamStore from "./TeamStore";
import { staticList } from "src/APIs/table.api";

interface Props {
  type: string;
  team?: TeamStore;
}

const status_buttons: STEP[] = [
  {
    id: 1,
    name: "Active",
    sequence: 1,
  },
  {
    id: 2,
    name: "In-Active",
    sequence: 2,
  },
  {
    id: 3,
    name: "Show All",
    sequence: 3,
  },
];

function MemberListPage(props: Props) {
  const ref = useRef<any>(null);
  const [selectedStatus, setStatus] = useState(status_buttons[0]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    staticList("team/detail").then((doc) => {
      if (doc.code !== "200") {
        setLoading(false);
        return;
      }
      setLoading(false);
      props.team && props.team?.setData(doc?.data ?? []);
    });
  }, []);

  const { data, rawData } = props.team ?? {};

  const getData = () => {
    if (props.type === "all-teams") {
      return rawData;
    }
    if (props.type === "external-teams") {
      return rawData?.filter((m) => m.external);
    }

    if (props.type === "internal-teams") {
      return rawData?.filter((m) => !m.external);
    }
    return [];
  };

  const totalActive = React.useMemo(() => {
    return getData()?.filter((m) => m.enable).length;
  }, [getData()]);

  const totalInactive = React.useMemo(() => {
    return getData()?.filter((m) => !m.enable).length;
  }, [getData()]);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <TeamListScreen
        createdDepartment={(department) =>
          props.team?.updateTable(department)
        }
        afterAddedMembers={(departments) => props.team?.setData(departments)}
        deletedItem={(item) =>
          props.team?.setData(
            props.team?.rawData.filter((m) => m.id !== item.id)
          )
        }
        onSearch={(text) => {
          props.team?.onDeepSearch(text, "users");
          setSearch(text);
        }}
        search={search}
        processing={loading ?? false}
        teams={data ?? []}
        headerButtons={status_buttons}
        active={selectedStatus}
        onHeaderButton={(st) => {
          setStatus(st);
          if (st.id === 1) {
            props.team?.changeStatus(
              props.team?.rawData.filter((m) => m.enable)
            );
          } else if (st.id===2){
            props.team?.changeStatus(
              props.team?.rawData.filter((m) => !m.enable)
            );
          } else {
            props.team?.changeStatus(props.team?.rawData);
          }
        }}
        totalInactive={totalInactive ?? 0}
        totalActive={totalActive ?? 0}
      />
    </div>
  );
}

export default inject("team")(observer(MemberListPage));
