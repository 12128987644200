import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import { IoCopyOutline } from "react-icons/io5";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { TEAM } from "src/interfaces/team.interface";
import { LAYOUTS } from "src/assets/layouts";
import { COLORS } from "src/assets/colors";
import { formatMoney, sum } from "src/utilities/formatter.utilities";
import NumberInput from "src/components/NumberInput";

interface Props {
  row: PRODUCT_TYPE;
  isDisabled: boolean;
  onMarkup: (
    value: string,
    type: PRODUCT_TYPE,
    isAll?: boolean,
    noHasModify?: boolean
  ) => void;
  onCopyToAll: (value: number) => void;
  itemInTeams: GROUP_OR_ITEM[];
  team: TEAM;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
}));

export default function MarkupRow(props: Props) {
  const [show, setShow] = React.useState<any | null>(false);
  const row = props.row;
  const itemInType = props.itemInTeams.filter(
    (i) => i.product?.productType?.id === row.id
  );
  const totalMarkup: number =
    sum(itemInType, "markup") + sum(itemInType, "disperse");
  const total: number = sum(itemInType, "total");
  const disperse: number = sum(itemInType, "disperse");
  const totalAfterMarkup: number = totalMarkup + total + disperse;
  const [markupValue, setMarkup] = useState("0");
  const [profitMarginValue, setProfitMargin] = useState("0");

  const [isReady, setReady] = React.useState<any | null>(false);

  useEffect(() => {
    if (!isReady) return;
    onMarkup(
      props.row?.markupPercentage
        ? props.row?.markupPercentage?.toString()
        : "0"
    );
  }, [props.row.markupPercentage]);

  useEffect(() => {
    onMarkup(
      props.row?.markupPercentage
        ? props.row?.markupPercentage?.toString()
        : "0",
      true
    );
    setTimeout(() => setReady(true), 200);
  }, []);

  const onProfitMargin = (e: string) => {
    if(e === profitMarginValue) return;
    const profitMargin = e;
    setProfitMargin(profitMargin);
    const markup = formatMoney((100 * Number(profitMargin)) / (100 - Number(profitMargin)));
    setMarkup(markup ?? "0");
    props.onMarkup(markup ?? "0", row, props.team.id === "all_team");
  };

  const onMarkup = (e: string, noHasModify?: boolean) => {
    if(e === markupValue) return;
    const markup = e;
    setMarkup(markup);
    const profitMargin = formatMoney((100 * Number(markup)) / (100 + Number(markup)));
    setProfitMargin(profitMargin?? "0");
    props.onMarkup(e, row, props.team.id === "all_team", noHasModify);
  };

  return (
    <TableRow
      style={{
        backgroundColor: show ? COLORS.background_primary : COLORS.white,
      }}
      onMouseLeave={() => setShow(false)}
      onMouseEnter={() => setShow(true)}
      key={row.name}
    >
      <StyledTableCell
        style={{
          paddingLeft: LAYOUTS.padding_big,
          textTransform: "capitalize",
        }}
        component="th"
        scope="row"
      >
        {row.name}
      </StyledTableCell>
      <StyledTableCell
        style={{
          paddingLeft: LAYOUTS.padding_big,
          display: "flex",
          alignItems: "center",
        }}
        align="left"
      >
        <div style={{ width: 100 }}>
          <NumberInput
            disabled={props.isDisabled}
            value={markupValue}
            onChange={(e) => onMarkup(e)}
          />
        </div>
        {show ? (
          <div
            onClick={() => props.onCopyToAll(row.markupPercentage ?? 0)}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: LAYOUTS.margin,
              padding: LAYOUTS.padding_small,
              borderRadius: LAYOUTS.radius,
              backgroundColor: COLORS.blue_transparent,
              paddingLeft: LAYOUTS.padding,
              paddingRight: LAYOUTS.padding,
              cursor: "pointer",
            }}
          >
            <IoCopyOutline
              style={{ marginRight: LAYOUTS.margin_small, cursor: "pointer" }}
            />
            <p style={{ cursor: "pointer", fontSize: 8 }}>Copy To All</p>
          </div>
        ) : null}
      </StyledTableCell>
      <StyledTableCell
        style={{ paddingLeft: LAYOUTS.padding_big, alignItems: "center" }}
        align="left"
      >
        <div style={{ width: 100 }}>
          <NumberInput
            disabled={props.isDisabled}
            value={profitMarginValue}
            onChange={(e) => onProfitMargin(e)}
          />
        </div>
      </StyledTableCell>
      <StyledTableCell
        style={{ paddingLeft: LAYOUTS.padding_big }}
        align="left"
      >
        ${formatMoney(totalMarkup)}
      </StyledTableCell>
      <StyledTableCell
        style={{ paddingLeft: LAYOUTS.padding_big }}
        align="left"
      >
        ${formatMoney(total)}
      </StyledTableCell>
      <StyledTableCell
        style={{ paddingLeft: LAYOUTS.padding_big }}
        align="left"
      >
        ${formatMoney(totalAfterMarkup)}
      </StyledTableCell>
    </TableRow>
  );
}
