import { Checkbox as Box } from "./ui/checkbox";
import { Label } from "./ui/label";

interface Props {
  label?: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  active?: boolean;
  clasName?: string;
}

export function Checkbox(props: Props) {
  return (
    <div className={props.label ? "flex items-center space-x-2" : ""}>
      <Box
        className={`${props.clasName ?? "bg-white w-[20px] h-[20px]"} ${
          props.active ? "border-secondary" : ""
        }`}
        checked={props.value}
        disabled={!props.onChange || props.disabled}
        onCheckedChange={(e) => props.onChange && props.onChange(e as boolean)}
        id="terms"
      />
      {props.label ? <Label className="ml-2">{props.label}</Label> : null}
    </div>
  );
}
