import { ReloadIcon } from "@radix-ui/react-icons";
import { ChangeEventHandler, useEffect, useState } from "react";
interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  loading?: boolean;
  focus?:boolean
  placeholder?:string
  value?:string;
}

export default function SearchInput(props: Props) {
  const [value,setValue] = useState("");
  useEffect(() => {
    setValue(props.value ?? "");
  },[props.value]);

  return (
    <div className="relative text-muted-foreground focus-within:text-gray-400 w-full">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          {props.loading ? (
            <ReloadIcon className="w-5 h-5  animate-spin" />
          ) : (
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-5 h-5 mt-1"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          )}
        </button>
      </span>
      <input
        value={value}
        autoFocus = {props.focus}
        onChange={(e) => {
            const text = e.target.value;
            setValue(text);
            props.onChange(e);
        }}
        role="presentation" autoComplete="off"
        type="search"
        name="q"
        className={`w-full py-2 text-sm bg-white rounded-md pl-10 pr-4 focus:outline-none focus:bg-white focus:text-gray-900 border-[1px] border-gray-300 ${props.value ? "text-gray-900" : ""}`}
        placeholder={props.placeholder || "Search..."}
      />
    </div>
  );
}
