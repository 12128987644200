import { Label } from "src/components/ui/label";

interface Props {
  title: string;
}

export const ContentTitle = (props: Props) => {
  return (
    <div className="flex">
      <Label className="text-white bg-primary items-center font-semibold flex px-3 pl-2 rounded-sm uppercase">
        <div className="w-[5px] h-6 rounded-r-sm mr-2 my-2 bg-secondary" />
        {props.title}
      </Label>
    </div>
  );
};
