import { RxDragHandleDots2 } from "react-icons/rx";
import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import { Checkbox } from "src/components/CheckBox";

interface props {
  item: ENTITY_CONFIG;
  onVisible: (v: boolean) => void;
}

export function EntityItem(props: props) {
  const item = props.item;

  return (
    <li key={item?.id} className={"select-none list-none mt-3 cursor-pointer"}>
      <div
        key={item?.id}
        className={`bg-gray-50 border-solid border-[0.5px] rounded-md  p-3 flex items-center justify-between dark:text-white hover:bg-slate-100 dark:hover:bg-black`}
      >
        <div>
          <div className="font-semibold mb-1">{item?.displayName}</div>
          <Checkbox
            onChange={props.onVisible}
            label="Visible"
            value={item.visible}
          />
        </div>
        <RxDragHandleDots2 className="h-5 w-5 text-black cursor-pointer" />
      </div>
    </li>
  );
}
