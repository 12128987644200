import { COLUMN_TYPES } from "src/constants/table.constants";
import { ENTITY } from "src/interfaces/init.interface";

export const PRODUCT_ENTITY: ENTITY = {
  id: 5,
  code: "prd",
  name: "Product Configuration",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "Product Name",
      columnName: "name",
      sequence: 0,
      visible: true,
      sortable: true,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Department",
      columnName: "department.name",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "department.name",
      columnType: COLUMN_TYPES.text,
    },

    {
      id: 3,
      displayName: "Description",
      columnName: "description",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "description",
      columnType: COLUMN_TYPES.text,
    },

    {
      id: 4,
      displayName: "Price",
      columnName: "price",
      sequence: 3,
      visible: true,
      sortable: true,
      sortedColumnName: "price",
      columnType: COLUMN_TYPES.currency,
    },
    {
      id: 5,
      displayName: "Tax Included",
      columnName: "taxIncluded",
      sequence: 4,
      visible: true,
      sortable: false,
      sortedColumnName: "",
      columnType: COLUMN_TYPES.checkbox,
    },
    {
      id: 6,
      displayName: "Item Type",
      columnName: "productType.name",
      sequence: 5,
      visible: true,
      sortable: true,
      sortedColumnName: "productType.name",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 7,
      displayName: "Status",
      columnName: "enable",
      sequence: 6,
      visible: true,
      sortable: false,
      sortedColumnName: "",
      columnType: COLUMN_TYPES.checkbox,
    },
  ],
};

export const CATALOG_ENTITY: ENTITY = {
  id: 5,
  code: "ctl",
  name: "Catalog",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "Item",
      columnName: "productName",
      sequence: 0,
      visible: true,
      sortable: true,
      sortedColumnName: "productName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Catalog Name",
      columnName: "name",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 3,
      displayName: "Price",
      columnName: "price",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "price",
      columnType: COLUMN_TYPES.currency,
    },
    {
      id: 4,
      displayName: "Status",
      columnName: "enable",
      sequence: 3,
      visible: true,
      sortable: false,
      sortedColumnName: "",
      columnType: COLUMN_TYPES.checkbox,
    },
  ],
};

export const PRODUCTION_RATE_ENTITY: ENTITY = {
  id: 5,
  code: "pr",
  name: "Production Rate",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "Product Name",
      columnName: "productName",
      sequence: 0,
      visible: true,
      sortable: true,
      sortedColumnName: "productName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Type or Variation",
      columnName: "type",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "type",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 3,
      displayName: "Unit",
      columnName: "unit",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "unit",
    },
    {
      id: 4,
      displayName: "Production Rate",
      columnName: "rate",
      sequence: 3,
      visible: true,
      sortable: true,
      sortedColumnName: "rate",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 5,
      displayName: "Status",
      columnName: "enable",
      sequence: 4,
      visible: true,
      sortable: false,
      sortedColumnName: "enable",
      columnType: COLUMN_TYPES.checkbox,
    },
  ],
};

export const LABOR_PRICING_TEAM: ENTITY = {
  id: 5,
  code: "lpt",
  name: "Labor Pricing by Team",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "Name",
      columnName: "name",
      sequence: 0,
      visible: true,
      sortable: true,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Price",
      columnName: "price",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "price",
      columnType: COLUMN_TYPES.currency,
    },
    {
      id: 3,
      displayName: "Status",
      columnName: "enable",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "enable",
      columnType: COLUMN_TYPES.checkbox,
    },
  ],
};

export const MEMBER_ENTITY: ENTITY = {
  id: 4,
  code: "mb",
  name: "User Management",
  isDummy: true,
  entityConfigurations: [
    {
      id: 0,
      displayName: "Full Name",
      columnName: "fullName",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "fullName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 1,
      displayName: "Role",
      columnName: "roleName",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "roleName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Team",
      columnName: "teamName",
      sequence: 3,
      visible: true,
      sortable: true,
      sortedColumnName: "teamName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 3,
      displayName: "Department",
      columnName: "departmentName",
      sequence: 4,
      visible: true,
      sortable: true,
      sortedColumnName: "departmentName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 3,
      displayName: "Joined Date",
      columnName: "joinedDate",
      sequence: 4,
      visible: true,
      sortable: true,
      sortedColumnName: "joinedDate",
      columnType: COLUMN_TYPES.date,
    },
  ],
};

export const ROLE_ENTITY: ENTITY = {
  id: 5,
  code: "role",
  name: "Role Management",
  isDummy: true,
  entityConfigurations: [
    {
      id: 0,
      displayName: "Name",
      columnName: "name",
      sequence: 1,
      visible: true,
      sortable: true,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 1,
      displayName: "Admin",
      columnName: "admin",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "admin",
      columnType: COLUMN_TYPES.checkbox,
    },
    {
      id: 1,
      displayName: "Status",
      columnName: "status",
      sequence: 2,
      visible: true,
      sortable: true,
      sortedColumnName: "enable",
      columnType: COLUMN_TYPES.checkbox,
    },
  ],
};

export const ESTIMATE_TEMPLATE: ENTITY = {
  id: 6,
  code: "est_tem",
  name: "Estimate Template",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "NAME",
      columnName: "name",
      sequence: 1,
      visible: true,
      sortable: false,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },

    {
      id: 3,
      displayName: "STATUS",
      columnName: "enable",
      sequence: 2,
      visible: true,
      sortable: false,
      sortedColumnName: "enable",
      columnType: COLUMN_TYPES.checkbox,
    },
    {
      id: 3,
      displayName: "CREATED AT",
      columnName: "createdAt",
      sequence: 4,
      visible: true,
      sortable: false,
      sortedColumnName: "createdAt",
      columnType: COLUMN_TYPES.date,
    },
    {
      id: 2,
      displayName: "UPDATED DATE",
      columnName: "updatedAt",
      sequence: 3,
      visible: true,
      sortable: false,
      sortedColumnName: "updatedAt",
      columnType: COLUMN_TYPES.date,
    },
  ],
};

export const PROPOSAL_LIST: ENTITY = {
  id: 7,
  code: "proposal_list",
  name: "Proposal List",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "Number",
      columnName: "number",
      sequence: 1,
      visible: true,
      sortable: false,
      sortedColumnName: "number",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "Customer Name",
      columnName: "customerName",
      sequence: 2,
      visible: true,
      sortable: false,
      sortedColumnName: "customerName",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 3,
      displayName: "Estimate Number",
      columnName: "estimateNumber",
      sequence: 3,
      visible: true,
      sortable: false,
      sortedColumnName: "estimateNumber",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 4,
      displayName: "Estimate Amount",
      columnName: "estimateAmount",
      sequence: 4,
      visible: true,
      sortable: false,
      sortedColumnName: "estimateAmount",
      columnType: COLUMN_TYPES.text,
    },
  ],
};

export const PROPOSAL_TEMPLATE: ENTITY = {
  id: 8,
  code: "proposal_template",
  name: "Proposal Template",
  isDummy: true,
  entityConfigurations: [
    {
      id: 1,
      displayName: "No.",
      columnName: "id",
      sequence: 1,
      visible: true,
      sortable: false,
      sortedColumnName: "id",
      columnType: COLUMN_TYPES.text,
    },
    {
      id: 2,
      displayName: "NAME",
      columnName: "name",
      sequence: 2,
      visible: true,
      sortable: false,
      sortedColumnName: "name",
      columnType: COLUMN_TYPES.text,
    },
  ],
};
