import { IconButton } from "@mui/material";
import {
  HiMiniBanknotes,
  HiMiniXCircle,
  HiPrinter,
  HiSquare2Stack,
  HiTrash,
} from "react-icons/hi2";
import { IoSave } from "react-icons/io5";
import GlowingWrapper from "src/components/GlowingWrapper";
import MainButton from "src/components/MainButton";
import EstimateSummary from "./estimateSummaryDialogue/EstimateSummary";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { getTotal } from "./estimate.utility";
import { formatMoney } from "src/utilities/formatter.utilities";
import useDetectPrint from "react-detect-print";
import { DeleteButton } from "src/components/DeleteButton";
import { useNavigate } from "react-router-dom";
import { AlertDialogue } from "src/components/AlertDialogue";
import ActivityIndicator from "src/components/ActivityIndicator";
import React from "react";
import { PreviewButton } from "./PreviewButton";

interface Props {
  goBack: () => void;
  items: GROUP_OR_ITEM[];
  exclusion: string;
  taxRate: number;
  onPrint: () => void;
  estimateId?: number;
  isModify: boolean;
  onClone: () => void;
  processing: boolean;
  onSave: (onSaved?: () => void) => void;
  printing: boolean;
}

export default function PageFooter(props: Props) {
  const summary = React.useMemo(() => {
    return getTotal(props.taxRate, props.items);
  }, [props.items]);
  const isPrinting = useDetectPrint();
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-1 h-14 bg-primary p-4 drop-shadow justify-between items-center ${
        isPrinting ? "fixed left-0 right-0 bottom-0" : ""
      }`}
    >
      <div className="flex  flex-1 items-center">
        <div className="w-32 mr-3">
          {props.isModify ? (
            <AlertDialogue
              variant="destructive"
              continueLabel="Leave"
              onContinue={() => props.goBack()}
              title="Leaving Without Save"
              description="You have modified something without saving, would you like to leave?"
              renderButton={
                <MainButton
                  variant={"outline"}
                  className="mr-2"
                  type="button"
                  icon={HiMiniXCircle}
                  onClick={() => null}
                  label="Cancel"
                />
              }
            />
          ) : (
            <MainButton
              variant={"outline"}
              className="mr-2"
              type="button"
              icon={HiMiniXCircle}
              onClick={() => props.goBack()}
              label="Cancel"
            />
          )}
        </div>
        <div className="bg-white/20 p-1 rounded-md text-primary font-bold text-lg pr-6 text-white pl-6">
          <GlowingWrapper>
            <HiMiniBanknotes className="text-white mr-2" />
            <span>${formatMoney(summary.total)}</span>
          </GlowingWrapper>
        </div>
      </div>
      <div className="flex flex-1"></div>
      <div className="flex items-center">
        <EstimateSummary
          taxRate={props.taxRate}
          exclusion={props.exclusion}
          items={props.items}
        />
        <PreviewButton
          savingEstimate={props.processing}
          isModify={props.isModify}
          estimateId={props.estimateId}
          onSaveEstimate={props.onSave}
        />
        <MainButton
          loading={props.processing}
          variant={"destructive"}
          type="button"
          icon={IoSave}
          onClick={() => props.onSave()}
          label="Save"
        />
        <AlertDialogue
          continueLabel="Clone"
          description="You are about to clone this estimate details to another one, would you like to continue?"
          title="Clone Estimate"
          onContinue={() => props.onClone()}
          renderButton={
            <IconButton
              style={{
                backgroundColor: "rgba(255,255,255,0.1)",
                marginLeft: 12,
              }}
            >
              <HiSquare2Stack className="text-white" />
            </IconButton>
          }
        />
        <IconButton
          disabled={props.printing}
          onClick={() => (props.printing ? null : props.onPrint())}
          style={{ backgroundColor: "rgba(255,255,255,0.1)", marginLeft: 12 }}
        >
          {props.printing ? (
            <div
              style={{
                width: 21,
                height: 21,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator isWhite />
            </div>
          ) : (
            <HiPrinter className="text-white" />
          )}
        </IconButton>
        {props.estimateId ? (
          <DeleteButton
            message="Are you sure to delete this estimate?"
            title="Delete Estimate"
            renderComponent={
              <IconButton
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  marginLeft: 12,
                }}
              >
                <HiTrash className="text-white" />
              </IconButton>
            }
            id={props.estimateId}
            deleted={() => navigate(-1)}
            path="estimate"
          />
        ) : null}
      </div>
    </div>
  );
}
