import { IconButton } from "@mui/material";
import {
  HiEye,
  HiMiniBanknotes,
  HiMiniXCircle,
  HiTrash,
} from "react-icons/hi2";
import { IoSave } from "react-icons/io5";
import GlowingWrapper from "src/components/GlowingWrapper";
import MainButton from "src/components/MainButton";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { formatMoney } from "src/utilities/formatter.utilities";
import useDetectPrint from "react-detect-print";
import { DeleteButton } from "src/components/DeleteButton";
import { useNavigate } from "react-router-dom";
import { AlertDialogue } from "src/components/AlertDialogue";
import { getTotal } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";

interface Props {
  goBack: () => void;
  items: GROUP_OR_ITEM[];
  estimateId?: number | string;
  isModify: boolean;
  processing: boolean;
  onSave: () => void;
  hidePreview?: boolean;
}

export default function PageFooter(props: Props) {
  const summary = getTotal(
    0,
    props.items.map((m) => {
      return { ...m, total: m.quantity * m.price };
    })
  );
  const isPrinting = useDetectPrint();
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-1 h-14 bg-primary p-4 drop-shadow justify-between items-center ${
        isPrinting ? "fixed left-0 right-0 bottom-0" : ""
      }`}
    >
      <div className="flex  flex-1 items-center">
        <div className="w-32 mr-3">
          {props.isModify ? (
            <AlertDialogue
              variant="destructive"
              continueLabel="Leave"
              onContinue={() => props.goBack()}
              title="Leaving Without Save"
              description="You have modified something without saving, would you like to leave?"
              renderButton={
                <MainButton
                  variant={"outline"}
                  className="mr-2"
                  type="button"
                  icon={HiMiniXCircle}
                  onClick={() => null}
                  label="Cancel"
                />
              }
            />
          ) : (
            <MainButton
              variant={"outline"}
              className="mr-2"
              type="button"
              icon={HiMiniXCircle}
              onClick={() => props.goBack()}
              label="Cancel"
            />
          )}
        </div>
        <div className="bg-white/20 p-1 rounded-md text-primary font-bold text-lg min-w-40 text-white pl-6">
          <GlowingWrapper>
            <HiMiniBanknotes className="text-white mr-2" />
            <span>${formatMoney(summary.total)}</span>
          </GlowingWrapper>
        </div>
      </div>
      <div className="flex flex-1"></div>
      <div className="flex items-center">
        <MainButton
          loading={props.processing}
          variant={"destructive"}
          type="button"
          icon={IoSave}
          onClick={() => props.onSave()}
          label="Save"
        />
        {props.estimateId ? (
          <DeleteButton
            message="Are you sure to delete this estimate?"
            title="Delete Estimate"
            renderComponent={
              <IconButton
                style={{
                  backgroundColor: "rgba(255,255,255,0.1)",
                  marginLeft: 12,
                }}
              >
                <HiTrash className="text-white" />
              </IconButton>
            }
            id={props.estimateId}
            deleted={() => navigate(-1)}
            path="estimate-template"
          />
        ) : null}
      </div>
    </div>
  );
}
