import { arrayMoveImmutable } from "array-move";
import {
  GROUP,
  GROUP_OR_ITEM,
  GROUP_PAYLOAD,
  ITEM,
  ITEM_PAYLOAD,
  MARKUP,
  MARKUP_PAYLOAD,
} from "src/interfaces/estimate.interface";
import {
  formatMoney,
  orderByAsc,
  sum,
} from "src/utilities/formatter.utilities";
import { v4 as uuidv4 } from "uuid";
import { arrayToTree } from "performant-array-to-tree";
import {
  getGroupCodeToDisplay,
  sortByMultiFields,
} from "src/utilities/functions.utilities";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import _ from "lodash";

export const taxAmount = (tax: number | null, amount: number) => {
  if (!tax) return 0;
  const taxPrice = amount * tax;
  return taxPrice;
};

export function mappingGroupOrItemToArray(groups: GROUP[], items: ITEM[]) {
  const groupsWithSubOf = mappingSubOfGroups(orderByAsc(groups, "level"));
  const mappedGroups: GROUP_OR_ITEM[] = groupsWithSubOf.map((m) => {
    return mappingToItemOrGroup(m, groupsWithSubOf);
  });
  const mappedItems: GROUP_OR_ITEM[] = items.map((m) => {
    return mappingToItemOrGroup(m, groupsWithSubOf);
  });
  return [...mappedGroups, ...mappedItems];
}

export function mappingEstimateTemplateGroupOrItemToArray(
  groups: GROUP[],
  items: ITEM[]
) {
  const groupsWithSubOf = mappingSubOfGroups(groups);
  const mappedGroups: GROUP_OR_ITEM[] = groupsWithSubOf.map((m) => {
    return mappingTemplateDetailsToItemOrGroup(m, groupsWithSubOf);
  });
  const mappedItems: GROUP_OR_ITEM[] = items.map((m) => {
    return mappingTemplateDetailsToItemOrGroup(m, groupsWithSubOf);
  });
  return [...mappedGroups, ...mappedItems];
}

export function remapGroupCode(items: GROUP_OR_ITEM[]) {
  let _items = orderByAsc(items, "level") as GROUP_OR_ITEM[];
  _items.forEach((m, gIndex) => {
    if (m.isGroup) {
      const newParentCode = uuidv4();
      const children = _items.filter((ch) => ch.parentGroupCode === m.code);
      children.forEach((chi) => {
        const index = _items.findIndex((m) => m.id === chi.id);
        _items[index].parentGroupCode = newParentCode;
      });
      _items[gIndex].code = newParentCode;
    }
  });
  return _items;
}

export function mappingToGroupPayload(item: GROUP_OR_ITEM) {
  const group: GROUP_PAYLOAD = {
    id: item.isNew ? undefined : item.id,
    code: item.code ?? "",
    name: item.name ?? "",
    level: item.level,
    parentGroupCode: item.parentGroupCode,
    teamId: item.teamId,
    editedComments: item.editedComments,
    newComments: item.newComments,
    deletedComments: item.deletedComments,
    sequence: item.sequence,
    description: item.description,
  };
  return group;
}

export function mappingToItemPayload(item: GROUP_OR_ITEM) {
  const doc: ITEM_PAYLOAD = {
    id: item.isNew ? undefined : item.id,
    productionRateQuantity: item.productionRateQuantity,
    price: item.price,
    quantity: item.quantity,
    disperse: item.disperse,
    markup: item.markup,
    taxIncluded: item.taxIncluded,
    subContractor: item.subContractor,
    sequence: item.sequence,
    groupCode: item.parentGroupCode,
    productId: item.productId,
    catalogId: item.catalogId,
    productionRateId: item.productionRateId,
    teamId: item.teamId,
    editedComments: item.editedComments,
    newComments: item.newComments,
    deletedComments: item.deletedComments,
    description: item.description ?? "",
  };
  return doc;
}

export function mappingToItemOrGroup(doc: ITEM | GROUP | any, groups: GROUP[]) {
  if (doc.level) {
    const data: GROUP_OR_ITEM = {
      ...doc,
      children: [],
      isGroup: true,
      product: null,
      catalog: null,
      productionRate: null,
      productionRateQuantity: null,
      price: 0,
      quantity: 0,
      disperse: 0,
      markup: 0,
      taxIncluded: false,
      subContractor: false,
      code: doc.code,
      parentGroupCode: doc.parentGroupCode,
      productId: null,
      catalogId: null,
      productionRateId: null,
      total: 0,
      taxAmount: 0,
      totalAfterMarkup: 0,
    };
    return data;
  } else {
    const parent = groups.find((m) => m.code === doc.groupCode);
    const data: GROUP_OR_ITEM = {
      ...doc,
      isGroup: false,
      code: null,
      name: "",
      level: parent?.level ? parent?.level + 1 : 1,
      parentGroupCode: doc.groupCode,
    };
    return data;
  }
}

export function mappingTemplateDetailsToItemOrGroup(
  doc: ITEM | GROUP | any,
  groups: GROUP[]
) {
  if (doc.level) {
    const data: GROUP_OR_ITEM = {
      ...doc,
      id: uuidv4(),
      isGroup: true,
      isNew: true,
    };
    return data;
  } else {
    const parent = groups.find((m) => m.code === doc.groupCode);
    const data: GROUP_OR_ITEM = {
      ...doc,
      id: uuidv4(),
      isGroup: false,
      code: null,
      name: "",
      isNew: true,
      level: parent?.level ? parent?.level + 1 : 1,
      parentGroupCode: doc.groupCode,
      disperse: doc.disperse ?? 0,
      total: doc.price * doc.quantity,
    };
    return data;
  }
}

export function mappingSubOfGroups(groups: GROUP[]) {
  let orderGroups: GROUP[] = orderByAsc(groups, "level");
  groups.forEach((gr) => {
    const parent = groups.find((m) => m.code === gr.parentGroupCode);
    const index = orderGroups.findIndex((m) => m.id === gr.id);
    if (parent) {
      orderGroups[index].subOf = parent?.subOf?.concat(parent.code);
    } else {
      orderGroups[index].subOf = [];
    }
  });
  return orderGroups;
}

export function createItem() {
  const item: GROUP_OR_ITEM = {
    id: uuidv4(),
    isNew: true,
    isGroup: false,
    code: null,
    name: null,
    level: 0,
    team: null,
    hasComment: false,
    parentGroupCode: null,
    teamId: null,
    editedComments: [],
    newComments: [],
    deletedComments: [],
    comment: [],
    sequence: 0,
    product: null,
    catalog: null,
    productionRate: null,
    productionRateQuantity: 0,
    description: null,
    price: 0,
    quantity: 0,
    disperse: 0,
    markup: 0,
    taxIncluded: false,
    subContractor: false,
    productId: null,
    catalogId: null,
    productionRateId: null,
    total: 0,
    taxAmount: 0,
    totalAfterMarkup: 0,
  };
  return item;
}

export function reorderSequence(data: any[]) {
  return data.map((m, i) => {
    return {
      ...m,
      sequence: i + 1,
    };
  });
}

export function getSubGroups(item: GROUP_OR_ITEM, items: GROUP_OR_ITEM[]) {
  const groupChildren = items.filter(
    (m) => m.isGroup && item?.code && m.subOf?.includes(item.code)
  );
  return groupChildren;
}

export function onDeleteItem(
  item: GROUP_OR_ITEM,
  allItems: GROUP_OR_ITEM[],
  keepChildren?: boolean
) {
  let items = allItems;
  const siblings = items.filter(
    (m) => m.parentGroupCode === item.parentGroupCode
  );

  const mapItems = reorderSequence(
    orderByAsc(siblings, "sequence").filter((m) => m.id !== item.id)
  );
  if (item.isGroup && !keepChildren) {
    const subGroupCodes = getSubGroups(item, items).map((m) => m.code);
    items = items.filter(
      (m) =>
        !subGroupCodes.includes(m.parentGroupCode) &&
        m.parentGroupCode !== item.code
    );
  }
  return replaceGroupItems(item?.parentGroupCode, mapItems, items);
}

export function addNewRow(
  row: GROUP_OR_ITEM,
  siblings: GROUP_OR_ITEM[],
  items: GROUP_OR_ITEM[],
  defaultItem?: GROUP_OR_ITEM
) {
  const group = items.find((m) => m.code === row.parentGroupCode);
  let item = defaultItem || createItem();
  item.parentGroupCode = row.parentGroupCode;
  item.sequence = row.sequence + 1;
  item.team = group?.team ?? null;
  item.teamId = group?.teamId ?? null;
  item.level = row.level;
  item.subOf = row.subOf;
  const previousItems = siblings
    .filter((m) => m.sequence < item.sequence)
    .concat(item);
  const mapItems = previousItems.concat(mappingSiblings(item, siblings));
  return replaceGroupItems(row.parentGroupCode, mapItems, items);
}

export function updateChildrenLevel(
  row: GROUP_OR_ITEM,
  items: GROUP_OR_ITEM[]
) {
  let _items = items;
  const groupCodes = getSubGroups(row, items)
    .filter((m) => m.isGroup)
    .map((m) => m.id)
    .concat(row.code!);
  const childrens = _items.filter(
    (m) => m.parentGroupCode && groupCodes.includes(m.parentGroupCode)
  );
  childrens.forEach((m) => {
    const index = items.findIndex((row) => m.id === row.id);
    m.level = m.level + row.level;
    m.subOf = row.subOf?.concat(row.code!);
    _items[index] = m;
  });
  return _items;
}

export function mappingSiblings(
  item: GROUP_OR_ITEM,
  siblings: GROUP_OR_ITEM[]
) {
  const _siblings = siblings
    .filter((m) => m.sequence >= item.sequence)
    .map((m) => {
      return { ...m, sequence: m.sequence + 1 };
    });
  return _siblings;
}

export function onDrag(
  result: any,
  siblings: GROUP_OR_ITEM[],
  items: GROUP_OR_ITEM[]
) {
  if (!result.destination) {
    return items;
  }
  const parentGroupCode =
    items.find((m) => m.id === result?.draggableId)?.parentGroupCode ?? null;
  const mappItems = reorderSequence(
    arrayMoveImmutable(siblings, result.source.index, result.destination.index)
  );
  return replaceGroupItems(parentGroupCode, mappItems, items);
}

export function replaceGroupItems(
  parentGroupCode: string | null,
  mapItems: GROUP_OR_ITEM[],
  items: GROUP_OR_ITEM[]
) {
  const forReplacingItems = items.filter((m) =>
    !parentGroupCode ? m.level !== 1 : m?.parentGroupCode !== parentGroupCode
  );
  const preparedItems = forReplacingItems.concat(mapItems);
  return preparedItems;
}

export function getTotal(taxRate: number, items: GROUP_OR_ITEM[]) {
  const subTotla = sum(items, "total");
  const totalMarkup = sum(items, "markup");
  const totalSummary = subTotla + totalMarkup;
  const taxableItems = items.filter((m) => m.taxIncluded);
  const taxableAmount =
    sum(taxableItems, "total") + sum(taxableItems, "markup");
  const total = totalSummary + taxAmount(taxRate, taxableAmount);
  const tax = `${formatMoney(taxRate * 100)}% ($${formatMoney(
    taxAmount(taxRate, taxableAmount)
  )})`;
  return {
    tax,
    total,
    subTotla,
    totalMarkup,
    totalSummary,
    taxAmount,
    taxableItems,
    taxableAmount,
  };
}

export function toTreeData(items: GROUP_OR_ITEM[]) {
  const groups = [...items];
  let groupsWithCode = sortByMultiFields(groups, ["level"], ["asc"]);
  groupsWithCode.forEach((m, index) => {
    const parent = groupsWithCode.find((g) => m.parentGroupCode === g.code);
    if (m.isGroup === false) {
      m.displayCode = getGroupCodeToDisplay(
        m.sequence,
        parent?.displayCode ?? null
      );
      m.code = m.id;
    } else {
      m.displayCode = getGroupCodeToDisplay(
        m.sequence,
        parent?.displayCode ?? null
      );
    }
    groupsWithCode[index] = m;
  });
  const tree = arrayToTree(
    groupsWithCode.map((m) => {
      return {
        ...m,
        title: `${m.displayCode}. ${
          m.name || m.description || m.product?.name || "Item"
        }`,
        expanded: true,
      };
    }),
    {
      id: "code",
      parentId: "parentGroupCode",
    }
  );
  return tree;
}

export function productTypeToMarkUps(
  productTypes: PRODUCT_TYPE[],
  markups: MARKUP[],
  items: ITEM[]
) {
  const mappingMarkup: MARKUP_PAYLOAD[] = productTypes.map((m) => {
    const id = markups.find(
      (ma) => ma.productTypeId === m.id && ma.teamId === m.teamId
    )?.id;
    return {
      id: id ?? null,
      productTypeId: m.id ?? 0,
      rate: m.markupPercentage ?? 0,
      teamId: m.teamId,
    } as any;
  });
  return mappingMarkup.filter((m) => m.productTypeId !== 0);
}

export function noNextRelativeLevels(
  group: GROUP_OR_ITEM,
  allItems: GROUP_OR_ITEM[],
  groupItems: GROUP_OR_ITEM[],
  index: number
) {
  const relatives = getSubGroups(
    group,
    allItems.filter((m) => m.isGroup)
  );
  let levels = relatives
    .map((relativeGroup) => {
      const relativeItems: GROUP_OR_ITEM[] = allItems.filter(
        (m) =>
          m.isGroup === false &&
          m.parentGroupCode === relativeGroup.parentGroupCode
      );
      const index = relativeItems.findIndex((m) => {
        return m.parentGroupCode === relativeGroup.code;
      });
      if (relativeItems[index + 1] === undefined) {
        return relativeGroup.level - 1;
      } else {
        return 0;
      }
    })
    .filter((m) => m !== 0);
  if (groupItems[index + 1] === undefined) {
    levels = levels.concat(group.level - 1);
  }
  return levels;
}

export function updateIndividualItemMarkup(
  item: GROUP_OR_ITEM,
  items: GROUP_OR_ITEM[],
  productMarkupTypes:PRODUCT_TYPE[]
) {
  const productType = productMarkupTypes.find((m) =>
    item.teamId
      ? m.id === item?.product?.productType?.id && item.team?.id === m?.teamId
      : m.id === item?.product?.productType?.id
  );

  const index = items.findIndex((m) => m.id === item.id);
  if (productType) {
    const percentage = productType?.markupPercentage ?? 0;
    const markupAmount = ((item?.total ?? 0) * percentage) / 100;
    const totalAfterMarkup = markupAmount + (item?.total ?? 0);
    const data = {
      ...item,
      markup: markupAmount,
      totalAfterMarkup: totalAfterMarkup,
    };
    items[index] = data;
  }
  return items;
}



export function normalizeSequences(items: GROUP_OR_ITEM[]): GROUP_OR_ITEM[] {
  const sequenceMap: Record<string, number> = {};

  const sortedItems = items.sort((a, b) => {
    return a.parentGroupCode === b.parentGroupCode ? a.sequence - b.sequence : (a.parentGroupCode || '') > (b.parentGroupCode || '') ? 1 : -1;
  });

  return sortedItems.map(item => {
    const parentId = item.parentGroupCode || 'root';
    if (!sequenceMap[parentId]) {
      sequenceMap[parentId] = 1; 
    } else {
      sequenceMap[parentId]++;
    }
    return {
      ...item,
      sequence: sequenceMap[parentId], 
    };
  });
}