import { HiPlusCircle } from "react-icons/hi2";
import MainButton from "../MainButton";
import SearchInput from "../SearchInput";
import UpdateEntity from "../entity/UpdateEntity";

interface Props {
  onAdd?: () => void;
  onChange: (text: string) => void;
  addLabel?: string;
  showAll?: boolean;
  onShowAll?: () => void;
  onShowActive?: () => void;
  entityCode?: string;
}

export default function TableHeader(props: Props) {
  return (
    <div className="w-full  h-12 flex justify-between">
      <div className="h-80 w-96">
        <SearchInput onChange={(e) => props.onChange(e.target.value)} />
      </div>
      <div className="flex">
        {props.onShowActive ? (
          <div className="w-45 mr-2 ml-2">
            <MainButton
              variant={props.showAll ? "outline" : "default"}
              onClick={() => props.onShowActive && props.onShowActive()}
              label={`Active`}
              type="button"
            />
          </div>
        ) : null}
        {props.onShowAll ? (
          <div className="w-45 mr-2">
            <MainButton
              variant={!props.showAll ? "outline" : "default"}
              onClick={() => props.onShowAll && props.onShowAll()}
              label={`Show All`}
              type="button"
            />
          </div>
        ) : null}
        {props.onAdd ? (
          <MainButton
            variant={"outline"}
            icon={HiPlusCircle}
            onClick={() => props.onAdd && props.onAdd()}
            type="button"
          />
        ) : null}
        {props.entityCode ? (
          <UpdateEntity entityCode={props.entityCode} />
        ) : null}
      </div>
    </div>
  );
}
