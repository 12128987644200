import { IconType } from "react-icons";
import { useEffect, useState } from "react";
import React from "react";
import AutoCompleteView from "./AutoCompleteView";
import { staticSearch } from "src/utilities/mapping.utilities";

interface Props {
  icon?: IconType;
  label?: string;
  placeholder?: string;
  onIconClick?: () => void;
  path?: string;
  onAdd?: () => void;
  required?: boolean;
  onSelectedValue: (value: any) => void;
  value: any;
  disabled?: boolean;
  hideBorder?: boolean;
  data: any[];
  errorMessage?:string
  disablePortal?:boolean;
}

export function AutoCompleteStatic(props: Props) {
  const [hasOpened, setOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [text, setText] = React.useState("");

  useEffect(() => {
    if (hasOpened && data.length === 0) {
      setData(props.data);
    }
  }, [hasOpened]);

  useEffect(() => {
    const data = staticSearch(props.data,text);
    setData(data)
  }, [text]);

  return (
    <AutoCompleteView
      errorMessage = {props.errorMessage}
      hideBorder={props.hideBorder}
      disabled={props.disabled}
      value={props.value}
      onSelectedValue={props.onSelectedValue}
      required={props.required}
      onOpenChange={(v) => setOpen(v)}
      data={data}
      setText={(t) => setText(t)}
      label={props.label}
      placeholder={props.placeholder}
      icon={props.icon}
    />
  );
}
