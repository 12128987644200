import { useEffect, useState } from "react";
import AddressContent from "src/components/address/AddressContent";
import { MAP_PROPERTY } from "src/components/autoComplete/AutoCompleteAddress";
import { Checkbox } from "src/components/CheckBox";
import { ADDRESS } from "src/interfaces/customer.interface";
import { COUNTRY } from "src/interfaces/init.interface";
import { nullString } from "src/utilities/functions.utilities";

interface Props {
  value: ADDRESS | null;
  label: string;
  otherValue?: ADDRESS | null;
  hasSameAs?: boolean;
  sameAsLabel?: string;
  onChange: (data: ADDRESS) => void;
  sameAsBillingAddress?: boolean;
  onSameAS?: (v: boolean) => void;
}

export default function Address(props: Props) {
  const [line, setLine] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [latlong, setLatLong] = useState("");
  const [sameAs, setSameAs] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const { line1, city, postalCode, country, countryCode, state, stateCode } =
      props.value ?? {};
    setLine(line1 ?? "");
    setCity(city ?? "");
    setPostal(postalCode ?? "");
    setCountry(country ?? "");
    setCountryCode(countryCode ?? "");
    setState(state ?? "");
    setStateCode(stateCode ?? "");
    setSameAs(props.sameAsBillingAddress ?? false);
    setTimeout(() => setReady(true), 500);
  }, []);

  useEffect(() => {
    if (!ready) return;
    if (!props.sameAsBillingAddress) {
      setLine("");
      setCity("");
      setPostal("");
      setCountry("");
      setCountryCode("");
      setState("");
      setStateCode("");
    }
  }, [props.sameAsBillingAddress]);

  useEffect(() => {
    if (!props.sameAsBillingAddress) return;
    setLine(props.otherValue?.line1 ?? "");
    setCity(props.otherValue?.city ?? "");
    setPostal(props.otherValue?.postalCode ?? "");
    setCountry(props.otherValue?.country ?? "");
    setCountryCode(props.otherValue?.countryCode ?? "");
    setState(props.otherValue?.state ?? "");
    setStateCode(props.otherValue?.stateCode ?? "");
  }, [props.otherValue, props.sameAsBillingAddress]);

  useEffect(() => {
    onSubmit();
  }, [line, city, postal, country, countryCode, state, stateCode, sameAs]);

  const onSelectedLine = (line: string, data: MAP_PROPERTY | null) => {
    setLine(line);
    if (data) {
      setCountry(data.country);
      setCountryCode(data.country_code);
      setState(data.state);
      setStateCode(data.state_code);
      setPostal(data.postcode);
      setCity(data.city);
      data.lat && data.lon
        ? setLatLong(`${data?.lat},${data?.lon}`)
        : setLatLong("");
    }
  };

  const onSelectedState = (line: string, data: MAP_PROPERTY | null) => {
    setState(data?.state ?? "");
    setStateCode(data?.state_code ?? "");
  };

  const onSelectedCountry = (country: COUNTRY) => {
    setCountry(country.name);
    setCountryCode(country.code);
  };

  const onSubmit = () => {
    const data: ADDRESS = {
      latLong: nullString(latlong),
      line1: nullString(line),
      city: nullString(city),
      stateCode: null,
      state: nullString(state),
      countryCode: null,
      country: nullString(country),
      postalCode: nullString(postal),
    };
    props.onChange(data);
    if (props.onSameAS) {
      props.onSameAS(sameAs);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-1">
        <span className="font-semibold mr-2">{props.label}</span>
        {props.hasSameAs ? (
          <Checkbox
            value={sameAs ?? false}
            onChange={(v) => setSameAs(v)}
            label={props.sameAsLabel ?? "Same as billing address"}
          />
        ) : null}
      </div>
      <AddressContent
        disabled={sameAs}
        onSelectedCountry={onSelectedCountry}
        onSelectedLine={onSelectedLine}
        onSelectedState={onSelectedState}
        setCity={(t) => setCity(t)}
        setPostal={(t) => setPostal(t)}
        state={state}
        city={city}
        country={country}
        postal={postal}
        line={line}
      />
    </div>
  );
}
