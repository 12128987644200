import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import MainButton from "src/components/MainButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Input } from "src/components/ui/input";
import { TableCell } from "src/components/ui/table";
import { UDF_LINK, UDF_LIST } from "src/interfaces/udf.interface";
import {
  onCreateUdfList,
  onGetUdfList,
  onUdfListReorder,
} from "src/services/udf.service";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { reorderSequence } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";
import { Reorder } from "framer-motion";
import { ValueListItem } from "./ValueListItem";

interface Props {
  udfId: string;
  value: UDF_LINK | null;
  onSave: (v: any, onSuccess: () => void) => void;
}

export const ValueList = (props: Props) => {
  const [isSelected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const [udfList, setUdfList] = useState<UDF_LIST[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [addLabel, setAddLabel] = useState("");
  const [edit, setEdit] = useState(false);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    if (props.value) {
      setSelectedValue(props.value?.value);
    }
    getUdfList(props.udfId);
  }, [props.value]);

  const getUdfList = (udfId: string) => {
    setLoading(true);
    onGetUdfList(udfId).then((res) => {
      if (res) {
        setUdfList(res.data);
      } else {
        setUdfList([]);
      }
      setLoading(false);
    });
  };

  const getFilteredList = () => {
    const results = orderByAsc(
      udfList.filter((s) => s.value.includes(addLabel)),
      "sequence"
    );
    return results;
  };

  const addUdfList = (value: string) => {
    setAdding(true);
    const doc: UDF_LIST = {
      value: value,
      backgroundColor: "C4C4C4",
      selected: false,
      sequence: udfList.length,
    };
    onCreateUdfList(props.udfId, doc).then((data) => {
      if (data) {
        setUdfList([...udfList, data]);
        setAddLabel("");
      }
      setAdding(false);
    });
  };

  const onSave = (value: string) => {
    setLoading(true);
    props.onSave(value, () => {
      setLoading(false);
      setAddLabel("");
    });
  };
  const onUpdated = (udf: UDF_LIST) => {
    const index = udfList.findIndex((m) => m.id === udf.id);
    let newData = [...udfList];
    newData[index] = udf;
    setUdfList(newData);
  };
  const onDeleted = (udf: UDF_LIST) => {
    setUdfList(udfList.filter((m) => m.id !== udf.id));
  };

  const onReorder = (result: any) => {
    const reordered = reorderSequence(result);
    setUdfList(reordered);
    if (timer) {
      clearTimeout(timer);
    }

    const timeOut = setTimeout(() => {
      onUdfListReorder(props.udfId, reordered).then((res) => {
        if (res === null) return;
      });
    }, 2000);
    setTimer(timeOut);
  };

  const renderList = () => {
    return (
      <Reorder.Group axis="y" values={getFilteredList()} onReorder={onReorder}>
        {getFilteredList().map((item, index) => (
          <Reorder.Item key={item.id} value={item}>
            <ValueListItem
              item={item}
              lastIndex={index === getFilteredList().length - 1}
              active={selectedValue === item.value}
              isEdit={edit}
              onItem={() => {
                setSelectedValue(item.value);
                onSave(item.value);
              }}
              onUpdated={onUpdated}
              onDeleted={onDeleted}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    );
  };

  return (
    <TableCell className="border-r-[0.9px] p-0 px-[1px]">
      <div className="relative flex">
        <DropdownMenu
          onOpenChange={(value) => {
            setSelected(!isSelected);
            if (edit === true) {
              setEdit(false);
            }
          }}
        >
          <DropdownMenuTrigger asChild>
            <div
              className={`${
                isSelected ? "border-blue-600 border-[1px] rounded-sm" : ""
              }`}
            >
              <Input
                value={selectedValue}
                className={`min-h-[46px] hover:bg-white cursor-pointer border-0 caret-transparent`}
                type="text"
                placeholder="Enter text"
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[338px] h-96 p-4 pt-2 pb-0">
            <Input
              autoFocus
              type="text"
              className={"my-2"}
              placeholder={`Create label`}
              value={addLabel}
              onChange={(e) => {
                setAddLabel(e.target.value);
              }}
            />
            {addLabel ? (
              <div className={`overflow-auto h-[190px] mt-2`}>
                {renderList()}
              </div>
            ) : (
              <div className={`overflow-auto h-[225px] mt-2`}>
                {renderList()}
              </div>
            )}
            {addLabel ? (
              <MainButton
                disabled={
                  udfList.find((m) => m.value === addLabel)?.value === addLabel
                }
                loading={adding}
                label="Create as a new label"
                className="mt-2"
                onClick={() => {
                  addUdfList(addLabel);
                }}
              />
            ) : null}
            <div className="border-t-[1px] my-2" />
            <div
              onClick={() => {
                setEdit(!edit);
              }}
              className="cursor-pointer rounded-sm py-2 flex justify-center items-center hover:bg-gray-100"
            >
              {edit ? "Apply" : "Edit Label"}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
        {loading ? (
          <Loader2
            color={COLORS.primary}
            className="h-4 w-4 animate-spin absolute right-2 top-5"
          />
        ) : null}
      </div>
    </TableCell>
  );
};
