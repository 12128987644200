import { useEffect, useState } from "react";
import DepartmentListScreen from "./DepartmentListScreen";
import { deepStaticSearch } from "src/utilities/mapping.utilities";
import { DEPARTMENT } from "src/interfaces/team.interface";
import { STEP } from "src/components/Stepper";
import { staticList } from "src/APIs/table.api";

export const MEMBER_ENTITY = {
  id: 4,
  code: "user",
  name: "User Management",
  entityConfigurations: [
    {
      id: "7f6b7c53-d5a5-46ef-9f31-e129ad93d40c",
      displayName: "Full Name",
      columnName: "fullName",
      sequence: 1,
      visible: true,
    },
    {
      id: "eb514253-4f84-4ce8-82e6-5658b8441",
      displayName: "Role",
      columnName: "roleName",
      sequence: 2,
      visible: true,
    },
    {
      id: "eb514253-4f84-4ce8-82e6-5658b8446ss",
      displayName: "Team",
      columnName: "teamName",
      sequence: 3,
      visible: true,
      hasLeaderMenu: true,
    },
  ],
};

const status_buttons: STEP[] = [
  {
    id: 1,
    name: "Active",
    sequence: 1,
  },
  {
    id: 2,
    name: "In-Active",
    sequence: 2,
  },
  {
    id: 3,
    name: "Show All",
    sequence: 3,
  },
];

function DepartmentList() {
  const [loading, setLoading] = useState(true);
  const [departmentList, setDepartmentList] = useState<DEPARTMENT[]>([]);
  const [data, setData] = useState<DEPARTMENT[]>([]);
  const [search, setSearch] = useState<string>("");
  const [selectedStatus, setStatus] = useState(status_buttons[0]);

  useEffect(() => {
    staticList("department/detail").then((data) => {
      if (data.code !== "200") {
        setLoading(false);
        return;
      }
      setLoading(false);
      setDepartmentList(data.data);
    });
  }, []);

  useEffect(() => {
    setData(
      departmentList.filter((m) =>
        selectedStatus.id === 1 ? m.enable : true
      ) ?? []
    );
  }, [departmentList]);

  function onSearch(text: string) {
    setSearch(text);
    if (!text) {
      setData(departmentList ?? []);
      return;
    } else {
      setData(deepStaticSearch(departmentList ?? [], text, "users"));
    }
  }

  function onClearSearch() {
    setSearch("");
    setData(deepStaticSearch(departmentList ?? [], "", "users"));
  }

  return (
    <DepartmentListScreen
      createdDepartment={(department) => {
        setDepartmentList([department, ...departmentList]);
      }}
      afterAddedMembers={(departments) => {
        setDepartmentList(departments);
      }}
      deletedItem={(item) => {
        setDepartmentList(departmentList.filter((m) => m.id !== item.id));
      }}
      onSearch={onSearch}
      search={search}
      onClearSearch={onClearSearch}
      processing={loading ?? false}
      departments={data ?? []}
      headerButtons={status_buttons}
      active={selectedStatus}
      onHeaderButton={(st) => {
        setStatus(st);
        if (st.id === 1) {
          setData(departmentList.filter((m) => m.enable));
        } else if (st.id === 2) {
          setData(departmentList.filter((m) => !m.enable));
        } else {
          setData(departmentList);
        }
      }}
      totalInactive={departmentList.filter((m) => !m.enable).length}
      totalActive={departmentList.filter((m) => m.enable).length}
    />
  );
}

export default DepartmentList;
