import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi2";
import { RxDragHandleDots2 } from "react-icons/rx";
import MainInput from "src/components/MainInput";
import { Label } from "src/components/ui/label";
import { LEAD_STAGE } from "src/interfaces/customer.interface";
import CRMStore from "src/stores/crm.store";
import { formatMoney } from "src/utilities/formatter.utilities";
import { Loader2 } from "lucide-react";
import { CRM_TYPES } from "src/constants/crm.constants";

interface Props {
  crm?: CRMStore;
  active: boolean;
  isLastIndex: boolean;
  item: LEAD_STAGE;
}

const CrmStageTabItem = (props: Props) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (props.item) {
      setName(props.item.name);
    }
  }, [props.item.name]);

  return (
    <div
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={() => {
        props.crm?.onChangeStage(CRM_TYPES.lead, props.item);
        setEditing(false);
      }}
      className={`${
        props.active ? "bg-white" : "bg-gray-200 pr-5"
      } cursor-pointer ${
        props.isLastIndex ? "mr-0" : "mr-0"
      } py-1 px-1 flex items-center w-full rounded-t-md justify-between relative`}
    >
      {editing ? (
        <div className="w-full flex items-center">
          <div className="w-full">
            <MainInput
              focus
              noMarginTop
              placeholder="Status name"
              value={name}
              onChange={setName}
            />
          </div>
          <div
            onClick={
              props.crm?.processingUpdateStage
                ? undefined
                : (e) => {
                    e.stopPropagation();
                    const stage = {
                      ...props.item,
                      name: name,
                    };
                    props.crm?.onUpdateStageName(stage, () => {
                      setEditing(false);
                    });
                  }
            }
            className="p-2 ml-1 mr-4 hover:bg-muted hover:rounded-full"
          >
            {props.crm?.processingUpdateStage ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <HiCheck className="h-4 w-4" />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex justify-center">
            {editing ? null : (
              <Label
                onClick={
                  props.active
                    ? (e) => {
                        setEditing(true);
                        e.stopPropagation();
                      }
                    : undefined
                }
                className={`text-[11px] ${
                  props.active
                    ? "hover:bg-muted hover:px-3 hover:rounded-sm cursor-text"
                    : "cursor-pointer"
                }`}
              >
                {name}
              </Label>
            )}
          </div>
          <div className=" text-[10px] text-center">
            QTY {props.item?.totalLead} $
            {formatMoney(props.item?.dealValue ?? 0)}
          </div>
        </div>
      )}
      {mouseOver ? (
        <RxDragHandleDots2 className="h-3 w-3 text-black cursor-pointer absolute right-2" />
      ) : null}
    </div>
  );
};
export default inject("crm")(observer(CrmStageTabItem));
