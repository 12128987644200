import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainTable from "src/components/tableComponents/MainTable";

export default function EstimateContact() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden m-4 p-5 bg-white">
      <MainTable
        addLabel="Create Estimate"
        onAdd={() => navigate(`/create-estimate?contact=${id}`)}
        height={height - 40}
        onClick={(row: any) => navigate(`/estimate-details/${row?.id}`)}
        entityCode="est"
        path={`customer/${id}/get-estimate`}
      />
    </div>
  );
}
