import { makeAutoObservable, observable, action } from "mobx";
import { updateObjArray } from "src/utilities/functions.utilities";
import {  staticSearch } from "src/utilities/mapping.utilities";

export default class TableStore {
  @observable data: any[] = [];
  @observable rawData: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action searchData(text:string){
    if(text){
      this.data = staticSearch(this.rawData,text)
    }else{
      this.data = this.rawData
    }
  }

  @action setData(data: any[]) {
    this.data = data;
    this.rawData = data;
  }

  @action updateTable(doc: any) {
    this.data = [doc, ...this.data];
    this.rawData = this.data;
  }

  @action updateData(doc:any){
    this.data = [...updateObjArray(this.data, doc)];
    this.rawData = this.data;
  }
}
