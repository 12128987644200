import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { COLORS } from "../assets/colors";
import { LAYOUTS } from "../assets/layouts";
import MainButton from "./MainButton";

interface Props {
  cancelLabel: string;
  continueLabel: string;
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  title: string;
  description: string;
  useDontAskMeAgain?: boolean;
  localKey?: string;
  isTop?: boolean;
  useWarning?: boolean;
}

function MuiOptionsDialog(props: Props) {
  useEffect(() => {
    return () => {};
  }, [props.isOpen]);
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={
        props.isTop ? { sx: { mt: "-500px", verticalAlign: "top" } } : undefined
      }
    >
      <DialogTitle
        style={{
          fontWeight: "bold",
          color: COLORS.text,
          minWidth: 400,
          fontSize: 16,
          paddingBottom: 0,
        }}
      >
        {props.title}
      </DialogTitle>
      {props.description ? (
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions style={{ paddingBottom: LAYOUTS.padding }}>
          <MainButton
            variant={"outline"}
            label={props.cancelLabel}
            onClick={() => props.onClose()}
          />
          <MainButton
            variant={"destructive"}
            label={props.continueLabel}
            onClick={() => props.onContinue()}
          />
      </DialogActions>
    </Dialog>
  );
}

export default MuiOptionsDialog;
