import { COLORS } from "src/assets/colors";
import { SPACE_ROW } from "./ProductDetails";

interface Props {
  level: number;
  siblingsLevel: number[];
  isLast: boolean;
  isItem?: boolean;
  hover?: boolean;
  rowId: string;
}

export default function Tree(props: Props) {
  const { level, siblingsLevel, isLast, hover } = props;
  return (
    <div
      style={{
        width:
          level === 1
            ? SPACE_ROW - 20
            : SPACE_ROW * level + (props.isItem ? 3 : 0) - 20,
      }}
    >
      {Array.from({ length: level }, (v, k) => k + 1).map((lv) => {
        if (lv === 1 || (!siblingsLevel.includes(lv) && lv !== level)) return;
        return (
          <div
            style={{
              width: 2,
              backgroundColor: COLORS.border,
              bottom: level === lv && isLast ? 24.5 : 0,
              top: 0,
              left: SPACE_ROW * lv - 1 + SPACE_ROW - 6 - 20,
              position: "absolute",
            }}
          />
        );
      })}
      {level === 1 ? null : (
        <div
          style={{
            width: props.isItem && !hover ? 28 : 25,
            backgroundColor: COLORS.border,
            height: 2.2,
            position: "absolute",
            left: SPACE_ROW * level - 1 + SPACE_ROW - 4.3 - 21.5,
            transition: "all 100ms",
          }}
        />
      )}
     { props.isItem && level !== 1  ?  <div
        style={{
          opacity: hover ? 0.3 : 1,
          width: 2,
          backgroundColor: COLORS.border,
          top: 0,
          bottom:0,
          left: SPACE_ROW * level - 1 + SPACE_ROW + 4,
          position: "absolute",
          zIndex:1
        }}
      /> : null}
    </div>
  );
}
