import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../../components/ui/sheet";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { HiXMark } from "react-icons/hi2";
import {
  PERMISSION,
  PERMISSION_CLASSIFICATION,
  ROLE,
} from "src/interfaces/init.interface";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { nullString } from "src/utilities/functions.utilities";
import { AlertDestructive } from "src/components/AlertDestructive";
import MemberStore from "src/app/team/member/tables/MemberStore";
import PermissionSetup from "src/app/team/member/PermissionSetup";
import MainInput from "src/components/MainInput";
import TableStore from "src/components/tableComponents/TableStore";
import { createRole, updateRole } from "src/APIs/admin.api";
import { details } from "src/APIs/table.api";

interface Props {
  disabled?: boolean;
  table?: TableStore;
  auth?: AuthStore;
  open: boolean;
  onOpen: (value: boolean) => void;
  member?: MemberStore;
  role?: ROLE | null;
}

function AddRole(props: Props) {
  const [roleName, setRoleName] = useState("");
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [loadingRole, setLoadingRole] = useState(false);

  const [permissions, setPermission] = useState<PERMISSION[]>([]);
  const [permissionClassifications, setPermissionClassifications] = useState<
    PERMISSION_CLASSIFICATION[]
  >([]);

  useEffect(() => {
    const { permissionClassifications } = props.auth ?? {};
    const _permissionClassifications = orderByAsc(
      permissionClassifications ?? [],
      "sequence"
    );
    setPermissionClassifications(_permissionClassifications);
    if (props.role) {
      setRoleName(props.role.name);
      onChangeRole(props.role);
    }
    return () => {
      setRoleName("");
      setErrorMsg([]);
      setPermission([]);
      setPermissionClassifications([]);
    };
  }, [props.auth?.permissionClassifications, props.open]);

  function onPermission(v: PERMISSION_CLASSIFICATION, permission: PERMISSION) {
    if (permissions.find((m) => m.id === permission.id)) {
      if (permission.parentId !== null) {
        setPermission(
          permissions.filter(
            (m) =>
              m.id !== permission.id &&
              m.parentId !== permission.id &&
              m.id !== permission.parentId
          )
        );
        return;
      }
      setPermission(
        permissions.filter(
          (m) => m.id !== permission.id && m.parentId !== permission.id
        )
      );
    } else {
      setPermission(
        permissions.concat(
          v.permissions.filter(
            (m) => m.id === permission.id || m.parentId === permission.id
          )
        )
      );
    }
  }

  function onChangeRole(role: ROLE) {
    if (!role) {
      setPermission([]);
    } else {
      setLoadingRole(true);
      role?.id &&
        details("role", role?.id).then((doc) => {
          if (doc.code !== "200") {
            setLoadingRole(false);
            return;
          }
          setLoadingRole(false);
          setPermission(doc?.data.permissions);
        });
    }
  }

  function isValid() {
    let msg: string[] = [];
    const isErrorLastName = nullString(roleName ?? "") === null;
    if (isErrorLastName) msg = msg.concat("Please enter Role Name \n");

    return msg;
  }

  function onCreate() {
    const validMessage = isValid();
    if (validMessage.length > 0) {
      setErrorMsg(validMessage);
      return;
    }
    setProcessing(true);
    setErrorMsg([]);
    const role = props.role;
    const doc: ROLE = {
      id: role?.id,
      name: roleName ?? "",
      permissions: permissions,
    };
    if (role?.id) {
      updateRole(doc).then((result) => {
        if (!result?.data) {
          setProcessing(false);
          return;
        }
        props.table?.updateData(result?.data);
        setProcessing(false);
        props.onOpen(false);
      });
    } else {
      createRole(doc).then((result) => {
        if (!result?.data) {
          setProcessing(false);
          return;
        }
        props.table?.updateData(result?.data);
        setProcessing(false);
        props.onOpen(false);
      });
    }
  }

  return (
    <Sheet onOpenChange={(v) => props.onOpen(v)} open={props.open}>
      <SheetContent className=" overflow-auto h-[99%] w-[40%]">
        <SheetHeader className="flex items-center flex-row justify-between">
          <SheetTitle>
            {props.role?.id ? "Update Role" : "Create New Role"}
          </SheetTitle>
          <SheetClose>
            <HiXMark size={22} />
          </SheetClose>
        </SheetHeader>
        {errorMsg.length > 0 ? (
          <div className="mb-4 mt-4 bg-white drop-shadow rounded-md">
            <AlertDestructive title="Missing Information" messages={errorMsg} />
          </div>
        ) : null}
        <div className="flex flex-1 gap-4">
          <MainInput
            required
            placeholder="Enter role name"
            value={roleName}
            onChange={setRoleName}
            label="Role Name"
          />
        </div>
        <PermissionSetup
          enable
          permissions={permissions}
          permissionClassifications={permissionClassifications}
          onPermission={onPermission}
          processing={loadingRole}
          role={props.role || null}
        />
        <SheetFooter>
          <div className="w-52">
            <MainButton
              variant={"outline"}
              type="button"
              label="Cancel"
              onClick={() => props.onOpen(false)}
            />
          </div>
          <div className="w-52">
            <MainButton
              loading={processing}
              type="button"
              label="Save"
              onClick={() => onCreate()}
            />
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

export default inject("auth", "table")(observer(AddRole));
