import InputPhone from "src/components/InputPhone";
import MainInput from "src/components/MainInput";

export default function AssociatedContact() {
  return (
    <div>
      <div className="flex items-center">
        <span className="font-semibold">Associated Person</span>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter full name"
            value={""}
            onChange={(t) => null}
            label="Full Name"
          />
        </div>
        <div className="flex flex-1 ">
          <MainInput
            placeholder="Enter relationship"
            value={""}
            onChange={(t) => null}
            label="Relationship"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter email"
            value={""}
            onChange={(t) => null}
            label="Email"
          />
        </div>
        <div className="flex flex-1 ">
          <InputPhone
            placeholder="Enter phone"
            value={""}
            onChange={(t) => null}
            label="Phone"
          />
        </div>
      </div>
    </div>
  );
}
