import {
  PhoneInput,
  guessCountryByPartialPhoneNumber,
} from "react-international-phone";
import { AsYouType } from "libphonenumber-js";
import { Label } from "./ui/label";
import { useEffect, useState } from "react";
import { phoneOnly } from "src/utilities/formatter.utilities";
import { isPhoneValid } from "src/utilities/functions.utilities";

interface Props {
  label?: string;
  required?: boolean;
  placeholder?: string;
  value: string | null;
  onChange: (t: string) => void;
  onBlur?: () => void;
  onEnter?: () => void;
}

export function TelephoneInput(props: Props) {
  const [text, setText] = useState("");
  useEffect(() => {
    if (!props.value) return;
    const phone = new AsYouType().input(props.value);
    const country = guessCountryByPartialPhoneNumber({ phone });
    if (!country?.country) {
      setText(phone);
    } else {
      setText(phone);
    }
  }, [props.value]);

  return (
    <div className={props.label ? "mt-4 w-full" : "w-full"}>
      {props.label ? (
        <Label>
          {props.label}
          {props.required ? <span className="text-destructive">*</span> : null}
        </Label>
      ) : null}
      <PhoneInput
        onChange={(e) => {
          const phone = phoneOnly(e);
          setText(phone);
        }}
        defaultCountry="us"
        inputProps={{
          onKeyDownCapture: (e) => {
            if (e.key === "Enter") {
              props.onEnter && props.onEnter();
            }
          },
          onBlur: () => {
            props.onBlur && props.onBlur();
            if (text.includes("+")) {
              const phone = new AsYouType().input(text);
              if (!isPhoneValid(phone)) {
                return;
              }
              setText(phone);
              props.onChange && props.onChange(phone.replaceAll(" ", ""));
            } else {
              const phone = new AsYouType().input(`+1${text}`);
              if (!isPhoneValid(phone)) {
                return;
              }
              setText(phone);
              props.onChange && props.onChange(phone.replaceAll(" ", ""));
            }
          },

          className:
            "flex mt-2 h-9 w-full rounded-md border border-gray-300 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50 bg-white",
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            borderWidth: 0,
            backgroundColor: "transparent",
            alignItems: "start",
          },
          dropdownStyleProps: {
            className: "rounded",
          },
          className:
            "mr-2 mt-2 flex h-9 bg-white  rounded-md border border-gray-300 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50",
        }}
        className="mt-0"
        value={text}
      />
    </div>
  );
}
