import { INavigationBarItem } from "../navigationBar/NavigationBarItem";
import { Link, useLocation } from "react-router-dom";

interface Props {
  routes: INavigationBarItem[];
  className?: string | null;
  noActiveBorder?: boolean;
}

export default function SubSidebar(props: Props) {
  const pathname = useLocation().pathname;
  return (
    <nav
      className={
        props.className ??
        "flex items-center justify-between p-3 lg:px-4 drop-shadow bg-white"
      }
      aria-label="Global"
    >
      <div className="flex gap-x-4">
        {props.routes.map((item) => {
          const isActive = pathname.includes(item.route);
          return (
            <Link
              relative="route"
              to={`${item.route}`}
              key={item.id}
              className={`relative text-sm font-semibold leading-6 text-gray-900 ${
                !isActive ? "opacity-35" : "opacity-100"
              } ${
                isActive ? "" : "hover:opacity-65"
              } cursor-pointer min-w-20 text-center`}
            >
              {item.name}
              {props.noActiveBorder ? null : (
                <div
                  className={`${
                    isActive ? "w-full" : "w-0"
                  } transition-all bg-primary h-1 absolute bottom-[-12.5px] rounded drop-shadow`}
                />
              )}
            </Link>
          );
        })}
      </div>
    </nav>
  );
}
