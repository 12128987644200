import { useState } from "react";
import { HiOutlinePlusSmall, HiXMark } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Label } from "src/components/ui/label";
import ProposalMainInput from "../dynamicComponent/ProposalMainInput";
import MainInputNoState from "src/components/MainInputNoState";

interface Props {
  onAdd: (title: string) => void;
}

export const AddDateButton = (props: Props) => {
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <HiOutlinePlusSmall size={18} className="mr-1" /> Add Date
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-80 p-4">
        <div className="flex justify-between">
          <Label className="font-bold text-black">Add Date Title</Label>
          <HiXMark
            size={18}
            onClick={() => {
              setOpen(!open);
            }}
            className="cursor-pointer"
          />
        </div>
        <MainInputNoState
          label="Date Title"
          placeholder="Enter title of date"
          value={title}
          onChange={setTitle}
        />
        <MainButton
          disabled={title === ""}
          label="Add Date"
          className="bg-secondary mt-6"
          onClick={() => {
            props.onAdd(title);
            setOpen(!open);
            setTitle("");
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
