import * as Icons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as Io5Icons from "react-icons/io5";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as TbIcons from "react-icons/tb";



export default function CrmRenderIcon ({name,size,color}){
    let IconComponent = Icons[name];
    if(!IconComponent){
        IconComponent = IoIcons[name];
    }
    if(!IconComponent){
        IconComponent = Io5Icons[name];
    }
    if(!IconComponent){
        IconComponent = FaIcons[name];
    }
    if(!IconComponent){
        IconComponent = RiIcons[name];
    }
    if(!IconComponent){
        IconComponent = BiIcons[name];
    }
    if(!IconComponent){
        IconComponent = BsIcons[name];
    }
    if(!IconComponent){
        IconComponent = TbIcons[name];
    }
    if(!IconComponent){
        IconComponent = MdIcons[name];
    }
    if (!IconComponent) { // Return a default one
      return <IoIcons.IoIosCube />;
    }
    return <IconComponent size = {size} color = {color} />;
};