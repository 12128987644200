import { META_DATA } from "src/interfaces/proposal.interface";
import { ContentTitle } from "../ContentTitle";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import { COMPONENT_TYPE } from "src/constants";
import { Label } from "src/components/ui/label";
import RichTextEditor from "src/components/richTextEditor";

interface Props {
  meta: META_DATA;
  onChange: (child: META_DATA) => void;
}

export const TermsSection = (props: Props) => {
  return (
    <div>
      <ContentTitle title={props.meta?.label} />
      {props.meta.children.map((item) => {
        if (item.type === COMPONENT_TYPE.rich_text.key) {
          return (
            <div className="mt-6">
              <Label className="font-semibold text-gray-500">
                {item.label}
              </Label>
              <div className="border-[1px] rounded-sm mt-4">
                <RichTextEditor
                  key={item.key}
                  autoFocus
                  value={item.value}
                  onChange={(text) => {
                    const data = {
                      ...item,
                      value: text,
                    };
                    props.onChange(data);
                  }}
                />
              </div>
            </div>
          );
        }
        return (
          <DynamicInput
            key={item.id}
            onChange={(text) => {
              const data = {
                ...item,
                value: text,
              };
              props.onChange(data);
            }}
            meta={item}
          />
        );
      })}
    </div>
  );
};
