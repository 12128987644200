import { Outlet } from "react-router-dom";
import SubSidebar from "src/components/subSidebar/SubSidebar";
import { product_config } from "src/routes/routeNav.config";


export default function ProductAndConfigLayout() {
  return (
    <div className="flex flex-1 flex-col">
      <SubSidebar routes={product_config} />
      <Outlet />
    </div>
  );
}
