import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LoginPage from "../app/auth/login";
import HomePage from "src/app/home";
import { inject, observer } from "mobx-react";
import {
  getAuth,
  getPreviousPath,
  getToken,
  savePreviousPath,
} from "src/utilities/storage.utilities";
import AuthStore from "src/stores/auth.store";
import MainLayout from "src/app/main";
import NotFound from "src/app/notFound";
import EstimateLayout from "src/app/estimate";
import EstimateDetails from "src/app/estimate/estimateList/estimateDetails";
import {
  contact,
  estimate,
  lead,
  member,
  product_config,
  proposal,
  teams,
  user_defined,
} from "./routeNav.config";
import MemberLayout from "src/app/team/member";
import TeamLayouts from "src/app/team";
import MemberDetails from "src/app/team/memberDetails";
import DepartmentList from "src/app/team/department";
import TeamListLayout from "src/app/team/team";
import CRMLayout from "src/app/crm";
import ContactPageList from "src/app/crm/contact";
import ContactDetails from "src/app/crm/contact/contactDetails";
import AdminLayout from "src/app/admin";
import RoleListPage from "src/app/admin/role";
import UserDefinedLayout from "src/app/admin/userDefined";
import ProductAndConfigLayout from "src/app/productAndConfigure";
import { ProposalDetailLayout } from "src/app/estimate/proposal/proposalDetail";
import CreatePresetGroup from "src/app/productAndConfigure/presetGroupList/CreatePresetGroup";
import LeadLayout from "src/app/crm/lead";
import ProposalLayout from "src/app/estimate/proposal";
import EstimateAndInvoicesLayout from "src/app/estimate/estimateAndInvoicesLayout";
import NoInternet from "src/app/noInternet";

interface Props {
  auth?: AuthStore;
}

function RootRouter(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const authCookie = getAuth();
  const { authData } = props.auth ?? {};
  useEffect(() => {
    if (authCookie) {
      props.auth?.onLoggedInSuccess(authCookie);
    }
  }, []);

  useEffect(() => {
    if (authData || authCookie) {
      const pathname = location.pathname;
      if (pathname === "/login" || pathname === "/") {
        const previousPath = getPreviousPath();
        navigate(previousPath === "/" ? "main" : previousPath || "/main", {
          replace: true,
        });
      }
    } else {
      navigate("login", { replace: true });
    }
  }, [authData]);

  useEffect(() => {
    const pathname = location.pathname;
    savePreviousPath(pathname);
  }, [location]);

  const isAuthenticated = () => !!(authData || authCookie);

  return (
    <Routes location={location}>
      <Route
        path="login"
        element={isAuthenticated() ? <Navigate to="/main" /> : <LoginPage />}
      />

      {isAuthenticated() ? (
        <>
          <Route path="*" element={<NotFound />} />
          <Route path="no-internet" element={<NoInternet />} />
          <Route path="main" element={<MainLayout />}>
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<HomePage />} />
            <Route
              path="estimate-and-invoices"
              element={<EstimateAndInvoicesLayout />}
            >
              <Route path="" element={<Navigate to="estimate" />} />
              <Route path="estimate" element={<EstimateLayout />}>
                <Route path="" element={<Navigate to={estimate[0].route} />} />
                {estimate.map((route) => (
                  <Route
                    key={route.id}
                    path={route.route}
                    element={<route.element />}
                  />
                ))}
              </Route>
              <Route path="proposal" element={<ProposalLayout />}>
                <Route path="" element={<Navigate to={proposal[0].route} />} />
                {proposal.map((route) => (
                  <Route
                    key={route.id}
                    path={route.route}
                    element={<route.element />}
                  />
                ))}
              </Route>
            </Route>
            <Route path="crm" element={<CRMLayout />}>
              <Route path="" element={<Navigate to="contact" />} />
              <Route path="contact" element={<ContactPageList />} />
            </Route>
            <Route path="team" element={<TeamLayouts />}>
              <Route path="members" element={<MemberLayout />}>
                <Route path="" element={<Navigate to={member[0].route} />} />
                {member.map((route) => (
                  <Route
                    key={route.id}
                    path={route.route}
                    element={<route.element type={route.type} />}
                  />
                ))}
              </Route>
              <Route path="teams" element={<TeamListLayout />}>
                <Route path="" element={<Navigate to={teams[0].route} />} />
                {teams.map((route) => (
                  <Route
                    key={route.id}
                    path={route.route}
                    element={<route.element type={route.type} />}
                  />
                ))}
              </Route>
              <Route path="departments" element={<DepartmentList />} />
            </Route>
            <Route path="product" element={<ProductAndConfigLayout />}>
              <Route
                path=""
                element={<Navigate to={product_config[0].route} />}
              />
              {product_config.map((route) => (
                <Route
                  key={route.id}
                  path={route.route}
                  element={<route.element />}
                />
              ))}
            </Route>
            <Route path="admin" element={<AdminLayout />}>
              <Route path="" element={<Navigate to="role" />} />
              <Route path="role" element={<RoleListPage />} />
              <Route path="user-defined" element={<UserDefinedLayout />}>
                <Route
                  path=""
                  element={
                    <Navigate
                      to={`${user_defined[0].route}/:entityCode/:title`}
                    />
                  }
                />
                {user_defined.map((route) => (
                  <Route
                    key={route.id}
                    path={`${route.route}/:entityCode/:title`}
                    element={<route.element />}
                  />
                ))}
              </Route>
            </Route>
          </Route>
          <Route path="contact-details/:id" element={<ContactDetails />}>
            <Route path="" element={<Navigate to={contact[0].route} />} />
            {contact.map((route) => (
              <Route
                key={route.id}
                path={route.route}
                element={<route.element />}
              />
            ))}
          </Route>
          <Route path="estimate-details/:id" element={<EstimateDetails />} />
          <Route
            path="proposal-details/:id/:isTemplate/:estimateId"
            element={<ProposalDetailLayout />}
          />
          <Route path="create-estimate" element={<EstimateDetails />} />
          <Route path="create-preset-group" element={<CreatePresetGroup />} />
          <Route
            path="create-preset-group/:id"
            element={<CreatePresetGroup />}
          />
          <Route path="member-details/:id" element={<MemberDetails />} />
        </>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
}

export default inject("auth")(observer(RootRouter));
