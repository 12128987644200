import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { HiMiniTrash } from "react-icons/hi2";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RESPONSE } from "src/APIs/error_handler";
import { COLORS } from "src/assets/colors";
import { DeleteButton } from "src/components/DeleteButton";
import { Input } from "src/components/ui/input";
import { UDF_LIST } from "src/interfaces/udf.interface";
import { onUpdateUdfListValue } from "src/services/udf.service";

interface Props {
  item: UDF_LIST;
  active: boolean;
  lastIndex: boolean;
  isEdit: boolean;
  onItem: () => void;
  onUpdated?: (udf: UDF_LIST) => void;
  onDeleted?: (udf: UDF_LIST) => void;
}

export const ValueListItem = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (props.item) {
      setValue(props.item.value);
    }
  }, []);

  const onUpdate = (value: string) => {
    if (props.item.value === value) return;
    setLoading(true);
    const doc: UDF_LIST = {
      ...props.item,
      value: value,
    };
    onUpdateUdfListValue(doc).then((res) => {
      if (res !== null) {
        props.onUpdated && props.onUpdated(doc);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      {props.isEdit ? (
        <div className="flex relative items-center justify-center bg-muted rounded-sm mb-2 p-[5px]">
          <div className={`rounded-sm mr-1`}>
            <RxDragHandleDots2 className="h-4 w-5 text-black cursor-pointer" />
          </div>
          <Input
            type="text"
            className={`mx-1 bg-white`}
            placeholder={`Enter`}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={() => {
              onUpdate(value);
            }}
            onKeyDownCapture={(e) => {
              if (e.key === "Enter") {
                onUpdate(value);
              }
            }}
          />
          {loading ? (
            <Loader2
              color={COLORS.primary}
              className="h-4 w-4 animate-spin absolute right-[38px] top-[14px]"
            />
          ) : null}
          <div
            className=" bg-red-50 rounded-full p-[5px]"
            onClick={(e) => e.stopPropagation()}
          >
            <DeleteButton
              renderComponent={
                <HiMiniTrash
                  color={COLORS.red}
                  className="cursor-pointer"
                  size={12}
                />
              }
              deleted={(res: RESPONSE) => {
                props.onDeleted && props.onDeleted(res.data);
              }}
              id={`${props.item.id}`}
              path={`udf-list`}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={props.active ? undefined : () => props.onItem()}
          className={`mt-1 p-3 flex items-center rounded-sm cursor-pointer hover:bg-gray-100 ${
            props.active ? "bg-gray-100" : ""
          }`}
        >
          <div className={`rounded-sm mr-1`}>
            <RxDragHandleDots2 className="h-4 w-5 text-black cursor-pointer" />
          </div>
          {value}
        </div>
      )}
    </>
  );
};
