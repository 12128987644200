import { STEP } from "src/components/Stepper";

interface Props {
  active: STEP;
  steps: STEP[];
  onTab: (tabl: STEP) => void;
}

export default function Tab(props: Props) {
  return (
    <div className="mx-4">
      <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
        <ul
          className="flex flex-wrap -mb-px"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          {props.steps.map((m) => {
            const active = props.active?.id === m.id;
            return (
              <li role="presentation">
                <button
                  onClick={() => props.onTab(m)}
                  className={`inline-block ${active ? "text-primary" : "text-gray-500"} hover:opacity-90 ${active ? "border-primary":"border-transparent"}  rounded-t-lg py-4 px-4 text-sm font-medium text-center  border-b-2 dark:text-gray-400 dark:hover:text-gray-300`}
                  id="contacts-tab"
                  data-tabs-target="#contacts"
                  type="button"
                  role="tab"
                  aria-controls="contacts"
                  aria-selected="false"
                >
                  {m.name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
