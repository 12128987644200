import { inject, observer } from "mobx-react";
import { useState } from "react";
import { HiOutlineArrowUpOnSquare } from "react-icons/hi2";
import { getDownloadPdfLink } from "src/APIs/proposal.api";
import MainButton from "src/components/MainButton";
import ProposalStore from "src/stores/proposal.store";
import { getToken } from "src/utilities/storage.utilities";

interface Props {
  proposal?: ProposalStore;
}

const ExportProposalButton = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const { corporateId, isTemplate } = props.proposal ?? {};

  const handleDownload = async () => {
    setLoading(true);
    const token = getToken();
    if (!token) {
      setLoading(false);
      return;
    }
    fetch(getDownloadPdfLink(corporateId ?? 0, isTemplate), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = isTemplate ? "templates.pdf" : "proposals.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  return (
    <div className="mr-3">
      <MainButton
        onClick={() => {
          handleDownload();
        }}
        loading={loading}
        icon={HiOutlineArrowUpOnSquare}
        size={18}
        label="EXPORT PDF"
        className="text-[11.5px]"
      />
    </div>
  );
};

export default inject("proposal")(observer(ExportProposalButton));
