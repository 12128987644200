import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainTable from "src/components/tableComponents/MainTable";
import AddContact from "./AddContact";

export default function ContactListPage() {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const [showAdd, setShowAdd] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        addLabel="Add Contact"
        onAdd={() => setShowAdd(true)}
        height={height + 45}
        onClick={(row: any) => navigate(`/contact-details/${row?.id}`)}
        entityCode="cus"
        path="customer"
      />
      <AddContact onOpen={(v) => setShowAdd(v)} open={showAdd} />
    </div>
  );
}
