import MainButton from "src/components/MainButton";
import { WarrantySection } from "./WarrantySection";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";

interface Props {
  proposal?: ProposalStore;
}

function ContentWarrantyAndSupport(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};

  return (
    <div>
      {sectionDetail?.meta.map((parent) => {
        return (
          <WarrantySection
            key={parent.id}
            meta={parent}
            onChange={(child) => {
              props.proposal?.onUpdatePayload2Level(parent, child);
            }}
          />
        );
      })}
      <MainButton
        disabled={props.proposal?.disableSave}
        onClick={onUpdateSection}
        loading={processingContentUpdate}
        label="Save"
        className="mt-10"
      />
    </div>
  );
}

export default inject("proposal")(observer(ContentWarrantyAndSupport));
