import { HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import images from "src/assets/images";
import { AlertDialogue } from "src/components/AlertDialogue";
import MainButton from "src/components/MainButton";
import ExportProposalButton from "./ExportProposalButton";

interface Props {
  isModify?: boolean;
  estimateId: number;
  isTemplate: boolean;
}

// /main/estimate-and-invoices/proposal/proposal-list
// /main/estimate-and-invoices/proposal/proposal-template

export default function ProposalPageHeader(props: Props) {
  const navigate = useNavigate();
  return (
    <div
      id={"header"}
      className="flex flex-1 h-14 bg-white p-4 drop-shadow justify-between items-center"
    >
      <img alt="logo" className="h-9 w-30" src={images.LYNQiFY} />
      <div className="flex">
        <ExportProposalButton />
        {props.isModify === true ? (
          <AlertDialogue
            variant="destructive"
            continueLabel="Leave"
            onContinue={() => {
              navigate(-1);
            }}
            title="Leaving Without Save"
            description="You have modified something without saving, would you like to leave?"
            renderButton={
              <MainButton
                variant={"link"}
                type={"button"}
                onClick={() => null}
                icon={HiXMark}
              />
            }
          />
        ) : (
          <MainButton
            variant={"link"}
            type={"button"}
            onClick={() => {
              if (props.estimateId !== 0) {
                navigate(`/estimate-details/${props.estimateId}`);
                return;
              }
              if (props.isTemplate === true) {
                navigate(
                  `/main/estimate-and-invoices/proposal/proposal-template`
                );
                return;
              }
              navigate(`/main/estimate-and-invoices/proposal/proposal-list`);
            }}
            icon={HiXMark}
          />
        )}
      </div>
    </div>
  );
}
