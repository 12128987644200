import EstimateSettingContent from "./EstimateSettingContent";

function EstimateSettingPage() {
  return (
    <div className=" p-4">
      <EstimateSettingContent />
    </div>
  );
}

export default EstimateSettingPage;
