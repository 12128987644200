import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import html2canvas from "html2canvas";
// import { useRef } from "react";

interface Props {
  loading: boolean;
  isError: boolean;
  pdfFile: string;
  rawHtml: any;
}

export const SectionPdfViewer = (props: Props) => {
  // const ref = useRef<any>(null);
  // const convertHTMLToImageBase64 = () => {
  //   if (ref.current) {
  //     html2canvas(ref.current).then((canvas) => {
  //       const image = canvas.toDataURL("image/png");
  //       console.log(">>>", image);
  //     });
  //   }
  // };
  if (props.loading) {
    return (
      <div className={`overflow-auto p-5 flex justify-center items-center`}>
        <EstimateDetailsSkeleton />
      </div>
    );
  }
  return (
    <div className="h-screen overflow-auto pb-80">
      <div dangerouslySetInnerHTML={{ __html: props.rawHtml }} />
    </div>
    // <div
    //   className={`overflow-auto p-5 flex justify-center ${
    //     props.isError || props.loading ? "" : "pl-24"
    //   } items-center`}
    // >
    //   <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
    //     {props.loading ? (
    //       <EstimateDetailsSkeleton />
    //     ) : props.isError ? (
    //       <NotFound title="NO PDF FOUND" />
    //     ) : (
    //       <Viewer pageLayout={{}} theme="dark" fileUrl={props.pdfFile} />
    //     )}
    //   </Worker>
    // </div>
  );
};
