import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
} from "../ui/dialog";
import MainButton from "../MainButton";
import { Button } from "../ui/button";
import { cn } from "src/lib/utils";
import { IconType } from "react-icons";
import { ADDRESS } from "src/interfaces/customer.interface";
import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import  {
  MAP_PROPERTY,
} from "../autoComplete/AutoCompleteAddress";
import {
  HiOutlineChatBubbleBottomCenterText,
  HiOutlineDocumentText,
} from "react-icons/hi2";
import { COUNTRY } from "src/interfaces/init.interface";
import { BellIcon } from "@radix-ui/react-icons";
import React from "react";

import { TAX_RATE } from "src/interfaces/estimate.interface";
import { formatMoney } from "src/utilities/formatter.utilities";
import { getAddressLineDisplay } from "src/utilities/mapping.utilities";
import AddressContent from "./AddressContent";
import { taxRate } from "src/APIs/estimate_details.api";

interface Props {
  disabled?: boolean;
  icon?: IconType;
  onIconClick?: () => void;
  placeholder?: string;
  value: ADDRESS | null;
  label: string;
  required?: boolean;
  onSubmit: (data: ADDRESS, taxRate?: number | null) => void;
  withTax?: boolean;
}

export function AddressSelector(props: Props) {
  const [timeoutRef, setTimeoutRef] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [line, setLine] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [taxrate, setTaxRate] = useState<number | null>(null);

  const [latlong, setLatLong] = useState("");

  useEffect(() => {
    if (!open) return;
    if (!props.withTax) return;
    if (missDataForTax) return;
    const controller = new AbortController();
    const signal = controller.signal;
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const timeOut = setTimeout(async () => {
      taxRate(countryCode, stateCode, postal, signal).then((data) => {
        const result: TAX_RATE = data?.data;
        setTaxRate(result?.estimatedCombinedRate ?? null);
      });
    }, 200);
    setTimeoutRef(timeOut);
  }, [postal, stateCode, countryCode, open]);

  const missDataForTax = React.useMemo(() => {
    return !postal || !stateCode || !countryCode;
  }, [postal, stateCode, countryCode]);

  useEffect(() => {
    const { line1, city, postalCode, country, countryCode, state, stateCode } =
      props.value ?? {};
    setLine(line1 ?? "");
    setCity(city ?? "");
    setPostal(postalCode ?? "");
    setCountry(country ?? "");
    setCountryCode(countryCode ?? "");
    setState(state ?? "");
    setStateCode(stateCode ?? "");
  }, [props.value,open]);

  const onSelectedLine = (line: string, data: MAP_PROPERTY | null) => {
    setLine(line);
    if (data) {
      setCountry(data.country);
      setCountryCode(data.country_code);
      setState(data.state);
      setStateCode(data.state_code);
      setPostal(data.postcode);
      setCity(data.city);
      data.lat && data.lon
        ? setLatLong(`${data?.lat},${data?.lon}`)
        : setLatLong("");
    }
  };

  const onSelectedState = (line: string, data: MAP_PROPERTY | null) => {
    setState(data?.state ?? "");
    setStateCode(data?.state_code ?? "");
  };

  const onSelectedCountry = (country: COUNTRY) => {
    setCountry(country?.name);
    setCountryCode(country?.code);
  };

  const onSubmit = () => {
    const data: ADDRESS = {
      latLong: latlong,
      line1: line,
      city: city,
      stateCode: stateCode,
      state: state,
      countryCode: countryCode,
      country: country,
      postalCode: postal,
    };
    props.onSubmit(data, taxrate);
    setOpen(false);
  };

  return (
    <div className="mt-4 w-full">
      <Label>
        {props.label}
        {props.required ? <span className="text-destructive">*</span> : null}
      </Label>
      <Dialog
        onOpenChange={() => {
          setTaxRate(null);
          setOpen(!open);
        }}
        open={open}
      >
        <DialogTrigger asChild>
          <Button
            disabled={props.disabled}
            variant={"outline"}
            className={cn(
              `h-20 mt-2 pl-3 text-left font-normal w-full border border-gray-300 flex justify-between items-start }`
            )}
          >
            <div className="flex flex-1 items-center">
              {props.icon ? (
                <props.icon
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onIconClick && props.onIconClick();
                  }}
                  className="mr-2 h-4 w-4 opacity-90 "
                />
              ) : null}
              <span className={!line ? "text-muted-foreground" : ""}>
                {getAddressLineDisplay(props.value) || props.placeholder}
              </span>
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] min-h-96">
          <div className="h-full w-full mb-12">
            <DialogHeader>
              <DialogTitle>{props.label}</DialogTitle>
            </DialogHeader>
            <AddressContent
              onSelectedCountry={onSelectedCountry}
              onSelectedLine={onSelectedLine}
              onSelectedState={onSelectedState}
              setCity={(t) => setCity(t)}
              setPostal={(t) => setPostal(t)}
              state={state}
              city={city}
              country={country}
              postal={postal}
              line={line}
            />
            {props.withTax ? (
              <div className=" flex items-center space-x-4 rounded-md border p-4 mt-6">
                {missDataForTax ? (
                  <BellIcon />
                ) : !taxrate ? (
                  <HiOutlineChatBubbleBottomCenterText size={22} />
                ) : (
                  <HiOutlineDocumentText size={22} />
                )}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-medium leading-none">Tax Rate</p>
                  <p className="text-sm text-muted-foreground">
                    {missDataForTax
                      ? "Please fill in country, state, and postal code to get tax rate in this area!"
                      : taxrate
                      ? `${formatMoney(taxrate * 100)}%`
                      : "Tax rate is not available in the area!"}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <DialogFooter>
            <MainButton
              onClick={() => onSubmit()}
              label="Submit"
              type="submit"
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
