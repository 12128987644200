import {
  createCRMSetup,
  createContact,
  createCrm,
  createCrmSetup,
  createCustomer,
  createDealType,
  createDefinedField,
  createGroup,
  createSource,
  createStage,
  createStatus,
  crmStageReorder,
  crmStatusReorder,
  crmUpdate,
  defineFieldListSetDefault,
  defineFieldListUpdateColor,
  defineFieldListUpdateName,
  definedFieldEnable,
  definedFieldListReorder,
  definedFieldReorder,
  definedFieldUpdateName,
  enableStage,
  enableStatus,
  fieldListCreate,
  fieldListReorder,
  fieldListSetDefault,
  fieldListShow,
  fieldListUpdate,
  getClientList,
  getCrmDetailById,
  getCrmSetup,
  getDealType,
  getDealTypeList,
  getDefinedFieldsByTableId,
  getDefinedFieldsList,
  getEstimateByCustomer,
  getGroup,
  getGroupsList,
  getLeadList,
  getOpportunityList,
  getSource,
  getSourceList,
  getStage,
  getStageList,
  getStatus,
  getStatusList,
  getStatusWithSummary,
  setColorOnFly,
  setCrmDefault,
  setCrmSetupOnFly,
  setNameOnFly,
  setStageDefault,
  updateCustomer,
  updateDealType,
  updateGroup,
  updateSource,
  updateStage,
  updateStageName,
  updateStatus,
  updateStatusName,
} from "src/APIs/crm.api";
import { RESPONSE } from "src/APIs/error_handler";
import {
  DEFINED_FIELD,
  DEFINED_FIELD_LIST,
  DEFINED_FIELD_REORDER,
  CRM_SETUP,
  CRM_TYPE,
} from "src/interfaces/crm.interface";
import CUSTOMER, {
  DEAL_TYPE,
  LEAD,
  LEAD_GROUP,
  LEAD_SOURCE,
  LEAD_STAGE,
  LEAD_STATUS,
} from "src/interfaces/customer.interface";
import { FIELD_LIST_ITEM } from "src/interfaces/product.interface";
import { orderByAsc, orderByDesc } from "src/utilities/formatter.utilities";

export async function onCreateContact(contact: CUSTOMER) {
  const res = await createContact(contact);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetLeadList(
  type: string,
  statusId: string,
  stageId: string,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  const res = await getLeadList(
    type,
    statusId,
    stageId,
    page,
    length,
    search,
    sort,
    desc,
    dateFrom,
    dateTo
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetOpportunityList(
  statusId: number,
  stageId: number,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  const res = await getOpportunityList(
    statusId,
    stageId,
    page,
    length,
    search,
    sort,
    desc,
    dateFrom,
    dateTo
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetClientList(
  statusId: number,
  stageId: number,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  const res = await getClientList(
    statusId,
    stageId,
    page,
    length,
    search,
    sort,
    desc,
    dateFrom,
    dateTo
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetStatus(type: string) {
  const res = await getStatus(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetStatusWithSummary(type: string) {
  const res = await getStatusWithSummary(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetGroup(type: string) {
  const res = await getGroup(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetStage(type: string) {
  const res = await getStage(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetSource(type: string) {
  const res = await getSource(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetDealType(type: string) {
  const res = await getDealType(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetCrmSetup(type: string, setupType: CRM_SETUP) {
  const res = await getCrmSetup(type, setupType);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetCrmDetailById(id: number, type: string) {
  const res = await getCrmDetailById(id, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetSourceList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  const res = await getSourceList(
    page,
    length,
    type,
    search,
    sort,
    desc,
    isAll
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetStageList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  const res = await getStageList(page, length, type, search, sort, desc, isAll);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetStatusList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  const res = await getStatusList(
    page,
    length,
    type,
    search,
    sort,
    desc,
    isAll
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetGroupsList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  const res = await getGroupsList(
    page,
    length,
    type,
    search,
    sort,
    desc,
    isAll
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetDealTypeList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  const res = await getDealTypeList(
    page,
    length,
    type,
    search,
    sort,
    desc,
    isAll
  );
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateCustomer(customer: CUSTOMER) {
  const res = await createCustomer(customer);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onSetStageDefault(stage: LEAD_STAGE) {
  const res = await setStageDefault(stage);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onSetCrmSetupOnFly(
  doc: LEAD_GROUP | LEAD_SOURCE | DEAL_TYPE,
  leadId: number,
  type: string,
  setupType: CRM_SETUP
) {
  const res = await setCrmSetupOnFly(doc, leadId, type, setupType);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onSetColorOnFly(
  id: string,
  type: CRM_SETUP,
  color: string
) {
  const res = await setColorOnFly(id, type, color);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onSetNameOnFly(
  id: string,
  type: CRM_SETUP,
  color: string
) {
  const res = await setNameOnFly(id, type, color);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onSetCrmDefault(type: CRM_SETUP, id: number) {
  const res = await setCrmDefault(type, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateCrm(lead: LEAD, type: string) {
  const res = await createCrm(lead, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateSource(leadSource: LEAD_SOURCE, type: string) {
  const res = await createSource(leadSource, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateCrmSetup(
  doc: LEAD_SOURCE | LEAD_GROUP | DEAL_TYPE,
  setupType: CRM_SETUP,
  type: string
) {
  const res = await createCrmSetup(doc, setupType, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateStage(v: LEAD_STAGE) {
  const res = await createStage(v);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateStatus(doc: LEAD_STATUS, type: string) {
  const res = await createStatus(doc, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateGroup(doc: LEAD_GROUP, type: string) {
  const res = await createGroup(doc, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateDealType(v: DEAL_TYPE, type: string) {
  const res = await createDealType(v, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateCRMSetup(
  v: DEAL_TYPE,
  type: string,
  setupType: CRM_SETUP
) {
  const res = await createCRMSetup(v, type, setupType);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateDealType(v: DEAL_TYPE, id: string) {
  const res = await updateDealType(v, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateCustomer(customer: CUSTOMER, id: number) {
  const res = await updateCustomer(customer, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCrmUpdate(v: LEAD, id: number, type: string) {
  const res = await crmUpdate(v, id, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateSource(v: LEAD_SOURCE, id: string) {
  const res = await updateSource(v, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateStage(v: LEAD_STAGE, id: string) {
  const res = await updateStage(v, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateStatus(v: LEAD_STATUS, id: string) {
  const res = await updateStatus(v, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateStatusName(status: LEAD_STATUS) {
  const res = await updateStatusName(status);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateStageName(stage: LEAD_STAGE) {
  const res = await updateStageName(stage);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onEnableStatus(enable: boolean, id: string) {
  const res = await enableStatus(enable, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onEnableStage(enable: boolean, id: string) {
  const res = await enableStage(enable, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateGroup(v: LEAD_GROUP, id: string) {
  const res = await updateGroup(v, id);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetEstimateByCustomer(customerId: number) {
  const res = await getEstimateByCustomer(customerId);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCrmStatusReorder(doc: LEAD_STATUS[], type: string) {
  const res = await crmStatusReorder(doc, type);
  if (res.code === "200") {
    const listStatus = res?.data as LEAD_STATUS[];
    const data = orderByDesc(
      listStatus.filter((status) => status.enable),
      "sequence"
    );
    const result = {
      ...res,
      data: data,
    };
    return result as RESPONSE;
  } else {
    return null;
  }
}

export async function onCrmStageReorder(doc: LEAD_STAGE[], statusId: string) {
  const res = await crmStageReorder(doc, statusId);
  if (res.code === "200") {
    const listStatus = res?.data as LEAD_STATUS[];
    const data = orderByDesc(
      listStatus.filter((status) => status.enable),
      "sequence"
    );
    const result = {
      ...res,
      data: data,
    };
    return result as RESPONSE;
  } else {
    return null;
  }
}

export async function onGetDefinedFieldsByTableId(tableId: string) {
  const res = await getDefinedFieldsByTableId(tableId);
  if (res.code === "200") {
    const result = {
      ...res,
      data: orderByAsc(res?.data, "sequence"),
    };
    return result as RESPONSE;
  } else {
    return null;
  }
}

export async function onFieldListShow(type: string) {
  const res = await fieldListShow(type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetDefinedFieldsList(definedFieldId: string) {
  const res = await getDefinedFieldsList(definedFieldId);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateDefinedField(
  doc: DEFINED_FIELD,
  crmType: CRM_TYPE
) {
  const res = await createDefinedField(doc, crmType);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefinedFieldUpdateName(id: string, name: string) {
  const res = await definedFieldUpdateName(id, name);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefinedFieldEnable(data: DEFINED_FIELD) {
  const res = await definedFieldEnable(data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefinedFieldReorder(
  crmType: CRM_TYPE,
  data: DEFINED_FIELD_REORDER[]
) {
  const res = await definedFieldReorder(crmType, data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefinedFieldListReorder(
  definedFieldId: string,
  data: DEFINED_FIELD_REORDER[]
) {
  const res = await definedFieldListReorder(definedFieldId, data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefineFieldListUpdateName(data: DEFINED_FIELD_LIST) {
  const res = await defineFieldListUpdateName(data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefineFieldListUpdateColor(data: DEFINED_FIELD_LIST) {
  const res = await defineFieldListUpdateColor(data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onDefineFieldListSetDefault(data: DEFINED_FIELD_LIST) {
  const res = await defineFieldListSetDefault(data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onFieldListSetDefault(data: FIELD_LIST_ITEM) {
  const res = await fieldListSetDefault(data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onFieldListReorder(
  type: string,
  data: DEFINED_FIELD_REORDER[]
) {
  const res = await fieldListReorder(type, data);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onFieldListCreate(data: FIELD_LIST_ITEM, type: string) {
  const res = await fieldListCreate(data, type);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onFieldListUpdate(id: string, value: string) {
  const res = await fieldListUpdate(id, value);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}
