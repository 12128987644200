import { HiPlusCircle } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import { Card } from "src/components/ui/card";
import { DrawerTitle } from "src/components/ui/drawer";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import { PRODUCT } from "src/interfaces/product.interface";
import { useState } from "react";
import ProductionRateForm from "../productionRateList/ProductionRateForm";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import ProductionRateRow from "./ProductionRateRow";
import { orderByAsc } from "src/utilities/formatter.utilities";


interface Props {
  data: PRODUCTION_RATE[];
  loading: boolean;
  product: PRODUCT | null;
  updateProductionRate: (rates: PRODUCTION_RATE[]) => void;
  auth?: AuthStore;
  onDeleted:(value:PRODUCTION_RATE) => void
}

function ProductionRateList(props: Props) {
  const [openCreate, setOpenCreate] = useState(false);
  const [productionRate, setProductionRate] = useState<PRODUCTION_RATE | null>(
    null
  );
 

  return (
    <Card className="flex flex-1 p-4 flex-col">
      <DrawerTitle className="text-lg flex items-center">
        Production Rate List{" "}
        <div className="w-22">
          {props.product?.id ? (
            <MainButton
              variant={"ghost"}
              type="button"
              onClick={() => setOpenCreate(true)}
              icon={HiPlusCircle}
            />
          ) : null}
        </div>
      </DrawerTitle>
      {props.loading ? (
        <SmallListSkeleton />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Type or Variation</TableHead>
              <TableHead>Rate</TableHead>
              <TableHead>Unit</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Default</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderByAsc(props.data,"type").map((data) => {
              const unit = props.auth?.unitList.find((m) => m.id === data.unit);
              return (
                <ProductionRateRow
                  onDefault={(data) => props.updateProductionRate(data)}
                  onDeleted={() => props.onDeleted(data)}
                  unit={unit || null}
                  data={data}
                  onClick={() => {
                    setProductionRate(data);
                    setOpenCreate(true);
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      )}
      <ProductionRateForm
        onCreatedProductionRate={(productionRates) => {
          setProductionRate(productionRates[productionRates.length - 1]);
          props.updateProductionRate(productionRates);
        }}
        productionRate={productionRate}
        onOpen={(value) => {
          setOpenCreate(value);
          if (!value) setProductionRate(null);
        }}
        open={openCreate}
        product={props.product}
      />
    </Card>
  );
}

export default inject("auth")(observer(ProductionRateList));
