import  {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { LAYOUTS } from "../../assets/layouts";
import { ProfileAvatar } from "../ProfileAvatar";

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];
    if (item) {
      props.command({ id: item?.id, label: item?.fullName });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }
      return false;
    },
  }));

  const RenderSuggestions = (value) => {
    return (
      <div className="p-2 h-10 mb-4" onClick={() => selectItem(value?.index)}>
        <div className="mr-4 flex items-center cursor-pointer">
          <div className="mr-2">
          <ProfileAvatar
            name={value?.value?.fullName}
            url={value?.value?.profilePictureUrl}
          />
          </div>
          <span className="font-normal ml-16">
            {value?.value?.fullName ?? ""}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div 
    className="bg-white drop-shadow rounded w-80 h-[500px] overflow-hidden">
      {props.items.length ? (
        props.items.map((item, index) => (
          <RenderSuggestions index={index} key={index} value={item} />
        ))
      ) : (
        <span style={{ padding: LAYOUTS.padding }}>No Result</span>
      )}
    </div>
  );
});
