import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { updateUser } from "src/APIs/team.api";
import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";
import ActivityIndicator from "src/components/ActivityIndicator";
import { AutoCompleteStatic } from "src/components/autoComplete/AutoCompleteStatic";
import { Checkbox } from "src/components/CheckBox";
import MainButton from "src/components/MainButton";
import {
  PERMISSION,
  PERMISSION_CLASSIFICATION,
  ROLE,
  UPDATE_USER_ROLE,
  USER,
} from "src/interfaces/init.interface";
import AuthStore from "src/stores/auth.store";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { nullString } from "src/utilities/functions.utilities";

interface Props {
  user: USER | null;
  auth?: AuthStore;
  updateSuccess: (doc: USER) => void;
}

function PermissionSetup(props: Props) {
  const [loadingRole, setLoadingRole] = useState(false);

  const [role, setRole] = useState<ROLE | null>(null);
  const [permissions, setPermission] = useState<PERMISSION[]>([]);
  const [permissionClassifications, setPermissionClassifications] = useState<
    PERMISSION_CLASSIFICATION[]
  >([]);
  const [errorMsg, setErrorMsg] = useState<string[]>([]);

  useEffect(() => {
    const { roleId } = props.user ?? {};
    const { permissionClassifications } = props.auth ?? {};
    const _permissionClassifications = orderByAsc(
      permissionClassifications ?? [],
      "sequence"
    );
    setPermissionClassifications(_permissionClassifications);
    if (roleId) {
      const roleDetails = props.auth?.roles.find((m) => m.id === roleId);
      setLoadingRole(false);
      setPermission(roleDetails?.permissions ?? []);
      setRole(roleDetails ?? null);
    }
    return () => {};
  }, [props.auth?.permissionClassifications]);

  function onPermission(v: PERMISSION_CLASSIFICATION, permission: PERMISSION) {
    if (permissions.find((m) => m.id === permission.id)) {
      if (permission.parentId !== null) {
        setPermission(
          permissions.filter(
            (m) =>
              m.id !== permission.id &&
              m.parentId !== permission.id &&
              m.id !== permission.parentId
          )
        );
        return;
      }
      setPermission(
        permissions.filter(
          (m) => m.id !== permission.id && m.parentId !== permission.id
        )
      );
    } else {
      setPermission(
        permissions.concat(
          v.permissions.filter(
            (m) => m.id === permission.id || m.parentId === permission.id
          )
        )
      );
    }
  }

  function onChangeRole(role: ROLE) {
    if (!role) {
      setRole(role);
      setPermission([]);
    } else {
      setRole(role);
      setLoadingRole(true);
      if (role.id) {
        const roleDetails = props.auth?.roles.find((m) => m.id === role.id);
        setLoadingRole(false);
        setPermission(roleDetails?.permissions ?? []);
      }
    }
  }

  function isValid() {
    let msg: string[] = [];
    const isERole = nullString(role?.name ?? "") === null;
    if (isERole) msg = msg.concat("Please choose role \n");
    return msg;
  }

  function onSave() {
    const invalidMsg = isValid();
    setErrorMsg(invalidMsg);
    if (invalidMsg.length === 0) {
      setLoadingRole(true);
      const data: UPDATE_USER_ROLE = {
        id: props.user?.id,
        roleId: role?.id!,
        permissions: permissions.map((m) => m.id),
      };
      updateUser(data, "/change-role").then((doc) => {
        setLoadingRole(false);
        props.updateSuccess(doc?.data);
      });
    }
  }

  return (
    <div className="p-4">
      <div className="w-2/4">
        <AutoCompleteStatic
          data={props.auth?.roles ?? []}
          required
          placeholder="Choose role"
          onSelectedValue={(value) => onChangeRole(value)}
          value={role}
          label="Role"
        />
      </div>
      <div className="flex items-center gap-4 mt-4">
        <span className="text-lg font-bold">Permissions</span>
        {loadingRole ? <ActivityIndicator /> : null}
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {permissionClassifications.map((item) => {
          const _permissions = orderByAsc(item.permissions ?? [], "sequence");
          return (
            <div
              style={{
                padding: LAYOUTS.padding_big,
                display: "flex",
                backgroundColor: COLORS.background_primary,
                marginBottom: LAYOUTS.margin,
                borderRadius: LAYOUTS.radius,
                flex: 1,
                flexDirection: "column",
              }}
            >
              <h4>{item?.name}</h4>
              <div>
                {_permissions.map((m) => {
                  return (
                    <div className="mt-2">
                      <Checkbox
                        disabled={!role || loadingRole}
                        label={m.name}
                        value={
                          permissions.find((e) => e?.id === m.id) ? true : false
                        }
                        onChange={(v) => {
                          onPermission(item, m);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <div className="mt-10 w-[35%] self-center">
          <MainButton
            loading={loadingRole}
            className="text-white"
            type="button"
            onClick={() => onSave()}
            label="Save Changes"
          />
        </div>
      </div>
    </div>
  );
}

export default inject("auth")(observer(PermissionSetup));
