import { IconType } from "react-icons";
import { useEffect, useState } from "react";
import React from "react";
import AutoCompleteView from "./AutoCompleteView";
import { staticSearch } from "src/utilities/mapping.utilities";
import { staticList } from "src/APIs/table.api";

interface Props {
  icon?: IconType;
  label?: string;
  placeholder?: string;
  onIconClick?: () => void;
  path: string;
  onAdd?: () => void;
  required?: boolean;
  onSelectedValue: (value: any) => void;
  value: any;
  disabled?: boolean;
  hideBorder?: boolean;
  enablePortal?: boolean;
  isProposalTemplate?: boolean;
}

export function AutoCompleteWithAsync(props: Props) {
  const [hasOpened, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [text, setText] = React.useState("");
  const [ready, setReady] = useState(false);

  async function fetchData() {
    if (loading) return;
    setLoading(true);
    if (props.isProposalTemplate) {
      const res = await staticList(props.path);
      if (!res) {
        setSearching(false);
        return;
      }
      setOptions(res as any);
      setData(res as any);
    } else {
      const result = await staticList(props.path);
      const content = result?.data?.content || result?.data;
      if (!content) {
        setSearching(false);
        return;
      }
      setOptions(content);
      setData(content);
    }
    setLoading(false);
    setReady(true);
  }

  useEffect(() => {
    if (hasOpened && data.length === 0) {
      fetchData();
    }
  }, [hasOpened]);

  useEffect(() => {
    if (!ready) return;
    setSearching(true);
    if (text) {
      const result = staticSearch(options, text);
      setData(result);
    } else {
      setData(options);
    }
  }, [text]);

  return (
    <AutoCompleteView
      enablePortal={props.enablePortal}
      hideBorder={props.hideBorder}
      disabled={props.disabled}
      value={props.value}
      onSelectedValue={props.onSelectedValue}
      required={props.required}
      onOpenChange={(v) => setOpen(v)}
      data={data}
      loading={loading}
      searching={searching}
      setText={(t) => setText(t)}
      label={props.label}
      placeholder={props.placeholder}
      icon={props.icon}
    />
  );
}
