import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "src/components/ui/tableEstimate";
import { COLUMNS, COLUMNS_TEMPLATE, SPACE_ROW } from "./ProductDetails";
import {
  COMMENT,
  ESTIMATE_TEMPLATE,
  GROUP_OR_ITEM,
} from "src/interfaces/estimate.interface";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import MainInput from "src/components/MainInput";
import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  getContentWidth,
  getGroupCodeToDisplay,
  measureInputTextWidth,
  nextSibling,
  sortByMultiFields,
  title,
  updateObjArray,
} from "src/utilities/functions.utilities";
import { formatMoney, sum } from "src/utilities/formatter.utilities";
import ProductDetailsRow from "./ProductDetailsRow";
import { HiTrash } from "react-icons/hi2";
import CollapsedButton from "./CollapsedButton";
import DropdownMenuRow from "./DropdownMenu";
import { getSubGroups } from "./estimate.utility";
import { TEAM } from "src/interfaces/team.interface";
import CommentMui from "./CommentMui";
import AddButtonRow from "./AddButtonRow";
import CheckButton from "./CheckButton";
import ProductDetailsGroupSkeleton from "./ProductDetailsGroupSkeleton";
import Tree from "./Tree";
import { useInView } from "react-intersection-observer";
import mergeRefs from "merge-refs";
import { GroupDescription } from "./GroupDescription";

interface Props {
  provided: DraggableProvided;
  item: GROUP_OR_ITEM;
  allItems: GROUP_OR_ITEM[];
  parentCode: string | null;
  onAdd: (item: GROUP_OR_ITEM) => void;
  onDragEnd: (result: any, siblings: GROUP_OR_ITEM[]) => void;
  levels: number[];
  onHideAll: () => void;
  onShowAll: () => void;
  onShowByLevel: (lv: number) => void;
  onDelete: (item: GROUP_OR_ITEM) => void;
  onMoveTo: (item: GROUP_OR_ITEM) => void;
  updateItem: (i: GROUP_OR_ITEM, isUpdateMarkup?: boolean) => void;
  onSetPrice: (i: number, group: GROUP_OR_ITEM) => void;
  onAddNewGroup: (name: string, item: GROUP_OR_ITEM) => void;
  onTeam: (group: GROUP_OR_ITEM, team: TEAM) => void;
  onChangeGroupName: (t: string, group: GROUP_OR_ITEM) => void;
  onAddSubItem: (group: GROUP_OR_ITEM) => void;
  onAddTemplate: (row: GROUP_OR_ITEM, template: ESTIMATE_TEMPLATE) => void;
  isLast?: boolean;
  estimateId: number | string | undefined;
  onCheck: (row: GROUP_OR_ITEM) => void;
  checkItems: GROUP_OR_ITEM[];
  onCheckAll: () => void;
  onUnCheckAll: () => void;

  activeId: string;
  onActive: (id: string) => void;

  collapsedId: string[];
  onCollapse: (id: string) => void;
  siblingsLevel: number[];
  isTemplate?: boolean;
  isPrinting?: boolean;
  alphabetic: string;
  onGroupDescription: (t: string, group: GROUP_OR_ITEM) => void;
}

function ProductDetailsGroup(props: Props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const span: any = useRef();
  const [name, setName] = useState("");
  const [children, setChildren] = useState<GROUP_OR_ITEM[]>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [total, setTotal] = useState(0);
  const [team, setTeam] = useState<TEAM | null>(null);
  const [editingText, setEditing] = useState(false);
  const [ready, setReady] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [lastEmptyItem, setLastEmptyItem] = useState<GROUP_OR_ITEM | null>(
    null
  );
  const [hover, setHover] = useState(false);
  const [checked, setCheck] = useState(false);
  const [description, setDescription] = useState("");

  const [minWidth, setMinWidth] = useState(0);
  const [width, setWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(1200);

  //control minWidth of the group title
  useEffect(() => {
    var timer: any = null;
    const element = document.getElementById(COLUMNS[0].accessorKey);
    const resizeObserver = new ResizeObserver((entries) => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        for (let entry of entries) {
          setMinWidth(entry.contentRect.width);
        }
      }, 150);
    });
    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setWidth(span.current?.offsetWidth + 10);
  }, [name]);

  // useEffect(() => {
  //   const leftContainer = document.getElementById(`left_container_${props.item.id}`);
  //   const input = document.getElementById(`${props.item.id}_input`);
  //   input?.addEventListener("input", function (event:any) {
  //     const contentWidth =  measureInputTextWidth(`${props.item.id}_input`) ?? 0
  //     if(!leftContainer) return;
  //     leftContainer.style.width = `${contentWidth + 10}px`;
  //   });
  // }, [editingText]);

  useEffect(() => {
    const { name, id, code, description } = props.item;
    const children = props.allItems.filter(
      (m) =>
        (m.parentGroupCode === code || m.parentGroupCode === code) &&
        m.id !== id
    );
    const groupChildrenCodes = getSubGroups(props.item, props.allItems)
      .map((m) => m.code)
      .concat(props.item.code);
    const allItemsOfGroupChildren = props.allItems
      .filter(
        (m) =>
          m.parentGroupCode &&
          !m.isGroup &&
          groupChildrenCodes.includes(m.parentGroupCode)
      )
      .map((m) => {
        return { ...m, total: (m?.price ?? 0) * (m?.quantity ?? 0) };
      });
    const _total = sum(allItemsOfGroupChildren, "total");
    const _disperse = sum(allItemsOfGroupChildren, "disperse");
    const _markup = sum(allItemsOfGroupChildren, "markup");
    setTotal(_total + _disperse + _markup);
    setName(name ?? "");
    setDescription(description ?? "");

    setChildren(
      sortByMultiFields(children, ["sequence", "level"], ["asc", "asc"])
    );
    setTimeout(() => setReady(true), 500);
  }, [props.item, props.allItems, props.item.teamId]);

  useEffect(() => {
    setTeam(props.item.team);
  }, [props.item.team]);

  useEffect(() => {
    if (!ready) return;
    const lastItems = children;
    if (lastItems.length === 0) {
      setReady(false);
      props.onAddSubItem(props.item);
      setTimeout(() => setReady(true), 500);
      return;
    }
    if (lastItems.length > 1) {
      setLastEmptyItem(null);
    } else {
      setLastEmptyItem(lastItems[0]);
    }
  }, [ready, children]);

  useEffect(() => {
    setCheck(
      props.checkItems.find((m) => m.id === props.item.id) ? true : false
    );
  }, [props.checkItems]);

  useEffect(() => {
    const isCollapsed = props.collapsedId.includes(props.item.id ?? "");
    setCollapsed(isCollapsed);
  }, [props.collapsedId]);

  const groupCode = React.useMemo(() => {
    const { sequence } = props.item;
    return getGroupCodeToDisplay(sequence, props.parentCode);
  }, [props.parentCode, props.item.sequence]);

  const renderTotal = React.useMemo(() => {
    return (
      <div
        className={"bg-white p-2 py-1 rounded-md text-primary font-bold ml-2"}
      >
        {" "}
        {`$${formatMoney(total)}`}
      </div>
    );
  }, [total, props.item, props.allItems]);

  const renderHeaderSpacing = React.useMemo(() => {
    if (props.item.level !== 1) return null;
    return (
      <TableRow>
        <TableCell
          className=" hover:bg-white bg-slate-50"
          colSpan={(props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).length}
        >
          <div className="w-full h-[1px] bg-primary drop-shadow-md" />
        </TableCell>
      </TableRow>
    );
  }, [props.item]);

  const renderDropDownMenu = React.useMemo(() => {
    return (
      <DropdownMenuRow
        onCheckAll={props.onCheckAll}
        onUnCheckAll={props.onUnCheckAll}
        onMoveTo={() => props.onMoveTo(props.item)}
        onLevel={(lv) => props.onShowByLevel(lv)}
        onShowAll={() => props.onShowAll()}
        onHideAll={() => props.onHideAll()}
        levels={props.levels}
      />
    );
  }, [props.levels, props.collapsedId]);

  function onChangeGroupName(text: string) {
    setEditing(false);
    if (text.trim() === "") {
      text = "Untitle Group";
    }
    props.onChangeGroupName(text, props.item);
    setName(text);
  }

  function onChangeGroupDescription(text: string) {
    setEditing(false);
    props.onGroupDescription(text, props.item);
    setDescription(text);
  }

  const renderTree = React.useMemo(() => {
    return (
      <Tree
        rowId={`${props.item.id}_row`}
        hover={hover}
        level={props.item.level}
        siblingsLevel={props.siblingsLevel}
        isLast={props.isLast ?? false}
      />
    );
  }, [props.item.level, props.isLast, props.siblingsLevel]);

  function onComment(comment: COMMENT) {
    let item = props.item;
    if (props.item?.comment?.find((m) => m.id === comment.id)) {
      item = {
        ...props.item,
        comment: updateObjArray(item.comment, comment),
        editedComments: updateObjArray(item.editedComments, comment),
      };
      props.updateItem(item);
      return;
    }
    item = {
      ...props.item,
      hasComment: true,
      newComments: updateObjArray(item.newComments, comment),
    };
    props.updateItem(item);
  }

  function onDeleteComment(comment: COMMENT) {
    let item = props.item;
    if (props.item?.comment?.find((m) => m.id === comment.id)) {
      item = {
        ...props.item,
        comment: item.comment.filter((m) => m.id !== comment.id),
        editedComments: item.editedComments.filter((m) => m.id !== comment.id),
        deletedComments: item.deletedComments.concat(comment.id ?? ""),
      };
      item.hasComment = !(
        item?.comment?.length === 0 && item?.newComments?.length === 0
      );
      props.updateItem(item);
      return;
    }
    item = {
      ...props.item,
      newComments: item.newComments.filter((m) => m.id !== comment.id),
    };
    item.hasComment = !(
      item?.comment?.length === 0 && item?.newComments?.length === 0
    );
    props.updateItem(item);
  }

  function autoUpdateComment(listComment: COMMENT[]) {
    let item: GROUP_OR_ITEM = {
      ...props.item,
      comment: listComment,
    };
    props.updateItem(item);
  }

  const whiteSpaceWidth = React.useMemo(() => {
    return SPACE_ROW * props.item.level - 1 + SPACE_ROW + 4;
  }, [props.item.level]);

  return (
    <>
      {renderHeaderSpacing}
      <TableRow
        onClick={() => {
          const autocompletes =
            document.getElementsByClassName("thisAutoComplete");
          Array.from(autocompletes).forEach((e: any) => e.blur());
        }}
        id={`${props.item.id}_row`}
        {...props.provided.draggableProps}
        ref={mergeRefs(props.provided.innerRef, ref) as any}
        style={{
          ...props.provided.draggableProps.style,
          cursor: "grab",
          background: `linear-gradient(to right, #ffffff ${whiteSpaceWidth}px,  rgb(241 245 249) 0%)`,
          backgroundColor: `rgb(241 245 249)`,
        }}
        className={`cursor-pointer draggable ${
          props.item.level === 1 ? "drop-shadow" : ""
        }`}
      >
        <TableCell
          className="p-0 border-t-0"
          colSpan={(props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).length}
        >
          <Table className="w-full">
            <DragDropContext
              onBeforeCapture={() => {
                let element: any = document.getElementById("tableContainer");
                element.style.overflowX = "hidden";
              }}
              onDragEnd={(result) => {
                props.onDragEnd(result, children);
                let element: any = document.getElementById("tableContainer");
                element.style.overflowX = "unset";
              }}
            >
              <TableBody>
                <TableRow
                  onMouseLeave={() => setHover(false)}
                  onMouseEnter={() => setHover(true)}
                  {...props.provided.dragHandleProps}
                  className="bg-transparent relative"
                >
                  <TableCell
                    className="pt-2 pb-2 px-0"
                    colSpan={
                      (props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).length - 1
                    }
                  >
                    <div className="flex items-center">
                      {!inView ? null : renderDropDownMenu}
                      {!inView ? null : (
                        <CheckButton
                          checked={checked}
                          onChange={() => props.onCheck(props.item)}
                        />
                      )}
                      {renderTree}
                      {!inView ? null : (
                        <CollapsedButton
                          rowId={`${props.item.id}_row`}
                          hasChildren={children.length > 0}
                          onChange={() =>
                            props.onCollapse(props.item?.id ?? "")
                          }
                          checked={collapsed}
                        />
                      )}
                      {!inView ? null : (
                        <div
                          style={{ width: hover ? 18 : 0 }}
                          className={`${
                            hover ? "ml-1 mr-2 " : ""
                          } transition-all`}
                        >
                          <AddButtonRow
                            show={hover}
                            onClick={() => props.onAdd(props.item)}
                          />
                        </div>
                      )}
                      {!inView ? null : <h2 className="mr-1">{groupCode}.</h2>}
                      {!inView ? null : (
                        <div
                          id={`left_container_${props.item.id}`}
                          style={{
                            minWidth: editingText ? minWidth : 50,
                            width: editingText ? width + 40 : width + 10,
                            maxWidth: maxWidth,
                            overflow: "hidden",
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              opacity: 0,
                              bottom: 0,
                              left: -200,
                            }}
                            className="font-semibold text-nowrap"
                            id="hide"
                            ref={span}
                          >
                            {title(name, props.alphabetic ?? "")}
                          </span>
                          {editingText ? (
                            <MainInput
                              maxLength={150}
                              id={`${props.item.id}_input`}
                              placeholder="Enter description"
                              noMarginTop
                              focus
                              extendClass={"bg-transparent font-bold"}
                              hideBorder
                              onChange={onChangeGroupName}
                              value={name}
                            />
                          ) : (
                            <p
                              onClick={() => setEditing(true)}
                              className="font-semibold cursor-text text-nowrap"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {title(name, props.alphabetic ?? "")}
                            </p>
                          )}
                        </div>
                      )}

                      {!inView ? null : renderTotal}
                      {!inView ? null : (
                        <div className="flex flex-1 ">
                          <GroupDescription
                            onChange={(text) => onChangeGroupDescription(text)}
                            value={description}
                          />
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="p-2 justify-end flex">
                    <div
                      style={{
                        width: props.isTemplate
                          ? COLUMNS_TEMPLATE[COLUMNS_TEMPLATE.length - 1]
                              .width - 15
                          : COLUMNS[COLUMNS.length - 1].width - 15,
                      }}
                      className={`flex items-center`}
                    >
                      {props.isTemplate ? (
                        <div className="w-full h-10" />
                      ) : (
                        <div className="w-[100%] mt-[-6px]">
                          <AutoCompleteWithLazyLoad
                            enablePortal
                            placeholder="Choose team"
                            onSelectedValue={(value) => {
                              setTeam(value);
                              props.onTeam(props.item, value);
                            }}
                            value={team}
                            path="team"
                          />
                        </div>
                      )}
                      <div className="">
                        <HiTrash
                          onClick={() => props.onDelete(props.item)}
                          size={15}
                          className="ml-2 text-slate-500 mr-0 cursor-pointer"
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
              {
                <Droppable
                  renderClone={(provided, snapshot, rubric) => {
                    const row = props.allItems.find(
                      (m) =>
                        m.id ===
                        provided.draggableProps["data-rbd-draggable-id"]
                    );
                    return (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        {row?.isGroup ? (
                          <ProductDetailsGroupSkeleton
                            allItems={props.allItems}
                            item={row}
                            parentCode={groupCode}
                            estimateId={props?.estimateId}
                          />
                        ) : (
                          <ProductDetailsRow
                            siblingsLevel={props.siblingsLevel}
                            activeId={props.activeId}
                            onActive={() => null}
                            onCheckAll={() => null}
                            onUnCheckAll={() => null}
                            onCheck={() => null}
                            checkItems={[]}
                            isLastEmpty={false}
                            estimateId={props?.estimateId}
                            key={row?.id}
                            onAddTemplate={props.onAddTemplate}
                            onAddNewGroup={props.onAddNewGroup}
                            updateItem={() => null}
                            onMoveTo={() => null}
                            onDelete={() => null}
                            levels={[]}
                            onHideAll={() => null}
                            onShowAll={() => null}
                            onShowByLevel={() => null}
                            onAdd={() => null}
                            item={row ?? ({} as any)}
                            parentCode={groupCode}
                          />
                        )}
                      </div>
                    );
                  }}
                  droppableId={`${props.item.id}_SUB_ITEM`}
                >
                  {(provided) => {
                    return (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        key={`${props.item.id}_SUB_ITEM`}
                        className={`${collapsed ? "hidden" : "visible"}`}
                      >
                        {children.map((row, index) => {
                          const isLast = index === children.length - 1;
                          return (
                            <Draggable
                              key={row.id}
                              index={index}
                              draggableId={row.id ?? ""}
                            >
                              {(provided) => {
                                if (row.isGroup) {
                                  const next = nextSibling(children, index);
                                  return (
                                    <ProductDetailsGroup
                                      onGroupDescription={
                                        props.onGroupDescription
                                      }
                                      alphabetic={props.alphabetic}
                                      isPrinting={props.isPrinting}
                                      isTemplate={props.isTemplate}
                                      siblingsLevel={
                                        next
                                          ? [...props.siblingsLevel, next]
                                          : props.siblingsLevel
                                      }
                                      collapsedId={props.collapsedId}
                                      onCollapse={props.onCollapse}
                                      activeId={props.activeId}
                                      onActive={props.onActive}
                                      onCheckAll={props.onCheckAll}
                                      onUnCheckAll={props.onUnCheckAll}
                                      checkItems={props.checkItems}
                                      onCheck={props.onCheck}
                                      estimateId={props.estimateId}
                                      isLast={isLast}
                                      onAddTemplate={props.onAddTemplate}
                                      onAddSubItem={props.onAddSubItem}
                                      onChangeGroupName={
                                        props.onChangeGroupName
                                      }
                                      onTeam={props.onTeam}
                                      onAddNewGroup={props.onAddNewGroup}
                                      onSetPrice={props.onSetPrice}
                                      updateItem={props.updateItem}
                                      onMoveTo={props.onMoveTo}
                                      onDelete={props.onDelete}
                                      levels={props.levels}
                                      onHideAll={props.onHideAll}
                                      onShowAll={props.onShowAll}
                                      onShowByLevel={props.onShowByLevel}
                                      onDragEnd={props.onDragEnd}
                                      onAdd={props.onAdd}
                                      allItems={props.allItems}
                                      item={row}
                                      parentCode={groupCode}
                                      provided={provided}
                                    />
                                  );
                                }
                                return (
                                  <ProductDetailsRow
                                    isPrinting={props.isPrinting}
                                    isTemplate={props.isTemplate}
                                    siblingsLevel={props.siblingsLevel}
                                    activeId={props.activeId}
                                    onActive={props.onActive}
                                    onCheckAll={props.onCheckAll}
                                    onUnCheckAll={props.onUnCheckAll}
                                    onCheck={props.onCheck}
                                    checkItems={props.checkItems}
                                    isLastEmpty={lastEmptyItem?.id === row.id}
                                    estimateId={props.estimateId}
                                    isLast={isLast}
                                    onAddTemplate={props.onAddTemplate}
                                    onAddNewGroup={props.onAddNewGroup}
                                    updateItem={props.updateItem}
                                    onMoveTo={props.onMoveTo}
                                    onDelete={props.onDelete}
                                    levels={props.levels}
                                    onHideAll={props.onHideAll}
                                    onShowAll={props.onShowAll}
                                    onShowByLevel={props.onShowByLevel}
                                    onAdd={props.onAdd}
                                    key={row.id}
                                    item={row}
                                    parentCode={groupCode}
                                    provided={provided}
                                  />
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </TableBody>
                    );
                  }}
                </Droppable>
              }
            </DragDropContext>
          </Table>
        </TableCell>
      </TableRow>
      {openComment ? (
        <CommentMui
          isGroup
          autoUpdateComment={autoUpdateComment}
          estimateId={props.estimateId}
          onComment={(comment) => onComment(comment)}
          onDeleteCommment={(comment) => onDeleteComment(comment)}
          open={openComment}
          onClose={() => setOpenComment(false)}
          item={props.item}
        />
      ) : null}
    </>
  );
}

export default ProductDetailsGroup;
