import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { Label } from "src/components/ui/label";
import ProposalStore from "src/stores/proposal.store";
import { SectionPdfViewer } from "./SectionPdfViewer";

interface Props {
  id: number;
  isTemplate?: boolean;
  proposal?: ProposalStore;
}

function ProposalPreview(props: Props) {
  useEffect(() => {
    if (!props.proposal) return;
    if (props.proposal?.section !== null) {
      props.proposal.onGetHtml(
        props.id,
        props.proposal?.section.id,
        props.isTemplate
      );
    }
  }, [props.proposal?.section]);

  const { processingPreview, errorPreview, pdfBase64, rawHtml } =
    props.proposal ?? {};

  return (
    <div className="w-[50%]">
      <div className="h-16 border-b-[1px] flex items-center justify-between px-5">
        <Label className="text-xl font-semibold">PDF Preview</Label>
        <div className="flex items-center">
          <TbLayoutSidebarRightExpand
            className="cursor-pointer w-6 h-6"
            onClick={() => {}}
          />
          <HiEllipsisVertical
            className="ml-3 cursor-pointer w-6 h-6"
            onClick={() => {}}
          />
        </div>
      </div>
      <SectionPdfViewer
        rawHtml={rawHtml}
        loading={processingPreview ?? false}
        isError={errorPreview ?? false}
        pdfFile={pdfBase64 ?? ""}
      />
    </div>
  );
}
export default inject("proposal")(observer(ProposalPreview));
