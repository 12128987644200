import { HiMapPin } from "react-icons/hi2";
import AutoCompleteAddress, { MAP_PROPERTY } from "../autoComplete/AutoCompleteAddress";
import AutoCompleteCountry from "../autoComplete/AutoCompleteCountry";
import MainInput from "../MainInput";

interface Props {
    onSelectedCountry:(data:any) => void
    line:string | null
    onSelectedLine:(line:string,map:MAP_PROPERTY | null) => void
    city:string| null
    setCity:(t:string) => void
    state:string | null
    onSelectedState:(line: string, data: MAP_PROPERTY | null) => void
    postal:string | null
    setPostal:(t:string) => void
    country:any
    disabled?:boolean;
}

export default function AddressContent(props:Props) {
  return (
    <div>
      <AutoCompleteAddress
        disabled = {props.disabled}
        icon={HiMapPin}
        placeholder="Enter address line"
        value={props.line}
        label="Address Line"
        onSelectedValue={props.onSelectedLine}
      />
      <div className="flex flex-1 gap-2">
        <div className="flex flex-1">
        <MainInput
          disabled = {props.disabled}
          placeholder="Enter city"
          value={props.city}
          onChange={(text) => props.setCity(text)}
          label="City"
        />
        </div>
        <div className=" flex flex-1">
        <AutoCompleteAddress
          disabled = {props.disabled}
          type="state"
          placeholder="Choose state"
          value={props.state}
          label="State"
          onSelectedValue={props.onSelectedState}
        />
        </div>
      </div>
      <div className="flex flex-1 gap-2">
        <MainInput
        disabled = {props.disabled}
          placeholder="Enter postal code"
          value={props.postal}
          onChange={(text) => props.setPostal(text)}
          label="Postal Code"
        />
        <AutoCompleteCountry
        disabled = {props.disabled}
          placeholder="Choose country"
          value={props.country}
          label="Country"
          onSelectedValue={(data) => props.onSelectedCountry(data)}
        />
      </div>
    </div>
  );
}
