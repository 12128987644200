import { useEffect } from "react";
import { IconType } from "react-icons/lib";
import { useLocation, useNavigate } from "react-router-dom";

export interface INavigationBarItem {
  id: string;
  name: string;
  route: string;
  icon?: IconType;
  subRoutes?: INavigationBarItem[];
  showKey: string;
  onShow: (t: string) => void;
  element?: any
  type?:string
}

export default function NavigationBarItem(props: INavigationBarItem) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const isActive = pathname.includes(props.route);
  const isShow = props.showKey === props.id;
  const isSelected = isActive || isShow;
  
  useEffect(() => {
    if (isSelected) {
      props.onShow(props.id);
    }
  }, []);

  const handleRoute = (route: string, e: any) => {
    e.stopPropagation();
    navigate(route);
  };

  return (
    <li
      key={props.id}
      className={"select-none"}
      onClick={(e) =>
        props.subRoutes ? props.onShow(props.id) : handleRoute(props.route, e)
      }
    >
      <div
        className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ${
          isActive || (!isShow && isSelected)
            ? "bg-white/10"
            : "hover:bg-slate-100/5"
        } dark:hover:bg-gray-700 cursor-pointer`}
      >
        {props.icon ? <props.icon size={28} className={"text-white/70"} /> : null}
        <span className={` ${ isActive || (!isShow && isSelected) ? "text-white" : "text-white/70"}  w-full ${!props.icon ? "" : "ml-3"} text-sm`}>
          {props.name}
        </span>
        {props.subRoutes ? (
          <svg
            className={`text-white/70 w-8 h-8 transition-all ${isShow ? "" : "-rotate-90"}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        ) : null}
      </div>
      {props.subRoutes ? (
        <ul
          id="dropdown-example"
          className={`${!isShow ? "" : "py-2 space-y-2"}`}
        >
          {props.subRoutes?.map((m, inx) => {
            const actve = pathname.startsWith(m.route);
            return (
              <li
                key={m.id}
                onClick={(e) => handleRoute(m.route, e)}
                className={`cursor-pointer relative pl-10 ${
                  isShow ? "h-9" : "h-0 overflow-hidden"
                } transition-all`}>
                <div
                  className={`bg-gray-500 w-1 absolute bottom-0 ${
                    inx === 0 ? "top-0" : "top-[-10px]"
                  } left-4 rounded-sm`}
                />
                <div
                  className={` text-sm flex items-center w-full p-2  font-normal text-gray-900 transition duration-75 rounded-lg group ${
                    actve ? "bg-white/10 text-white" : "hover:bg-white/10 text-white/70"
                  }  dark:hover:bg-gray-700 pl-5`}
                >
                  {m.name}
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </li>
  );
}
