import { RxDragHandleDots2 } from "react-icons/rx";
import MainInput from "src/components/MainInput";
import { useEffect, useState } from "react";
import { UDF_LIST } from "src/interfaces/udf.interface";
import UdfColorButton from "../UdfColorButton";
import { DeleteButton } from "src/components/DeleteButton";
import { HiMiniTrash } from "react-icons/hi2";
import { RESPONSE } from "src/APIs/error_handler";
import { Loader2 } from "lucide-react";
import { COLORS } from "src/assets/colors";
import { onUpdateUdfListValue } from "src/services/udf.service";
import UdfSetDefaultButton from "../UdfSetDefaultButton";

interface props {
  udfList: UDF_LIST;
  onSuccessDelete: () => void;
  onSuccessUpdate: (udfList: UDF_LIST) => void;
  onSuccessSetDefault: (list: UDF_LIST[]) => void;
}

export function UdfTypeListItem(props: props) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (props.udfList) {
      setValue(props.udfList.value);
    }
  }, []);

  const onUpdate = (name: string) => {
    setLoading(true);
    const doc: UDF_LIST = {
      ...props.udfList,
      value: name,
    };
    onUpdateUdfListValue(doc).then((res) => {
      if (res !== null) {
        props.onSuccessUpdate(doc);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const item = props.udfList;

  return (
    <li key={item.id} className={"select-none list-none"}>
      <div
        key={item.id}
        className={`bg-gray-50 border-solid border-[0.5px] rounded-sm flex items-center py-2 px-3 mb-2 text-base font-normal text-gray-900 dark:text-white hover:bg-slate-100 dark:hover:bg-black cursor-text`}
      >
        <div className={`rounded-sm mr-3`}>
          <RxDragHandleDots2 className="h-4 w-5 text-black cursor-pointer" />
        </div>
        <div className={`text-black w-full text-sm relative`}>
          <MainInput
            disableEdit={loading}
            noMarginTop
            onChange={(text) => {
              if (text === value) return;
              setValue(text);
              onUpdate(text);
            }}
            hideBorder
            value={value}
          />
          <div className="absolute right-3 top-[9px]">
            {loading ? (
              <Loader2
                color={COLORS.primary}
                className="h-4 w-4 animate-spin"
              />
            ) : null}
          </div>
        </div>
        <div className=" ml-4 mr-1">
          <UdfSetDefaultButton
            udfList={item}
            onSuccessSetDefault={props.onSuccessSetDefault}
          />
        </div>
        <div className="mx-3">
          <UdfColorButton udfList={item} />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <DeleteButton
            renderComponent={
              <HiMiniTrash className="cursor-pointer" size={16} />
            }
            deleted={(res: RESPONSE) => {
              props.onSuccessDelete();
            }}
            id={`${item.id}`}
            path={`udf-list`}
          />
        </div>
      </div>
    </li>
  );
}
