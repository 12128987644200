import { useParams } from "react-router-dom";
import ProposalContent from "./proposalContent";
import ProposalPreview from "./proposalPreview";
import ProposalSection from "./proposalSection";
import { convertStringToBoolean } from "src/utilities/mapping.utilities";
import ProposalPageHeader from "./ProposalPageHeader";

export function ProposalDetailLayout() {
  const { id, isTemplate, estimateId } = useParams();

  return (
    <div>
      <ProposalPageHeader
        isTemplate={convertStringToBoolean(isTemplate)}
        estimateId={Number(estimateId || 0)}
      />
      <div className="flex flex-1 bg-white">
        <ProposalSection
          id={Number(id)}
          isTemplate={convertStringToBoolean(isTemplate)}
        />
        <ProposalContent
          id={Number(id)}
          isTemplate={convertStringToBoolean(isTemplate)}
        />
        <ProposalPreview
          id={Number(id)}
          isTemplate={convertStringToBoolean(isTemplate)}
        />
      </div>
    </div>
  );
}
