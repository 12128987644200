import { ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "./ui/button";
import { IconType } from "react-icons/lib";

interface Props {
    label?:string;
    variant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | null | undefined
    type?:"submit" | "reset" | "button" | undefined
    onClick?:(e:any) => void
    icon?:IconType
    loading?:boolean
    size?:number
    className?:string
    disabled?:boolean
}

export default function MainButton(props:Props){
    return <Button 
    disabled = {props.loading || props.disabled}
    onClick={(e) =>  props.onClick && props.onClick(e)}
    type={props.type}
    variant={props.variant} className={`${props.className} w-full`}>
        {props.loading ? <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> : null}
        {props.icon ? <props.icon  size={props.size || 24} className={`${props.label ? 'mr-2' : ''} ${props.variant === 'outline' ? 'text-primary' : ''}`}/> : null}
        {props.label}
    </Button>
}