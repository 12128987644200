import { HiOutlineXMark } from "react-icons/hi2";
import { DrawerClose } from "./ui/drawer";

interface Props {
    onClick?:() => void
}

export default function CloseDrawerButton(props:Props) {
  return (
    <DrawerClose onClick={() => props.onClick && props.onClick()} asChild>
      <div className="rounded-full bg-slate-100 p-2 cursor-pointer  w-10 h-10 flex justify-center items-center">
        <HiOutlineXMark size={26} />
      </div>
    </DrawerClose>
  );
}
