import { Skeleton } from "src/components/ui/skeleton";

export function ProposalSectionSkeleton() {
  return (
    <div className="h-screen overflow-auto">
      <div className="m-4 mr-3 mb-40">
        {[1, 2, 3, 4, 5].map((item) => {
          return (
            <Skeleton
              key={item}
              className="h-60 rounded-sm cursor-pointer mb-4"
            />
          );
        })}
      </div>
    </div>
  );
}
