import { STEP } from "src/components/Stepper";
import AccountHeader from "./AccountHeader";
import Tab from "./Tab";
import { useEffect, useState } from "react";
import AccountForm from "./AccountForm";
import { USER } from "src/interfaces/init.interface";
import { useParams } from "react-router-dom";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import PermissionSetup from "./PermissionSetup";
import Workplace from "./Workplace";
import NoteTab from "./NoteTab";
import PageHeader from "src/components/PageHeader";
import { customerDetails } from "src/APIs/table.api";

const tabs: STEP[] = [
  { name: "Account", sequence: 1, id: 1 },
  { name: "Permissions", sequence: 2, id: 2 },
  { name: "Workplace", sequence: 3, id: 3 },
  { name: "Note", sequence: 4, id: 4 },
];

const external_tabs: STEP[] = [
  { name: "Account", sequence: 1, id: 1 },
  { name: "Workplace", sequence: 3, id: 3 },
  { name: "Note", sequence: 4, id: 4 },
];

export default function MemberDetails() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<STEP>(tabs[0]);
  const [user, setUser] = useState<USER | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }
    customerDetails(id).then((doc) => {
      setUser(doc?.data);
      setLoading(false);
    });
  }, []);

  if (loading) return <EstimateDetailsSkeleton />;

  return (
    <div className="w-screen h-screen bg-slate-50">
      <PageHeader />
      <div className="h-full w-full flex flex-1 overflow-hidden flex-col">
        <div className=" overflow-auto bg-white h-screen drop-shadow relative pb-24">
          <AccountHeader user={user} />
          <div className="sticky top-0">
            <Tab
              onTab={(t) => setActiveTab(t)}
              active={activeTab}
              steps={user?.external ? external_tabs : tabs}
            />
          </div>
         { activeTab.id === 1 ? <AccountForm updateSuccess={(doc) => setUser(doc)} user={user} /> : null}
         { activeTab.id === 2 ? <PermissionSetup updateSuccess={(doc) => setUser(doc)}   user={user} /> : null}
         { activeTab.id === 3 ? <Workplace updateSuccess={(doc) => setUser(doc)}   user={user} /> : null}
         { activeTab.id === 4 ? <NoteTab  /> : null}
        </div>
      </div>
    </div>
  );
}
