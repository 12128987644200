import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { HiCheckCircle, HiMiniPlusCircle, HiMiniXMark } from "react-icons/hi2";
import { COLORS } from "src/assets/colors";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Label } from "src/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { COLOR } from "src/interfaces/init.interface";
import { UDF_LIST } from "src/interfaces/udf.interface";
import AuthStore from "src/stores/auth.store";
import UDFStore from "src/stores/udf.store";
import { renderColor } from "src/utilities/functions.utilities";

interface Props {
  auth?: AuthStore;
  udf?: UDFStore;
}

function UdfListAdd(props: Props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState<COLOR | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.auth) {
      setColor(props.auth.colors.find((m) => m.selected === true) || null);
    }
  }, []);

  const onAdd = () => {
    if (!props.udf) return;
    setLoading(true);
    const doc: UDF_LIST = {
      value: name,
      backgroundColor: color === null ? "C4C4C4" : color.code,
      selected: false,
      sequence:
        props.udf?.selectedUdf.udfLists === undefined
          ? 0
          : props.udf?.selectedUdf.udfLists.length,
    };
    props.udf?.onAddUdfList(doc, () => {
      setLoading(false);
      setOpen(false);
      setName("");
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <HiMiniPlusCircle size={20} className="cursor-pointer" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex justify-between items-center">
            <DialogTitle>Add new item</DialogTitle>
            <HiMiniXMark
              onClick={() => {
                setOpen(false);
              }}
              size={18}
              className="cursor-pointer"
            />
          </div>
          <DialogDescription>
            Make changes to your item here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 pb-4">
          <MainInput
            noMarginTop
            label="Name"
            placeholder="Enter Name"
            value={name}
            onChange={setName}
          />
          <Label>Background</Label>
          <div className="grid gap-y-3 grid-cols-8 border-[1.5px] rounded-md p-2">
            {props.auth?.colors?.map((item) => {
              const active = item.code === color?.code;
              return (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        onClick={() => {
                          setColor(item);
                        }}
                        style={{
                          background:
                            renderColor(item.code) || COLORS.background_primary,
                        }}
                        className={`h-10 w-10 rounded-md border-[1px] cursor-pointer relative flex items-center justify-center`}
                      >
                        {active ? (
                          <HiCheckCircle
                            color={
                              item.code === "ffffff00"
                                ? COLORS.primary
                                : COLORS.white
                            }
                            className="absolute h-6 w-6 rounded-full"
                          />
                        ) : null}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{item.name}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
          </div>
        </div>
        <DialogFooter>
          <div>
            <MainButton
              onClick={() => {
                setOpen(false);
              }}
              label="Cancel"
              variant={"outline"}
            />
          </div>
          <div>
            <MainButton
              disabled={name === ""}
              loading={loading}
              label="Save"
              onClick={onAdd}
            />
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default inject("auth", "udf")(observer(UdfListAdd));
