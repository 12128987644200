import  { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { COLORS } from "../../assets/colors";
import { LAYOUTS } from "../../assets/layouts";
import "./styles.scss";
import Color from "@tiptap/extension-color";
import Gapcursor from "@tiptap/extension-gapcursor";
import Paragraph from "@tiptap/extension-paragraph";
import TextStyle from "@tiptap/extension-text-style";
import Text from "@tiptap/extension-text";
import suggestion from "./suggestion";
import Mention from "@tiptap/extension-mention";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'


import { CSSProperties } from "styled-components";
import { IconButton } from "@mui/material";
import { IoTrash } from "react-icons/io5";
import MenuBar from "./MenuBar";
import TaskItem from "@tiptap/extension-task-item";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Link from '@tiptap/extension-link'
import { Bold } from "@tiptap/extension-bold";
interface Props {
  isError: boolean;
  description?: string;
  onChange: (t: string) => void;
  height?: number;
  isChat?: boolean;
  onSend?: () => void;
  isFocus?: boolean;
  fontSize?: number;
  marginTop?: number | string | null;
  images?: any[];
  onAttachFile?: () => void;
  hideHeaderTools?:boolean;
}


const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },
})


export default function RichTextEditorWithMention(props: Props) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Paragraph,
      TextStyle,
      Color,
      Gapcursor,
      Underline,
      TaskItem,
      ListItem,
      Bold,
      TextAlign,
      CustomTableCell,
      Document,
      Paragraph,
      Text,
      Heading,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        linkOnPaste:true,
        HTMLAttributes:{
          target: null,
        }
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      OrderedList.configure({
        itemTypeName: 'listItem',
      }),
      Mention.configure({
        renderLabel(props) {
          //onSelected =>
          return props.node?.attrs?.label ?? "";
        },
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
    ],
    editorProps: {
      attributes: {
        class: 'prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
      },
    },
    content: "",
  });

  useEffect(() => {
    editor?.commands.focus();
    editor?.commands.setContent(props.description ?? "", false, {
      preserveWhitespace: "full",
    });
  }, [props.description, editor]);

  useEffect(() => {
    props.onChange(editor?.getHTML() ?? props.description ?? "");
  }, [editor?.getHTML()]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: COLORS.white,
        borderRadius: LAYOUTS.radius,
        marginTop: props.marginTop ?? LAYOUTS.margin,
        overflow: "hidden",
        borderStyle: "solid",
        borderWidth:  1,
        borderColor:COLORS.text
      }}>
     { props.hideHeaderTools ? null : <MenuBar
        onAttachFile={props.onAttachFile}
        isChat={props.isChat}
        editor={editor}
      />}
      <EditorContent
        autoFocus
        style={{
          borderTopStyle: "solid",
          borderTopWidth: 1,
          borderTopColor: COLORS.border,
          minHeight: props.height ?? "100%",
          overflowX: "hidden",
          overflowY: "auto",
          fontSize: props.fontSize,
          overscrollBehavior: "contain",
        }}
        editor={editor}
      />
      <div style={{ display: "flex", padding: LAYOUTS.padding }}>
        {props.images &&
          props.images.map((img) => {
            return (
              <div style={fileContainer}>
                {/* <UserAssetImage
                  isError={false}
                  user={undefined}
                  file={{ base64: img.content }}
                /> */}
                <IconButton
                  // disabled={deleting}
                  // onClick={() => onDeleteImage(file)}
                  size="small"
                  style={{
                    backgroundColor: COLORS.white,
                    position: "absolute",
                    top: -5,
                    right: -5,
                    borderWidth: 1,
                    borderColor: COLORS.border,
                    borderStyle: "solid",
                  }}
                >
                  {/* {deleting ? <CircularProgress size={13} /> : <IoTrash />} */}
                  {<IoTrash />}
                </IconButton>
              </div>
            );
          })}
      </div>
    </div>
  );
}

const fileContainer: CSSProperties = {
  height: 80,
  width: 85,
  borderRadius: LAYOUTS.radius,
  borderColor: COLORS.sub_text,
  opacity: "initial",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginRight: LAYOUTS.margin,
  backgroundColor: COLORS.background_primary,
  position: "relative",
  marginBottom: LAYOUTS.margin,
  boxShadow: COLORS.shadow,
};

