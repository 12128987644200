import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "src/components/ui/dialog";
import { DialogHeader, DialogFooter } from "src/components/ui/dialog";
import {
  FIELD_LIST_ITEM,
  PRODUCT,
  PRODUCTION_RATE_PAYLOAD,
} from "src/interfaces/product.interface";
import { useEffect, useState } from "react";
import MainInput from "src/components/MainInput";
import NumberInput from "src/components/NumberInput";
import MainButton from "src/components/MainButton";
import { Checkbox } from "src/components/CheckBox";
import { PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import { AutoCompleteStatic } from "src/components/autoComplete/AutoCompleteStatic";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { createProductionRate, updateProductionRate } from "src/APIs/product.api";
import { details } from "src/APIs/table.api";

interface Props {
  product?: PRODUCT | null;
  open: boolean;
  onOpen: (value: boolean) => void;
  productionRate: PRODUCTION_RATE | null;
  onCreatedProductionRate: (productionRate: PRODUCTION_RATE[]) => void;
  auth?: AuthStore;
}

function ProductionRateForm(props: Props) {
  const [enable, setEnable] = useState(true);
  const [isDefault, setDefault] = useState(false);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState<FIELD_LIST_ITEM | null>(null);
  const [rate, setrate] = useState("");
  const [processing, setProcessing] = useState(false);
  const [product, setProduct] = useState<PRODUCT | null>(null);

  useEffect(() => {
    if (!props.open) {
      setProduct(null);
      return;
    }
    if (!props.product && props.productionRate?.type) {
      details("product", props.productionRate?.productId).then((data) => {
        if (data.code === "200") {
          setProduct(data.data);
        }
      });
    } else if (props.product) {
      setProduct(props.product);
    }
  }, [props.product, props.open]);

  useEffect(() => {
    if (props.open && props.productionRate) {
      const { type, enable, selected, rate, unit } = props.productionRate ?? {};
      setName(type);
      setEnable(enable);
      setDefault(selected);
      setrate(rate.toString());
      if (unit) {
        const _unit = props.auth?.unitList.find((m) => m.id === unit);
        setUnit(_unit ?? null);
      }
    } else {
      setUnit(null);
      setName("");
      setEnable(true);
      setDefault(false);
      setrate("");
    }
  }, [props.open, props.productionRate]);

  const onSave = async () => {
    setProcessing(true);
    if (!product?.id || !unit?.id) return;
    const data: PRODUCTION_RATE_PAYLOAD = {
      type: name,
      unit: unit?.id,
      rate: Number(rate),
      enable: enable,
      selected: isDefault,
      productId: product?.id,
    };
    const res = props.productionRate?.id
      ? await updateProductionRate(data, props.productionRate?.id)
      : await createProductionRate(data);
    if (res !== null) {
      props.onCreatedProductionRate(res.data);
    }
    setProcessing(false);
    props.onOpen(false);
  };

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {props.productionRate?.id ? "Update" : "Create"} Production Rate
          </DialogTitle>
        </DialogHeader>
        <div className="w-full h-full mb-20">
          {!props.product ? (
            <AutoCompleteWithLazyLoad
              label="Product"
              placeholder="Choose product"
              path="product"
              value={product}
              onSelectedValue={(value) => setProduct(value)}
            />
          ) : null}
          <div className="flex gap-4">
            <MainInput
              required
              placeholder="Enter type name"
              value={name}
              onChange={(value) => setName(value)}
              label="Type"
            />
            <NumberInput
              placeholder="Enter rate"
              value={rate}
              onChange={(value) => setrate(value)}
              label="Rate($)"
            />
          </div>
          <div className="flex gap-4">
            <AutoCompleteStatic
              data={props.auth?.unitList || []}
              value={unit?.value ?? ""}
              placeholder="Choose unit"
              onSelectedValue={(value) => setUnit(value)}
              label="Unit Type"
            />
          </div>
          <div className="flex gap-4 mb-6 mt-4">
            <Checkbox
              value={enable}
              label="Enable"
              onChange={() => setEnable(!enable)}
            />
            <Checkbox
              value={isDefault}
              label="Default"
              onChange={() => setDefault(!isDefault)}
            />
          </div>
        </div>
        <DialogFooter>
          <MainButton
            type="button"
            variant={"outline"}
            label="Cancel"
            onClick={() => props.onOpen(false)}
          />
          <MainButton
            loading={processing}
            type="button"
            label="Save"
            onClick={() => onSave()}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default inject("auth")(observer(ProductionRateForm));
