import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Label } from "src/components/ui/label";
import { UDF_LIST } from "src/interfaces/udf.interface";
import { inject, observer } from "mobx-react";
import UDFStore from "src/stores/udf.store";
import { UdfTypeListItem } from "./UdfTypeListItem";
import { orderByAsc } from "src/utilities/formatter.utilities";
import UdfListAdd from "./UdfListAdd";

interface props {
  udf?: UDFStore;
}

function UdfListTypeList(props: props) {
  const onDragEnd = (result: any) => {
    props.udf?.onDragUdfList(result);
  };

  if (!props.udf) return <div />;

  return (
    <div className="mt-5">
      <div className="flex items-center">
        <Label className="font-semibold text-[15px] mr-3">Item of list</Label>
        <UdfListAdd />
      </div>
      <div className="mt-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`OPTION_${props.udf?.selectedUdf.id}`}>
            {(provided, snapshot) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderByAsc(
                    props.udf?.selectedUdf?.udfLists ?? [],
                    "sequence"
                  ).map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        index={index}
                        draggableId={`${item.id}`}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className="draggble"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <UdfTypeListItem
                                key={item.id}
                                udfList={item}
                                onSuccessDelete={() => {
                                  props.udf?.onDeleteUdfList(item);
                                }}
                                onSuccessUpdate={(doc) => {
                                  props.udf?.onUpdateUdfListValue(doc);
                                }}
                                onSuccessSetDefault={(list: UDF_LIST[]) => {
                                  props.udf?.onSetDefaultUdfList(list);
                                }}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default inject("udf")(observer(UdfListTypeList));
