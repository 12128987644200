import { Drawer } from "@mui/material";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { getComment } from "src/APIs/estimate_details.api";

import MainButton from "src/components/MainButton";
import MainTextArea from "src/components/MainTextArea";
import RichTextEditorWithMention from "src/components/richTextWithMention";
import { COMMENT, GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import AuthStore from "src/stores/auth.store";
import { orderByDesc } from "src/utilities/formatter.utilities";
import { v4 as uuidv4 } from "uuid";
import CommentItem from "./CommentItem";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import EmptyData from "src/components/emptyData";

interface Props {
  open: boolean;
  onClose: () => void;
  item: GROUP_OR_ITEM;
  auth?: AuthStore;
  onComment: (doc: COMMENT) => void;
  isGroup?: boolean;
  estimateId: number | string | undefined;
  onDeleteCommment: (deleteComment: COMMENT) => void;
  autoUpdateComment: (cmts: COMMENT[]) => void;
}

function CommentMui(props: Props) {
  const [comment, setComment] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [comments, setComments] = useState<COMMENT[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!showEditor) setComment("");
  }, [showEditor]);

  useEffect(() => {
    if (!props.open || !loading) return;
    if (!props.estimateId || !props.item?.id || !props.item.hasComment) {
      setLoading(false);
      return;
    }
    getComment(props.isGroup ?? false, props.estimateId, props.item?.id).then(
      (doc) => {
        const comments = orderByDesc(
          doc?.data.concat(props.item.newComments),
          "createdAt"
        );
        setComments(comments);
        props.autoUpdateComment(comments);
        setLoading(false);
      }
    );
  }, [props.open]);

  const ref = useRef<any>(null);
  const scrollToLastFruit = () => {
    const lastChildElement = ref?.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Drawer onClose={() => props.onClose()} open={props.open} anchor="right">
      <div className="flex p-4 border-b justify-between items-center">
        <span className="font-semibold text-lg">Comment</span>
        <div
          onClick={() => props.onClose()}
          className="rounded-full bg-slate-100 p-2 cursor-pointer drop-shadow-sm w-10 h-10 flex justify-center items-center"
        >
          <HiOutlineXMark size={26} />
        </div>
      </div>
      <div className="bg-white p-4 w-[600px]">
        {showEditor ? null : (
          <MainTextArea
            placeholder="What's your idea?"
            value={""}
            onClick={() => setShowEditor(true)}
          />
        )}
        {!showEditor ? null : (
          <div className="border rounded-lg overflow-hidden">
            <RichTextEditorWithMention
              hideHeaderTools
              marginTop={0}
              isFocus={true}
              onSend={() => {}}
              isChat
              height={170}
              fontSize={12}
              isError={false}
              description={comment}
              onChange={(text) => {
                setComment(text);
              }}
            />
          </div>
        )}
        {!showEditor ? null : (
          <div className="flex justify-end">
            <div className="w-32 mt-6 mr-2">
              <MainButton
                variant={"outline"}
                type="button"
                onClick={() => setShowEditor(false)}
                label="Cancel"
              />
            </div>
            <div className="w-32 mt-6">
              <MainButton
                type="button"
                onClick={() => {
                  if (
                    comment.replace(/\s/g, "") === "<p></p>" ||
                    comment.replace(/\s/g, "") === ""
                  )
                    return;
                  const doc: COMMENT = {
                    id: uuidv4(),
                    body: comment,
                    local: true,
                    username: props.auth?.authData?.fullName,
                    createdAt: new Date(),
                    profilePictureUrl:
                      props.auth?.authData?.profilePictureUrl ?? "",
                  };
                  setShowEditor(false);
                  props.onComment(doc);
                  setComments(comments.concat(doc));
                  setComment("");
                }}
                label="Submit"
              />
            </div>
          </div>
        )}
        <div className="mt-6">
          {loading ? (
            <SmallListSkeleton />
          ) : comments.length === 0 ? (
            <EmptyData
              title="No Comments"
              message="There's no comment yet. Please leave a comment here to make communication."
            />
          ) : (
            orderByDesc(comments, "createdAt").map((m: COMMENT) => {
              return (
                <CommentItem
                  onDelete={() => {
                    props.onDeleteCommment(m);
                    setComments(comments.filter((cmt) => cmt.id !== m.id));
                  }}
                  onFinishEdit={(doc) => props.onComment(doc)}
                  commment={m}
                />
              );
            })
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default inject("auth")(observer(CommentMui));
