import { useState, useEffect } from "react";
import { updateDefaultCatalogue, updateEnableCatalog } from "src/APIs/product.api";
import { Checkbox } from "src/components/CheckBox";
import { DeleteButton } from "src/components/DeleteButton";
import { Switch } from "src/components/ui/switch";
import { TableRow, TableCell } from "src/components/ui/table";
import { CATALOGUE } from "src/interfaces/estimate.interface";

import { formatMoney } from "src/utilities/formatter.utilities";

interface pRow {
  onClick: () => void;
  data: CATALOGUE;
  onDeleted: () => void;
  onDefault: (list: CATALOGUE[]) => void;
}

export default function CatalogueRow(props: pRow) {
  const { data } = props;
  const [isDefault, setDefault] = useState(false);
  const [isEnabled, setEnable] = useState(false);

  useEffect(() => {
    setDefault(props.data.selected);
    setEnable(props.data.enable);
  }, [props.data]);

  async function onDefault(doc: CATALOGUE, value: boolean) {
    setDefault(value);
    const result = await updateDefaultCatalogue(doc.id ?? 0);
    if (!result) setDefault(!value);
    props.onDefault(result?.data);
  }

  async function onEnable(doc: CATALOGUE, value: boolean) {
    setEnable(value);
    const result = await updateEnableCatalog(doc.id ?? 0, value);
    if (!result) setEnable(!value);
  }

  return (
    <TableRow
      onClick={props.onClick}
      className={"cursor-pointer"}
      key={data.id}
    >
      <TableCell>{data.name}</TableCell>
      <TableCell>${formatMoney(data.price)}</TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Checkbox
        onChange={(e) => onEnable(props.data, e)}
        value={isEnabled}
        />
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center">
          <Switch
             onCheckedChange={(v) => isDefault ? null : (onDefault(props.data, v))}
             checked={isDefault}
          />
         
        </div>
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
      <DeleteButton
            path="catalog"
            id={props.data.id ?? 0}
            deleted={(result) => props.onDeleted()}
          />
      </TableCell>
    </TableRow>
  );
}
