export const CRM_TYPES = {
  lead: {
    name: "Lead",
    id: "l",
    key: "lead",
  },
  opportunity: {
    name: "Opportunity",
    id: "o",
    key: "opportunity",
  },
  client: {
    name: "Client",
    id: "c",
    key: "client",
  },
};

export const CRM_SETUP = {
  status: {
    name: "Status",
    key: "status",
  },
  stage: {
    name: "Stage",
    key: "stage",
  },
  source: {
    name: "Source",
    key: "source",
  },
  dealType: {
    name: "Deal Type",
    key: "deal-type",
  },
  group: {
    name: "Group",
    key: "group",
  },
};

export const FIELD_TYPE = {
  list: {
    name: "List",
    key: "list",
  },
  text: {
    name: "Text",
    key: "text",
  },
  date: {
    name: "Date",
    key: "date",
  },
  number: {
    name: "Number",
    key: "number",
  },
  checkbox: {
    name: "Checkbox",
    key: "checkbox",
  },
  progress: {
    name: "Progress",
    key: "progress",
  },
  timeline: {
    name: "Timeline",
    key: "timeline",
  },
  email: {
    name: "Email",
    key: "email",
  },
  telephone: {
    name: "Telephone",
    key: "telephone",
  },
};
