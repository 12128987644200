import { HiMiniPlus } from "react-icons/hi2";
import { ReferenceImageSection } from "./ReferenceImageSection";
import MainButton from "src/components/MainButton";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";

interface Props {
  proposal?: ProposalStore;
}

function ContentReferenceImage(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};

  return (
    <div>
      {sectionDetail?.meta.map((parent) => {
        return (
          <ReferenceImageSection
            key={parent.id}
            parent={parent}
            sectionId={sectionDetail.id}
            onChange={(child) => {
              props.proposal?.onUpdateReferenceImagePayload(parent, child);
            }}
          />
        );
      })}
      <div className="flex justify-end mb-16">
        <div className="flex">
          <MainButton
            onClick={props.proposal?.onAddReferenceImage}
            variant={"outline"}
            loading={props.proposal?.processingContentUpdate}
            icon={HiMiniPlus}
            size={18}
            label="Add Another Row"
            className="flex"
          />
        </div>
      </div>
      <MainButton
        disabled={props.proposal?.disableSave}
        loading={processingContentUpdate}
        onClick={onUpdateSection}
        label="Save"
      />
    </div>
  );
}

export default inject("proposal")(observer(ContentReferenceImage));
