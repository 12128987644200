import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../../components/ui/sheet";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import Account from "./Account";
import { useEffect, useState } from "react";
import PermissionSetup from "./PermissionSetup";
import MainButton from "src/components/MainButton";
import {  HiXMark } from "react-icons/hi2";
import {
  CREATE_USER_PAYLOAD,
  PERMISSION,
  PERMISSION_CLASSIFICATION,
  ROLE,
} from "src/interfaces/init.interface";
import { orderByAsc } from "src/utilities/formatter.utilities";
import {
  isValidEmail,
  isValidPassword,
} from "src/utilities/validation.utilities";
import { nullString } from "src/utilities/functions.utilities";
import { AlertDestructive } from "src/components/AlertDestructive";
import { Checkbox } from "src/components/CheckBox";
import MemberStore from "./tables/MemberStore";
import { createUser } from "src/APIs/team.api";
import { details } from "src/APIs/table.api";
interface Props {
  disabled?: boolean;
  auth?: AuthStore;
  open: boolean;
  onOpen: (value: boolean) => void;
  member?: MemberStore;
}

function AddMember(props: Props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setRole] = useState<ROLE | null>(null);

  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [external, setExternal] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [loadingRole, setLoadingRole] = useState(false);

  const [permissions, setPermission] = useState<PERMISSION[]>([]);
  const [permissionClassifications, setPermissionClassifications] = useState<
    PERMISSION_CLASSIFICATION[]
  >([]);

  useEffect(() => {
    const { permissionClassifications } = props.auth ?? {};
    const _permissionClassifications = orderByAsc(
      permissionClassifications ?? [],
      "sequence"
    );
    setPermissionClassifications(_permissionClassifications);
    return () => {};
  }, [props.auth?.permissionClassifications]);

  function onPermission(v: PERMISSION_CLASSIFICATION, permission: PERMISSION) {
    if (permissions.find((m) => m.id === permission.id)) {
      if (permission.parentId !== null) {
        setPermission(
          permissions.filter(
            (m) =>
              m.id !== permission.id &&
              m.parentId !== permission.id &&
              m.id !== permission.parentId
          )
        );
        return;
      }
      setPermission(
        permissions.filter(
          (m) => m.id !== permission.id && m.parentId !== permission.id
        )
      );
    } else {
      setPermission(
        permissions.concat(
          v.permissions.filter(
            (m) => m.id === permission.id || m.parentId === permission.id
          )
        )
      );
    }
  }

  function onChangeRole(role: ROLE) {
    if (!role) {
      setRole(selectedRole);
      setPermission([]);
    } else {
      setRole(role);
      setLoadingRole(true);
      role?.id &&
        details("role", role?.id).then((doc) => {
          if (doc.code !== "200") {
            setLoadingRole(false);
            return;
          }
          setLoadingRole(false);
          setPermission(doc?.data.permissions);
        });
    }
  }

  function isValid() {
    let msg: string[] = [];
    const isErrorLastName = nullString(lastName ?? "") === null;
    if (isErrorLastName) msg = msg.concat("Please enter Last Name \n");
    const isErrorFirstName = nullString(firstName ?? "") === null;
    if (isErrorFirstName) msg = msg.concat("Please enter First Name \n");

    const isErrorEmail =
      nullString(email ?? "") === null || isValidEmail(email ?? "") === false;
    if (isErrorEmail) msg = msg.concat("Email is invalid \n");

    if (!external) {
      const isErrorUserName = nullString(username ?? "") === null;
      if (isErrorUserName) msg = msg.concat("Please enter Username \n");

      const isErrorPassword = isValidPassword(password ?? "") === false;
      if (isErrorPassword)
        msg = msg.concat("Please enter password (Minimum 8 characters) \n");

      const isERole = nullString(selectedRole?.name ?? "") === null;
      if (isERole) msg = msg.concat("Please choose role \n");
    }

    return msg;
  }

  function onCreate() {
    const validMessage = isValid();
    if (validMessage.length > 0) {
      setErrorMsg(validMessage);
      return;
    }
    setProcessing(true);
    setErrorMsg([]);
    const doc: CREATE_USER_PAYLOAD = {
      lastName: lastName,
      firstName: firstName,
      email: email,
      username: username,
      confirmPassword: password,
      enable: true,
      lock: false,
      roleId: selectedRole?.id!,
      external: external,
      permissions: permissions.map((m) => m.id),
    };
    createUser(doc).then((result) => {
      const fullName = `${firstName} ${lastName}`;
      if (result) {
        result.data.fullName = fullName;
      } else {
        setProcessing(false);
        return;
      }
      props.member?.updateTable(result?.data);
      setProcessing(false);
      props.onOpen(false);
    });
  }

  return (
    <Sheet 
    onOpenChange={(v) => props.onOpen(v)}
    open={props.open}>
      <SheetContent className=" overflow-auto h-[99%] w-[50%]">
        <SheetHeader className="flex items-center flex-row justify-between">
          <SheetTitle>{"Create New Member"}</SheetTitle>
          <SheetClose>
            <HiXMark size={22} />
          </SheetClose>
        </SheetHeader>
        {errorMsg.length > 0 ? (
          <div className="mb-4 bg-white drop-shadow rounded-md">
            <AlertDestructive title="Missing Information" messages={errorMsg} />
          </div>
        ) : null}
        <div className="flex mt-2">
          <Checkbox
            value={external}
            onChange={(value) => setExternal(value)}
            label="External"
          />
        </div>
        <Account
          external={external}
          role={selectedRole}
          onRole={onChangeRole}
          firstName={firstName}
          lastName={lastName}
          username={username}
          email={email}
          password={password}
          onFirstName={(t) => setFirstName(t)}
          onLastName={(t) => setLastName(t)}
          onUserName={(t) => setUsername(t)}
          onEmail={(t) => setEmail(t)}
          onPassword={(t) => setPassword(t)}
        />

        {external ? null : (
          <PermissionSetup
            permissions={permissions}
            permissionClassifications={permissionClassifications}
            onPermission={onPermission}
            processing={loadingRole}
            role={selectedRole}
          />
        )}
        <SheetFooter>
        <div className="w-52">
            <MainButton
              variant={"outline"}
              loading={processing}
              type="button"
              label="Cancel"
              onClick={() => props.onOpen(false)}
            />
          </div>
          <div className="w-52">
            <MainButton
              loading={processing}
              type="button"
              label="Create Account"
              onClick={() => onCreate()}
            />
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

export default inject("auth", "member")(observer(AddMember));
