import {
  HiHome,
  HiKey,
  HiMiniBuildingOffice2,
  HiMiniBuildingStorefront,
  HiMiniPuzzlePiece,
  HiMiniRectangleGroup,
  HiMiniSquare2Stack,
  HiMiniUserGroup,
} from "react-icons/hi2";
import { v4 as uuidv4 } from "uuid";
import { INavigationBarItem } from "../components/navigationBar/NavigationBarItem";
export default [
  {
    id: uuidv4(),
    name: "Dashboard",
    route: "dashboard",
    icon: HiHome,
  },
  {
    id: uuidv4(),
    name: "Contacts",
    route: "crm/contact",
    icon: HiMiniUserGroup,
  },
  {
    id: uuidv4(),
    name: "Vendors and Sub-Contractors",
    route: "ven",
    icon: HiMiniBuildingStorefront,
    subRoutes: [
      {
        id: uuidv4(),
        name: "Sub-Contractors",
        route: "//",
      },
      {
        id: uuidv4(),
        name: "Vendors",
        route: "//",
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Estimate and Invoices",
    route: "estimate-and-invoices",
    icon: HiMiniSquare2Stack,
    subRoutes: [
      {
        id: uuidv4(),
        name: "Estimate",
        route: "/main/estimate-and-invoices/estimate",
      },
      {
        id: uuidv4(),
        name: "Proposal",
        route: "/main/estimate-and-invoices/proposal",
      },
      {
        id: uuidv4(),
        name: "Invoices",
        route: "//",
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Products and Services",
    route: "product",
    icon: HiMiniPuzzlePiece,
  },
  {
    id: uuidv4(),
    name: "Teams",
    route: "team",
    icon: HiMiniBuildingOffice2,
    subRoutes: [
      {
        id: uuidv4(),
        name: "Members",
        route: "/main/team/members",
      },
      {
        id: uuidv4(),
        name: "Teams",
        route: "/main/team/teams",
      },
      {
        id: uuidv4(),
        name: "Departments",
        route: "/main/team/departments",
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Administration",
    route: "/admin",
    icon: HiKey,
    subRoutes: [
      {
        id: uuidv4(),
        name: "Roles",
        route: "/main/admin/role",
      },
      {
        id: uuidv4(),
        name: "Coporate",
        route: "//",
      },
      {
        id: uuidv4(),
        name: "Billing",
        route: "//",
      },
      {
        id: uuidv4(),
        name: "User defined fields",
        route: "/main/admin/user-defined",
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Task List",
    route: "//",
    icon: HiMiniRectangleGroup,
  },
] as INavigationBarItem[];
