import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import { TableRow } from "../../../../components/ui/table";
import { useEffect } from "react";
import { displayDate, formatMoney } from "src/utilities/formatter.utilities";
import CrmTableBodyCell from "./CrmTableBodyCell";
import { CRM_TYPE } from "src/interfaces/crm.interface";

interface Props {
  crmType: CRM_TYPE;
  columns: ENTITY_CONFIG[];
  item: any;
}

const CrmTableBodyRow = (props: Props) => {
  useEffect(() => {}, []);

  function renderValue(field: string) {
    const data = props.item;
    const fields = field.split(".");
    const value = fields.length > 1 ? fieldData(field, data) : data[field];
    if (
      field === "createdAt" ||
      field === "updatedAt" ||
      field === "joinedDate" ||
      field === "validTill"
    ) {
      return displayDate(value);
    } else if (field === "customer.createdAt") {
      return displayDate(value);
    } else if (field === "paid") {
      return value;
    } else if (field === "enable") {
      return value ? "Active" : "Inactive";
    } else if (
      field === "cost" ||
      field === "price" ||
      field === "costAfterMarkup" ||
      field === "dealValue"
    ) {
      return typeof value === "number" || value === null
        ? `$${formatMoney(value)}`
        : `$${value}`;
    } else if (field === "admin") {
      return value ? "Yes" : "No";
    } else if (field === "entityConfigurations") {
      // const columns = value.map((m: IEntity) => m.displayName).join(", ");
      // return columns;
    } else if (field === "telephone1") {
      // if (value === null) return "N/A";
      // return new AsYouType().input(value);
    } else if (field === "sequence") {
      return value;
    } else if (field === "selected") {
      return value ? "Yes" : "No";
    } else {
      return value ? value?.toString() : "N/A";
    }
  }

  function fieldData(fields: string, data: any) {
    return fields.split(".")?.reduce((o, d) => (o ? o[d] : ""), data);
  }

  return (
    <TableRow className=" h-14 cursor-pointer">
      {props.columns.map((item) => {
        return (
          <CrmTableBodyCell
            crmType={props.crmType}
            rowId={props.item.id}
            udfLinks={props.item.udfLinks}
            column={item}
            defaultValue={renderValue(item.columnName)}
          />
        );
      })}
    </TableRow>
  );
};

export default CrmTableBodyRow;
