import React, { useEffect } from "react";
import { TableCell } from "src/components/ui/table";
import { COLUMNS } from "./ProductDetails";

interface Props {
  children: React.ReactNode;
  id: string;
  itemId: string;
  firstCell?: boolean;
  secondCell?: boolean;
}

export default function EstimateTableCell(props: Props) {
  useEffect(() => {
    const header = document.getElementById(props.id);
    if (props.firstCell) {
      const firstCell = document.getElementById(
        `${props.itemId}_${COLUMNS[0].accessorKey}_div`
      );
      if (firstCell) {
        firstCell.style.width = `${header?.clientWidth}px`;
      }
    } else if (props.secondCell) {
      const secondCell = document.getElementById(
        `${props.itemId}_${COLUMNS[1].accessorKey}_div`
      );
      if (secondCell) {
        secondCell.style.width = `${header?.clientWidth}px`;
      }
    } else {
      const cell = document.getElementById(`${props.id}_${props.itemId}_cell`);
      const element = document.getElementById(props.id);
      const minWidth = COLUMNS.find(m => m.accessorKey === props.id)?.width
      if(cell){
        cell.style.width = `${element?.clientWidth}px`;
        cell.style.minWidth = `${minWidth}px`;
      }
    }
  }, []);
  return (
    <TableCell
      id={`${props.id}_${props.itemId}_cell`}
      className={`px-0 relative ${!props.firstCell ? "border-l" : ""}`}
    >
      {props.children}
    </TableCell>
  );
}