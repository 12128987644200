import { useEffect, useState } from "react";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
  onChange?: (t: string) => void;
  disabled?: boolean;
  onClick?: (e: any) => void;
  active?: boolean;
  hideBorder?: boolean;
  height?:number;
}

export default function MainTextArea(props: Props) {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value]);

  return (
    <div className="mt-4 relative">
      {props.label ? <Label>{props.label}</Label> : null}
      <Textarea
        disabled={
          (props.onClick ? true : false) || !props.onChange || props.disabled
        }
        onBlur={(e) => {
          props.onChange && props.onChange(text);
        }}
        onChange={(e) => setText(e.target.value)}
        value={text}
        className={`resize-none h-24 mt-2 bg-white ${props.onClick && !props.disabled
          ? "disabled:text-muted-foreground border border-gray-400"
          : ""
          } ${props.active ? "border border-secondary" : props.hideBorder ? "border-none shadow-transparent" : ""}`}
        placeholder={props.placeholder}
        style={{minHeight:props.height}}
      />
      {props.onClick && !props.disabled ? (
        <div
          className="absolute left-0 right-0 top-0 bottom-0 cursor-text"
          onClick={(e) => props.onClick && props.onClick(e)}
        />
      ) : null}
    </div>
  );
}
