import { HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import MainButton from "src/components/MainButton";
import { Label } from "src/components/ui/label";

interface Props {
  onEditContact: () => void;
}

export default function ContactDetailHeader(props: Props) {
  const navigate = useNavigate();
  return (
    <div className="flex h-14 bg-white p-4 drop-shadow justify-between items-center">
      <Label className="text-xl font-bold uppercase">Contact Detail</Label>
      <div className="flex">
        <MainButton
          variant={"link"}
          type={"button"}
          label={"Edit"}
          onClick={props.onEditContact}
        />
        <MainButton
          variant={"link"}
          type={"button"}
          onClick={() => navigate("/main/crm/contact")}
          icon={HiXMark}
        />
      </div>
    </div>
  );
}
