import { Outlet } from "react-router-dom";
import SideBar from "src/components/SideBar";
import NavigationBar from "src/components/navigationBar/NavigationBar";
import routeConfig from "src/routes/route.config";

export default function MainLayout() {
  return (
    <div className="h-screen w-screen flex">
      <NavigationBar routes={routeConfig} />
      <div className="flex flex-1 flex-col overflow-hidden">
        <SideBar />
        <Outlet />
      </div>
    </div>
  );
}
