import { makeAutoObservable, observable, action } from "mobx";
import { updateObjArray } from "src/utilities/functions.utilities";
import { deepStaticSearch } from "src/utilities/mapping.utilities";

export default class TeamStore {
  @observable data: any[] = [];
  @observable rawData: any[] = [];


  constructor() {
    makeAutoObservable(this);
  }

  @action setData(data: any[]) {
    this.rawData = data;
    this.data = data;
  }

  @action changeStatus(data: any[]) {
    this.data = data;
  }

  @action updateTable(doc: any) {
    this.data = [doc, ...this.data];
    this.rawData = [doc, ...this.data];
  }

  @action updateData(doc: any) {
    this.data = [...updateObjArray(this.data, doc)];
  }

  @action onDeepSearch(text:string,keySearch:string) {
    if(text){
      this.data = deepStaticSearch(this.rawData,text,keySearch)
    }else {
      this.data = this.rawData;
    }
  }
}
