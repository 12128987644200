import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { nullString } from "src/utilities/functions.utilities";
import { TEAM } from "src/interfaces/team.interface";
import { LAYOUTS } from "src/assets/layouts";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import { Checkbox } from "src/components/CheckBox";
import { createTeam } from "src/APIs/team.api";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  createdTeam: (department: TEAM) => void;
}

function AddTeam(props: Props) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("0");

  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [external, setExternal] = useState(false);

  React.useEffect(() => {
    setName("");
  }, [props.isOpen]);

  function onSave() {
    setLoading(true);
    if (nullString(name) === null) {
      setError(true);
      setLoading(false);
    } else {
      createTeam(name, price ? Number(price) : 0, external).then((data) => {
        setLoading(false);
        setError(false);
        props.createdTeam(data?.data);
        props.onClose();
      });
    }
  }

  return (
    <Dialog open={props.isOpen} onClose={() => props.onClose()}>
      <DialogTitle>Create Team</DialogTitle>
      <DialogContent className="w-96 flex flex-col items-end">
        <MainInput
          value={name}
          onChange={(t) => setName(t)}
          label="Name"
        />
        <MainInput
          prefix="$"
          extendClass="mb-4"
          value={price}
          onChange={(t) => setPrice(t)}
          label="Price"
        />
        <Checkbox
          value={external}
          onChange={() => setExternal(!external)}
          label="External"
        />
      </DialogContent>
      <DialogActions style={{ paddingBottom: LAYOUTS.padding }}>
        <MainButton
          type="button"
          onClick={() => props.onClose()}
          variant={"outline"}
          label="Cancel"
        />
        <MainButton
          type="button"
          onClick={onSave}
          label="Save"
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AddTeam;
