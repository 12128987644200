import { Dialog, DialogContent, DialogTitle } from "src/components/ui/dialog";
import { DialogHeader, DialogFooter } from "src/components/ui/dialog";
import { CATALOGUE_PAYLOAD, PRODUCT } from "src/interfaces/product.interface";
import { useEffect, useState } from "react";
import MainInput from "src/components/MainInput";
import NumberInput from "src/components/NumberInput";
import MainButton from "src/components/MainButton";
import { Checkbox } from "src/components/CheckBox";
import UploadFile from "src/components/UploadFile";
import { CATALOGUE } from "src/interfaces/estimate.interface";

import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { onGetImages } from "src/services/auth.service";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import { createCatalogue, updateCatalogue } from "src/APIs/product.api";
import { details } from "src/APIs/table.api";
import { CatalogueHistory } from "./CatalogueHistory";

interface Props {
  product?: PRODUCT | null;
  open: boolean;
  onOpen: (value: boolean) => void;
  catalogue: CATALOGUE | null;
  onCreatedCatalogue: (catalogue: CATALOGUE[]) => void;
}

export function CatalogueForm(props: Props) {
  const [enable, setEnable] = useState(true);
  const [isDefault, setDefault] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [processing, setProcessing] = useState(false);
  const [product, setProduct] = useState<PRODUCT | null>(null);
  const [loadingImg, setLoadingImg] = useState(true);

  useEffect(() => {
    if (!props.open) {
      setProduct(null);
      setLoadingImg(true);
      return;
    }
    if (!props.product && props.catalogue?.productId) {
      details("product", props.catalogue?.productId).then((data) => {
        if (data.code === "200") {
          setProduct(data.data);
        }
      });
    } else if (props.product) {
      setProduct(props.product);
    }
  }, [props.product, props.open]);

  useEffect(() => {
    if (props.catalogue?.id) {
      onGetImages("catalog", props.catalogue?.id).then((res) => {
        if (!res) {
          setLoadingImg(false);
          return;
        }
        setLoadingImg(false);
        setImages(res as any);
      });
    }
  }, [props.catalogue]);

  useEffect(() => {
    if (props.open && props.catalogue) {
      const { name, enable, selected, price } = props.catalogue;
      setName(name);
      setEnable(enable);
      setDefault(selected);
      setPrice(price.toString());
      setImages(images);
    } else {
      setName("");
      setEnable(true);
      setDefault(false);
      setPrice("");
      setImages([]);
    }
  }, [props.open, props.catalogue]);

  const onSave = async () => {
    setProcessing(true);
    if (!product?.id) return;
    const data: CATALOGUE_PAYLOAD = {
      name: name,
      price: Number(price),
      enable: enable,
      selected: isDefault,
    };
    const res = props.catalogue?.id
      ? await updateCatalogue(data, props.catalogue?.id)
      : await createCatalogue(data, product?.id);
    if (res !== null) {
      if (props.catalogue?.id) {
        props.onOpen(false);
      }
      props.onCreatedCatalogue(res.data);
    }
    setTimeout(() => {
      setProcessing(false);
    }, 1500);
  };

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex justify-between flex-1 items-center">
            <div className="w-full">
            {props.catalogue?.id ? "Update" : "Create"} Catalogue
            </div>
           { props.catalogue ? <div><CatalogueHistory catalogue={props.catalogue} /></div> : null}
          </DialogTitle>
        </DialogHeader>
        <div className="w-full h-full mb-20">
          {!props.product ? (
            <AutoCompleteWithLazyLoad
              label="Product"
              placeholder="Choose product"
              path="product"
              value={product}
              onSelectedValue={(value) => setProduct(value)}
            />
          ) : null}
          <div className="flex gap-4">
            <MainInput
              required
              placeholder="Enter catalogue name"
              value={name}
              onChange={(value) => setName(value)}
              label="Name"
            />
            <NumberInput
              placeholder="Enter price"
              value={price}
              onChange={(value) => setPrice(value)}
              label="Price($)"
            />
          </div>
          <div className="flex gap-4 mb-6 mt-4">
            <Checkbox
              value={enable}
              label="Enable"
              onChange={() => setEnable(!enable)}
            />
            <Checkbox
              value={isDefault}
              label="Default"
              onChange={() => setDefault(!isDefault)}
            />
          </div>
          {props.catalogue?.id ? (
            loadingImg ? (
              <SmallListSkeleton />
            ) : (
              <UploadFile
                uploadedImage={(img) => setImages(images.concat(img))}
                images={images ?? []}
                imagePath={`catalog/${props.catalogue.id}/get-image`}
                path={`catalog/${props.catalogue.id}/upload-image`}
                accepted={[".jpg", ".jpeg", "png"]}
              />
            )
          ) : null}
        </div>
        <DialogFooter className="flex">
          <MainButton
            type="button"
            variant={"outline"}
            label="Cancel"
            onClick={() => props.onOpen(false)}
          />
          <MainButton
            loading={processing}
            type="button"
            label="Save"
            onClick={() => onSave()}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
