import { useState } from "react";
import { HiMiniXMark, HiPlusSmall } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Label } from "src/components/ui/label";
import { ContentTitle } from "../proposalContent/ContentTitle";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import ProposalMainInput from "../proposalContent/dynamicComponent/ProposalMainInput";
import MainInputNoState from "src/components/MainInputNoState";

interface Props {
  proposalId: number;
  proposal?: ProposalStore;
}

const AddSectionButton = (props: Props) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <MainButton
          className=" mt-4"
          variant={"outline"}
          icon={HiPlusSmall}
          label="Add"
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <ContentTitle title={"Add New Page"} />
            <HiMiniXMark
              onClick={() => {
                setOpen(!open);
              }}
              className="h-5 w-5 cursor-pointer"
            />
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="">
            <Label className="text-lg text-gray-500">Page Name</Label>
            <MainInputNoState
              placeholder="Enter page name"
              value={name}
              onChange={setName}
              className=" mt-3"
            />
          </div>
        </div>
        <DialogFooter>
          <MainButton
            disabled={name === ""}
            loading={props.proposal?.processingCreateSection}
            onClick={() => {
              props.proposal?.onCreateSection(props.proposalId, name, () => {
                setOpen(!open);
                setName("");
              });
            }}
            className="mt-4 bg-secondary"
            label="Add Page"
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default inject("proposal")(observer(AddSectionButton));
