import { Outlet } from "react-router-dom";
import SubSidebar from "src/components/subSidebar/SubSidebar";

import {  teams } from "src/routes/routeNav.config";

export default function TeamListLayout() {
  return (
    <div className="flex flex-1 flex-col">
      <SubSidebar routes={teams} />
      <Outlet />
    </div>
  );
}
