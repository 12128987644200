import { useState } from "react";
import { Label } from "src/components/ui/label";
import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import { Skeleton } from "src/components/ui/skeleton";

interface Props {
  item: PROPOSAL_TEMPLATE;
  index: number;
  active: boolean;
  onClick?: () => void;
}
export const ProposalTemplateItem = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div className="mb-4">
      <div>
        {loading ? (
          <Skeleton className="h-60 w-40 rounded-sm cursor-pointer mr-2" />
        ) : error ? (
          <div
            onClick={props.onClick}
            className={`${props.active ? "border-2" : "border-[0.5px]"} ${
              props.active ? "border-primary" : "border-gray-500"
            } flex-1 h-60 w-40 flex items-center justify-center rounded-sm cursor-pointer`}
          >
            {`${props.index + 1}`}
          </div>
        ) : (
          <div
            onClick={props.onClick}
            className={` hover:bg-slate-50 ${
              props.active ? "border-2" : "border-[0.5px]"
            } ${
              props.active ? "border-primary" : "border-gray-500"
            } flex-1 h-60 w-48 mr-4 flex items-center justify-center rounded-sm cursor-pointer`}
          >
            {`${props.index + 1}`}
          </div>
        )}
      </div>
      <Label
        className={`${
          props.active ? "text-primary" : "text-gray-500"
        } mt-[10px] cursor-pointer flex items-center justify-center`}
      >
        {props.item.name}
      </Label>
    </div>
  );
};
