import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import { Input } from "src/components/ui/input";
import { TableCell } from "src/components/ui/table";

interface Props {
  value: any;
  onSave: (v: any, onSuccess: () => void) => void;
}

export const ValueEmail = (props: Props) => {
  const [email, setEmail] = useState("");
  const [editing, setEdting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(props.value);
  }, [props.value]);

  const onUpdate = () => {
    if (props.value === email) {
      setEdting(false);
      return;
    }
    setLoading(true);
    props.onSave(email, () => {
      setLoading(false);
      setEdting(false);
    });
  };

  return (
    <TableCell className="border-r-[0.9px] p-0 px-[1px]">
      {editing ? (
        <div className="relative flex">
          <Input
            autoFocus
            value={email}
            onBlur={(e) => {
              onUpdate();
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDownCapture={(e) => {
              if (e.key === "Enter") {
                onUpdate();
              }
            }}
            className="min-h-[46px] bg-white"
            type="email"
            placeholder="Enter email"
          />
          {loading ? (
            <Loader2
              color={COLORS.primary}
              className="h-4 w-4 animate-spin absolute right-2 top-4"
            />
          ) : null}
        </div>
      ) : (
        <div
          onClick={() => {
            setEdting(true);
          }}
          className="hover:bg-white min-h-[48px] flex items-center cursor-text px-1"
        >
          {email}
        </div>
      )}
    </TableCell>
  );
};
