import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainTable from "src/components/tableComponents/MainTable";
import { ESTIMATE_TEMPLATE } from "src/constants/static_entity.constants";


export default function PresetGroupListPage() {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);



  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        onAdd={() => navigate(`/create-preset-group`)}
        height={height}
        onClick={(row: any) => navigate(`/create-preset-group/${row?.id}`)}
        entityCode={ESTIMATE_TEMPLATE.code}
        path="estimate-template"
      />
    </div>
  );
}
