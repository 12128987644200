import { HiChevronDown } from "react-icons/hi2";
import { COLORS } from "src/assets/colors";

interface Props {
  checked: boolean;
  onChange: () => void;
  hasChildren?:boolean;
  rowId:string
  isSummary?:boolean;
}
export default function CollapsedButton(props: Props) {
  return (
    <div
      onClick={() => props.onChange()}
      className={ props.isSummary ? "mr-2  w-6 h-6 cursor-pointer hover:opacity-70" : "hover:opacity-80 mr-2 cursor-pointer bg-white rounded-full w-6 h-6 flex items-start justify-center relative border"}>
      <HiChevronDown className={`text-slate-500 ${props.checked ? "-rotate-90" : ""} transition-all mt-[1.4px]`} size={18} />
     { props.checked || !props.hasChildren ? null : <div className="h-4 w-[2px] absolute bottom-[-17px]" style={{backgroundColor:COLORS.border, left: 9}} />}
    </div>
  );
}
