import Lottie from "lottie-react";
import { lotties } from "src/assets/lotties";
import { Label } from "./ui/label";

export default function CommingSoon() {
  return (
    <div className="flex mt-32 items-center justify-center">
      <div>
        <Lottie
          className="h-32 w-32"
          animationData={lotties.COMMING_SOON}
          loop={true}
        />
        <Label className="text-lg font-light ">
          We're sorry, the page you are looking for is in development.
        </Label>
      </div>
    </div>
  );
}
