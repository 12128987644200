import { Skeleton } from "src/components/ui/skeleton";

export const UdfSkeletonList = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((item, index) => {
        return (
          <Skeleton
            key={index}
            className={`h-12 rounded-sm flex items-center py-3 px-5 mb-3 text-base font-normal text-gray-900 ${
              index === 1 || index === 4 ? "mr-40" : ""
            }`}
          />
        );
      })}
    </div>
  );
};
