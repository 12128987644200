import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import { Skeleton } from "src/components/ui/skeleton";
import { ProposalTemplateItem } from "./AddProposalTemplate/ProposalTemplateItem";

interface props {
  list: PROPOSAL_TEMPLATE[];
  loading: boolean;
  onClick: (v: PROPOSAL_TEMPLATE) => void;
}

export const TempateList = (props: props) => {
  return (
    <div>
      {props.loading ? (
        <div className="mt-5 grid gap-x-8 gap-y-4 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
            return (
              <Skeleton className="h-60 w-48 mr-3 rounded-sm cursor-pointer mb-4" />
            );
          })}
        </div>
      ) : (
        <div className="mt-5 grid gap-x-8 gap-y-4 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-2">
          {props.list.map((item, index) => {
            return (
              <ProposalTemplateItem
                key={item.id}
                onClick={() => props.onClick(item)}
                item={item}
                index={index}
                active={false}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
