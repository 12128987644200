import { useRef, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import { Dialog, DialogContent } from "src/components/ui/dialog";
import { Label } from "src/components/ui/label";
import SignaturePad from "react-signature-pad-wrapper";
import MainButton from "src/components/MainButton";
import { convertHTMLToImage } from "src/utilities/formatter.utilities";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import { onUploadImageBySection } from "src/services/proposal.service";
import { v4 as uuidv4 } from "uuid";
import { META_DATA } from "src/interfaces/proposal.interface";

interface Props {
  child: META_DATA;
  parent: META_DATA;
  open: boolean;
  onOpen: (value: boolean) => void;
  proposal?: ProposalStore;
}

function SignaturePopUp(props: Props) {
  const ref = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const uploadSignature = async () => {
    if (!props.proposal?.sectionDetail?.id) return;
    setLoading(true);
    const image = await convertHTMLToImage(ref.current.canvas.current);

    var newImage = await onUploadImageBySection(
      props.proposal?.sectionDetail?.id,
      { id: uuidv4() + ".png", base64: image.href }
    );
    if (!newImage) {
      setLoading(false);
      return;
    }
    if (newImage.id) {
      const childData = {
        ...props.child,
        value: `${newImage.id}`,
      };

      props.proposal.onUpdateProposalSignOff4ImagePayload(
        props.parent,
        childData,
        () => {
          props.proposal?.onUpdateSection(() => {
            setLoading(false);
            props.onOpen(!props.open);
          });
        }
      );
    }
  };

  return (
    <>
      <Dialog onOpenChange={(v) => props.onOpen(v)} open={props.open}>
        <DialogContent className="sm:max-w-[425px] p-4 z-[9999999]">
          <div className="flex items-center justify-between">
            <Label className="font-semibold">Signature</Label>
            <HiMiniXMark
              className=" cursor-pointer"
              size={23}
              onClick={() => {
                props.onOpen(false);
              }}
            />
          </div>
          <div
            className={`h-64 border-[1.8px] border-dashed rounded-sm mt-3 cursor-pointer flex items-center justify-center bg-muted`}
          >
            <SignaturePad
              ref={ref}
              options={{
                minWidth: 0.5,
                maxWidth: 2.5,
              }}
            />
          </div>
          <div className="flex justify-end">
            <div className="flex">
              <MainButton
                variant={"outline"}
                label="Clear"
                onClick={() => {
                  ref.current?.clear();
                }}
                className=" mr-3"
              />
              <MainButton
                label="Save"
                loading={loading}
                onClick={uploadSignature}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default inject("proposal")(observer(SignaturePopUp));
