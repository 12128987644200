import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useEffect, useState } from "react";
import { FILE } from "src/interfaces/auth.interface";

interface IMAGESHOW {
  src: string;
  alt?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  selectedImage: FILE;
  images: FILE[];
}

export function MainImageViewer(props: Props) {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<IMAGESHOW[]>([]);
  const [startingSlideIndex, setStartingSlideIndex] = useState(0);
  const [showThumbnails, setShowThumbnails] = useState(true);

  useEffect(() => {
    setLoading(true);
    const index = props.images.findIndex(
      (m) => m.id === props.selectedImage.id
    );
    setStartingSlideIndex(index);
    setImages(props.images.map((m) => ({ src: m.url ?? "" })));
    if (props.images.length === 1) {
      setShowThumbnails(false);
    }
    setLoading(false);
  }, [props.selectedImage]);

  return (
    <SlideshowLightbox
      showLoader={loading}
      startingSlideIndex={startingSlideIndex}
      images={images}
      showThumbnails={showThumbnails}
      open={props.open}
      lightboxIdentifier="lbox1"
      onClose={props.onClose}
      className="z-50"
    ></SlideshowLightbox>
  );
}
