import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import MainButton from "src/components/MainButton";
import { SECTIONS } from "src/constants/proposal.constants";
import { TemporarySection } from "./temporarySection";

interface Props {
  proposal?: ProposalStore;
}

function TemporaryContent(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};
  const readOnly =
    sectionDetail?.template === SECTIONS.projectProposal.template;

  return (
    <div>
      {sectionDetail?.meta.map((parent) => {
        return (
          <TemporarySection
            readonly={readOnly}
            key={parent.id}
            parent={parent}
            onChange={(child) => {
              props.proposal?.onUpdatePayload2Level(parent, child);
            }}
          />
        );
      })}
      {readOnly === true ? null : (
        <MainButton
          disabled={props.proposal?.disableSave}
          loading={processingContentUpdate}
          onClick={onUpdateSection}
          label="Save"
        />
      )}
    </div>
  );
}

export default inject("proposal")(observer(TemporaryContent));
