import { Loader2 } from "lucide-react";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import { Checkbox } from "src/components/CheckBox";
import { Label } from "src/components/ui/label";
import { UDF } from "src/interfaces/udf.interface";
import UDFStore from "src/stores/udf.store";

interface Props {
  udf?: UDFStore;
  label?: string;
  // used for render enable button on list
  item?: UDF;
}

function UdfEnableButton(props: Props) {
  // const [open, setOpen] = useState(false);
  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false);

  const { selectedUdf } = props.udf ?? {};

  useEffect(() => {
    if (props.item) {
      setEnable(props.item.enable);
    }
  }, [selectedUdf?.enable]);

  return (
    <div>
      {props.label ? (
        <div>
          {loading ? (
            <div className="flex items-center">
              <Loader2
                color={COLORS.primary}
                className="h-4 w-4 my-1 animate-spin"
              />
              <Label className="ml-2">{props.label}</Label>
            </div>
          ) : (
            <Checkbox
              label={props.label}
              clasName={"h-[20px] w-[20px]"}
              onChange={(v) => {
                setLoading(true);
                props.udf?.onEnableUdf(enable, () => {
                  setLoading(false);
                  setEnable(!enable);
                });
              }}
              value={enable}
            />
          )}
        </div>
      ) : (
        <div>
          {loading ? (
            <Loader2
              color={COLORS.primary}
              className="h-4 w-4 my-1 animate-spin"
            />
          ) : (
            <Checkbox
              clasName={"h-[15px] w-[15px]"}
              onChange={(v) => {
                setLoading(true);
                props.udf?.onEnableUdf(enable, () => {
                  setLoading(false);
                  setEnable(!enable);
                });
              }}
              value={enable}
            />
          )}
        </div>
      )}
    </div>
    // <Dialog
    //   open={open}
    //   onOpenChange={() => {
    //     if (!open) {
    //       setOpen(true);
    //     }
    //   }}
    // >
    //   <DialogTrigger>
    //     <Checkbox
    //       label={props.label}
    //       clasName={props.label ? "h-[18px] w-[18px]" : "h-[15px] w-[15px]"}
    //       onChange={(v) => {
    //         setOpen(true);
    //       }}
    //       value={enable}
    //     />
    //   </DialogTrigger>
    //   <DialogContent className="sm:max-w-md">
    //     <DialogHeader>
    //       <DialogTitle>{`${
    //         enable ? "Disable" : "Enable"
    //       } user defined field`}</DialogTitle>
    //       <DialogDescription>
    //         {`Are you sure to ${
    //           enable ? "disable" : "enable"
    //         } this user defined field?`}
    //       </DialogDescription>
    //     </DialogHeader>
    //     <DialogFooter className="justify-end">
    //       <DialogClose asChild>
    //         <Button
    //           onClick={() => setOpen(false)}
    //           type="button"
    //           variant={"outline"}
    //         >
    //           Close
    //         </Button>
    //       </DialogClose>
    //       <DialogClose>
    //         <MainButton
    //           loading={loadingUpdate}
    //           label="Yes"
    //           onClick={() => {
    //             props.udf?.onEnableUdf(enable, () => {
    //               setOpen(false);
    //             });
    //           }}
    //           type="button"
    //         />
    //       </DialogClose>
    //     </DialogFooter>
    //   </DialogContent>
    // </Dialog>
  );
}

export default inject("udf")(observer(UdfEnableButton));
