import { HiOutlineIdentification } from "react-icons/hi2";
import InputPhone from "src/components/InputPhone";
import MainInput from "src/components/MainInput";

interface Props {
  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  displayName: string;
  companyName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  fax: string;
  website: string;
  spouse: string;

  onTitle: (t: string) => void;
  onFirstName: (t: string) => void;
  onLastName: (t: string) => void;
  onMiddleName: (t: string) => void;
  onSuffix: (t: string) => void;
  onDisplayName: (t: string) => void;
  onCompanyName: (t: string) => void;
  onEmail: (t: string) => void;
  onPhone: (t: string) => void;
  onMobilePhone: (t: string) => void;
  onFax: (t: string) => void;
  onWebsite: (t: string) => void;
  onSpouse: (t: string) => void;
}

export default function NameAndContact(props: Props) {
  return (
    <div>
      <div className="flex items-center">
        <HiOutlineIdentification size={22} className="mr-2" />
        <span className="font-semibold">Name and Contact</span>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter title"
            value={props.title}
            onChange={props.onTitle}
            label="Title"
          />
        </div>
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter first name"
            value={props.firstName}
            onChange={props.onFirstName}
            label="First Name"
          />
        </div>
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter middle name"
            value={props.middleName}
            onChange={props.onMiddleName}
            label="Middle Name"
          />
        </div>
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter last name"
            value={props.lastName}
            onChange={props.onLastName}
            label="Last Name"
          />
        </div>
        <div className="flex flex-1 ">
          <MainInput
            placeholder="Enter suffix"
            value={props.suffix}
            onChange={props.onSuffix}
            label="Suffix"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter display name"
            value={props.displayName}
            onChange={props.onDisplayName}
            label="Display name"
          />
        </div>
        <div className="flex flex-1 ">
          <MainInput
            placeholder="Enter company name"
            value={props.companyName}
            onChange={props.onCompanyName}
            label="Company name"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter email"
            value={props.email}
            onChange={props.onEmail}
            label="Email"
          />
        </div>
        <div className="flex flex-1 ">
          <InputPhone
            placeholder="Enter phone"
            value={props.phone}
            onChange={props.onPhone}
            label="Phone"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <InputPhone
            placeholder="Enter mobile number"
            value={props.mobilePhone}
            onChange={props.onMobilePhone}
            label="Mobile number"
          />
        </div>
        <div className="flex flex-1 ">
          <MainInput
            placeholder="Enter fax"
            value={props.fax}
            onChange={props.onFax}
            label="Fax"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-1">
          <MainInput
            placeholder="Enter website"
            value={props.website}
            onChange={props.onWebsite}
            label="Website"
          />
        </div>
        <div className="flex flex-1 ">
          <MainInput
            placeholder="Enter spouse information"
            value={props.spouse}
            onChange={props.onSpouse}
            label="Spouse Information"
          />
        </div>
      </div>
    </div>
  );
}
