import { INavigationBarItem } from "src/components/navigationBar/NavigationBarItem";

interface Props {
  isActive: boolean;
  onClick?: () => void;
  navigationBarItem: INavigationBarItem;
}

export default function UdfNavigationBarItem(props: Props) {
  const item = props.navigationBarItem;

  return (
    <div
      onClick={props.onClick}
      key={item.id}
      className={"select-none list-none"}
    >
      <div
        className={`rounded-sm flex items-center py-4 px-5 mb-1 text-base font-normal text-gray-900 dark:text-white ${
          props.isActive ? " bg-gray-200" : "hover:bg-slate-200"
        } cursor-pointer`}
      >
        <span
          className={` ${
            props.isActive ? "text-black" : " text-gray-500"
          }  w-full  text-sm`}
        >
          {item.name}
        </span>
      </div>
    </div>
  );
}
