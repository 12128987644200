import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import { TableCell } from "../../../../components/ui/table";
import { useEffect, useState } from "react";
import { UDF_LINK, UDF_LINK_CREATE } from "src/interfaces/udf.interface";
import { ValueText } from "./valueType/ValueText";
import { FIELD_TYPE } from "src/constants/crm.constants";
import { onCreateUdfLink, onUpdateUdfLink } from "src/services/udf.service";
import { CRM_TYPE } from "src/interfaces/crm.interface";
import { ValueDate } from "./valueType/ValueDate";
import { ValueNumber } from "./valueType/ValueNumber";
import { ValuePhone } from "./valueType/ValuePhone";
import { ValueEmail } from "./valueType/ValueEmail";
import { ValueCheckBox } from "./valueType/ValueCheckBox";
import { ValueList } from "./valueType/valueTypeList/ValueList";

interface Props {
  crmType: CRM_TYPE;
  rowId: number;
  column: ENTITY_CONFIG;
  udfLinks: UDF_LINK[];
  defaultValue: any;
}

const CrmTableBodyCell = (props: Props) => {
  const [udfLink, setUdfLink] = useState<UDF_LINK | null>(null);

  useEffect(() => {
    const defaultUdfLink = getDefaultUdfLink();
    if (defaultUdfLink) {
      setUdfLink(defaultUdfLink);
    }
  }, [props.defaultValue]);

  const getDefaultUdfLink = () => {
    const data = props.udfLinks.find(
      (m) => m.userDefinedField.id === props.column.id
    );
    return data;
  };

  const onSave = (item: any, onSuccess: () => void) => {
    if (udfLink?.id) {
      onUpdateUdfLink(udfLink?.id ?? "", item).then((res) => {
        if (res !== null) {
          setUdfLink(res.data);
          onSuccess();
        } else {
          onSuccess();
          return;
        }
      });
    } else {
      const doc: UDF_LINK_CREATE = {
        entityId: props.rowId,
        userDefinedFieldId: props.column.id.toString(),
        value: item,
      };
      onCreateUdfLink(props.crmType, doc).then((res) => {
        if (res !== null) {
          setUdfLink(res.data);
          onSuccess();
        } else {
          onSuccess();
          return;
        }
      });
    }
  };

  const columnType = props.column.columnType;

  if (columnType === FIELD_TYPE.text.key) {
    return <ValueText value={udfLink?.value ?? "N/A"} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.date.key) {
    return <ValueDate value={udfLink?.value ?? "N/A"} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.number.key) {
    return <ValueNumber value={udfLink?.value ?? "00"} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.telephone.key) {
    return <ValuePhone value={udfLink?.value ?? "00"} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.email.key) {
    return <ValueEmail value={udfLink?.value ?? ""} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.checkbox.key) {
    return <ValueCheckBox value={udfLink?.value ?? false} onSave={onSave} />;
  }

  if (columnType === FIELD_TYPE.list.key) {
    return (
      <ValueList
        udfId={props.column.id.toString()}
        value={udfLink}
        onSave={onSave}
      />
    );
  }

  return (
    <TableCell className="border-r-[0.9px]">{props.defaultValue}</TableCell>
  );
};

export default CrmTableBodyCell;
