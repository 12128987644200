import ContentWarrantyAndSupport from "./warrantyAndSupport";
import ProposalStore from "src/stores/proposal.store";
import ContentReferenceImage from "./referenceImages";
import ContentOurTeam from "./ourTeams";
import ContentProposalSignOff from "./proposalsSignOff";
import ContentCover from "./cover";
import ContentFinancialTerm from "./financialTerms";
import ContentProjectSchedule from "./projectSchedule";
import ContentTermsAndCondition from "./termsAndCondition";
import ContentProjectProposal from "./projectProposal";
import NotFound from "src/app/notFound";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import TemporaryContent from "./temporaryContent";
import { SECTIONS } from "src/constants/proposal.constants";

interface Props {
  id: number;
  isTemplate?: boolean;
  proposal?: ProposalStore;
}

function ProposalContent(props: Props) {
  useEffect(() => {
    if (!props.proposal?.section) return;
    if (props.proposal.section.id === null) return;

    if (props.proposal.section !== null) {
      props.proposal.onGetSection(
        props.id,
        props.proposal?.section.id,
        props.isTemplate
      );
    }
  }, [props.proposal?.section]);

  const renderContent = () => {
    const template = props.proposal?.section?.template;

    if (template === SECTIONS.cover.template) {
      return <ContentCover />;
    }

    if (template === SECTIONS.referenceImages.template) {
      return <ContentReferenceImage />;
    }

    if (template === SECTIONS.projectSchedule.template) {
      return <ContentProjectSchedule />;
    }

    if (template === SECTIONS.financialTerm.template) {
      return <ContentFinancialTerm />;
    }

    if (template === SECTIONS.ourTeam.template) {
      return <ContentOurTeam />;
    }

    if (template === SECTIONS.warranty.template) {
      return <ContentWarrantyAndSupport />;
    }

    if (template === SECTIONS.termsCondition.template) {
      return <ContentTermsAndCondition />;
    }

    if (template === SECTIONS.proposalSignOff.template) {
      return <ContentProposalSignOff />;
    }

    if (template === SECTIONS.projectProposal.template) {
      return <ContentProjectProposal />;
    }

    return <TemporaryContent />;
  };

  if (props.proposal?.processingContent || !props.proposal) {
    return (
      <div className="w-[35%] h-screen overflow-auto p-5 border-x-[1px]">
        <EstimateDetailsSkeleton />
      </div>
    );
  }

  if (props.proposal?.sectionDetail === null) {
    return (
      <div className="w-[35%] h-screen overflow-auto p-5 border-x-[1px]">
        <NotFound />
      </div>
    );
  }

  return (
    <div className="w-[35%] h-screen overflow-auto p-5 border-x-[1px]">
      <div className="mb-16">{renderContent()}</div>
    </div>
  );
}

export default inject("proposal")(observer(ProposalContent));
