import { HiMiniPlus } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import { FinancialTermSection } from "./FinancialTermSection";
import { ContentTitle } from "../ContentTitle";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";

interface Props {
  proposal?: ProposalStore;
}

function ContentFinancialTerm(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};

  return (
    <div>
      <ContentTitle title={sectionDetail?.title ?? ""} />
      {sectionDetail?.meta.map((parent) => {
        return (
          <FinancialTermSection
            key={parent.id}
            parent={parent}
            onChnage={(child) => {
              props.proposal?.onUpdatePayload2Level(parent, child);
            }}
            onAfterDelete={(data) => {
              props.proposal?.onDeleteFinancialTerm(data);
            }}
          />
        );
      })}
      <div className="flex justify-end mt-8">
        <div className="flex">
          <MainButton
            onClick={props.proposal?.onAddFinancialTerm}
            variant={"outline"}
            loading={props.proposal?.processingContentUpdate}
            icon={HiMiniPlus}
            size={18}
            label="Add Milestone"
            className="flex"
          />
        </div>
      </div>
      {sectionDetail?.meta.length === 0 ? null : (
        <MainButton
          disabled={props.proposal?.disableSave}
          onClick={onUpdateSection}
          loading={processingContentUpdate}
          label="Save"
          className="mt-8"
        />
      )}
    </div>
  );
}

export default inject("proposal")(observer(ContentFinancialTerm));
