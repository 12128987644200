import "react-international-phone/style.css";
import {
  BaseTextFieldProps,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";
import {
  CountryIso2,
  FlagEmoji,
  defaultCountries,
  usePhoneInput,
} from "react-international-phone";
import { useEffect, useState } from "react";

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
}

export const PhoneNumber: React.FC<MUIPhoneProps> = ({
  value,
  ...restProps
}) => {
  const [isReady, setReady] = useState(false);
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry:"us",
      value: value,
      countries: defaultCountries,
    });
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1000)
    return (() => setReady(false));
  }, []);
  if (phone === "+") return <p>N/A</p>
  return (
    <TextField
      variant="standard"
      color="primary"
      value={phone}
      size="small"
      onChange={handlePhoneValueChange}
      type="tel"
      InputLabelProps={{
        style: { fontWeight: "600" },
        shrink: true,
      }}
      inputProps={{
        contentEditable: false,
        style: {
          marginBottom: -3,
          cursor: "pointer"
        }
      }}
      fullWidth
      inputRef={inputRef}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px", cursor: "pointer" }}
          >
            <Select
              inputProps={{ IconComponent: () => null, sx: { padding: 0 } }}
              disabled
              sx={{
                width: "max-content",
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "6px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagEmoji iso2={value} style={{ display: "flex", cursor: "pointer" }} />
              )}>
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
