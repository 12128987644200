import { HiMiniTrash } from "react-icons/hi2";
import { DeleteButton } from "src/components/DeleteButton";
import { Label } from "src/components/ui/label";

interface Props {
  title: string;
  deleteId: string;
  onAfterDelete?: () => void;
}

export const ContentSubTitle = (props: Props) => {
  return (
    <div className="bg-muted px-4 rounded-sm flex items-center justify-between">
      <Label className="my-3 font-bold">{props.title}</Label>
      {props.deleteId ? (
        <DeleteButton
          renderComponent={
            <div className="rounded-full bg-red-100 p-[4px] cursor-pointer">
              <HiMiniTrash className=" text-red-500" />
            </div>
          }
          isDeleteProposal
          id={props.deleteId}
          path={""}
          deleted={() => {}}
          onAfterdeletedProposal={() => {
            props.onAfterDelete && props.onAfterDelete();
          }}
        />
      ) : null}
    </div>
  );
};
