import { getAPI } from ".";
import { RESPONSE } from "./error_handler";

export const paginationList = async (
  path: string,
  page?: number,
  length?: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  signal?: AbortSignal
) => {
  var url = `${process.env.REACT_APP_MAIN_API}/${path}`;
  if (page !== undefined && length !== undefined) {
    url = url + `?page=${page}&length=${length}`;
  }

  if (search) {
    url = `${url}&search=${search}`;
  }

  if (sort === "customer.fullName") {
    sort = "customer.firstName";
  }

  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const data = await getAPI(url, signal);
  return data as RESPONSE;
};

export const staticList = async (path: string) => {
  var url = `${process.env.REACT_APP_MAIN_API}/${path}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const details = async (path: string, id: string | number) => {
  var url = `${process.env.REACT_APP_MAIN_API}/${path}/${id}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const customerDetails = async (id: string | number) => {
  var url = `${process.env.REACT_APP_MAIN_API}/user/${id}/view-detail`;
  const data = await getAPI(url);
  return data as RESPONSE;
};
