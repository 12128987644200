import { inject, observer } from "mobx-react";
import { useState } from "react";
import { HiMiniXMark, HiOutlinePlusSmall } from "react-icons/hi2";
import { ContentTitle } from "src/app/estimate/proposal/proposalDetail/proposalContent/ContentTitle";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Label } from "src/components/ui/label";
import { CRM_TYPES } from "src/constants/crm.constants";
import { LEAD_STAGE, LEAD_STATUS } from "src/interfaces/customer.interface";
import CRMStore from "src/stores/crm.store";

interface Props {
  crm?: CRMStore;
  isStage?: boolean;
}

const CrmAddTabarButton = (props: Props) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  const { statusList, selectedStatus, processingAddCrmTab } = props.crm ?? {};

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <MainButton variant={"ghost"} icon={HiOutlinePlusSmall} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <ContentTitle
              title={props.isStage ? "Add New Stage" : "Add New Status"}
            />
            <HiMiniXMark
              onClick={() => {
                setOpen(!open);
              }}
              className="h-5 w-5 cursor-pointer"
            />
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="">
            <Label className="text-lg text-gray-500">
              {props.isStage ? "Stage" : "Status"} Name
            </Label>
            <MainInput
              placeholder={`Enter ${props.isStage ? "stage" : "status"} name`}
              value={name}
              onChange={setName}
            />
          </div>
        </div>
        <DialogFooter>
          <MainButton
            loading={processingAddCrmTab}
            disabled={name === ""}
            onClick={() => {
              if (props.isStage) {
                const stage: LEAD_STAGE = {
                  name: name,
                  leadStatusId: selectedStatus?.id ?? "0",
                  enable: true,
                  selected: false,
                  sequence: Number(
                    selectedStatus?.stages
                      ? selectedStatus?.stages?.length + 1
                      : 0
                  ),
                  backgroundColor: "FFFFFF",
                };
                props.crm?.onCreateStage(stage, () => {
                  setOpen(!open);
                });
              } else {
                const status: LEAD_STATUS = {
                  name: name,
                  enable: true,
                  completed: false,
                  selected: false,
                  sequence: Number(statusList ? statusList.length + 1 : 0),
                  backgroundColor: "FFFFFF",
                };
                props.crm?.onCreateStatus(status, CRM_TYPES.lead, () => {
                  setOpen(!open);
                });
              }
            }}
            className="mt-4 bg-secondary"
            label={`Add ${props.isStage ? "Stage" : "Status"}`}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default inject("crm")(observer(CrmAddTabarButton));
