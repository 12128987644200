import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { FaDiamond } from "react-icons/fa6";
import styled from 'styled-components';
import {
  FaHSquare
} from "react-icons/fa";
class SketchColor extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '0',
      g: '3',
      b: '51',
      a: '1',
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    const color = this.state.color;
    this.props.onChanged(`rgba(${color.r},${color.g},${color.b},${color.a})`);
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
  };

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          color: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '10',
          right:15
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    const Icon = this.props.icon;
    return (
      <div>
        <div style={ styles.swatch } onClick={ this.handleClick }>
         { Icon ? <Icon style={ styles.color }/>  : <FaHSquare size={15} style={ styles.color  }/>}
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }
      </div>
    )
  }
}

const DiamondIcon = styled(FaDiamond)`
`

export default SketchColor