import CRMStore from "src/stores/crm.store";
import CrmStatusTab from "../../crmStatusTab";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LEAD_STAGE, LEAD_STATUS } from "src/interfaces/customer.interface";
import { CRM_TYPES } from "src/constants/crm.constants";
import CrmStageTab from "../../crmStageTab";
import CrmMainTable from "src/app/crm/crmTableComponents";
import { useNavigate } from "react-router-dom";
interface Props {
  crm?: CRMStore;
  auth?: AuthStore;
}

function LeadListPage(props: Props) {
  var timeoutRef: any = null;
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [size, setSize] = useState<number>(15);
  const [sort, setSort] = useState<string>("");
  const [desc, setDesc] = useState<boolean>(false);

  const [selectedStage, setStage] = useState<LEAD_STAGE | null>(null);
  const [selectedStatus, setStatus] = useState<LEAD_STATUS | null>(null);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [isCustomDate, setCustomDate] = useState<boolean | undefined>(false);

  const { data, totalPages, processing, page, totalRows } = props.crm || {};

  useEffect(() => {
    if (search) {
      onSearch("");
    }
  }, [window.location.hash]);

  useEffect(() => {
    props.crm?.onGetStatusWithSummary(CRM_TYPES.lead.key);
  }, []);

  useEffect(() => {
    onPageChanged(0);
  }, [dateFrom, dateTo]);

  const onSearch = (text: string) => {
    setSearch(text);
    props.crm?.onGetList(
      CRM_TYPES.lead,
      selectedStatus?.id,
      selectedStage?.id,
      page || 0,
      text,
      size,
      sort,
      desc
    );
  };

  const onClearSearch = () => {
    setSearch("");
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    timeoutRef = setTimeout(() => {
      props.crm?.onGetList(
        CRM_TYPES.lead,
        selectedStatus?.id,
        selectedStage?.id,
        page || 0,
        "",
        size,
        sort,
        desc
      );
    }, 1000);
  };

  const onPageChanged = (p: number) => {
    props.crm?.onGetList(
      CRM_TYPES.lead,
      selectedStatus?.id,
      selectedStage?.id,
      p,
      search,
      size,
      sort,
      desc,
      dateFrom,
      dateTo
    );
  };

  const onSizeChanged = (s: number) => {
    setSize(s);
    props.crm?.onGetList(
      CRM_TYPES.lead,
      selectedStatus?.id,
      selectedStage?.id,
      page,
      search,
      s,
      sort,
      desc
    );
  };

  const onSort = (s: any) => {
    setDesc(!desc);
    setSort(s);
    props.crm?.onGetList(
      CRM_TYPES.lead,
      selectedStatus?.id,
      selectedStage?.id,
      page,
      search,
      size,
      s,
      desc
    );
  };

  const onOpenCustomer = () => {
    setOpenCustomer(!openCustomer);
  };

  const onSelectFilter = (
    dateFrom: Date | null,
    dateTo: Date | null,
    isCustomDate?: boolean
  ) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setCustomDate(isCustomDate);
  };

  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const [showAdd, setShowAdd] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { entities } = props.auth || {};
  const entity = entities?.find((m) => m.code === CRM_TYPES.lead.key) ?? null;
  if (!entity) return <div />;

  return (
    <div className="bg-muted mt-4 ml-2 rounded-tl-md h-full w-full p-2 pl-0 border-t-[1.5px] border-l-[1.5px]">
      <CrmStatusTab />
      <div className="mt-2" />
      <CrmStageTab />
      <div className="bg-white h-full rounded-tl-md">
        <CrmMainTable entityCode="lead" />
      </div>
    </div>
  );
}

export default inject("crm", "auth")(observer(LeadListPage));
