import { Skeleton } from "./ui/skeleton";

 
export function LargeListSkeleton() {
  return (
    <div className="flex flex-1 flex-col justify-start gap-3 p-4">
      <Skeleton className="h-7 w-full rounded" />
      <Skeleton className="h-7 w-2/3 rounded" />
      <Skeleton className="h-7 w-1/3 rounded" />
      <Skeleton className="h-7 w-10/12 rounded" />
      <Skeleton className="h-7 w-2/3 rounded" />
      <Skeleton className="h-7 w-1/3 rounded" />
    </div>
  )
}