import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import {
  IoAlertCircleOutline,
  IoAt,
  IoBusinessOutline,
  IoCalculatorOutline,
  IoCalendarClearOutline,
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
  IoPersonCircleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { TbCirclesRelation } from "react-icons/tb";
import { useParams } from "react-router-dom";
import NotFound from "src/app/notFound";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import CUSTOMER from "src/interfaces/customer.interface";
import CRMStore from "src/stores/crm.store";
import { displayDateComment } from "src/utilities/formatter.utilities";
import ItemInfo from "../ItemInfo";

interface Props {
  crm?: CRMStore;
}

function CustomerDetails(props: Props) {
  const { id } = useParams();
  const [contactData, setContactData] = useState<CUSTOMER | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.crm?.contactDetails === null) {
      if (!id) {
        setLoading(false);
        return;
      }
      props.crm.onGetContactDetails(Number(id)).then((result) => {
        if (result?.data && result.code === "200") {
          setContactData(result?.data);
        } else {
          setErrorMessage(result?.message);
        }
        setLoading(false);
      });
    } else {
      setContactData(props.crm?.contactDetails || null);
      setLoading(false);
    }
  }, [props.crm?.contactDetails]);

  if (loading) {
    return <EstimateDetailsSkeleton />;
  }

  if (errorMessage) {
    return (
      <NotFound
        title={errorMessage}
        message={
          "We're sorry, the estimate that you are looking for is not found or you don't have the access right!"
        }
      />
    );
  }

  return (
    <div className="m-4 p-5 bg-white rounded-md flex">
      <div className="w-full">
        <div className="font-bold text-xl uppercase mb-6">Contact Info</div>
        <ItemInfo
          icon={IoPersonCircleOutline}
          label={"Full Name"}
          value={contactData?.fullName ?? "N/A"}
        />
        <ItemInfo
          icon={IoPersonOutline}
          label={"Email"}
          value={contactData?.email ?? "N/A"}
        />
        <ItemInfo
          icon={IoCallOutline}
          label={"Phone"}
          value={contactData?.telephone1 ?? "N/A"}
        />
        <ItemInfo
          icon={IoCalculatorOutline}
          label={"Mobile"}
          value={contactData?.telephone2 ?? "N/A"}
        />
        <ItemInfo
          icon={IoAlertCircleOutline}
          label={"Fax"}
          value={contactData?.fax ?? "N/A"}
        />
        <ItemInfo
          icon={IoAt}
          label={"Website"}
          value={contactData?.website ?? "N/A"}
        />
        <ItemInfo
          icon={IoBusinessOutline}
          label={"Company"}
          value={contactData?.companyName ?? "N/A"}
          noBorder
        />
      </div>
      <div className="w-full ml-8">
        <div className="font-bold text-xl uppercase mb-6">Associated Info</div>
        <ItemInfo icon={IoPersonCircleOutline} label={"Name"} value={"N/A"} />
        <ItemInfo
          icon={TbCirclesRelation}
          label={"Relationship"}
          value={"N/A"}
        />
        <ItemInfo icon={IoMailOutline} label={"Email"} value={"N/A"} />
        <ItemInfo icon={IoCallOutline} label={"Phone"} value={"N/A"} />
        <ItemInfo
          icon={IoLocationOutline}
          label={"Full Address"}
          value={"N/A"}
          noBorder
        />
      </div>
      <div className="w-full ml-8">
        <div className="font-bold text-xl uppercase mb-6">Additional Info</div>
        <ItemInfo
          icon={IoCalendarClearOutline}
          label={"Creation Log"}
          value={
            contactData?.createdAt
              ? displayDateComment(contactData?.createdAt)
              : "N/A"
          }
        />
        <ItemInfo
          icon={IoLocationOutline}
          label={"Billing Address"}
          value={contactData?.billingAddress?.fullAddress ?? "N/A"}
        />
        <ItemInfo
          icon={IoLocationOutline}
          label={"Shipping Address"}
          value={contactData?.shippingAddress?.fullAddress ?? "N/A"}
          noBorder
        />
      </div>
    </div>
  );
}

export default inject("crm")(observer(CustomerDetails));
