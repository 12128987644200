import { v4 as uuidv4 } from "uuid";
import { INavigationBarItem } from "../components/navigationBar/NavigationBarItem";
import EstimateSettingPage from "src/app/estimate/estimateSetting";
import EstimateListPage from "src/app/estimate/estimateList";
import ProductListPage from "src/app/productAndConfigure/productList";
import PresetGroupListPage from "src/app/productAndConfigure/presetGroupList";
import CatalogueListPage from "src/app/productAndConfigure/catalogueList";
import ProductionRateListPage from "src/app/productAndConfigure/productionRateList";
import LaborPricingByItem from "src/app/productAndConfigure/laborPricingByItem";
import MemberListPage from "src/app/team/member/MemberListPage";
import TeamListPage from "src/app/team/team/TeamListPage";
import CustomerDetails from "src/app/crm/contact/contactDetails/customerDetails";
import EstimateContact from "src/app/crm/contact/contactDetails/estimateContact";
import CommingSoon from "src/components/CommingSoon";
import HistoryContact from "src/app/crm/contact/contactDetails/historyContact";
import UdfPage from "src/app/admin/userDefined/userDefinedPage";
import LeadListPage from "src/app/crm/lead/leadList";
import { LeadSettingPage } from "src/app/crm/lead/leadSetting";
import ProposalListPage from "src/app/estimate/proposal/proposalList";
import ProposalTemplatePage from "src/app/estimate/proposal/proposalTemplate";
export const estimate = [
  {
    id: uuidv4(),
    name: "Overview",
    route: "estimate-list",
    element: EstimateListPage,
  },
  {
    id: uuidv4(),
    name: "Settings",
    route: "estimate-setting",
    element: EstimateSettingPage,
  },
] as INavigationBarItem[];

export const member = [
  {
    id: uuidv4(),
    name: "All Members",
    route: "all-members",
    element: MemberListPage,
    type: "all-members",
  },
  {
    id: uuidv4(),
    name: "Internal Members",
    route: "internal-members",
    element: MemberListPage,
    type: "internal-members",
  },
  {
    id: uuidv4(),
    name: "External Members",
    route: "external-members",
    element: MemberListPage,
    type: "external-members",
  },
] as INavigationBarItem[];

export const teams = [
  {
    id: uuidv4(),
    name: "All Teams",
    route: "all-teams",
    element: TeamListPage,
    type: "all-teams",
  },
  {
    id: uuidv4(),
    name: "Internal Teams",
    route: "internal-teams",
    element: TeamListPage,
    type: "internal-teams",
  },
  {
    id: uuidv4(),
    name: "External Teams",
    route: "external-teams",
    element: TeamListPage,
    type: "external-teams",
  },
] as INavigationBarItem[];

export const product_config = [
  {
    id: uuidv4(),
    name: "Item Pricing",
    route: "product-list",
    element: ProductListPage,
  },
  {
    id: uuidv4(),
    name: "Preset Groups",
    route: "preset-group-list",
    element: PresetGroupListPage,
  },
  {
    id: uuidv4(),
    name: "Catalogues",
    route: "catalogue-list",
    element: CatalogueListPage,
  },
  {
    id: uuidv4(),
    name: "Production Rate",
    route: "production-rate",
    element: ProductionRateListPage,
  },
  {
    id: uuidv4(),
    name: "Labor Pricing by Team",
    route: "labor-pricing",
    element: LaborPricingByItem,
  },
] as INavigationBarItem[];

export const contact = [
  {
    id: uuidv4(),
    name: "Customer Details",
    route: "customer-details",
    element: CustomerDetails,
  },
  {
    id: uuidv4(),
    name: "Estimates",
    route: "estimates",
    element: EstimateContact,
  },
  {
    id: uuidv4(),
    name: "Proposals",
    route: "proposals",
    element: CommingSoon,
  },
  {
    id: uuidv4(),
    name: "Invoices",
    route: "invoices",
    element: CommingSoon,
  },
  {
    id: uuidv4(),
    name: "Payments",
    route: "payments",
    element: CommingSoon,
  },
  {
    id: uuidv4(),
    name: "Projects",
    route: "projects",
    element: CommingSoon,
  },
  {
    id: uuidv4(),
    name: "History",
    route: "history",
    element: HistoryContact,
  },
] as INavigationBarItem[];

export const user_defined = [
  {
    id: uuidv4(),
    name: "Contact",
    showKey: "customer",
    route: "udf-customer",
    element: UdfPage,
  },
  {
    id: uuidv4(),
    name: "Lead",
    showKey: "lead",
    route: "udf-lead",
    element: UdfPage,
  },
  {
    id: uuidv4(),
    name: "Client",
    showKey: "client",
    route: "udf-client",
    element: UdfPage,
  },
  {
    id: uuidv4(),
    name: "Opportunity",
    showKey: "opportunity",
    route: "udf-opportunity",
    element: UdfPage,
  },
] as INavigationBarItem[];

export const lead = [
  {
    id: uuidv4(),
    name: "Overview",
    route: "lead-list",
    element: LeadListPage,
  },
  {
    id: uuidv4(),
    name: "Settings",
    route: "lead-setting",
    element: LeadSettingPage,
  },
] as INavigationBarItem[];

export const proposal = [
  {
    id: uuidv4(),
    name: "Overview",
    route: "proposal-list",
    element: ProposalListPage,
  },
  {
    id: uuidv4(),
    name: "Template",
    route: "proposal-template",
    element: ProposalTemplatePage,
  },
] as INavigationBarItem[];
