import TitleWithBox from "src/components/TitleWithBox";
import SummarySnapshot from "./SummarySnapshot";
import SummaryTable from "./SummaryTable/SummaryTable";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";

interface Props {
    items:GROUP_OR_ITEM[]
    exclusion:string;
    taxRate:number | null;
    isChild?:boolean
}

export default function EstimateSummaryContent(props:Props) {
  return (
    <div className={`h-full w-full   ${props.isChild ? "" : "p-4  overflow-auto bg-slate-50"}`}>
      <TitleWithBox title="Pricing Snapshot" />
      <div className="mt-4" />
      <SummarySnapshot
        items={props.items}
        exclusion={props.exclusion}
        taxRate={props.taxRate}
      />
      <div className="mt-12" />
      <TitleWithBox title="Pricing Breakdowns" />
      <SummaryTable items={props.items} />
    </div>
  );
}
