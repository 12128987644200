import {
  deleteItem,
  getImages,
  image,
  initData,
  login,
  refreshToken,
  updateEntity,
  uploadImage,
} from "src/APIs/auth.api";
import { RESPONSE } from "src/APIs/error_handler";
import { loginForm } from "src/app/auth/login/FormController";
import { AUTH, FILE } from "src/interfaces/auth.interface";
import { ENTITY } from "src/interfaces/init.interface";

import { saveAuth } from "src/utilities/storage.utilities";
import { z } from "zod";

export async function onLogin(values: z.infer<typeof loginForm>) {
  let result: any = (await login(values.username, values.password)) ?? {};
  if (!result?.code) {
    result.message = "No internet connection!";
    return result;
  }

  if (result?.code !== "200") {
    if (result?.code?.toString() === "401") {
      result.message = "Invalid credentials!";
    }
    if (result?.code?.toString() === "500") {
      result.message = "Something went wrong!";
    }
    return result;
  } else {
    const data: AUTH = result?.data;
    saveAuth(data);
    return result;
  }
}

export async function getInitData() {
  const result = await initData();
  if (result?.code !== "200") return;
  return result.data;
}

export async function onGetImages(path: string, id: number | string) {
  const result = await getImages(path, id);
  if (result?.code !== "200") return;
  return result.data as RESPONSE;
}

export async function onUploadImage(path: string, image: FILE) {
  const res = await uploadImage(path, image);
  if (res?.code !== "200") return;
  return res;
}

export async function onDeleteItem(path: string, id: number | string) {
  const res = await deleteItem(id, path);
  if (res?.code !== "200") return;
  return res;
}

export async function onGetImage(path: string) {
  const res = await image(path);
  if (res?.code !== "200") return;
  return res;
}

export async function onUpdateEntity(doc: ENTITY) {
  const res = await updateEntity(doc);
  if (res?.code !== "200") return;
  return res;
}
