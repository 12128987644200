import {
  IconButton,
  CircularProgress,
  TableRow,
  TableCell,
} from "@mui/material";
import { TbCheck, TbX } from "react-icons/tb";
import { COLORS } from "../../../assets/colors";
import { LAYOUTS } from "../../../assets/layouts";
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import React from "react";

import { IoChevronDown } from "react-icons/io5";
import { MEMBER_ENTITY } from ".";
import { DEPARTMENT } from "src/interfaces/team.interface";
import { ENTITY_CONFIG, USER } from "src/interfaces/init.interface";
import { nullString } from "src/utilities/functions.utilities";
import {
  displayDate,
  formatMoney,
} from "src/utilities/formatter.utilities";
import AuthStore from "src/stores/auth.store";
import { useNavigate } from "react-router-dom";
import DropDownDepartmentMenu from "./DropDownDepartmentMenu";
import AddMembers from "./AddMembers";
import MainInput from "src/components/MainInput";
import { ProfileAvatar } from "src/components/ProfileAvatar";
import { updateDepartment } from "src/APIs/team.api";

interface Props {
  deprtment: DEPARTMENT;
  onSelectUser: (i: USER) => void;
  onCollapsed: () => void;
  collapsed: boolean;
  auth?: AuthStore;
  deleted: () => void;
  afterAddedMembers: (departments: DEPARTMENT[]) => void;
}

function DepartmentItem(props: Props) {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [isError, setError] = useState(false);
  const [isOpenAddMember, setOpenAddMember] = useState(false);

  useEffect(() => {
    setName(props.deprtment?.name);
  }, []);

  function onSave() {
    setLoading(true);
    if (nullString(name) === null) {
      setError(true);
      setLoading(false);
      setEdit(true);
      return;
    }

    updateDepartment(props.deprtment?.id, name).then((doc) => {
      setLoading(false);
      setEdit(!isEdit);
    });
  }

  return (
    <React.Fragment>
      <AddMembers
        afterAddedMembers={props.afterAddedMembers}
        isDepartment
        item={props.deprtment}
        isOpen={isOpenAddMember}
        onClose={() => setOpenAddMember(false)}
      />
      <TableRow className={!props.deprtment.enable ? "opacity-60" :""}>
        <TableCell colSpan={MEMBER_ENTITY.entityConfigurations.length}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => props.onCollapsed()}
              style={{
                backgroundColor: COLORS.transparent,
                marginRight: LAYOUTS.margin,
                paddingLeft: 20,
              }}
              size="small"
            >
              <IoChevronDown
                style={{
                  transform: props.collapsed ? "rotate(-90deg)" : "rotate(0)",
                  transition: "all 200ms",
                }}
              />
            </IconButton>
            {isEdit ? (
              <MainInput value={name} onChange={(t) => setName(t)} />
            ) : (
              <h4>
                {name}{" "}
                <b
                  style={{
                    textTransform: "lowercase",
                    color: COLORS.primary,
                    paddingLeft: LAYOUTS.padding_small,
                  }}
                >
                  ({props.deprtment?.users?.length ?? "0"} members)
                </b>
              </h4>
            )}
            {!isEdit ? (
              <div onClick={(e) => e.stopPropagation()}>
                <DropDownDepartmentMenu
                  department={props.deprtment}
                  onDelete={() => props.deleted()}
                  onAddMember={() => {
                    setOpenAddMember(true);
                  }}
                  onEdit={() => {
                    setEdit(true);
                  }}
                />
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  disabled={loading}
                  size="small"
                  style={
                    isEdit
                      ? {
                          backgroundColor: COLORS.blue_transparent,
                          color: COLORS.blue,
                          marginLeft: LAYOUTS.margin_small,
                        }
                      : { marginLeft: LAYOUTS.margin_small }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    onSave();
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ width: 16.02, height: 16.02 }} />
                  ) : (
                    <TbCheck />
                  )}
                </IconButton>
                <IconButton
                  disabled={loading}
                  size="small"
                  style={{ marginLeft: LAYOUTS.margin_small }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEdit(false);
                    setError(false);
                    setName(props.deprtment?.name);
                  }}
                >
                  <TbX />
                </IconButton>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      {props.collapsed
        ? null
        : props.deprtment?.users?.map((m, ind) => {
            return (
              <TableRow
              className={!props.deprtment.enable ? "opacity-60" :""}
                key={m?.id}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => props.onSelectUser(m)}
              >
                {MEMBER_ENTITY.entityConfigurations.map((cell: any, index) => {
                  return (
                    <TableCell
                      style={{
                        paddingLeft: index === 0 ? 70 : 15,
                        paddingTop: LAYOUTS.padding,
                        paddingBottom: LAYOUTS.padding,
                      }}
                      key={cell.id}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {cell.columnName === "fullName" ?  <div className="mr-2">
                           <ProfileAvatar
                            url={m.profilePictureUrl}
                            name={m.fullName ?? ""}
                          />
                          </div> : null}
                        {renderValue(
                          cell.columnName,
                          m,
                          props.auth?.dateFormat
                        )}
                        <div style={{ display: "flex", flex: 1 }} />
                        {/* {cell.hasLeaderMenu ? <div onClick={(e) => e.stopPropagation()}><DropDownTeamLeader onLeader={() => onPromoteToLeader(m)} isShow={props.item?.leaderId !== null ? m.id === props.item.leaderId : false} /></div> : null} */}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
    </React.Fragment>
  );
}

function renderValue(field: string, data: any, pattern?: string) {
  const fields = field.split(".");
  const value = fields.length > 1 ? fieldData(field, data) : data[field];
  if (
    field === "createdAt" ||
    field === "updatedAt" ||
    field === "joinedDate"
  ) {
    return displayDate(value, pattern);
  } else if (field === "enable") {
    return value ? "Active" : "Inactive";
  } else if (
    field === "cost" ||
    field === "price" ||
    field === "costAfterMarkup"
  ) {
    return `$${formatMoney(value)}`;
  } else if (field === "entityConfigurations") {
    const columns = value.map((m: ENTITY_CONFIG) => m.displayName).join(", ");
    return columns;
  } else {
    return value ? value?.toString() : "N/A";
  }
}

function fieldData(fields: string, data: any) {
  return fields.split(".").reduce((o, d) => o[d], data);
}

export default inject("auth")(observer(DepartmentItem));
