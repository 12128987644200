import { useNavigate } from "react-router-dom";
import MainButton from "../MainButton";
import Lottie from "lottie-react";
import { lotties } from "src/assets/lotties";

interface Props {
  title?: string;
  message?: string;
  hideGoback?: boolean;
}

export default function EmptyData(props: Props) {
  const navigate = useNavigate();
  return (
    <div
      className="p-4"
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Lottie className="max-w-80" animationData={lotties.EMPTY} loop={true} />
      <h1 style={{ fontSize: 24, lineHeight: 1 }}>
        {props.title || "There are no data to display"}
      </h1>
      <p
        className="text-gray-400  text-center mt-2 mb-12"
        style={{ fontSize: 18 }}
      >
        {props.message ||
          "You have to create your defined field to get your first data."}
      </p>
      <div className="w-60">
        {props.hideGoback ? (
          <MainButton
            onClick={() => navigate(-1)}
            label="Go Back"
            type={"submit"}
          />
        ) : null}
      </div>
    </div>
  );
}
