import { UDF, UDF_LINK_CREATE, UDF_LIST } from "src/interfaces/udf.interface";
import { getAPI, postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";
import { CRM_TYPE } from "src/interfaces/crm.interface";

export async function getUdf(entityCode: String, isAll?: boolean) {
  const url =
    isAll === true
      ? `${process.env.REACT_APP_MAIN_API}/udf/all?entity=${entityCode}`
      : `${process.env.REACT_APP_MAIN_API}/udf?entity=${entityCode}&enable=true`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function getUdfList(udfId: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-list/show-by-udf?udf-id=${udfId}`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function updateUdfName(udfId: String, name: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf/${udfId}/set-name?name=${name}`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}

export async function updateUdfEnable(udfId: String, enable: boolean) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf/${udfId}/set-enable?enable=${enable}`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}

export async function createUdf(entityCode: String, udf: UDF) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf/${entityCode}`;
  const payload = {
    fieldTypeId: udf.fieldType?.id,
    name: udf.name,
    sequence: udf.sequence,
  };
  const data = await postAPI(url, payload, undefined, true);
  return data as RESPONSE;
}

export async function udfReorder(entityCode: string, udfs: UDF[]) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf/re-order?entity=${entityCode}`;
  const payLoad: any[] = udfs.map((m) => ({
    id: m.id,
    sequence: m.sequence,
  }));
  const res = await putAPI(url, payLoad, undefined, true);
  return res as RESPONSE;
}

export async function createUdfList(udfId: String, udfList: UDF_LIST) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-list`;
  const payload = {
    userDefinedFieldId: udfId,
    value: udfList.value,
    backgroundColor: udfList.backgroundColor,
    sequence: udfList.sequence,
  };
  const data = await postAPI(url, payload, undefined, true);
  return data as RESPONSE;
}

export async function updateUdfListValue(udfList: UDF_LIST) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-list/${udfList.id}/set-value?value=${udfList.value}`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}

export async function updateUdfListColor(udfList: UDF_LIST) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-list/${udfList.id}/set-color?background-color=${udfList.backgroundColor}`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}

export async function updateUdfListDefault(udfList: UDF_LIST) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-list/${udfList.id}/set-default`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}

export async function udfListReorder(udfId: string, udfLists: UDF_LIST[]) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/re-order?udf-id=${udfId}`;
  const payLoad: any[] = udfLists.map((m) => ({
    id: m.id,
    sequence: m.sequence,
  }));
  const res = await putAPI(url, payLoad, undefined, true);
  return res as RESPONSE;
}

export async function createUdfLink(
  crmType: CRM_TYPE,
  payload: UDF_LINK_CREATE
) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-link/${crmType.key}`;
  const data = await postAPI(url, payload, undefined, true);
  return data as RESPONSE;
}
export async function updateUdfLink(id: String, value: String) {
  const url = `${process.env.REACT_APP_MAIN_API}/udf-link/${id}/set-value?value=${value}`;
  const data = await putAPI(url, null, undefined, true);
  return data as RESPONSE;
}
