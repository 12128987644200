import { useEffect, useState } from "react";
import { Label } from "src/components/ui/label";
import { SECTION } from "src/interfaces/proposal.interface";
import { Skeleton } from "src/components/ui/skeleton";
import { onGetHtml } from "src/services/proposal.service";

interface Props {
  id: number;
  isTemplate?: boolean;
  item: SECTION;
  index: number;
  active: boolean;
  rawHTMLUpdate: any;
  rawHTMLUpdateProcessing: boolean;
  onClick?: () => void;
}
export const ProposalSectionItem = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [base46, setBase64] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.item.id) return;
    setLoading(true);
    const getData = async () => {
      onGetHtml(props.id, props.item.id, props.isTemplate).then(async (res) => {
        if (res !== null) {
          setBase64(res.data);
          setLoading(false);
        } else {
          setBase64("");
          setLoading(false);
          setError(true);
        }
      });
    };
    getData();
  }, []);

  return (
    <div className="mb-4">
      {loading ? (
        <Skeleton className="mb-4 w-[198px] h-[280px] cursor-pointer rounded-sm" />
      ) : props.active && props.rawHTMLUpdateProcessing ? (
        <Skeleton className="mb-4 w-[198px] h-[280px] cursor-pointer rounded-sm" />
      ) : error ? (
        <div
          onClick={props.onClick}
          className={`${props.active ? 'border-2' : 'border-[0.5px]'} ${
            props.active ? 'border-primary' : 'border-gray-500'
          } w-[198px] flex h-[280px] flex-1 cursor-pointer items-center justify-center rounded-sm`}
        >
          {`${props.index + 1}`}
        </div>
      ) : (
        <div
          onClick={props.onClick}
          className={`${props.active ? 'border-2' : 'border-[0.5px]'} ${
            props.active ? 'border-primary' : 'border-gray-500'
          } w-[198px]  h-[280px] rounded-sm cursor-pointer overflow-clip`}
        >
          <div className="pdf-container ">
            <div
              className="pdf-content"
              dangerouslySetInnerHTML={{ __html: props.active ? props.rawHTMLUpdate : base46 }}
            ></div>
          </div>
        </div>
      )}
      <Label
        className={`${
          props.active ? "text-primary" : "text-gray-500"
        } mt-[5px] cursor-pointer flex items-center justify-center`}
      >
        {props.item.title}
      </Label>
    </div>
  );
};
