import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { loginForm } from "./FormController";
import images from "src/assets/images";
import { AlertDestructive } from "src/components/AlertDestructive";
import MainButton from "src/components/MainButton";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { onLogin } from "src/services/auth.service";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import dayjs from "dayjs";

interface Props {
  auth?: AuthStore;
}

function LoginPage(props: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof loginForm>>({
    resolver: zodResolver(loginForm),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof loginForm>) {
    setLoading(true);
    setErrorMessage("");
    const result = await onLogin(values);
    if (!result?.data) {
      setLoading(false);
      setErrorMessage(result?.message ?? "");
    } else {
      const currentDate = dayjs().unix();
      const tokenExpired = currentDate + result?.data.tokenExpired / 1000;
      result.data.tokenExpired = tokenExpired;
      props.auth?.onLoggedInSuccess(result?.data, () => {
        setLoading(false);
      });
    }
  }

  return (
    <div className="w-full flex-col justify-center items-center px-6 py-12 lg:px-8 bg-gradient-to-tl from-indigo-900 to-sky-900 h-full">
      <div className="py-6 mt-20">
        <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
          <div className="w-full p-8 lg:w-1/2">
            <div className="flex justify-center">
              <img alt="logo" className="w-3/4" src={images.LYNQiFY_color} />
            </div>
            <p className="text-xl text-gray-600 text-center">Welcome back!</p>
            <div className="flex items-center justify-center mt-4 text-white rounded-lg shadow-md hover:bg-gray-100">
              <div className="px-4 py-3">
                <svg className="h-6 w-6" viewBox="0 0 40 40">
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#FFC107"
                  />
                  <path
                    d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
                    fill="#FF3D00"
                  />
                  <path
                    d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
                    fill="#4CAF50"
                  />
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#1976D2"
                  />
                </svg>
              </div>
              <h1 className="px-4 py-3 w-5/6 text-center text-gray-600 font-bold">
                Sign in with Google
              </h1>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <span className="border-b w-1/5 lg:w-1/4"></span>
              <div className="text-xs text-center text-gray-500 uppercase">
                or login with email
              </div>
              <span className="border-b w-1/5 lg:w-1/4"></span>
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                {errorMessage ? (
                  <div className="mt-2">
                    <AlertDestructive
                      title="Login Failed"
                      message={errorMessage}
                    />
                  </div>
                ) : null}
                <div className="p-2" />
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input placeholder="your@email.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="p-2" />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="********"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="p-4" />

                <MainButton loading={loading} type="submit" label="Login Now" />
              </form>
            </Form>
          </div>
          <img
            className="hidden lg:block lg:w-1/2 bg-cover"
            src={images.bg_login}
          />
        </div>
      </div>
      <p className="text-white text-center absolute bottom-8 left-0 right-0">
        © 2024 ANGKORSCAPE LLC. All Rights Reserved.
      </p>
    </div>
  );
}

export default inject("auth")(observer(LoginPage));
