import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import { TelephoneInput } from "src/components/TelephoneInput";
import { TableCell } from "src/components/ui/table";

interface Props {
  value: string;
  onSave: (v: string, onSuccess: () => void) => void;
}

export const ValuePhone = (props: Props) => {
  const [phone, setPhone] = useState("");
  const [editing, setEdting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPhone(props.value);
  }, [props.value]);

  const onUpdate = () => {
    if (props.value === phone) {
      setEdting(false);
      return;
    }
    setLoading(true);
    props.onSave(phone, () => {
      setLoading(false);
      setEdting(false);
    });
  };

  return (
    <TableCell className="border-r-[0.9px] p-0 px-[1px]">
      {editing ? (
        <div className="relative flex">
          <TelephoneInput
            onBlur={() => {
              onUpdate();
            }}
            onEnter={() => {
              onUpdate();
            }}
            value={phone}
            onChange={setPhone}
          />
          {loading ? (
            <Loader2
              color={COLORS.primary}
              className="h-4 w-4 animate-spin absolute right-2 top-4"
            />
          ) : null}
        </div>
      ) : (
        <div
          onClick={() => {
            setEdting(true);
          }}
          className="hover:bg-white min-h-[48px] flex items-center cursor-text px-1"
        >
          {phone}
        </div>
      )}
    </TableCell>
  );
};
