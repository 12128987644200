import { useLayoutEffect, useRef, useState } from "react";
import MainTable from "src/components/tableComponents/MainTable";
import { TEAM } from "src/interfaces/team.interface";
import  EditPricingDialog  from "./EditPricingDialog";
import { LABOR_PRICING_TEAM } from "src/constants/static_entity.constants";

export default function LaborPricingByItem() {
  const [height, setHeight] = useState(0);
  const [selectedItem, setItem] = useState<TEAM | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        height={height - 25}
        onClick={(row: any) => {
          setItem(row);
          setShowEdit(true);
        }}
        entityCode={LABOR_PRICING_TEAM.code}
        static
        path="team/all"
      />
      <EditPricingDialog 
      item={selectedItem}
      onClose={(v) => setShowEdit(v)} open={showEdit} />
    </div>
  );
}
