import MainButton from "./MainButton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";

interface Props {
  renderButton: React.ReactNode;
  title: string;
  description: string;
  onContinue: () => void;
  continueLabel: string;
  disabled?: boolean;
  variant?: "secondary" | "destructive";
}

export function AlertDialogue(props: Props) {
  return (
    <Dialog>
      <DialogTrigger asChild>{props.renderButton}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <div className="grid gap-4 py-4">
          <DialogHeader>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogDescription>{props.description}</DialogDescription>
          </DialogHeader>
        </div>
        <DialogFooter className="flex ">
          <DialogClose>
            <MainButton
              onClick={() => null}
              variant={"outline"}
              label="Cancel"
              type="button"
            />
          </DialogClose>
          <DialogClose>
            <MainButton
              onClick={() => props.onContinue()}
              variant={props.variant ?? "default"}
              label={props.continueLabel}
              type="button"
            />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
