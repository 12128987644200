import {
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import CrmTableHeaderItem from "./CrmTableHeaderItem";
import { ENTITY_CONFIG } from "src/interfaces/init.interface";

interface Props {
  columns: ENTITY_CONFIG[];
  onUpdated: (v: ENTITY_CONFIG) => void;
  onDeleted: (v: ENTITY_CONFIG) => void;
}

export function CrmTableHeader(props: Props) {
  return (
    <TableHeader>
      <TableRow className="h-11 bg-muted">
        {props.columns.map((item, index) => {
          return (
            <CrmTableHeaderItem
              isLast={index === props.columns.length - 1}
              onDeleted={props.onDeleted}
              onUpdated={props.onUpdated}
              item={item}
            />
          );
        })}
        <TableHead className="max-w-12 rounded-tr-md" />
      </TableRow>
    </TableHeader>
  );
}
