import { Table, TableCell, TableBody, TableRow } from "@mui/material";
import React from "react";

import SummaryRow, { COLUMNS } from "./SummaryRow";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { formatMoney, sum } from "src/utilities/formatter.utilities";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import { removeDuplicates } from "@tiptap/react";
import { COLORS } from "src/assets/colors";

interface Props {
  items: GROUP_OR_ITEM[];
  auth?: AuthStore;
}

const result = (
  type: PRODUCT_TYPE,
  total: number,
  totalQty: number,
  totalMarkup: number,
  egp: number,
  subtotalWithMarkup: number
) => {
  const estimateProf = (totalMarkup / subtotalWithMarkup) * 100;
  return {
    isBold: type.id === 99,
    productType: type.name,
    total: total ? `$${formatMoney(total)}` : "$0.00",
    qty: type.id === 99 ? "" : totalQty,
    totalMarkup: total ? `$${formatMoney(totalMarkup)}` : "$0.00",
    egp: total
      ? `$${formatMoney(totalMarkup)} (${egp ? formatMoney(estimateProf) : 0}%)`
      : "$0.00 (0%)",
    subtotalWithMarkup: total ? "$" + formatMoney(subtotalWithMarkup) : "$0.00",
  };
};

function SummaryTable(props: Props) {
  const total: PRODUCT_TYPE = {
    id: 99,
    code: "total",
    name: "Total",
    enable: true,
    markupPercentage: 0,
    teamId: "",
  };
  const productTypes =
    props.auth?.productTypes.filter((m) => m.code !== "bnd") ?? [];
  const columns = COLUMNS;
  const _overallData: any[] =
    productTypes
      .concat(total)
      .filter((m) => m.enable)
      .map((type) => {
        let items =
          type.id === 99
            ? props.items.filter((m) => !m.subContractor)
            : props.items.filter((m) => m.product?.productType?.id === type.id);
        if (type.id === 99) {
          const _items = props.items.filter((m) => m.subContractor);
          items = items.concat(_items);
        }

        const total = sum(items, "total");
        const disperse = sum(items, "disperse");
        const totalMarkup = sum(items, "markup") + disperse;
        const egp = totalMarkup;
        const totalQty = sum(items, "quantity");
        const subtotalWithMarkup = totalMarkup + total;
        return result(
          type,
          total,
          totalQty,
          totalMarkup,
          egp,
          subtotalWithMarkup
        );
      }) ?? [];

  const teams = removeDuplicates(props.items.map((m) => m.team)).map((m) => {
    const teamData: any[] =
      productTypes
        .concat(total)
        .filter((m) => m.enable)
        .map((type) => {
          let items =
            type.id === 99
              ? props.items.filter(
                  (i) => !i.subContractor && i.team?.id === m?.id
                )
              : props.items?.filter(
                  (i) =>
                    m?.id === i.team?.id &&
                    type?.id === i?.product?.productType?.id
                );

          if (type.id === 99) {
            const _items = props.items.filter(
              (i) => i.subContractor && i.team?.id == m?.id
            );
            items = items.concat(_items);
          }

          const total = sum(items, "total");
          const disperse = sum(items, "disperse");
          const totalMarkup = sum(items, "markup") + disperse;
          const egp = totalMarkup;
          const totalQty = sum(items, "quantity");
          const subtotalWithMarkup = totalMarkup + total;
          return result(
            type,
            total,
            totalQty,
            totalMarkup,
            egp,
            subtotalWithMarkup
          );
        }) ?? [];
    return { team: m, items: teamData };
  });

  const departments: any[] = removeDuplicates(
    props.items
      .filter((m) => m.product?.department)
      .map((m) => m.product?.department)
  ).map((m) => {
    const departmentData: any[] =
      productTypes
        .concat(total)
        .filter((m) => m.enable)
        .map((type) => {
          let items =
            type.id === 99
              ? props.items.filter(
                  (i) => !i.subContractor && i.product?.department?.id === m?.id
                )
              : props.items.filter(
                  (i) =>
                    i.product?.department?.id === m?.id &&
                    type?.id === i?.product?.productType?.id
                );
          if (type.id === 99) {
            const _items = props.items.filter(
              (i) => i.subContractor && i.product?.department?.id == m?.id
            );
            items = items.concat(_items);
          }

          const total = sum(items, "total");
          const disperse = sum(items, "disperse");
          const totalMarkup = sum(items, "markup") + disperse;
          const egp = totalMarkup;
          const totalQty = sum(items, "quantity");
          const subtotalWithMarkup = totalMarkup + total;
          return result(
            type,
            total,
            totalQty,
            totalMarkup,
            egp,
            subtotalWithMarkup
          );
        }) ?? [];
    return { department: m, items: departmentData };
  });

  return (
    <React.Fragment>
      <Table
        stickyHeader
        style={{ backgroundColor: COLORS.white }}
        size="small"
      >
        <TableBody>
          <SummaryRow isMain items={_overallData} name={"Overall Breakdown"} />
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ height: 60, borderBottomWidth: 0 }}
              colSpan={6}
            />
          </TableRow>
        </TableBody>
        {teams.length > 0 ? (
          <TableBody>
            <TableRow style={{ backgroundColor: COLORS.primary, height: 35 }}>
              <TableCell colSpan={columns.length}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 style={{ color: COLORS.white }}>Breakdown BY TEAM</h4>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}
        {teams.map((m) => {
          return (
            <TableBody key={m.team?.id}>
              <SummaryRow
                items={m.items}
                name={m?.team?.name ?? "Unassigned Team"}
              />
            </TableBody>
          );
        })}
        {teams.length > 0 ? (
          <TableBody>
            <TableRow>
              <TableCell
                style={{ height: 60, borderBottomWidth: 0 }}
                colSpan={6}
              />
            </TableRow>
          </TableBody>
        ) : null}
        {departments.length > 0 ? (
          <TableBody>
            <TableRow style={{ backgroundColor: COLORS.primary, height: 35 }}>
              <TableCell colSpan={columns.length}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 style={{ color: COLORS.white }}>
                    Breakdown BY DEPARTMENT
                  </h4>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}
        {departments.map((m) => {
          return (
            <TableBody key={m.department?.id}>
              <SummaryRow items={m.items} name={m?.department?.name ?? ""} />
            </TableBody>
          );
        })}
      </Table>
    </React.Fragment>
  );
}

export default inject("auth")(observer(SummaryTable));
