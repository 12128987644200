import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import { Card } from "src/components/ui/card";
import { DrawerTitle } from "src/components/ui/drawer";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { CATALOGUE } from "src/interfaces/estimate.interface";
import { PRODUCT } from "src/interfaces/product.interface";
import { CatalogueForm } from "../catalogueList/CatalogueForm";
import CatalogueRow from "./CatalogueRow";
import { orderByAsc } from "src/utilities/formatter.utilities";

interface Props {
  data: CATALOGUE[];
  loading: boolean;
  product: PRODUCT | null;
  updateCatalogue: (catalogue: CATALOGUE[]) => void;
  onDeleted:(doc:CATALOGUE) => void
}

export default function CatalogueList(props: Props) {
  const [openCreateCatalogue, setOpenCreateCatalogue] = useState(false);
  const [catalogue, setCatalogue] = useState<CATALOGUE | null>(null);
  return (
    <Card className="flex flex-1 p-4 flex-col">
      <DrawerTitle className="text-lg flex items-center">
        Catalogues List{" "}
        <div className="w-22">
          {props.product?.id ? (
            <MainButton
              variant={"ghost"}
              type="button"
              onClick={() => setOpenCreateCatalogue(true)}
              icon={HiPlusCircle}
            />
          ) : null}
        </div>
      </DrawerTitle>
      {props.loading ? (
        <SmallListSkeleton />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Default</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderByAsc(props.data,"name").map((data) => (
              <CatalogueRow
                onDefault={(data) => props.updateCatalogue(data)}
                data = {data}
                onDeleted={() => props.onDeleted(data)}
                onClick={() => {
                  setCatalogue(data);
                  setOpenCreateCatalogue(true);
                }}
              />
            ))}
          </TableBody>
        </Table>
      )}
      <CatalogueForm
        onCreatedCatalogue={(catalogues) => {
          setCatalogue(catalogues[catalogues.length - 1]);
          props.updateCatalogue(catalogues);
        }}
        catalogue={catalogue}
        onOpen={(value) => {
          setOpenCreateCatalogue(value);
          if(!value) setCatalogue(null);
        }}
        open={openCreateCatalogue}
        product={props.product}
      />
    </Card>
  );
}
