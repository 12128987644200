import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { updateEstimateSetting } from "src/APIs/estimate_details.api";
import { AutoCompleteStatic } from "src/components/autoComplete/AutoCompleteStatic";
import { Checkbox } from "src/components/CheckBox";
import MainButton from "src/components/MainButton";

import MainInput from "src/components/MainInput";
import MainTextArea from "src/components/MainTextArea";
import RichTextEditor from "src/components/richTextEditor";
import { ESTIMATE_SETTING } from "src/interfaces/estimate.interface";
import AuthStore from "src/stores/auth.store";
import { getAlphabeticObj } from "src/utilities/functions.utilities";
interface Props {
  auth?: AuthStore;
}

function EstimateSettingContent(props: Props) {
  const [enableExclusion, setEnableExclusion] = useState(false);
  const [note, setNote] = useState("");
  const [alphabetic, setAlphabetic] = useState<any | null>(null);
  const [processing, setProcessing] = useState(false);
  const [enableSaveDuration, setEnableSaveDuration] = useState(false);
  const [saveDuration, setSaveDuration] = useState("0");

  useEffect(() => {
    const { alphabetic, estimateSetting } = props.auth ?? {};
    const alp = getAlphabeticObj(alphabetic ?? "");
    setAlphabetic(alp);
    setNote(estimateSetting?.exclusionNote ?? "");
    setEnableExclusion(estimateSetting?.enableExclusionNote ?? false);
    setEnableSaveDuration(estimateSetting?.autoSave ?? false);
    setSaveDuration(estimateSetting?.autoSavePeriod.toString() ?? "0");
  }, [props.auth?.alphabetic]);

  function onSave() {
    setProcessing(true);
    const doc: ESTIMATE_SETTING = {
      exclusionNote: note,
      disperse: true,
      markup: true,
      enableExclusionNote: enableExclusion,
      caseType: alphabetic.key ?? alphabetic.code,
      autoSave: enableSaveDuration,
      autoSavePeriod: Number(saveDuration ?? "0"),
    };
    updateEstimateSetting(doc).then((result) => {
      if (result?.code !== "200") {
        setProcessing(false);
        return;
      } else {
        setProcessing(false);
        props.auth?.updateEstimateSetting(result?.data);
      }
    });
  }

  return (
    <div>
      <div className="mb-6 w-96">
        <AutoCompleteStatic
          label="Change group title alphabetic characters"
          value={alphabetic}
          onSelectedValue={(value) => {
            setAlphabetic(value);
          }}
          data={props.auth?.caseTypes ?? []}
        />
      </div>
      <div className="mb-[-2px] flex items-center">
        <Checkbox
          onChange={(value) => setEnableSaveDuration(value)}
          value={enableSaveDuration}
          label="Enable auto save"
        />
      </div>
      <MainInput
        value={saveDuration}
        onChange={(v) => setSaveDuration(v)}
        label="Duration (Minutes)"
      />
      <div className="mb-2 mt-6 flex items-center">
        <Checkbox
          onChange={(e) => setEnableExclusion(e)}
          value={enableExclusion}
        />
        <span className="mb-1 ml-2 font-semibold">Default Exclusion Note</span>
      </div>
      <div className="rounded-md">
        <RichTextEditor
          disableHeaderTools
          onChange={(e) => setNote(e)}
          value={note}
        />
      </div>

      <div className="flex flex-1" />
      <div className="mt-4 flex justify-end">
        <div className="w-32">
          <MainButton
            loading={processing}
            type="button"
            onClick={() => onSave()}
            label="Save"
          />
        </div>
      </div>
    </div>
  );
}

export default inject("auth")(observer(EstimateSettingContent));
