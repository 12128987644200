import { useState } from "react";
import AddContactUserDefined from "./UdfAdd";
import { UDF } from "src/interfaces/udf.interface";
import { renderColor } from "src/utilities/functions.utilities";
import { COLORS } from "src/assets/colors";
import CrmRenderIcon from "src/app/crm/crmTableComponents/CrmRenderIcon";
import { DeleteButton } from "src/components/DeleteButton";
import { RESPONSE } from "src/APIs/error_handler";
import { HiMiniTrash } from "react-icons/hi2";
import { RxDragHandleDots2 } from "react-icons/rx";
import UDFStore from "src/stores/udf.store";
import { inject, observer } from "mobx-react";
import UdfEnableButton from "../UdfEnableButton";

interface props {
  udf?: UDFStore;
  item: UDF;
  entityCode: string;
}

function UdfItem(props: props) {
  const item = props.item;
  const [openDetail, setOpenDetail] = useState(false);
  const onOpenDetail = () => setOpenDetail(!openDetail);

  return (
    <>
      <AddContactUserDefined
        entityCode={props.entityCode}
        item={props.item}
        open={openDetail}
        onOpen={onOpenDetail}
      />
      <li key={item.id} className={"select-none list-none"}>
        <div
          key={item.id}
          onClick={() => {
            props.udf?.onChangeUdf(item);
            onOpenDetail();
          }}
          className={`bg-gray-50 border-solid border-[0.5px] rounded-sm flex items-center py-3 px-5 mb-2 text-base font-normal text-gray-900 dark:text-white hover:bg-slate-100 dark:hover:bg-black cursor-pointer`}
        >
          <RxDragHandleDots2 className="h-4 w-5 text-black cursor-pointer mr-4" />
          <div
            style={{
              background:
                renderColor(item.fieldType?.color) || COLORS.background_primary,
            }}
            className={`p-1 rounded-sm mr-3`}
          >
            <CrmRenderIcon
              name={item.fieldType?.icon}
              size={16}
              color={COLORS.white}
            />
          </div>
          <span className={`text-black w-full text-sm`}>{item.name}</span>
          <div className="flex items-center justify-center">
            <div
              className="mt-[7px] mr-6"
              onClick={(e) => {
                e.stopPropagation();
                props.udf?.onChangeUdf(item);
              }}
            >
              <UdfEnableButton item={item} />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                props.udf?.onChangeUdf(item);
              }}
            >
              <DeleteButton
                renderComponent={<HiMiniTrash size={17} />}
                deleted={(res: RESPONSE) => {
                  props.udf?.onDeleteUdf();
                }}
                id={`${item.id}`}
                path={`udf`}
              />
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default inject("udf")(observer(UdfItem));
