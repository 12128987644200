import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {} from "react-icons/md";
import { Avatar, Typography } from "@mui/material";
import { DEPARTMENT, TEAM } from "src/interfaces/team.interface";
import { USER } from "src/interfaces/init.interface";
import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";
import { nameChar } from "src/utilities/formatter.utilities";
import { staticList } from "src/APIs/table.api";


interface Props {
  title?: string;
  item: TEAM | DEPARTMENT;
  value: USER | null;
  onSelected: (item: USER[]) => void;
  disabled?: boolean;
  isError?: boolean;
  readonly?: boolean;
  isBorder?: boolean;
  marginBottom?: any;
}
export default function AutoCompletedMultiMember(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<USER[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [item, setItem] = React.useState<TEAM | DEPARTMENT>();

  React.useEffect(() => {
    if (props.item) {
      setItem(props.item);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Typography style={{ marginBottom: 8 }}>
        Members
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: props.marginBottom ?? "8px",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {props.readonly ? (
            <TextField
              InputLabelProps={{
                style: { fontWeight: "600" },
                shrink: true,
              }}
              sx={
                props.isBorder
                  ? undefined
                  : {
                      border: "none",
                      "& fieldset": { border: "none" },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }
              }
              size={"small"}
              fullWidth
              disabled
              placeholder="Team"
              value={props.value?.fullName}
              error={props.isError}
            />
          ) : (
            <Autocomplete
              multiple
              ListboxProps={{
                sx: { overscrollBehavior: "contain", height: 100 },
              }}
              size="small"
              fullWidth
              disableCloseOnSelect
              disablePortal
              id="combo-box-demo"
              options={options}
              sx={{ flex: 1 }}
              renderOption={(props, option, { selected, index }) => {
                if (selected) return null;
                if (item?.users?.find((m) => m.id === option.id)) {
                  return null;
                }
                return (
                  <li
                    {...props}
                    style={{ color: selected ? COLORS.primary : COLORS.text }}
                  >
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {option.profilePictureUrl ? (
                        <img
                          style={{
                            marginRight: LAYOUTS.margin,
                            height: 30,
                            width: 30,
                            borderRadius: 100,
                            boxShadow: COLORS.shadow,
                          }}
                          src={option.profilePictureUrl ?? "N/A"}
                        />
                      ) : (
                        <Avatar
                          style={{
                            height: 30,
                            width: 30,
                            fontSize: 12,
                            marginRight: LAYOUTS.margin,
                          }}
                        >
                          {nameChar(option.fullName ?? "")}
                        </Avatar>
                      )}
                      {option.fullName} ({option.roleName})
                    </div>
                  </li>
                );
              }}
              onChange={(e: any, newValue: USER[] | any) => {
                props.onSelected(newValue);
              }}
              onOpen={(e) => {
                setLoading(true);
                staticList("user").then((doc) => {
                  if(doc.code !== "200") {
                    setLoading(false);
                    setOpen(true);
                    return;
                  }
                  setOptions(doc.data);
                  setLoading(false);
                  setOpen(true);
                });
              }}
              getOptionLabel={(m) => m?.fullName ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add member"
                  error={props.isError}
                  helperText={
                    props.isError ? "Please choose the members" : undefined
                  }
                  sx={
                    props.isBorder
                      ? undefined
                      : { border: "none", "& fieldset": { border: "none" } }
                  }
                  disabled={props.readonly}
                  InputLabelProps={{
                    style: { fontWeight: "600" },
                    shrink: true,
                  }}
                  variant="outlined"
                />
              )}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
