import { ContentTitle } from "../ContentTitle";
import { inject, observer } from "mobx-react";
import ProposalStore from "src/stores/proposal.store";
import images from "src/assets/images";
interface Props {
  proposal?: ProposalStore;
}

function ContentProjectProposal(props: Props) {
  const { sectionDetail } = props.proposal ?? {};

  return (
    <div className="mb-8">
      <ContentTitle title={"Proposal Information"} />
      <div className="flex justify-center items-center">
        <img
          className="h-[330px] mt-36"
          alt="logo"
          src={images.proposal_predefined_data}
        />
      </div>
    </div>
  );
}

export default inject("proposal")(observer(ContentProjectProposal));
