import { useEffect, useState } from "react";
import { HiIdentification, HiMap, HiMapPin } from "react-icons/hi2";
import images from "src/assets/images";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { Checkbox } from "src/components/CheckBox";
import DatePicker from "src/components/DatePicker";
import MainInput from "src/components/MainInput";
import MainTextArea from "src/components/MainTextArea";
import TitleWithBox from "src/components/TitleWithBox";
import CUSTOMER, { ADDRESS } from "src/interfaces/customer.interface";
import { AddressSelector } from "src/components/address/AddressSelector";
import { contactDetails } from "src/APIs/crm.api";
import { ESTIMATE_DETAILS } from "src/interfaces/estimate.interface";
import InputPhone from "src/components/InputPhone";

interface Props {
  data: ESTIMATE_DETAILS | null;
  onSetTaxRate: (tax: number) => void;
  taxRate: number;
  estimateNo: string | null;
  contactRouteId: string | number | null;
  onModify: () => void;
}

export default function EstimateInformation(props: Props) {
  const [customer, setCustomer] = useState<CUSTOMER | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [billingAddress, setBillingAddress] = useState<ADDRESS | null>(null);
  const [shippingAddress, setShippingAddress] = useState<ADDRESS | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [changeOrder, setChangeOrder] = useState<boolean>(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!props.data || !ready) return;
    props.data.customer = customer;
    props.data.telephone = phone;
    props.data.email = email;
    props.data.billingAddress = billingAddress;
    props.data.shippingAddress = shippingAddress;
    props.data.validTill = date;
    props.data.description = description;
    props.data.changeOrder = changeOrder;
    props.onModify();
  }, [
    customer,
    phone,
    email,
    billingAddress,
    shippingAddress,
    date,
    description,
    changeOrder,
  ]);

  useEffect(() => {
    if (props.data) {
      const {
        customer,
        telephone,
        email,
        billingAddress,
        shippingAddress,
        validTill,
        description,
        changeOrder,
        taxRate,
      } = props.data;
      if (props.contactRouteId) {
        contactDetails(props.contactRouteId as number).then((doc) => {
          if (doc.data) {
            const { displayName } = doc?.data as CUSTOMER;
            doc.data.fullName = `${displayName}`;
            setTimeout(() => onSelectedCustomer(doc.data), 1000);
          } else {
            setCustomer(customer);
            setPhone(telephone);
            setEmail(email);
            setBillingAddress(billingAddress);
            setShippingAddress(shippingAddress);
          }
        });
        setCustomer(customer);
        setPhone(telephone);
        setEmail(email);
        setBillingAddress(billingAddress);
        setShippingAddress(shippingAddress);
      } else {
        setCustomer(customer);
        setPhone(telephone);
        setEmail(email);
        setBillingAddress(billingAddress);
        setShippingAddress(shippingAddress);
      }
      setDate(validTill as Date);
      setDescription(description);
      setChangeOrder(changeOrder);
      props.onSetTaxRate(taxRate ?? 0);
      setTimeout(() => {
        setReady(true);
      }, 1000);
    }
  }, []);

  const onSelectedCustomer = (customer: CUSTOMER | null) => {
    const { telephone1, email, billingAddress, shippingAddress } =
      customer ?? {};
    setPhone(telephone1 ?? "");
    setEmail(email ?? "");
    setBillingAddress(billingAddress ?? null);
    setShippingAddress(shippingAddress ?? null);
    setCustomer(customer);
  };

  return (
    <div className="w-full mt-10">
      <TitleWithBox title="Estimate Informations" />
      <div className="flex mt-4 gap-8">
        <div className="flex flex-1 flex-col gap-1">
          {props.estimateNo ? (
            <MainInput
              disableEdit
              onChange={() => null}
              label="Customer"
              value={customer?.fullName ?? ""}
            />
          ) : (
            <AutoCompleteWithLazyLoad
              disabled={props.estimateNo ? true : false}
              onSelectedValue={(value) => onSelectedCustomer(value)}
              value={customer}
              required
              path="customer"
              icon={HiIdentification}
              placeholder="Choose customer"
              label="Customer"
            />
          )}
          <InputPhone
            onChange={(t) => setPhone(t)}
            value={phone}
            placeholder="Enter phone number"
            label="Phone Number"
          />
          <MainInput
            onChange={(text) => setEmail(text)}
            placeholder="your@email.com"
            label="Email"
            value={email}
          />
          <AddressSelector
            icon={HiMapPin}
            onSubmit={(value) => setBillingAddress(value)}
            value={billingAddress}
            placeholder="Enter billing address"
            label="Billing Address"
          />

          <AddressSelector
            withTax
            icon={HiMap}
            onSubmit={(value, tax) => {
              setShippingAddress(value);
              props.onSetTaxRate(tax ?? 0);
            }}
            value={shippingAddress}
            placeholder="Enter shipping address"
            label="Shipping Address"
          />
        </div>
        <div className="flex flex-1 flex-col">
          <MainInput
            disableEdit
            onChange={() => null}
            value={props.estimateNo}
            placeholder="System"
            label="Estimate No."
          />
          <DatePicker
            onDate={(date) => setDate(date)}
            value={date}
            placeholder="Choose date"
            label="Expired Date"
          />
          <MainTextArea
            onChange={(t) => setDescription(t)}
            value={description ?? ""}
            placeholder="Enter description"
            label="Description"
          />
          <div className="mt-6">
            <Checkbox
              value={changeOrder}
              onChange={(value) => setChangeOrder(value)}
              label="Change Order"
            />
          </div>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <img className="h-16 mb-56" alt="logo" src={images.LYNQiFY} />
        </div>
      </div>
    </div>
  );
}
