import { Typography } from "@mui/material";
import { Tooltip } from "react-tooltip";
import { COLORS } from "../assets/colors";

interface Props {
    show: boolean
    parentId: string
    message: string
}
export default function AlertTextField(props: Props) {
    return (
        <Tooltip
            style={{
                backgroundColor: COLORS.red,
                color: COLORS.white,
                zIndex: 66,
            }}
            isOpen={props.show}
            anchorSelect={`#${props.parentId}`}
            place="top-end">
            <Typography variant="caption" color={COLORS.white}>{props.message}</Typography>
        </Tooltip>
    )
}