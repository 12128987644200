import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from "styled-components";
import { LAYOUTS } from 'src/assets/layouts';
import { COLORS } from 'src/assets/colors';
import { PRODUCT_TYPE } from 'src/interfaces/init.interface';
import { GROUP_OR_ITEM } from 'src/interfaces/estimate.interface';
import { TEAM } from 'src/interfaces/team.interface';
import MarkupRow from './MarkupRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: LAYOUTS.padding,
    textTransform:"uppercase"
}));

interface Props {
    index: number,
    team: TEAM
    productTypes: PRODUCT_TYPE[]
    itemInTeams: GROUP_OR_ITEM[]
    isDisabled: boolean
    onMarkup: (value: string, type: PRODUCT_TYPE, isAll?: boolean, noHasModify?:boolean) => void;
    onCopyToAll: (value: number) => void
}

export default function MarkupTable(props: Props) {
    return (
        <React.Fragment>
            <div style={{ width: "100%", backgroundColor: COLORS.secondary,  padding: LAYOUTS.padding, paddingTop: LAYOUTS.padding_small, paddingBottom: LAYOUTS.padding_small, borderTopLeftRadius: LAYOUTS.radius, borderTopRightRadius: LAYOUTS.radius, color: COLORS.text, fontWeight: "bold", marginTop: LAYOUTS.margin, textTransform: "uppercase", paddingLeft: 15 }} >{props.team.name}</div>
            <TableContainer style={{  marginBottom: 45 }} component={Paper}>
                <Table
                    size='small'
                    sx={{ minWidth: 700, tableLayout: "fixed" }} aria-label="customized table">
                    <TableHead>
                        <TableRow className="bg-muted">
                            <StyledTableCell align="left">Product Type</StyledTableCell>
                            <StyledTableCell align="left">Markup(%)</StyledTableCell>
                            <StyledTableCell align="left">Profit Margin</StyledTableCell>
                            <StyledTableCell align="left">Markup Value</StyledTableCell>
                            <StyledTableCell align="left">Total</StyledTableCell>
                            <StyledTableCell align="left">Total With Markup</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.productTypes.map((row) => <MarkupRow
                            key={row.id}
                            itemInTeams={props.itemInTeams}
                            isDisabled={props.isDisabled}
                            team={props.team}
                            onCopyToAll={props.onCopyToAll}
                            onMarkup={props.onMarkup}
                            row={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}
