import { useState, useEffect } from "react";
import { updateDefaultProductionRate, updateEnableProductionRate } from "src/APIs/product.api";
import { Checkbox } from "src/components/CheckBox";
import { DeleteButton } from "src/components/DeleteButton";
import { Switch } from "src/components/ui/switch";
import { TableRow, TableCell } from "src/components/ui/table";
import { PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import { FIELD_LIST_ITEM } from "src/interfaces/product.interface";


interface pRow {
  onClick: () => void;
  data: PRODUCTION_RATE;
  unit: FIELD_LIST_ITEM | null;
  onDeleted: () => void;
  onDefault: (list: PRODUCTION_RATE[]) => void;
}

export default function ProductionRateRow(props: pRow) {
  const { data, unit } = props;
  const [isDefault, setDefault] = useState(false);
  const [isEnabled, setEnable] = useState(false);

  useEffect(() => {
    setDefault(props.data.selected);
    setEnable(props.data.enable);
  }, [props.data]);

  async function onDefault(doc: PRODUCTION_RATE, value: boolean) {
    setDefault(value);
    const result = await updateDefaultProductionRate(doc.id);
    if (!result) setDefault(!value);
    props.onDefault(result?.data);
  }

  async function onEnable(doc: PRODUCTION_RATE, value: boolean) {
    setEnable(value);
    const result = await updateEnableProductionRate(doc.id, value);
    if (!result) setEnable(!value);
  }

  return (
    <TableRow
      onClick={props.onClick}
      className={"cursor-pointer"}
      key={data.id}
    >
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.rate}</TableCell>
      <TableCell>{unit?.value ?? "N/A"}</TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Checkbox onChange={(e) => onEnable(props.data, e)} value={isEnabled} />
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center">
          <Switch
            onCheckedChange={(v) =>
              isDefault ? null : onDefault(props.data, v)
            }
            checked={isDefault}
          />
        </div>
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <DeleteButton
          path="production-rate"
          id={props.data.id}
          deleted={(result) => props.onDeleted()}
        />
      </TableCell>
    </TableRow>
  );
}
