import { makeAutoObservable, observable, action } from "mobx";
import {
  estimateSetting,
  getFieldListItems,
  initData,
  logOut,
  refreshToken,
} from "src/APIs/auth.api";
import { AUTH } from "src/interfaces/auth.interface";
import {
  CASE_TYPE,
  COLOR,
  COUNTRY,
  ENTITY,
  INIT_DATA,
  PERMISSION_CLASSIFICATION,
  PRODUCT_TYPE,
  ROLE,
} from "src/interfaces/init.interface";
import {
  removeAuth,
  removeStoredUniqueKey,
} from "src/utilities/storage.utilities";
import {
  CATALOG_ENTITY,
  ESTIMATE_TEMPLATE,
  LABOR_PRICING_TEAM,
  MEMBER_ENTITY,
  PRODUCT_ENTITY,
  PRODUCTION_RATE_ENTITY,
  PROPOSAL_LIST,
  PROPOSAL_TEMPLATE,
  ROLE_ENTITY,
} from "../constants/static_entity.constants";
import { FIELD_LIST_ITEM } from "src/interfaces/product.interface";
import { ALPHABETIC_CHARACTERS, FIELD_LIST_TYPES } from "src/constants";
import { ESTIMATE_SETTING } from "src/interfaces/estimate.interface";
import { onUpdateEntity } from "src/services/auth.service";
import { updateObjArray } from "src/utilities/functions.utilities";
import { FIELD_TYPE } from "src/interfaces/udf.interface";
import paginationAutoComplete from "src/APIs/auto_complete.api";
import dayjs from "dayjs";

export default class AuthStore {
  @observable alphabetic: string = ALPHABETIC_CHARACTERS.normal.value;
  @observable dateFormat: string = "MMM DD, YYYY";
  @observable permissionClassifications: PERMISSION_CLASSIFICATION[] = [];
  @observable fieldTypes: FIELD_TYPE[] = [];
  @observable entities: ENTITY[] = [];
  @observable countries: COUNTRY[] = [];
  @observable colors: COLOR[] = [];
  @observable productTypes: PRODUCT_TYPE[] = [];
  @observable caseTypes: CASE_TYPE[] = [];
  @observable authData: AUTH | null = null;
  @observable unitList: FIELD_LIST_ITEM[] = [];
  @observable estimateSetting: ESTIMATE_SETTING | null = null;
  @observable roles: ROLE[] = [];
  @observable changingColumn: boolean = false;
  @observable loggingOut: boolean = false;

  @observable isAutoSave: boolean = false;
  @observable durationAutoSave: number = 0;
  @observable intervalId: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetToken(nextExpired: number) {
    const currentDate = dayjs().unix();
    const tokenExpiredIn = ((nextExpired ?? 0) - currentDate) * 1000;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(
      () => {
        refreshToken().then((auth) => {
          if (!auth) {
            removeAuth();
            removeStoredUniqueKey();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return;
          }
          const authRes: any = auth;
          const currentDate = dayjs().unix();
          const tokenExpired = currentDate + authRes.tokenExpired / 1000;
          this.resetToken(tokenExpired);
        });
      },
      tokenExpiredIn <= 0 ? 5000 : tokenExpiredIn
    );
  }

  @action onLoggedInSuccess = async (auth: AUTH, callback?: () => void) => {
    const _initData: INIT_DATA = (await initData())?.data;
    const _unitList: FIELD_LIST_ITEM[] = (
      await getFieldListItems(FIELD_LIST_TYPES.production_rate_unit.key)
    ).data;
    const _estimateSetting: ESTIMATE_SETTING = (await estimateSetting())?.data;
    const _roles: ROLE[] = (await paginationAutoComplete("role", 0, 100, ""))
      ?.data?.content;
    if (_initData) {
      this.permissionClassifications = _initData.permissionClassifications;
      this.fieldTypes = _initData.fieldTypes;
      this.entities = _initData.entities.concat([
        PRODUCT_ENTITY,
        CATALOG_ENTITY,
        PRODUCTION_RATE_ENTITY,
        LABOR_PRICING_TEAM,
        MEMBER_ENTITY,
        ROLE_ENTITY,
        ESTIMATE_TEMPLATE,
        PROPOSAL_LIST,
        PROPOSAL_TEMPLATE,
      ]);
      this.countries = _initData.countries;
      this.colors = _initData.colors;
      this.productTypes = _initData.productType;
      this.caseTypes = _initData.caseTypes;
    }

    if (_roles) {
      this.roles = _roles;
    }

    if (_estimateSetting) {
      this.updateEstimateSetting(_estimateSetting);
    }

    if (_unitList) {
      this.unitList = _unitList;
    }

    this.resetToken(auth.tokenExpired);
    this.authData = auth;
    callback && callback();
  };

  @action updateEstimateSetting(data: ESTIMATE_SETTING) {
    this.estimateSetting = data;
    this.alphabetic = data.caseType;
    this.durationAutoSave = data.autoSavePeriod;
    this.isAutoSave = data.autoSave;
  }

  @action onLoggedOut = () => {
    this.loggingOut = true;
    logOut((res) => {
      this.loggingOut = false;
      if (res.code === "200") {
        this.authData = null;
        removeStoredUniqueKey();
        removeAuth();
      }
    });
  };

  @action onChangeIsAutoSave(v: boolean) {
    this.isAutoSave = v;
  }

  @action onUpdateEntity = async (
    entityDoc: ENTITY,
    callback: (data: ENTITY) => void
  ) => {
    if (this.changingColumn) return;
    this.changingColumn = true;
    try {
      const res = await onUpdateEntity(entityDoc);
      if (res?.data) {
        this.entities = updateObjArray(this.entities, res.data);
        callback(res.data);
      }
      this.changingColumn = false;
    } catch (error) {
      this.changingColumn = false;
    }
  };
}
