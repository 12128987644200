import ReactQuill from "react-quill";
import "./RichTextEditor.scss";
import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  value: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  disableHeaderTools?: boolean;
  readonly?: boolean;
  height?: number;
  noBorder?: boolean;
  withShadow?: boolean;
}

export const useFocusAndSetRef = (ref: any) => {
  ref = useCallback(
    (node: any) => {
      if (node !== null) {
        ref.current = node; // it is not done on it's own
        const len = node.unprivilegedEditor.getLength();
        const selection = { index: len, length: len };
        node.setEditorSelection(node.editor, selection);
      }
    },
    [ref]
  );
  return ref;
};

export default function RichTextEditor(props: Props) {
  let ref = useRef(null) as any;
  ref = useFocusAndSetRef(ref);
  const [text, setText] = useState("");

  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value]);

  return (
    <div
      onBlur={(e) => {
        props.onChange && props.onChange(text);
      }}
      className={
        props.readonly
          ? ""
          : ` p-4 size-full rounded-md ${props.noBorder ? "" : "mt-5 border border-gray-300  "} ${props.withShadow ? "mt-5 drop-shadow bg-white" : ""}  `
      }
    >
      <ReactQuill
        readOnly={props.readonly}
        ref={props.autoFocus ? ref : null}
        className={"ql-cus"}
        theme="snow"
        modules={{
          toolbar: null,
        }}
        placeholder="Enter your description..."
        value={text}
        onChange={setText}
      />
    </div>
  );
}
