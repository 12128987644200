import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import NotFound from "src/app/notFound";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import { STEP } from "src/components/Stepper";
import SubSidebar from "src/components/subSidebar/SubSidebar";
import CUSTOMER from "src/interfaces/customer.interface";
import { contact } from "src/routes/routeNav.config";
import CRMStore from "src/stores/crm.store";
import AddContact from "../AddContact";
import ContactDetailHeader from "./ContactDetailHeader";
import ContactDetailSubHeader from "./ContactDetailSubHeader";

const tabs: STEP[] = [
  {
    name: "Customer Details",
    id: 1,
    sequence: 1,
    route: "customer-details",
  },
  {
    name: "Estimates",
    id: 2,
    sequence: 2,
    route: "estimates",
  },
  {
    name: "Proposals",
    id: 3,
    sequence: 3,
  },
  {
    name: "Invoices",
    id: 4,
    sequence: 4,
  },
  {
    name: "Payments",
    id: 5,
    sequence: 5,
  },
  {
    name: "Projects",
    id: 6,
    sequence: 6,
  },
  {
    name: "History",
    id: 7,
    sequence: 7,
  },
];

interface Props {
  crm?: CRMStore;
}

function ContactDetails(props: Props) {
  const { id } = useParams();
  const [contactData, setContactData] = useState<CUSTOMER | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (props.crm?.contactDetails === null) {
      if (!id) {
        setLoading(false);
        return;
      }
      props.crm.onGetContactDetails(Number(id)).then((result) => {
        if (result?.data && result.code === "200") {
          setContactData(result?.data);
        } else {
          setErrorMessage(result?.message);
        }
        setLoading(false);
      });
    } else {
      setContactData(props.crm?.contactDetails || null);
      setLoading(false);
    }
    return () => {
      props.crm?.onClear();
    };
  }, []);

  if (loading) {
    return <EstimateDetailsSkeleton />;
  }

  if (errorMessage) {
    return (
      <NotFound
        title={errorMessage}
        message={
          "We're sorry, the estimate that you are looking for is not found or you don't have the access right!"
        }
      />
    );
  }

  return (
    <div className="w-screen h-screen bg-muted">
      <ContactDetailHeader onEditContact={() => setShowEdit(true)} />
      <div className="w-full h-full pb-14 overflow-auto">
        <ContactDetailSubHeader />
        <SubSidebar
          noActiveBorder
          className={
            "flex items-center justify-between p-4 lg:px-4 sticky top-0 z-40 bg-muted"
          }
          routes={contact}
        />
        <Outlet />
      </div>
      <AddContact
        title="Edit Contact"
        onOpen={(v) => setShowEdit(v)}
        open={showEdit}
      />
    </div>
  );
}

export default inject("crm")(observer(ContactDetails));
