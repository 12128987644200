import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import TableStore from "src/components/tableComponents/TableStore";
import { onCreateProposalByTemplate } from "src/services/proposal.service";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "src/components/ui/drawer";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import { CurrentTempate } from "../proposalTemplate/AddProposalTemplate/CurrentTemplate";
import { DefaultTempate } from "../proposalTemplate/AddProposalTemplate/DefaultTemplate";
import ProposalMainInput from "../proposalDetail/proposalContent/dynamicComponent/ProposalMainInput";
import MainInputNoState from "src/components/MainInputNoState";

interface Props {
  auth?: AuthStore;
  table?: TableStore;
  open: boolean;
  onOpen: (value: boolean) => void;
  estimateId: number;
  onCreated?: (v: PROPOSAL_TEMPLATE) => void;
}

function AddProposal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<PROPOSAL_TEMPLATE | null>(null);

  useEffect(() => {
    setName("");
    setSelectedTemplate(null);
  }, [props.open]);

  function onSave() {
    setLoading(true);
    onCreateProposalByTemplate(
      selectedTemplate?.id ?? 0,
      name,
      props.estimateId
    ).then((data) => {
      if (data !== null) {
        setLoading(false);
        props.table?.updateData({ id: data.id, name: data.name });
        props.onOpen(!props.open);
        props.onCreated && props.onCreated(data);
      }
    });
  }

  return (
    <Drawer open={props.open} onOpenChange={(open) => props.onOpen(open)}>
      <DrawerContent forceMount className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>Create New Proposal</DrawerTitle>
            <DrawerDescription>
              Please choose your favorite template
            </DrawerDescription>
          </div>
          <CloseDrawerButton onClick={() => props.onOpen(!props.open)} />
        </DrawerHeader>
        <div className="p-4 pt-2 bg-slate-50 overflow-auto">
          <div className="flex max-w-80">
            <MainInputNoState
              required
              placeholder="Enter title"
              value={name}
              onChange={setName}
              label="Proposal Title"
            />
          </div>
          <DefaultTempate
            value={selectedTemplate}
            onChange={(v) => setSelectedTemplate(v)}
          />
          <CurrentTempate
            noSearch
            value={selectedTemplate}
            onChange={(v) => setSelectedTemplate(v)}
          />

          <div className="flex justify-end mt-6 mb-3">
            <div className="w-52 mr-3">
              <MainButton
                variant={"outline"}
                type="button"
                label="Cancel"
                onClick={() => props.onOpen(false)}
              />
            </div>
            <div className="w-52">
              <MainButton
                disabled={name === "" || selectedTemplate === null}
                loading={loading}
                type="button"
                label="Save"
                onClick={() => onSave()}
              />
            </div>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default inject("auth", "table")(observer(AddProposal));
