import { PopoverClose } from "@radix-ui/react-popover";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import { Button } from "src/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TableCell } from "src/components/ui/table";
import {
  dateToString4API,
  displayDate,
  stringDateToDate,
} from "src/utilities/formatter.utilities";
import { cn } from "src/lib/utils";
import { Calendar } from "src/components/ui/calendar";

interface Props {
  value: string;
  onSave: (v: any, onSuccess: () => void) => void;
}

export const ValueDate = (props: Props) => {
  const [date, setDate] = useState<Date | null>(null);
  const [editing, setEdting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDate(stringDateToDate(props.value));
  }, [props.value]);

  const onUpdate = (date: Date) => {
    if (props.value === date.toString()) {
      setEdting(false);
      return;
    }
    setLoading(true);
    props.onSave(dateToString4API(date), () => {
      setLoading(false);
      setEdting(false);
    });
  };

  return (
    <TableCell className="border-r-[0.9px] p-0 px-[1px]">
      {editing ? (
        <div
          onMouseLeave={() => {
            setEdting(false);
          }}
          className="relative flex"
        >
          <Popover>
            <PopoverTrigger asChild>
              <Button
                disabled={false}
                variant={"outline"}
                className={cn(
                  `h-[46px] pl-3 text-left font-normal w-full border border-gray-300 ${
                    !date ? "text-muted-foreground" : ""
                  }`
                )}
              >
                <span>{date ? displayDate(date) : "Date"}</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="end">
              <PopoverClose>
                <Calendar
                  onDayClick={(date) => {
                    onUpdate(date);
                  }}
                  mode="single"
                  initialFocus
                />
              </PopoverClose>
            </PopoverContent>
          </Popover>
          {loading ? (
            <Loader2
              color={COLORS.primary}
              className="h-4 w-4 animate-spin absolute right-2 top-4"
            />
          ) : null}
        </div>
      ) : (
        <div
          onMouseLeave={() => {
            setEdting(false);
          }}
          onMouseOver={() => {
            setEdting(true);
          }}
          onClick={() => {
            setEdting(true);
          }}
          className="hover:bg-white min-h-[48px] flex items-center cursor-text px-1"
        >
          {date?.toDateString()}
        </div>
      )}
    </TableCell>
  );
};
