import { useLayoutEffect, useRef, useState } from "react";
import MainTable from "src/components/tableComponents/MainTable";
import { ROLE } from "src/interfaces/init.interface";
import AddRole from "./AddRole";
import { ROLE_ENTITY } from "src/constants/static_entity.constants";

export default function RoleListPage() {
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const [showAdd, setShowAdd] = useState(false);
  const [role, setRole] = useState<ROLE | null>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        addLabel="Add Role"
        onAdd={() => {
          setRole(null);
          setShowAdd(true);
        }}
        height={height + 45}
        onClick={(row: any) => {
          setRole(row);
          setShowAdd(true);
        }}
        entityCode={ROLE_ENTITY.code}
        path="role"
      />
      <AddRole role={role} onOpen={(v) => setShowAdd(v)} open={showAdd} />
    </div>
  );
}
