import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { IconButton, ListItemIcon } from "@mui/material";
import { IoMdMore } from "react-icons/io";
import {
  IoPencilOutline,
  IoPersonAddOutline,
  IoTrashBinOutline,
} from "react-icons/io5";
import { LAYOUTS } from "src/assets/layouts";
import { DeleteButton } from "src/components/DeleteButton";
import { DEPARTMENT } from "src/interfaces/team.interface";

interface Props {
  onEdit?: () => void;
  onAddMember?: () => void;
  department: DEPARTMENT;
  onDelete: () => void;
}

export default function DropDownDepartmentMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleClickListItem(e);
        }}
      >
        <IoMdMore />
      </IconButton>
      <Menu
        onBlur={(e) => e.stopPropagation()}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          style={{ fontSize: 14 }}
          onClick={(event) => {
            event.stopPropagation();
            props.onEdit && props.onEdit();
            handleClose();
          }}
        >
          <ListItemIcon>
            <IoPencilOutline size={LAYOUTS.icon_size} />
          </ListItemIcon>
          {"Rename"}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            props.onAddMember && props.onAddMember();
            handleClose();
          }}
          style={{ fontSize: 14 }}
        >
          <ListItemIcon>
            <IoPersonAddOutline size={LAYOUTS.icon_size} />
          </ListItemIcon>
          {"Add member"}
        </MenuItem>
        <DeleteButton
          renderComponent={
            <MenuItem
              style={{ fontSize: 14 }}>
              <ListItemIcon>
                <IoTrashBinOutline size={LAYOUTS.icon_size} />
              </ListItemIcon>
              {"Delete"}
            </MenuItem>
          }
          path="department"
          deleted={() => props.onDelete()}
          id={props.department.id}
        />
      </Menu>
    </React.Fragment>
  );
}
