import { Outlet } from "react-router-dom";
import SubSidebar from "src/components/subSidebar/SubSidebar";
import { estimate } from "src/routes/routeNav.config";

export default function EstimateLayout() {
  return (
    <div className="flex flex-1 flex-col">
      <SubSidebar routes={estimate} />
      <Outlet />
    </div>
  );
}
