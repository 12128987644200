import { nullString } from "./functions.utilities";

export function isValidPassword(inputText: string) {
    if (nullString(inputText) === null) return false;
    if (inputText.length >= 6 && inputText.length <= 24) {
      return true;
    } else {
      return false;
    }
  }


export function isValidEmail(inputText: string) {
    var mailFormat = /\S+@\S+\.\S+/;
    if (inputText.match(mailFormat)) {
      return true;
    } else {
      return false;
    }
  }