import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import { onCreateTemplateByDefaultTemplate } from "src/services/proposal.service";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "src/components/ui/drawer";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import { DefaultTempate } from "./DefaultTemplate";
import { CurrentTempate } from "./CurrentTemplate";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import MainInputNoState from "src/components/MainInputNoState";

interface Props {
  open: boolean;
  onOpen: (value: boolean) => void;
  onCreated?: (v: PROPOSAL_TEMPLATE) => void;
}

function AddProposalTemplate(props: Props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<PROPOSAL_TEMPLATE | null>(null);

  useEffect(() => {
    setName("");
    setSelectedTemplate(null);
  }, [props.open]);

  function onSave() {
    setLoading(true);
    onCreateTemplateByDefaultTemplate(selectedTemplate?.id ?? 0, name).then(
      (data) => {
        if (data !== null) {
          setLoading(false);
          props.onOpen(!props.open);
          props.onCreated && props.onCreated(data);
        }
      }
    );
  }

  return (
    <Drawer open={props.open} onOpenChange={(open) => props.onOpen(open)}>
      <DrawerContent forceMount className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>Create New Template</DrawerTitle>
            <DrawerDescription>
              Please choose your favorite template
            </DrawerDescription>
          </div>
          <CloseDrawerButton onClick={() => props.onOpen(!props.open)} />
        </DrawerHeader>
        <div className="p-4 pt-2 bg-slate-50 overflow-auto">
          <MainInputNoState
            focus
            label="Template Name"
            placeholder={`Enter name`}
            value={name}
            onChange={setName}
            className=" w-[280px]"
          />
          <DefaultTempate
            value={selectedTemplate}
            onChange={(v) => setSelectedTemplate(v)}
          />
          <CurrentTempate
            noSearch
            value={selectedTemplate}
            onChange={(v) => setSelectedTemplate(v)}
          />
          <div className="flex justify-end mt-6 mb-3">
            <div className="w-52 mr-3">
              <MainButton
                variant={"outline"}
                type="button"
                label="Cancel"
                onClick={() => props.onOpen(false)}
              />
            </div>
            <div className="w-52">
              <MainButton
                disabled={name === "" || selectedTemplate === null}
                loading={loading}
                type="button"
                label="Save"
                onClick={() => onSave()}
              />
            </div>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default AddProposalTemplate;
