import { Dialog, DialogContent, DialogTitle } from "src/components/ui/dialog";
import { DialogHeader, DialogFooter } from "src/components/ui/dialog";
import MainButton from "src/components/MainButton";
import { useCallback, useState } from "react";
import Cropper, { Area, Size } from "react-easy-crop";
import getCroppedImg from "src/utilities/functions.utilities";
import { Slider } from "@mui/material";
interface Props {
  open: boolean;
  onOpen: (value: boolean) => void;
  image: string | null;
  onUpload: (base64: string) => void;
  uploading:boolean
}

export function ImageCropper(props: Props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropSize, setCropSize] = useState<Size>({ width: 300, height: 300 });
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  );

  const onSaveCroped = async () => {
    getCroppedImg(props.image!, croppedArea!, 0, (base64) => {
      props.onUpload(base64);
    });
  };


  return (
    <Dialog  open={props.open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Crop Image</DialogTitle>
        </DialogHeader>
        <div className="w-full h-96 flex items-center justify-center relative">
          <div className="h-full w-full">
            {props.image ? (
              <Cropper
                style={{
                  cropAreaStyle: {
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
                image={props.image ?? ""}
                cropShape={"rect"}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                cropSize={cropSize}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                setCropSize={setCropSize}
                onCropComplete={onCropComplete}
              />
            ) : null}
          </div>
        </div>
        <Slider
          min = {1}
          max = {3}
          step={0.0001}
          value={zoom}
          onChange={(_, value) => {
            if (value === 0) return;
            setZoom(value as number);
          }}
        />
        <DialogFooter>
          <MainButton
            type="button"
            variant={"outline"}
            label="Cancel"
            onClick={() => {
              props.onOpen(false);
            }}
          />
          <MainButton
            loading = {props.uploading}
            type="button"
            label="Save"
            onClick={() => {
              onSaveCroped()
            }}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
