import { useState } from "react";
import MainButton from "src/components/MainButton";
import { Label } from "src/components/ui/label";
import SignaturePopUp from "./SignaturePopUp";
import { META_DATA } from "src/interfaces/proposal.interface";

interface Props {
  title: string;
  child: META_DATA;
  parent: META_DATA;
}

export const Signature = (props: Props) => {
  const [openSign, setOpenSign] = useState(false);
  const onOpenSign = () => setOpenSign(!openSign);

  return (
    <>
      <SignaturePopUp
        parent={props.parent}
        child={props.child}
        open={openSign}
        onOpen={onOpenSign}
      />
      <div>
        <div className="font-semibold text-gray-500 mt-4">{props.title}</div>
        <div
          onClick={props.child.value !== null ? undefined : onOpenSign}
          className={`h-64 border-[1.8px] border-dashed rounded-sm mt-3 cursor-pointer flex items-center justify-center bg-muted`}
        >
          {props.child.value !== null ? (
            <div>
              <img src={props.child.value} loading="lazy" alt="" />
            </div>
          ) : (
            <Label className="cursor-pointer">Click to sign</Label>
          )}
        </div>
        {props.child.value !== null ? (
          <div className="flex mt-3 items-end justify-end">
            <div className="flex">
              <MainButton
                onClick={onOpenSign}
                variant={"outline"}
                label="Edit"
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
