import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  Drawer,
} from "src/components/ui/drawer";
import { useState, useEffect } from "react";
import { orderByAsc } from "src/utilities/formatter.utilities";
import SortableTree, {
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree";
import { toTreeData } from "./estimate.utility";
//@ts-ignore
import FileExplorerTheme from "@nosferatu500/theme-file-explorer";
import {
  RiFolderFill,
  RiPriceTag3Fill,
  RiFolderSharedFill,
} from "react-icons/ri";
import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";
import MainButton from "src/components/MainButton";
import { HiMiniArrowLongRight } from "react-icons/hi2";


interface Props {
  open: boolean;
  onClose: () => void;
  items: GROUP_OR_ITEM[];
  onSave: (selectedGroup: GROUP_OR_ITEM) => void;
  checkItems: GROUP_OR_ITEM[];
}

export function ProductArrangement(props: Props) {
  const [items, setItems] = useState<any[]>([]);
  const [checkItems, setCheckItems] = useState<any[]>([]);
  const [selectedGroup, setGroup] = useState<GROUP_OR_ITEM | null>(null);

  useEffect(() => {
    if (!props.open) return;
    const treeData = toTreeData(orderByAsc(props.items, "sequence"));
    setItems(
      toggleExpandedForAll({
        treeData,
        expanded: true,
      })
    );
    setCheckItems(
      toggleExpandedForAll({
        treeData,
        expanded: true,
      })
    );
    return () => {
      props.items.forEach((m, i) => {
        props.items[i].displayCode = undefined;
      });
      setGroup(null);
      setItems([]);
      setCheckItems([]);
    };
  }, [props.open]);

  return (
    <Drawer open={props.open}>
      <DrawerContent className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex items-center">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>Move to group</DrawerTitle>
            <DrawerDescription className="mt-2">
              Please choose a group that you want to put your items in.
            </DrawerDescription>
          </div>
          <CloseDrawerButton onClick={() => props.onClose()} />
        </DrawerHeader>
        <div className="flex gap-4 h-full w-full p-4">
          <div className="w-[45%] overflow-hidden bg-slate-50 p-4 rounded-md drop-shadow">
            <SortableTree
              style={{
                fontSize: 16,
              }}
              theme={FileExplorerTheme}
              generateNodeProps={(rowInfo) => {
                const item = rowInfo?.node?.data;
                const isCheck = props.checkItems.find((m) => m.id === item.id)
                  ? true
                  : false;
                return {
                  title: (
                    <p
                      style={{
                        cursor: "not-allowed",
                        color: COLORS.text,
                        marginBottom: 3,
                        fontWeight: "normal",
                        opacity: 1,
                      }}
                    >
                      {rowInfo.node.data.title}
                    </p>
                  ),
                  subtitle: rowInfo.node.subTitle,
                  icons: [
                    item.isGroup ? (
                      <RiFolderFill
                        className="cursor-not-allowed"
                        size={LAYOUTS.icon_size}
                        color={COLORS.sub_text}
                        style={{
                          marginRight: LAYOUTS.margin,
                          color: isCheck ? COLORS.secondary : COLORS.primary,
                        }}
                      />
                    ) : (
                      <RiPriceTag3Fill
                        className="cursor-not-allowed"
                        size={LAYOUTS.icon_size}
                        color={COLORS.sub_text}
                        style={{
                          marginRight: LAYOUTS.margin,
                          color: isCheck ? COLORS.secondary : COLORS.primary,
                        }}
                      />
                    ),
                  ],
                };
              }}
              canDrag={() => false}
              treeData={checkItems}
              onChange={(treeData) => setCheckItems(treeData)}
            />
          </div>
          <div className="w-[10%] overflow-hidden p-4 flex justify-center items-center">
            <HiMiniArrowLongRight size={72} />
          </div>
          <div
            className={`w-[45%] overflow-hidden bg-slate-50 p-4 rounded-md drop-shadow`}
          >
            <SortableTree
              style={{
                fontSize: 16,
              }}
              theme={FileExplorerTheme}
              generateNodeProps={(rowInfo) => {
                const item = rowInfo?.node?.data;
                const isItem = item.isGroup === false;
                const disabled = props.checkItems.find((m) => m.id === item.id);
                const isSelected = rowInfo.node.data.id === selectedGroup?.id;
                if(isItem) return
                return {
                  title: (
                    <p
                      style={{
                        cursor: isItem || disabled ? "not-allowed" : "pointer",
                        color: isSelected ? COLORS.secondary : COLORS.text,
                        marginBottom: 3,
                        fontWeight: isSelected ? "bold" : "normal",
                        opacity: disabled ? 0.3 : 1,
                      }}
                    >
                      {rowInfo.node.data.title}
                    </p>
                  ),
                  subtitle: rowInfo.node.subTitle,
                  icons: [
                    isSelected ? (
                      <RiFolderSharedFill
                        className="cursor-pointer"
                        size={LAYOUTS.icon_size}
                        color={COLORS.sub_text}
                        style={{
                          marginRight: LAYOUTS.margin,
                          color: COLORS.secondary,
                        }}
                      />
                    ) : disabled ? (
                      isItem ? (
                        <RiPriceTag3Fill
                          className="cursor-not-allowed"
                          size={LAYOUTS.icon_size}
                          color={COLORS.sub_text}
                          style={{ marginRight: LAYOUTS.margin, opacity: 0.3 }}
                        />
                      ) : (
                        <RiFolderFill
                          className="cursor-not-allowed"
                          size={LAYOUTS.icon_size}
                          color={COLORS.sub_text}
                          style={{ marginRight: LAYOUTS.margin, opacity: 0.3 }}
                        />
                      )
                    ) : isItem ? (
                      <RiPriceTag3Fill
                        className="cursor-not-allowed"
                        size={LAYOUTS.icon_size}
                        color={COLORS.sub_text}
                        style={{ marginRight: LAYOUTS.margin }}
                      />
                    ) : (
                      <RiFolderFill
                        className="cursor-pointer"
                        size={LAYOUTS.icon_size}
                        color={COLORS.sub_text}
                        style={{ marginRight: LAYOUTS.margin }}
                      />
                    ),
                  ],
                  onClick: () =>
                    isItem
                      ? null
                      : disabled
                      ? null
                      : setGroup(rowInfo.node.data),
                };
              }}
              canDrag={() => false}
              treeData={items}
              onChange={(treeData) => setItems(treeData)}
            />
          </div>
        </div>
        <div className="flex justify-end p-4">
          <div className="w-52 mr-4">
            <MainButton
              variant={"outline"}
              type="button"
              onClick={() => props.onClose()}
              label="Cancel"
            />
          </div>
          <div className="w-52">
            <MainButton
              disabled={!selectedGroup}
              type="button"
              onClick={() => selectedGroup && props.onSave(selectedGroup)}
              label="Save Changes"
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
