import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Avatar, IconButton } from "@mui/material";
import { IoClose, IoPeopleOutline } from "react-icons/io5";
import {  TEAM } from "src/interfaces/team.interface";
import { USER } from "src/interfaces/init.interface";
import { LAYOUTS } from "src/assets/layouts";
import AutoCompletedMultiMember from "src/components/autoComplete/AutoCompletedMultiMember";
import MainButton from "src/components/MainButton";
import { addTeamMembers } from "src/APIs/team.api";

interface Props {
  isDepartment?: boolean;
  item: TEAM;
  isOpen: boolean;
  onClose: () => void;
  afterAddedMembers: (departments: TEAM[]) => void;
}

function AddMembers(props: Props) {
  const [isError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<USER[]>([]);

  const onSave = () => {
    setLoading(true);
    if (members.length === 0) {
      setError(true);
      setLoading(false);
    } else {
      addTeamMembers(
        props.item.id,
        members.map((m) => m.id)
      ).then((data) => {
        if (data?.code !== "200") {
          setError(true);
          setLoading(false);
          return;
        }
        props.afterAddedMembers(data.data);
        setLoading(false);
        setError(false);
        props.onClose();
      });
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        setError(false);
        props.onClose();
      }}
    >
      <DialogTitle>
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Avatar
            style={{
              height: 30,
              width: 30,
              fontSize: 12,
              marginRight: LAYOUTS.margin,
            }}
          >
            <IoPeopleOutline size={20} />
          </Avatar>
          Create Member
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          />
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => props.onClose()}
          >
            <IoClose fontSize="inherit" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          width: 380,
          padding: LAYOUTS.padding_big,
          paddingTop: 5,
          paddingBottom: 100,
        }}
      >
        <AutoCompletedMultiMember
          isError={isError}
          item={props.item}
          readonly={false}
          isBorder={true}
          value={null}
          onSelected={(m) => {
            setMembers(m);
            if (m.length !== 0) {
              setError(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: LAYOUTS.padding_big }}>
        <MainButton
          type="button"
          onClick={() => props.onClose()}
          variant={"outline"}
          label="Cancel"
        />
        <MainButton
          type="button"
          onClick={onSave}
          label="Save"
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AddMembers;
