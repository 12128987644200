import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { HiMiniCog8Tooth, HiXMark } from "react-icons/hi2";
import { ENTITY, ENTITY_CONFIG } from "src/interfaces/init.interface";
import { EntityItem } from "./EntityItem";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { arrayMoveImmutable } from "array-move";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorderSequence } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";

interface Props {
  entityCode?: string;
  auth?: AuthStore;
}

function UpdateEntity(props: Props) {
  const [columns, setColumns] = useState<ENTITY_CONFIG[]>([]);
  const [entity, setEntity] = useState<ENTITY>();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setProcessing(true);
    if (props.entityCode) {
      const data = props.auth?.entities.find(
        (m) => m.code === props.entityCode
      );
      setEntity(data);
      setColumns(orderByAsc(data?.entityConfigurations || [], "sequence"));
      setProcessing(false);
    }
  }, []);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = arrayMoveImmutable(
      columns,
      result.source.index,
      result.destination.index
    );
    setColumns(items);
  };

  const onSave = () => {
    if (entity) {
      var data: ENTITY = {
        ...entity,
        entityConfigurations: reorderSequence(columns),
      };
      props.auth?.onUpdateEntity(data, (doc) => {
        window.location.reload();
      });
    }
  };

  if (entity?.isDummy === true) {
    return <div />;
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <MainButton
          variant={"outline"}
          icon={HiMiniCog8Tooth}
          type="button"
          className="ml-3"
        />
      </SheetTrigger>
      <SheetContent className=" overflow-auto h-[99%] w-[40%]">
        <SheetHeader className="flex items-center flex-row justify-between mb-4">
          <SheetTitle>{"Update Entity"}</SheetTitle>
          <SheetClose>
            <HiXMark size={22} className="mb-2 mr-1" />
          </SheetClose>
        </SheetHeader>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`OPTION_${props.entityCode}`}>
            {(provided, snapshot) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {columns.map((row, index) => {
                    return (
                      <Draggable
                        key={row.id}
                        index={index}
                        draggableId={row.id.toString()}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className="draggble"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <EntityItem
                                onVisible={(v) => {
                                  columns[index] = {
                                    ...row,
                                    visible: !row.visible,
                                  };
                                  setColumns([...columns]);
                                }}
                                item={row}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <SheetFooter className="mt-6">
          <SheetClose asChild>
            <div className="w-52">
              <MainButton variant={"outline"} type="button" label="Cancel" />
            </div>
          </SheetClose>
          <div className="w-52">
            <MainButton
              loading={props.auth?.changingColumn}
              type="button"
              label="Save"
              onClick={onSave}
            />
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

export default inject("auth")(observer(UpdateEntity));
