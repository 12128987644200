import { useEffect, useState } from "react";
import MainInput from "src/components/MainInput";
import MainButton from "src/components/MainButton";
import { UPDATE_USER_PAYLOAD, USER } from "src/interfaces/init.interface";
import { nullString } from "src/utilities/functions.utilities";
import { isValidEmail } from "src/utilities/validation.utilities";
import { AlertDestructive } from "src/components/AlertDestructive";
import { Switch } from "src/components/ui/switch";
import { updateUser } from "src/APIs/team.api";

interface Props {
  user: USER | null;
  updateSuccess: (doc: USER) => void;
}

export default function AccountForm(props: Props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [enable, setEnable] = useState(true);

  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const { firstName, lastName, username, email, enable } = props.user ?? {};
    setFirstName(firstName ?? "");
    setLastName(lastName ?? "");
    setUsername(username ?? "");
    setEmail(email ?? "");
    setEnable(enable ?? true);
  }, []);

  function isValid() {
    let msg: string[] = [];
    const isErrorLastName = nullString(lastName ?? "") === null;
    if (isErrorLastName) msg = msg.concat("Please enter Last Name \n");
    const isErrorFirstName = nullString(firstName ?? "") === null;
    if (isErrorFirstName) msg = msg.concat("Please enter First Name \n");

    const isErrorEmail =
      nullString(email ?? "") === null || isValidEmail(email ?? "") === false;
    if (isErrorEmail) msg = msg.concat("Email is invalid \n");

    if (!props.user?.external) {
      const isErrorUserName = nullString(username ?? "") === null;
      if (isErrorUserName) msg = msg.concat("Please enter Username \n");
    }

    return msg;
  }

  function onSave() {
    const invalidMsg = isValid();
    setErrorMsg(invalidMsg);
    if (invalidMsg.length === 0) {
      setProcessing(true);
      const data: UPDATE_USER_PAYLOAD = {
        id: props.user?.id,
        firstName: firstName,
        lastName,
        email,
        username,
        enable,
      };
      updateUser(data).then((doc) => {
        setProcessing(false);
        if(doc?.code !== "200") return
        props.updateSuccess(doc?.data);
      });
    }
  }

  return (
    <div className="isolate bg-white px-6  lg:px-8">
      {errorMsg.length > 0 ? (
        <AlertDestructive messages={errorMsg} title="Missing Informations" />
      ) : null}
      <div className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <MainInput
            value={firstName}
            placeholder="Enter first name"
            onChange={(text) => setFirstName(text)}
            label="First Name"
          />
          <MainInput
            value={lastName}
            placeholder="Enter last name"
            onChange={(text) => setLastName(text)}
            label="Last Name"
          />
         { props.user?.external ? null : <div className="sm:col-span-2">
            <MainInput
              value={username}
              type="text"
              placeholder="Enter username"
              onChange={(text) => setUsername(text)}
              label="Username"
            />
          </div>}
          <div className="sm:col-span-2">
            <MainInput
              value={email}
              type="email"
              placeholder="Enter email"
              onChange={(text) => setEmail(text)}
              label="Email"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <Switch onCheckedChange={() => setEnable(!enable)} checked={enable} />
          <span>Enable</span>
        </div>
        <div className="mt-10">
          <MainButton
            loading={processing}
            className="text-white"
            type="button"
            onClick={() => onSave()}
            label="Save Changes"
          />
        </div>
      </div>
    </div>
  );
}
