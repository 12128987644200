export const SECTIONS = {
  cover: {
    template: "cover",
  },
  financialTerm: {
    template: "financial-terms",
  },
  ourTeam: {
    template: "our-team",
  },
  referenceImages: {
    template: "reference-images",
  },
  projectProposal: {
    template: "project-proposal",
  },
  projectSchedule: {
    template: "project-schedule",
  },
  proposalSignOff: {
    template: "proposal-sign-off",
  },
  termsCondition: {
    template: "terms-conditions",
  },
  warranty: {
    template: "warranty-support",
  },
};
