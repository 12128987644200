import { META_DATA } from "src/interfaces/proposal.interface";
import { ContentTitle } from "../ContentTitle";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import { COMPONENT_TYPE } from "src/constants";
import { Label } from "src/components/ui/label";
import RichTextEditor from "src/components/richTextEditor";

interface Props {
  parent: META_DATA;
  onChange: (child: META_DATA) => void;
  readonly?: boolean;
}

export const TemporarySection = (props: Props) => {
  return (
    <div className="mb-8">
      <ContentTitle title={props.parent?.label} />
      {props.parent.children.map((child) => {
        if (child.type === COMPONENT_TYPE.rich_text.key) {
          return (
            <div key={child.id} className="mt-6">
              <div className="flex items-center justify-between mb-5">
                <Label className="font-semibold text-gray-500">
                  {child.label}
                </Label>
              </div>
              <div className=" border-[1px] rounded-sm">
                <RichTextEditor
                  readonly={props.readonly}
                  autoFocus
                  noBorder
                  value={child.value}
                  onChange={(v) => {
                    const data = {
                      ...child,
                      value: v,
                    };
                    props.onChange(data);
                  }}
                />
              </div>
            </div>
          );
        }
        return (
          <DynamicInput
            readonly={props.readonly}
            key={child.id}
            onChange={(text) => {
              const data = {
                ...child,
                value: text,
              };
              props.onChange(data);
            }}
            meta={child}
          />
        );
      })}
    </div>
  );
};
