import { useEffect, useState } from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../../components/ui/sheet";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import AddProductForm from "./AddProductForm";
import CatalogueList from "./CatalogueList";
import ProductionRateList from "./ProductionRateList";
import { PRODUCT } from "src/interfaces/product.interface";
import { CATALOGUE, PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { HiXMark } from "react-icons/hi2";
import { details } from "src/APIs/table.api";
interface Props {
  disabled?: boolean;
  extendClass?: string;
  auth?: AuthStore;
  product: PRODUCT | null;
  open: boolean;
  selectedData?: CATALOGUE | PRODUCTION_RATE | null;
  onOpen: (value: boolean) => void;
  isFromChild?: boolean;
  addedProduct?: (prd: PRODUCT) => void;
}

function AddProduct(props: Props) {
  const [product, setProduct] = useState<PRODUCT | null>(null);
  const [catalogs, setCatalogs] = useState<CATALOGUE[]>([]);
  const [productionRates, setProductionRates] = useState<PRODUCTION_RATE[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.open && (props.product || props.selectedData)) {
      setLoading(true);
      details("product", props.selectedData?.productId ?? props.product?.id ?? 0).then((data) => {
        if (data.code.toString() === "200") {
          const product: PRODUCT = data.data;
          setProduct(product);
          setCatalogs(product.catalogs);
          setProductionRates(product.productionRates);
          setLoading(false);
        }
      });
    }
    if (!props.open) {
      setProduct(null);
      setCatalogs([]);
      setProductionRates([]);
    }
  }, [props.open]);

  return (
    <Sheet onOpenChange={(open) => props.onOpen(open)} open={props.open}>
      <SheetContent className=" overflow-auto h-[99%] w-[50%]">
        <SheetHeader className="flex pt-0 mb-4">
          <div className="flex flex-1 justify-between">
            <SheetTitle className="flex">
              {props.product?.id ? "Update Product" : "Create New Product"}
            </SheetTitle>
            <SheetClose>
              <HiXMark size={22} />
            </SheetClose>
          </div>
        </SheetHeader>
        {!product &&
        !props.product &&
        props.isFromChild &&
        !props.selectedData ? (
          <div className="w-[50%]">
            <AutoCompleteWithLazyLoad
              label="Product"
              placeholder="Choose product"
              value={product}
              onSelectedValue={(value) => setProduct(value)}
              path="product"
            />
          </div>
        ) : (
          <div className="flex flex-1 flex-col gap-4">
            <AddProductForm
              addedProduct={props.addedProduct}
              updateProduct={(data) => setProduct(data)}
              open={props.open}
              product={product}
            />
            {
              <CatalogueList
                updateCatalogue={(catalogues) => {
                  setCatalogs(catalogues);
                  if (product) product.catalogs = catalogues;
                }}
                onDeleted={(cata) => {
                  setCatalogs(catalogs.filter((m) => m.id !== cata.id));
                }}
                product={product}
                loading={loading}
                data={catalogs}
              />
            }
            {
              <ProductionRateList
                onDeleted={(prdr) => {
                  setProductionRates(
                    productionRates.filter((m) => m.id !== prdr.id)
                  );
                }}
                updateProductionRate={(rates) => {
                  setProductionRates(rates);
                  if (product) product.productionRates = rates;
                }}
                product={product}
                loading={loading}
                data={productionRates}
              />
            }
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}

export default inject("auth")(observer(AddProduct));
