
interface Props {
  url: string;
}

export default function Image(props: Props) {
  return (
    <img
      src={props.url}
      loading="lazy"
      alt=""
      className="hover:scale-110 absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded"
    />
  );
}
