import { useEffect } from "react";
import RootRouter from "./routes/RootRouter";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "mobx-react";
import connectedStores from "./stores";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { COLORS } from "./assets/colors";
import Tracker from "@openreplay/tracker";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#0F2F5B",
    },
    secondary: {
      main: "#e09902",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "0.880em",
          "&::placeholder": {
            color: COLORS.placeholder,
            opacity: 1,
          },
        },
      },
    },
  },
});

const stores = connectedStores();

const tracker = new Tracker({
  projectKey: process.env.REACT_APP_PUBLIC_OP_KEY ?? "",
  __DISABLE_SECURE_MODE: true,
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      tracker.start();
    }
  }, []);

  return (
    <HashRouter>
      <Provider {...stores}>
        <ToastContainer position={"top-center"} />
        <ThemeProvider theme={theme}>
          <RootRouter />
        </ThemeProvider>
        <ToastContainer />
      </Provider>
    </HashRouter>
  );
}

export default App;
