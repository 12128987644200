import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import CRMStore from "src/stores/crm.store";
import { LargeListSkeleton } from "../../../../components/LargeListSkeleton";
import { TableBody } from "../../../../components/ui/table";
import CrmTableBodyRow from "./CrmTableBodyRow";
import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import { CRM_TYPE } from "src/interfaces/crm.interface";

interface Props {
  crmType: CRM_TYPE;
  columns: ENTITY_CONFIG[];
  crm?: CRMStore;
}

function CrmTableBody(props: Props) {
  useEffect(() => {
    if (props.crm) {
      props.crm.onGetList(props.crmType);
    }
  }, []);

  if (props.crm?.processing) {
    return <LargeListSkeleton />;
  }
  if (props.crm?.data.length === 0) {
    return (
      <div
        className={`overflow-auto mt-8 ml-28 min-w-60 flex h-[415px] italic`}
      >
        No data to display
      </div>
    );
  }

  return (
    <TableBody>
      {props.crm?.data.map((item) => {
        return (
          <CrmTableBodyRow
            crmType={props.crmType}
            columns={props.columns}
            item={item}
          />
        );
      })}
    </TableBody>
  );
}

export default inject("crm")(observer(CrmTableBody));
