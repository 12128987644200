import { useLayoutEffect, useRef, useState } from "react";
import MainTable from "src/components/tableComponents/MainTable";
import { PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import AddProduct from "../productList/AddProduct";
import { PRODUCT } from "src/interfaces/product.interface";
import { PRODUCTION_RATE_ENTITY } from "src/constants/static_entity.constants";

function ProductionRateListPage() {
  const [product, setProduct] = useState<PRODUCT | null>(null);
  const [openProduct, setOpenProduct] = useState(false);
  const [selected, setSelected] = useState<PRODUCTION_RATE | null>(null);
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        addLabel="Add"
        onAdd={() => setOpenProduct(true)}
        height={height}
        onClick={(row: PRODUCTION_RATE) => {
          setProduct({ id: row.productId } as PRODUCT);
          setOpenProduct(true);
          setSelected(row);
        }}
        entityCode={PRODUCTION_RATE_ENTITY.code}
        path="production-rate"
      />
      <AddProduct
        isFromChild
        selectedData={selected}
        product={product}
        onOpen={(value) => {
          setOpenProduct(value);
          if (!value) {
            setProduct(null);
            setSelected(null);
          }
        }}
        open={openProduct}
      />
    </div>
  );
}

export default ProductionRateListPage;
