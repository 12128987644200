import { useEffect, useState } from "react";
import React from "react";
import AutoCompleteView from "./AutoCompleteView";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { COUNTRY } from "src/interfaces/init.interface";
import { HiCheckCircle, HiChevronRight } from "react-icons/hi2";

interface Props {
  label: string;
  placeholder?: string;
  required?: boolean;
  onSelectedValue: (value: any) => void;
  value: any;
  disabled?: boolean;
  auth?: AuthStore;
}

function AutoCompleteCountry(props: Props) {
  const { countries } = props.auth ?? {};
  const [hasOpened, setOpen] = useState(false);
  const [data, setData] = useState<COUNTRY[]>([]);
  const [text, setText] = React.useState("");

  useEffect(() => {
    if (hasOpened && data.length === 0) {
      setData(countries ?? []);
    }
  }, [hasOpened]);

  useEffect(() => {
    const result = countries?.filter(
      (m) =>
        m.name.toUpperCase().includes(text.toUpperCase()) ||
        m.code.toUpperCase().includes(text.toUpperCase())
    );
    if (text) {
      setData(result ?? []);
    } else {
      setData(countries ?? []);
    }
  }, [text]);

  return (
    <AutoCompleteView
      onOpenChange={(v) => setOpen(v)}
      disabled={props.disabled}
      value={props.value}
      onSelectedValue={props.onSelectedValue}
      required={props.required}
      data={data}
      searching={false}
      setText={(t) => setText(t)}
      label={props.label}
      placeholder={props.placeholder}
      renderOption={(option, active, onSelectedValue) => {
        return (
          <div
            onClick={() => onSelectedValue(option)}
            className="p-2 hover:bg-primary/5 rounded cursor-pointer flex items-center"
            key={option.code}
          >
            <img
              className="mr-4"
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code}.png`}
              alt=""
            />
            <span className="font-medium flex flex-1">{option.name}</span>
            {active ? <HiCheckCircle size={20} /> : <HiChevronRight />}
          </div>
        );
      }}
    />
  );
}

export default inject("auth")(observer(AutoCompleteCountry));
