import TitleWithBox from "src/components/TitleWithBox";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { TEAM } from "src/interfaces/team.interface";
import MarkupTable from "./MarkupTable";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import { formatMoney, sum } from "src/utilities/formatter.utilities";

interface Props {
  items: GROUP_OR_ITEM[];
  onCopyToAll: (value: number) => void;
  isDisabled: boolean;
  teams: TEAM[];
  onMarkup: (
    value: string,
    type: PRODUCT_TYPE,
    isAll?: boolean,
    noHasModify?: boolean
  ) => void;
  markupTypes: PRODUCT_TYPE[];
}

export default function Markup(props: Props) {
  const total = sum(props.items, "markup");
  return (
    <div className="w-full mt-12">
      <TitleWithBox title={`Markup ${formatMoney(total)}`} />
      {props.teams.map((team, index) => {
        const itemInTeams =
          team.id === "all_team"
            ? props.items
            : props.items?.filter(
                (m) => m.team?.id === team?.id || (team.id === null && !m.team)
              );
        const productTypes = props.markupTypes.filter(
          (m) => m.teamId === team.id
        );
        if (itemInTeams.length === 0 && team.id !== null) return;
        return (
          <MarkupTable
            key = {team.id}
            onCopyToAll={props.onCopyToAll}
            index={index}
            productTypes={productTypes}
            isDisabled={props.isDisabled}
            itemInTeams={itemInTeams}
            onMarkup={props.onMarkup}
            team={team}
          />
        );
      })}
    </div>
  );
}
