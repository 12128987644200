import { META_DATA } from "src/interfaces/proposal.interface";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import { ContentTitle } from "../ContentTitle";
import { COMPONENT_TYPE } from "src/constants";
import { Label } from "src/components/ui/label";
import RichTextEditor from "src/components/richTextEditor";
import { Signature } from "./signature";

interface Props {
  parent: META_DATA;
  onChnage: (child: META_DATA) => void;
}

export const ProposalSignOffSection = (props: Props) => {
  return (
    <div className="mb-10">
      <ContentTitle title={`${props.parent.label}`} />
      {props.parent.children.map((child) => {
        if (
          child.type === COMPONENT_TYPE.text.key &&
          child.key === "description"
        ) {
          return (
            <div key={child.id} className="mt-6">
              <div className="flex items-center justify-between mb-5">
                <Label className="font-semibold text-gray-500">
                  Description
                </Label>
              </div>
              <div className=" border-[1px] rounded-sm">
                <RichTextEditor
                  autoFocus
                  value={child.value}
                  onChange={(v) => {
                    const data = {
                      ...child,
                      value: v,
                    };
                    props.onChnage(data);
                  }}
                />
              </div>
            </div>
          );
        }
        if (child.type === COMPONENT_TYPE.image.key) {
          return (
            <Signature
              parent={props.parent}
              key={child.id}
              title={child.label}
              child={child}
            />
          );
        }
        return (
          <DynamicInput
            key={child.id}
            meta={child}
            onChange={(v) => {
              const data = {
                ...child,
                value: v,
              };
              props.onChnage(data);
            }}
          />
        );
      })}
    </div>
  );
};
