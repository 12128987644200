import HTMLString from "react-html-string";
import { ProfileAvatar } from "src/components/ProfileAvatar";
import { COMMENT } from "src/interfaces/estimate.interface";
import { displayDateComment } from "src/utilities/formatter.utilities";
import CommentAction from "./CommentAction";
import { useEffect, useState } from "react";
import RichTextEditorWithMention from "src/components/richTextWithMention";
import MainButton from "src/components/MainButton";
import MuiOptionsDialog from "src/components/MuiOptionsDialog";
import ProfilePopUp from "src/components/ProfilePopUp";

interface Props {
  commment: COMMENT;
  onFinishEdit: (e: COMMENT) => void;
  onDelete: () => void;
}
export default function CommentItem(props: Props) {
  const [showEdit, setShowEdit] = useState(false);
  const [comment, setComment] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [profileId, setProfileId] = useState("");

  const components = {
    p: (props: any) => (
      <p
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    span: (props: any) => (
      <a
        {...props}
        onClick={async () => {
          setProfileId(props["data-id"]);
          setOpenProfile(true);
        }}
        style={{
          cursor: "pointer",
          userSelect: "text",
        }}
      />
    ),
    h1: (props: any) => (
      <h1
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    h2: (props: any) => (
      <h2
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    h3: (props: any) => (
      <h3
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    h4: (props: any) => (
      <h4
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    h5: (props: any) => (
      <h5
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
    b: (props: any) => (
      <b
        {...props}
        style={{
          userSelect: "text",
          cursor: "text",
        }}
      />
    ),
  };

  useEffect(() => {
    setComment(props.commment.body);
  }, [props.commment]);
  return (
    <div className="p-4 rounded-md border mb-4">
      <div className="flex mb-4 items-center justify-between">
        <div className="flex items-center">
          <ProfileAvatar
            name={props.commment.username ?? "Unknown User"}
            url={props.commment.profilePictureUrl}
          />
          <span className="font-semibold ml-2">
            {props.commment.username ?? "Unknown User"}
          </span>
        </div>
        <div className="flex items-center">
          <span>{displayDateComment(props.commment.createdAt)}</span>
          <CommentAction
            onEdit={() => setShowEdit(true)}
            onDelete={() => setShowDelete(true)}
          />
        </div>
      </div>
      {showEdit ? (
        <div className="border rounded-lg overflow-hidden">
          <RichTextEditorWithMention
            marginTop={0}
            isFocus={true}
            onSend={() => {}}
            isChat
            height={170}
            fontSize={12}
            isError={false}
            description={comment}
            onChange={(text) => {
              setComment(text);
            }}
          />
        </div>
      ) : (
        <HTMLString html={comment} components={components} />
      )}

      {!showEdit ? null : (
        <div className="flex justify-end">
          <div className="w-32 mt-6 mr-2">
            <MainButton
              variant={"outline"}
              type="button"
              onClick={() => {
                setShowEdit(false);
                setComment(props.commment.body);
              }}
              label="Cancel"
            />
          </div>
          <div className="w-32 mt-6">
            <MainButton
              type="button"
              onClick={() => {
                if (
                  comment.replace(/\s/g, "") === "<p></p>" ||
                  comment.replace(/\s/g, "") === ""
                )
                  return;
                const doc: COMMENT = {
                  ...props.commment,
                  body: comment,
                };
                setComment(doc.body);
                props.onFinishEdit(doc);
                setShowEdit(false);
              }}
              label="Submit"
            />
          </div>
        </div>
      )}
      <MuiOptionsDialog
        cancelLabel={"Cancel"}
        continueLabel={"Delete"}
        isOpen={showDelete}
        title={"Delete Comment"}
        description="Are you sure to delete this comment?"
        onClose={() => setShowDelete(false)}
        onContinue={() => {
          props.onDelete();
          setShowDelete(false);
        }}
      />
      <ProfilePopUp
        data={profileId}
        open={openProfile}
        onOpen={(v) => setOpenProfile(v)}
      />
    </div>
  );
}
