import { HTMLInputTypeAttribute, useEffect, useState } from "react";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";

interface Props {
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  onChange?: (t: string) => void;
  value: string | null;
  disabled?: boolean;
  hideBorder?: boolean;
  extendClass?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  focus?: boolean;
  noMarginTop?: boolean;
  active?: boolean;
  onClick?: any;
  error?: boolean;
  disableEdit?: boolean;
  onEnter?: (text: string) => void;
}

export default function ProposalMainInput(props: Props) {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value]);

  return (
    <div className={props.label ? "mt-4 w-full mb-1" : ""}>
      {props.label ? (
        <Label>
          {props.label}
          {props.required ? <span className="text-destructive">*</span> : null}
        </Label>
      ) : null}
      <Input
        onClick={props.onClick}
        autoFocus={props.focus}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
          props.onChange && props.onChange(text);
        }}
        disabled={!props.onChange || props.disabled}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          props.onChange && props.onChange(text);
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            props.onChange && props.onChange(text);
            props.onEnter && props.onEnter(text);
          }
        }}
        type={props.type}
        className={`w-full ${props.noMarginTop ? "" : "mt-2"} ${
          props.active
            ? "border border-secondary"
            : props.hideBorder
            ? "border-transparent shadow-transparent"
            : ""
        } ring-0 outline-none bg-white `.concat(props.extendClass ?? "")}
        placeholder={props.placeholder ?? `Enter`}
      />
    </div>
  );
}
