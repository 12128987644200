import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import { COLORS } from "../../../assets/colors";
import { LAYOUTS } from "../../../assets/layouts";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import React from "react";
// @ts-ignore
import { MEMBER_ENTITY } from ".";
import DepartmentItem from "./DepartmentItem";
import { DEPARTMENT } from "src/interfaces/team.interface";
import { LargeListSkeleton } from "src/components/LargeListSkeleton";
import { IoChevronDown } from "react-icons/io5";
import TableHeader from "../member/tables/TableHeader";
import { STEP } from "src/components/Stepper";
import AddDepartment from "./AddDepartment";
import ProfilePopUp from "../../../components/ProfilePopUp";
import EmptyData from "src/components/emptyData";

interface Props {
  processing: boolean;
  departments: DEPARTMENT[];
  onSearch: (t: string) => void;
  search?: string;
  onClearSearch?: () => void;
  headerButtons: STEP[];
  active: STEP;
  onHeaderButton: (step: STEP) => void;
  totalInactive: number;
  totalActive: number;
  deletedItem: (item: DEPARTMENT) => void;
  afterAddedMembers: (departments: DEPARTMENT[]) => void;
  createdDepartment: (department: DEPARTMENT) => void;
}

export default function TeamListScreen(props: Props) {
  const [height, setHeight] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null) as any;
  const [isOpenModal, setOpenModal] = useState(null) as any;
  const [isShowProfile, setShowProfile] = useState(false);
  const [isManaging, setManaging] = useState(false);
  const tableRef = useRef(null);
  const [collapsed, setCollapsed] = useState<String[]>([]);
  const [collapsedAll, setCollapsedAll] = useState<boolean>(false);
  const [isOpenAddTeam, setOpenAddTeam] = useState(false);

  useEffect(() => {
    if (!collapsedAll) {
      setCollapsed([]);
    } else {
      setCollapsed(props.departments.map((m) => m.id));
    }
  }, [collapsedAll]);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const onCollapsed = (id: string) => {
    if (collapsed.includes(id)) {
      setCollapsed(collapsed.filter((m) => m != id));
    } else {
      setCollapsed(collapsed.concat(id));
    }
  };

  return (
    <React.Fragment>
      <AddDepartment
        createdDepartment={props.createdDepartment}
        isOpen={isOpenAddTeam}
        onClose={() => setOpenAddTeam(false)}
      />
      <div className="p-2 bg-white pb-0 pt-6">
        <TableHeader
          addLabel="Add Department"
          onAdd={() => setOpenAddTeam(true)}
          active={props.active}
          buttons={props.headerButtons}
          onButton={props.onHeaderButton}
          onChange={(text) => props.onSearch(text)}
          totalActive={props.totalActive}
          totalInactive={props.totalInactive}
        />
      </div>
      {props.processing ? (
        <LargeListSkeleton />
      ) : props.departments.length > 0 ? (
        <div
          style={{
            height: height - 110,
            overflow: "auto",
            position: "relative",
          }}
        >
          <Table
            stickyHeader
            ref={tableRef}
            style={{
              tableLayout: "fixed",
              width: "100%",
              backgroundColor: COLORS.white,
            }}
            size="small"
          >
            <TableHead style={{ backgroundColor: COLORS.input_background }}>
              {MEMBER_ENTITY.entityConfigurations.map((column, index) => {
                if (column.columnName === "profilePictureUrl") {
                  return (
                    <TableCell
                      style={{
                        width: 55,
                        fontWeight: "bold",
                        backgroundColor: COLORS.background_primary,
                      }}
                      key={column.id}
                    >
                      {column.displayName}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: COLORS.background_primary,
                    }}
                    key={column.id}
                  >
                    {index === 0 ? (
                      <IconButton
                        onClick={() => setCollapsedAll(!collapsedAll)}
                        style={{
                          marginRight: LAYOUTS.margin,
                        }}
                        size="small"
                      >
                        <IoChevronDown
                          style={{
                            transform: collapsedAll
                              ? "rotate(-90deg)"
                              : "rotate(0)",
                            transition: "all 200ms",
                          }}
                        />
                      </IconButton>
                    ) : null}
                    {column.displayName}
                  </TableCell>
                );
              })}
            </TableHead>
            <TableBody>
              {props.departments.map((item) => {
                return (
                  <DepartmentItem
                    afterAddedMembers={props.afterAddedMembers}
                    deleted={() => props.deletedItem(item)}
                    onCollapsed={() => onCollapsed(item?.id)}
                    collapsed={collapsed.includes(item.id)}
                    key={item.id}
                    deprtment={item}
                    onSelectUser={(user) => {
                      if (isManaging) {
                        setSelectedUser(user);
                        setOpenModal(true);
                      } else {
                        setShowProfile(true);
                        setSelectedUser(user);
                      }
                    }}
                  />
                );
              })}
            </TableBody>
          </Table>
          <div className="h-32" />
        </div>
      ) : (
        <EmptyData />
      )}
      <ProfilePopUp
        data={selectedUser}
        open={isShowProfile}
        onOpen={(v) => setShowProfile(v)}
      />
    </React.Fragment>
  );
}
