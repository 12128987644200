import { arrayMoveImmutable } from "array-move";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CRMStore from "src/stores/crm.store";
import { inject, observer } from "mobx-react";
import { CrmTabarSkeleton } from "../CrmTabarSkeleton";
import CrmAddTabarButton from "../CrmAddTabarButton";
import CrmStageTabItem from "./CrmStageTabItem";
import { Label } from "src/components/ui/label";

interface Props {
  crm?: CRMStore;
}

function CrmStageTab(props: Props) {
  const { selectedStatus, selectedStage, processingStatusList } =
    props.crm ?? {};

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = arrayMoveImmutable(
      selectedStatus?.stages ?? [],
      result.source.index,
      result.destination.index
    );
    props.crm?.onCrmStageReorder(items);
  };

  if (processingStatusList) {
    return <CrmTabarSkeleton isStage />;
  }

  return (
    <div className={`flex mx-2 items-center overflow-auto`}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction="horizontal"
          droppableId={`OPTION_${selectedStage?.id}`}
        >
          {(provided, snapshot) => {
            return (
              <div
                className="flex"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedStatus?.stages?.map((row, index) => {
                  const active = row.id === selectedStage?.id;
                  const lastIndex = selectedStatus?.stages
                    ? index === selectedStatus?.stages.length - 1
                    : false;
                  return (
                    <Draggable
                      key={row.id}
                      index={index}
                      draggableId={row.id ? row.id.toString() : ""}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={`draggble min-w-36 ${
                              lastIndex ? "" : "mr-2"
                            }`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <CrmStageTabItem
                              active={active}
                              isLastIndex={lastIndex}
                              item={row}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <div
        className={`flex items-center ${
          selectedStatus?.stages?.length === 0 ? "my-3" : ""
        }`}
      >
        {selectedStatus?.stages?.length === 0 ? (
          <Label className="text-sm min-w-44">(No stage on this status)</Label>
        ) : null}
        <CrmAddTabarButton isStage />
      </div>
    </div>
  );
}

export default inject("crm")(observer(CrmStageTab));
