export const lotties = {
    LOADING: require("./loading.json"),
    NOT_FOUND: require("./404.json"),
    EMPTY: require("./empty.json"),
    COMMING_SOON: require("./comming_soon.json"),
    WAVE: require("./wave.json"),
    HEADER_WAVE: require("./header_wave.json"),
    FOOTER_WAVE: require("./footer_wave.json"),
    BACKGROUND: require("./background.json"),
    QUESTION: require("./question.json"),
}