import { SECTION } from "src/interfaces/proposal.interface";
import { deleteAPI, getAPI, postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";

export const getSection = async (
  corporateId: number,
  sectionId: number,
  isTemplate?: boolean
) => {
  var url =
    isTemplate === true
      ? `${process.env.REACT_APP_MAIN_API}/proposal-template/template/${corporateId}/section/${sectionId}`
      : `${process.env.REACT_APP_MAIN_API}/proposal/${corporateId}/section/${sectionId}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const getPdfBySection = async (sectionId: number) => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal/pdf/${sectionId}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export async function updateSection(
  corporateId: number,
  section: SECTION,
  isTemplate?: boolean
) {
  let url =
    isTemplate === true
      ? `${process.env.REACT_APP_MAIN_API}/proposal-template/template/${corporateId}/section/${section.id}/html`
      : `${process.env.REACT_APP_MAIN_API}/proposal/${corporateId}/section/${section.id}/html`;
  const data = await putAPI(url, section);
  return data as RESPONSE;
}

export async function deleteSectionMetaData(id: any) {
  let url = `${process.env.REACT_APP_MAIN_API}/proposal-template/metadata/${id}`;
  await deleteAPI(url);
  return true;
}

export const getSectionList = async (
  corporateId: number,
  isTemplate?: boolean
) => {
  var url =
    isTemplate === true
      ? `${process.env.REACT_APP_MAIN_API}/proposal-template/corporate/template/${corporateId}`
      : `${process.env.REACT_APP_MAIN_API}/proposal/corporate/proposal/${corporateId}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const getHtml = async (
  corporateId: number,
  sectionId: number,
  isTemplate?: boolean
) => {
  var url =
    isTemplate === true
      ? `${process.env.REACT_APP_MAIN_API}/proposal-template/template/${corporateId}/section/${sectionId}/html`
      : `${process.env.REACT_APP_MAIN_API}/proposal/${corporateId}/section/${sectionId}/html`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const createSection = async (
  corporateId: number,
  isTemplate: boolean,
  sectionName: string
) => {
  var url =
    isTemplate === true
      ? `${process.env.REACT_APP_MAIN_API}/proposal-template/template/${corporateId}/section`
      : `${process.env.REACT_APP_MAIN_API}/proposal/${corporateId}/section`;
  const data = await postAPI(url, { name: sectionName });
  return data as RESPONSE;
};

export const createTemplate = async (
  existingTemplateId: number,
  templateName: string
) => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal-template/template/${existingTemplateId}`;
  const data = await postAPI(url, { name: templateName });
  return data as RESPONSE;
};

export const createProposal = async (
  templateId: number,
  proposalName: string,
  estimateId: number
) => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal/template/${templateId}/proposal`;
  const payload = {
    name: proposalName,
    estimateId: estimateId,
  };
  const data = await postAPI(url, payload);
  return data as RESPONSE;
};

export const getDefaultTemplate = async () => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal-template/template`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const getTemplateList = async () => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal-template/corporate/template`;
  const data = await getAPI(url);
  return data as RESPONSE;
};

export const getDownloadPdfLink = (
  corporateId: number,
  isTemplate?: boolean
) => {
  return isTemplate === true
    ? `${process.env.REACT_APP_MAIN_API}/proposal-template/corporate/template/${corporateId}/pdf`
    : `${process.env.REACT_APP_MAIN_API}/proposal/corporate/proposal/${corporateId}/pdf`;
};

export const checkB4CreateProposal = async (estimateId: number) => {
  var url = `${process.env.REACT_APP_MAIN_API}/proposal/corporate/estimate/${estimateId}/validate`;
  const data = await getAPI(url);
  return data as RESPONSE;
};
