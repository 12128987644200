import MainTextArea from "src/components/MainTextArea";

import TitleWithBox from "src/components/TitleWithBox";
import { ESTIMATE_DETAILS } from "src/interfaces";
import RichTextEditor from "../../../../components/richTextEditor";
interface Props {
  data: ESTIMATE_DETAILS | null;
  exclusion: string;
  setExclusion: (t: string) => void;
}

export default function ExclusionDetails(props: Props) {
  return (
    <div className="mt-10 flex flex-1 flex-col pt-2">
      <TitleWithBox title="Exlusion Details" />
      <RichTextEditor
        disableHeaderTools
        value={props.exclusion}
        onChange={(value) => props.setExclusion(value)}
        withShadow
        noBorder
      />
    </div>
  );
}
