import { MRT_ColumnDef } from "material-react-table";
import { formatMoney, orderByAsc, sum } from "./formatter.utilities";
import { ENTITY_CONFIG } from "../interfaces/init.interface";
import { ADDRESS } from "src/interfaces/customer.interface";
import { COLORS } from "src/assets/colors";
import { getSubGroups } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { sortByMultiFields } from "./functions.utilities";
import { Buffer } from "buffer";
export function mappingEntity(entities: ENTITY_CONFIG[]) {
  return orderByAsc(
    entities.filter((m) => m.visible),
    "sequence"
  ).map((d: { columnName: string; displayName: string }) => {
    const entity: MRT_ColumnDef<any> = {
      accessorKey: d.columnName,
      header: d.displayName,
    };
    return entity;
  });
}

export function staticSearch(data: any[], text: string) {
  if (!text) return data;
  const keys = data ? Object.keys(data[0]) : [""];
  if (keys.length === 0) return [];
  return data.filter((m) => {
    const data = keys.filter((k) => {
      return m[`${k}`] == null
        ? false
        : String(m[`${k}`])
            .replace(" ", "")
            .toLowerCase()
            .includes(text.toLowerCase().replaceAll(" ", ""));
    });
    return data.length > 0;
  });
}

export function deepStaticSearch(data: any[], text: string, key: string) {
  const filterData = data
    ?.filter((m) => {
      const isMatchName = m.name
        .toLocaleLowerCase()
        .replace(" ", "")
        .includes(text.toLocaleLowerCase().replace(" ", ""));
      const children =
        m[key].length > 0 ? staticSearch(m[key] ?? [], text) : [];
      return children.length > 0 || isMatchName;
    })
    .map((m) => {
      if (!m[key] || m[key].length === 0) return m;
      const children =
        m[key].length > 0 ? staticSearch(m[key] ?? [], text) : [];
      return { ...m, [key]: children };
    });
  return filterData ?? [];
}

export function calculate(text: string) {
  const result = eval(text.replaceAll(",", ""));
  return formatMoney(result) ?? "0";
}

export function getAddressLineDisplay(address: ADDRESS | null) {
  const { line1, city, country, state, postalCode } = address || {};
  let _address: string = "";
  if (line1) {
    _address = _address.concat(line1);
  }

  if (city) {
    _address = _address.concat(`, ${city}`);
  }
  if (state) {
    _address = _address.concat(`, ${state}`);
  }
  if (postalCode) {
    _address = _address.concat(`, ${postalCode}`);
  }
  if (country) {
    _address = _address.concat(`, ${country}`);
  }
  return _address;
}

export function getStatus(status?: number) {
  if (status === 1) {
    return "Draft";
  } else if (status === 2) {
    return "Sent";
  } else if (status === 4) {
    return "Viewed";
  } else if (status === 8) {
    return "Accepted";
  } else if (status === 32) {
    return "Rejected";
  }
  return status ? status.toString() : "N/A";
}

export function getStatusColor(status?: number) {
  if (status === 1) {
    return COLORS.primary;
  } else if (status === 2) {
    return "#F78662";
  } else if (status === 4) {
    return "#2A58B4";
  } else if (status === 8) {
    return COLORS.secondary;
  } else if (status === 32) {
    return "#F45250";
  }
  return status ? status.toString() : "N/A";
}

export function getDayLabel(day: number) {
  if (day === 1 || day === 21 || day === 31) {
    return `${day}ST`;
  }
  if (day === 2 || day === 22 || day === 32) {
    return `${day}ND`;
  }
  if (day === 3 || day === 23 || day === 33) {
    return `${day}RD`;
  }
  return `${day}TH`;
}

export function convertStringToBoolean(value?: string) {
  if (value === "true") return true;
  return false;
}



export function toTreeDataFlatten(items: GROUP_OR_ITEM[]) {
  const groups = sortByMultiFields(
    items.filter((m) => m.level === 1),
    ["level"],
    ["asc"]
  );
  const roots = groups.map((p,i) => mappingTree(p, items, (i + 1).toString(),i));
  return roots;
}

export function mappingTree(parent: GROUP_OR_ITEM, items: GROUP_OR_ITEM[], code:string, index:number) {
  const children = items.filter(
    (m) =>
      m.parentGroupCode === parent.code &&
      m.id !== parent.id &&
      m.isGroup &&
      m.level !== 1
  );
  const groupCode = parent.level === 1 ? code : `${code}.${index + 1}`
  const mapChldren: any = children.map((p,i) => mappingTree(p, items, groupCode,i));
  const siblings = getSubGroups(parent, items)
    .map((m) => m.code)
    .concat(parent?.code);
  const allItems = parent.isGroup ? items.filter(m => siblings.includes(m.parentGroupCode)) : [];
  const totalItems = sum(allItems,"total");
  const totalMarkup = sum(allItems,"markup");
  const total = parent.isGroup ? totalItems + totalMarkup : parent.total + parent.markup
  return { ...parent, code: groupCode, children: mapChldren, total, collapsed:  true };
}


export const reorderItemsWithSubItems = (items: any[]): any[] => {
  return items
    .sort((a, b) => a.sequence - b.sequence)
    .map((item,index:number) => ({
      ...item,
      sequence: index + 1,
      items: reorderItemsWithSubItems(item.items),
    }));
};

export function jsonToBase64(object:any) {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString("base64");
}