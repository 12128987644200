import { useEffect, useLayoutEffect, useRef, useState } from "react";
import MainTable from "src/components/tableComponents/MainTable";
import { PRODUCT } from "src/interfaces/product.interface";
import AddProduct from "./AddProduct";
import { PRODUCT_ENTITY } from "src/constants/static_entity.constants";

export default function ProductListPage() {
  const ref = useRef<any>(null);
  const [height, setHeight] = useState(0);

  const [product, setProduct] = useState<PRODUCT | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (!openModal) {
      setProduct(null);
    }
  }, [openModal]);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        useShowAll
        addLabel="Add"
        onAdd={() => setOpenModal(true)}
        height={height}
        onClick={(row: any) => {
          setOpenModal(true);
          setProduct(row);
        }}
        entityCode={PRODUCT_ENTITY.code}
        path="product"
      />
      <AddProduct
        product={product}
        onOpen={(value) => setOpenModal(value)}
        open={openModal}
      />
    </div>
  );
}
