import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EstimateInformation from "./EstimateInformation";
import { useEffect, useRef, useState } from "react";

import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import NotFound from "src/app/notFound";
import ProductDetails from "./ProductDetails";
import {
  CATALOGUE,
  ESTIMATE_PAYLOAD,
  ESTIMATE_TEMPLATE,
  ESTIMATE_DETAILS,
  GROUP_OR_ITEM,
} from "src/interfaces/estimate.interface";
import ExclusionDetails from "./ExclusionDetails";
import TotalSummary from "./TotalSummary";
import PageFooter from "./PageFooter";
import React from "react";
import { TEAM } from "src/interfaces/team.interface";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import {
  dateToString4API,
  orderByAsc,
  sum,
} from "src/utilities/formatter.utilities";
import { useReactToPrint } from "react-to-print";
import {
  addNewRow,
  createItem,
  getSubGroups,
  mappingEstimateTemplateGroupOrItemToArray,
  mappingToGroupPayload,
  mappingToItemPayload,
  normalizeSequences,
  onDeleteItem,
  productTypeToMarkUps,
  remapGroupCode,
  updateIndividualItemMarkup,
} from "./estimate.utility";
import { v4 as uuidv4 } from "uuid";
import CloneBanner from "./CloneBanner";
import { ADDRESS } from "src/interfaces/customer.interface";
import { removeDuplicates } from "@tiptap/react";
import { toast } from "react-toastify";
import PageHeader from "./PageHeader";

import EstimateSummaryContent from "./estimateSummaryDialogue/EstimateSummaryContent";
import {
  createEstimate,
  estimateDetails,
  estimateTemplateDetails,
  updateEstimate,
} from "src/APIs/estimate_details.api";
import { catalogueByProduct } from "src/APIs/product.api";
import { staticList } from "src/APIs/table.api";
import Markup from "./markupSection";

interface Props {
  auth?: AuthStore;
}

function EstimateDetails(props: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onAfterPrint: () => setPrinting(false),
    removeAfterPrint: true,
  });

  const [bigProcessing, setBigProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModify, setModify] = useState(false);
  const [ready, setReady] = useState(false);
  const [teams, setTeams] = useState<TEAM[]>([]);
  const [markupTypes, setMarkupTypes] = useState<PRODUCT_TYPE[]>([]);
  const [estimateData, setEstimateData] = useState<ESTIMATE_DETAILS | null>(
    null
  );
  const [items, setItems] = useState<GROUP_OR_ITEM[]>([]);
  const [taxRate, setTaxRate] = useState(0);
  const [exclusion, setExclusion] = useState("");
  const [estimateNo, setEstimateNo] = useState<string | null>(null);
  const [isClone, setClone] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [team, setTeam] = useState<TEAM | null>(null);
  const [selectedTemplateItem, setTemplateItem] =
    useState<GROUP_OR_ITEM | null>(null);
  const [isPrinting, setPrinting] = useState(false);
  const [isSave, setSave] = useState(true);

  useEffect(() => {
    let timeOut: any = null;
    if (props.auth?.isAutoSave === false) return;
    if (!isSave) {
      setSave(true);
      timeOut = setTimeout(
        () => {
          onSave();
        },
        (props.auth?.durationAutoSave || 1) * 1000 * 60
      );
    }
    return () => {
      if (timeOut !== null) {
        clearTimeout(timeOut);
      }
    };
  }, [items]);

  useEffect(() => {
    if (!ready) return;
    setModify(true);
  }, [items, estimateData, taxRate, exclusion]);

  useEffect(() => {
    if (!selectedTemplateItem) return;
    onAddRow(selectedTemplateItem);
    setTemplateItem(null);
  }, [items]);

  useEffect(() => {
    if (!id) {
      const { exclusionNote } = props.auth?.estimateSetting || {};
      setLoading(false);
      const data: ESTIMATE_DETAILS = {
        id: undefined,
        number: null,
        description: null,
        status: 0,
        note: null,
        telephone: null,
        email: null,
        billingAddress: null,
        sameBillingShippingAddress: false,
        taxRate: null,
        shippingAddress: null,
        exclusion: null,
        subContractorRate: null,
        validTill: null,
        cost: null,
        changeOrder: false,
        customer: null,
        groups: [],
        markups: [],
        items: [],
      };
      setExclusion(exclusionNote ?? "");
      setEstimateData(data);
      return;
    }
    estimateDetails(Number(id)).then((result) => {
      if (result?.data && result.code === "200") {
        setEstimateData(result?.data);
        setEstimateNo(result?.data?.number);
        setExclusion(result?.data.exclusion);
      } else {
        setErrorMessage(result?.message ?? "");
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!estimateData) return;
    const productTypes =
      props.auth?.productTypes
        .filter((m) => m.enable)
        .filter((m) => m.code !== "bnd") ?? [];
    if (productTypes.length === 0) return;
    staticList("team").then((result) => {
      if (result.code !== "200") return;
      const defaultTeam: any = {
        id: null,
        name: "Unassigned Team",
        enable: true,
      };
      const teamResult = result?.data;
      const _teams = [defaultTeam, ...teamResult];
      const markups = _teams.map((m) => {
        const types = productTypes.map((t) => {
          const previousMarkup = estimateData?.markups.find(
            (ma) => ma.teamId === m.id && t.id === ma.productTypeId
          );
          return { ...t, teamId: m.id, markupPercentage: previousMarkup?.rate };
        });
        return types;
      });
      setMarkupTypes(markups.flatMap((m) => m));
      setTeams(_teams);
      setTimeout(() => setReady(true), 1500);
    });
  }, [props.auth?.productTypes, estimateData]);

  useEffect(() => {
    if (items.length === 0) {
      let item = createItem();
      item.sequence = 1;
      item.level = 1;
      setItems([item]);
    }
  }, [items]);

  const onMarkup = (
    markUp: string,
    t: PRODUCT_TYPE,
    items: GROUP_OR_ITEM[],
    resetDisperse?: boolean,
    allTeam?: boolean
  ) => {
    if (Number(markUp) === t.markupPercentage && resetDisperse) return;
    const index = markupTypes.findIndex(
      (m) => m.id === t.id && t.teamId === m.teamId
    );
    const data: PRODUCT_TYPE = {
      ...t,
      markupPercentage: Number(markUp),
    };
    markupTypes[index] = data;
    calMarkUp(data, items, resetDisperse ?? false);
  };

  const resetMarkUp = (items: GROUP_OR_ITEM[]) => {
    markupTypes.forEach((type) => {
      onMarkup(type.markupPercentage?.toString() ?? "0", type, items);
    });
  };

  const calMarkUp = (
    productType: PRODUCT_TYPE,
    items: GROUP_OR_ITEM[],
    resetDisperse: boolean
  ) => {
    items.forEach((m, index) => {
      if (
        m.product?.productType?.id === productType?.id &&
        m.team?.id === productType?.teamId
      ) {
        const percentage = productType.markupPercentage ?? 0;
        const markupAmount = ((m?.total ?? 0) * percentage) / 100;
        const data = { ...m, markup: markupAmount };
        items[index] = data;
      } else if (
        m.product?.productType?.id === productType?.id &&
        !m.team &&
        !productType?.teamId
      ) {
        const percentage = productType.markupPercentage ?? 0;
        const markupAmount = ((m?.total ?? 0) * percentage) / 100;
        const data = { ...m, markup: markupAmount };
        items[index] = data;
      }
    });
    setItems([...items]);
    // const mainGroups = items.filter((m) => m.level === 1);
    // mainGroups.forEach((m) => {
    //   const subGroups = items.filter(
    //     (gr) => m.code && gr.subOf?.includes(m.code)
    //   );
    //   const groupKeys = [m.code, ...subGroups.map((m) => m.code)];
    //   const allItems = items.filter(
    //     (i) => i.parentGroupCode && groupKeys.includes(i.parentGroupCode)
    //   );
    //   const totalPrice = sum(allItems, "total");
    //   const totalMarkup = sum(allItems, "markup");
    //   const dispersePrice =
    //     (resetDisperse ? 0 : sum(allItems, "disperse")) +
    //     totalPrice +
    //     totalMarkup;
    //   calculateDisperse(m, dispersePrice, items);
    // });
  };

  const calculateDisperse = (
    group: GROUP_OR_ITEM,
    dispersePrice: number,
    items: GROUP_OR_ITEM[]
  ) => {
    const calculatedItems = items;
    const subGroups = getSubGroups(group, items);
    const allGroups = [group, ...subGroups];
    const groupKeys = allGroups.map((m) => m.code).concat(group.code);
    const allSubItems = items
      .filter((m) =>
        !m.isGroup && m.parentGroupCode
          ? groupKeys.includes(m.parentGroupCode)
          : false
      )
      .filter((m) => m?.price && m?.price > 0);
    const totalPrice = sum(allSubItems, "total");
    const totalMarkup = sum(allSubItems, "markup");
    const originalPrice = totalPrice + totalMarkup;
    const disperse = dispersePrice - originalPrice;
    allSubItems.forEach((m, i) => {
      const index = calculatedItems.findIndex((cm) => cm.id === m.id);
      if (i === allSubItems.length - 1) {
        const totalDisperse = sum(
          allSubItems.filter((c) => m.id !== c.id),
          "disperse"
        );
        calculatedItems[index].disperse = Number(
          (disperse - totalDisperse).toFixed(2)
        );
      } else {
        const totalPrice = m.total ?? m.quantity * m.price;
        const totalMarkup = m.markup ?? 0;
        const total = totalPrice + totalMarkup;
        const percentage = (total / originalPrice) * 100;
        const disperseResult = Number(
          ((disperse * percentage) / 100).toFixed(2)
        );
        calculatedItems[index].disperse = disperseResult;
      }
    });
    setItems([...calculatedItems]);
  };

  const onCopyToAll = (value: number) => {
    const teamsId = items.map((m) => m.team?.id);
    const markups = markupTypes.filter(
      (m) => teamsId.includes(m.teamId) || m.teamId === null
    );
    markups.forEach((m) => {
      onMarkup(value.toString(), m, items);
    });
  };

  const onUpdateItem = (item: GROUP_OR_ITEM, isUpdateMarkup?: boolean) => {
    const index = items.findIndex((m) => m.id === item.id);
    items[index] = item;
    const siblings = orderByAsc(
      items.filter((m) => m.parentGroupCode === item.parentGroupCode),
      "sequence"
    );
    const siblingIndex = siblings.findIndex((m) => m.id === item.id);
    const nextSibling = siblings[siblingIndex + 1];
    if (!nextSibling) {
      onAddRow(item, siblings);
    } else {
      if (isUpdateMarkup) {
        setItems([...items]);
      } else {
        const markupItems = updateIndividualItemMarkup(
          item,
          items,
          markupTypes
        );
        setItems([...markupItems]);
      }
    }
  };

  function onAddRow(row: GROUP_OR_ITEM, _siblings?: GROUP_OR_ITEM[]) {
    row = items.find((m) => m.id === row.id) || row;
    const siblings =
      _siblings ||
      items.filter((i) => i.parentGroupCode === row.parentGroupCode);

    const verifiedMarkupRows = updateIndividualItemMarkup(
      row,
      items,
      markupTypes
    );

    const markupRow = verifiedMarkupRows.find((m) => m.id === row.id);
    if (markupRow) {
      let _items = addNewRow(markupRow, siblings, items);
      const index = _items.findIndex((m) => m.id === markupRow.id);
      _items[index] = markupRow;
      setItems([..._items]);
    } else {
      setItems(addNewRow(row, siblings, items));
    }
  }

  function onDelete(item: GROUP_OR_ITEM) {
    const remainingItems = onDeleteItem(item, items);
    if (remainingItems.length === 0) {
      setItems([]);
    } else {
      setItems(remainingItems);
    }
  }

  function onAddNewGroup(name: string, item: GROUP_OR_ITEM) {
    const index = items.findIndex((m) => m.id === item.id);
    const parent = items.find((m) => m.code === item.parentGroupCode);
    let _item = createItem();
    _item.name = name || "Untitle";
    _item.isGroup = true;
    _item.id = item.id;
    _item.sequence = item.sequence;
    _item.parentGroupCode = item.parentGroupCode;
    _item.level = item.level;
    _item.code = uuidv4();
    _item.subOf =
      parent?.subOf && parent.subOf.length > 0
        ? parent.subOf.concat(parent.code ?? "")
        : [parent?.code ?? ""];
    _item.subOf = _item.subOf.filter((m) => m);
    items[index] = _item;
    setItems([...items]);
  }

  function onSelectGroupTeam(group: GROUP_OR_ITEM, team: TEAM | null) {
    const index = items.findIndex((m) => m.id === group.id);
    const subGroupCodes = getSubGroups(group, items)
      .map((m) => m.code)
      .concat(group.code);
    const childItems = items.filter((m) =>
      subGroupCodes.includes(m.parentGroupCode)
    );
    childItems.forEach((m) => {
      const index = items.findIndex((i) => m.id === i.id);
      if (m.product?.productType?.code === "svc" && !m.isGroup) {
        m.price = team?.price ?? 0;
        m.total = m.quantity * (team?.price ?? 0);
      }
      m.team = team;
      m.teamId = team?.id || null;
      items[index] = m;
    });
    items[index].team = team;
    items[index].teamId = team?.id;
    resetMarkUp([...items]);
  }

  function onChangeGroupName(t: string, group: GROUP_OR_ITEM) {
    const index = items.findIndex((m) => m.id === group.id);
    items[index].name = t;
  }

  function onChangeGroupDescription(t: string, group: GROUP_OR_ITEM) {
    const index = items.findIndex((m) => m.id === group.id);
    items[index].description = t;
  }

  function onClone() {
    setClone(true);
    setEstimateNo(null);
    setItems(
      items.map((m) => {
        return {
          ...m,
          id: uuidv4(),
          hasComment: false,
          newComments: [],
          editedComments: [],
          deletedComments: [],
        };
      })
    );
  }

  function onCancelClone() {
    setClone(false);
    setEstimateNo(estimateData?.number ?? null);
  }

  const checkNullAddress = (
    city: string | null,
    country: string | null,
    countryCode: string | null,
    line1: string | null,
    postalCode: string | null,
    state: string | null,
    stateCode: string | null
  ) => {
    if (
      city === null &&
      country === null &&
      countryCode === null &&
      line1 === null &&
      postalCode === null &&
      state === null &&
      stateCode === null
    ) {
      return null;
    }
  };

  function onAddSubItem(item: GROUP_OR_ITEM | null) {
    const siblings = !item
      ? items.filter((m) => m.level === 1)
      : items.filter((m) => m.parentGroupCode === item.code);
    let _item = createItem();
    _item.parentGroupCode = item?.code || null;
    _item.level = item ? item?.level + 1 : 1;
    _item.sequence = siblings.length + 1;
    setItems(items.concat(_item));
  }

  function onSave(onSaved?: () => void) {
    setProcessing(true);
    const {
      billingAddress,
      shippingAddress,
      changeOrder,
      email,
      telephone,
      id,
      description,
      customer,
      validTill,
      markups,
    } = estimateData ?? {};
    if (!customer) {
      setProcessing(false);
      toast("Please choose a customer!", { type: "warning" });
      return;
    }
    const _billingAddress: ADDRESS = {
      id:
        checkNullAddress(
          billingAddress?.city || null,
          billingAddress?.country || null,
          billingAddress?.countryCode || null,
          billingAddress?.line1 || null,
          billingAddress?.stateCode || null,
          billingAddress?.state || null,
          billingAddress?.stateCode || null
        ) !== null
          ? billingAddress?.id || null
          : null,
      latLong:
        checkNullAddress(
          billingAddress?.city || null,
          billingAddress?.country || null,
          billingAddress?.countryCode || null,
          billingAddress?.line1 || null,
          billingAddress?.stateCode || null,
          billingAddress?.state || null,
          billingAddress?.stateCode || null
        ) !== null
          ? billingAddress?.latLong || null
          : null,
      line1: billingAddress?.line1 || null,
      city: billingAddress?.city || null,
      state: billingAddress?.state || null,
      country: billingAddress?.country || null,
      postalCode: billingAddress?.postalCode || null,
      countryCode: billingAddress?.countryCode || null,
      stateCode: billingAddress?.stateCode || null,
      fullAddress:
        checkNullAddress(
          billingAddress?.city || null,
          billingAddress?.country || null,
          billingAddress?.countryCode || null,
          billingAddress?.line1 || null,
          billingAddress?.stateCode || null,
          billingAddress?.state || null,
          billingAddress?.stateCode || null
        ) !== null
          ? billingAddress?.fullAddress || null
          : null,
    };
    const _shippingAddress: ADDRESS = {
      id:
        checkNullAddress(
          shippingAddress?.city || null,
          shippingAddress?.country || null,
          shippingAddress?.countryCode || null,
          shippingAddress?.line1 || null,
          shippingAddress?.stateCode || null,
          shippingAddress?.state || null,
          shippingAddress?.stateCode || null
        ) !== null
          ? shippingAddress?.id || null
          : null,
      latLong:
        checkNullAddress(
          shippingAddress?.city || null,
          shippingAddress?.country || null,
          shippingAddress?.countryCode || null,
          shippingAddress?.line1 || null,
          shippingAddress?.stateCode || null,
          shippingAddress?.state || null,
          shippingAddress?.stateCode || null
        ) !== null
          ? shippingAddress?.latLong || null
          : null,
      line1: shippingAddress?.line1 || null,
      city: shippingAddress?.city || null,
      state: shippingAddress?.state || null,
      country: shippingAddress?.country || null,
      postalCode: shippingAddress?.postalCode || null,
      countryCode: shippingAddress?.countryCode || null,
      stateCode: shippingAddress?.stateCode || null,
      fullAddress:
        checkNullAddress(
          shippingAddress?.city || null,
          shippingAddress?.country || null,
          shippingAddress?.countryCode || null,
          shippingAddress?.line1 || null,
          shippingAddress?.stateCode || null,
          shippingAddress?.state || null,
          shippingAddress?.stateCode || null
        ) !== null
          ? shippingAddress?.fullAddress || null
          : null,
    };

    const normalizeItems = normalizeSequences(
      items.filter((m) => (m.isGroup ? true : m.product && m.quantity))
    );
    const resolvedGroups = normalizeItems
      .filter((m) => m.isGroup)
      .map((m) => mappingToGroupPayload(m));
    const resolvedItems = normalizeItems
      .filter((m) => !m.isGroup)
      .map((m) => mappingToItemPayload(m))
      .filter((m) => {
        const parent = resolvedGroups.find((p) => p.code === m.groupCode);
        if (!m.groupCode) return true;
        if (parent) return true;
        return false;
      });

    const _items = normalizeItems.filter((m) => m.isGroup === false) as any;
    const validTeams = removeDuplicates(
      items.filter((m) => !m.isGroup).map((m) => m.team)
    ).map((m) => m?.id);

    const payload: ESTIMATE_PAYLOAD = {
      description: description ?? null,
      validTill: validTill ? dateToString4API(validTill) : null,
      groups: resolvedGroups,
      items: resolvedItems,
      billingAddress: _billingAddress || null,
      shippingAddress: _shippingAddress || null,
      exclusion: exclusion,
      telephone: telephone || null,
      email: email || null,
      subContractorRate: 0,
      changeOrder: changeOrder || false,
      markups: productTypeToMarkUps(markupTypes, markups ?? [], _items).filter(
        (m) =>
          m.teamId !== "all_team" &&
          (!m.teamId || validTeams.includes(m.teamId))
      ),
    };
    if (!estimateNo || isClone) {
      if (!customer) return;

      if (payload.markups) {
        payload.markups = payload.markups.map((m) => {
          return { ...m, id: null };
        });
      }
      payload.items = payload.items.map((m) => {
        return { ...m, id: null };
      });
      payload.groups = payload.groups.map((m) => {
        return { ...m, id: null };
      });

      createEstimate(payload, customer).then((doc) => {
        if (doc?.code !== "200") {
          setProcessing(false);
          return;
        }
        setReady(false);
        setModify(false);
        setProcessing(false);
        setEstimateData(doc?.data);
        setEstimateNo(doc?.data?.number);
        setClone(false);
        setSave(false);
        onSaved && onSaved();
      });
    } else {
      if (!customer || !id) return;
      updateEstimate(payload, id, customer).then((doc) => {
        if (doc?.code !== "200") {
          setProcessing(false);
          return;
        }
        setReady(false);
        setModify(false);
        setProcessing(false);
        setEstimateData(doc?.data);
        setSave(false);
        onSaved && onSaved();
      });
    }
  }

  const onAddTemplate = async (
    item: GROUP_OR_ITEM,
    template: ESTIMATE_TEMPLATE
  ) => {
    setTemplateItem(item);
    setBigProcessing(true);
    const res = await estimateTemplateDetails(template.id);
    if (res?.code === "200") {
      const index = items.findIndex((m) => m.id === item.id);
      const parent = items.find((m) => m.code === item.parentGroupCode);
      let _item = createItem();
      _item.name = template.name || "Untitle";
      _item.isGroup = true;
      _item.id = item.id;
      _item.sequence = item.sequence;
      _item.parentGroupCode = item.parentGroupCode;
      _item.level = item.level;
      _item.code = uuidv4();
      _item.subOf =
        parent?.subOf && parent.subOf.length > 0
          ? parent.subOf.concat(parent.code ?? "")
          : [parent?.code ?? ""];
      _item.subOf = _item.subOf.filter((m) => m);
      items[index] = _item;
      let children = remapGroupCode(
        orderByAsc(
          mappingEstimateTemplateGroupOrItemToArray(
            res?.data?.groups,
            res?.data?.items
          ),
          "level"
        )
      ).map(async (m: GROUP_OR_ITEM) => {
        if (m.isGroup === false && !m.catalog && m.product?.catalogAvailable) {
          const catalogues = (await catalogueByProduct(m.product?.id ?? 0))
            .data;
          const selectedCatalogue: CATALOGUE = catalogues.find(
            (m: CATALOGUE) => m.selected
          );
          if (selectedCatalogue) {
            m.catalog = selectedCatalogue;
            m.price = selectedCatalogue.price;
            m.total = m.quantity * selectedCatalogue.price;
          }
        }
        return m;
      });
      Promise.all(children.flat()).then((children) => {
        orderByAsc(children, "level").forEach((m, i) => {
          if (m.isGroup) {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                parentGroupCode: _item.code,
                level: _item.level + 1,
                subOf: _item.subOf?.concat(_item.code ?? ""),
              };
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: _item.level + m.level,
                parentGroupCode: parent?.code,
                subOf:
                  parent?.subOf && parent.subOf.length > 0
                    ? parent.subOf.concat(parent.code ?? "")
                    : [parent?.code ?? ""],
              };
            }
          } else {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                parentGroupCode: _item.code,
                level: _item.level + 1,
              };
              children = updateIndividualItemMarkup(
                children[i],
                children,
                markupTypes
              );
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: _item.level + m.level,
                parentGroupCode: parent?.code ?? "",
                groupCode: parent?.code,
                code: parent?.code,
              };
              children = updateIndividualItemMarkup(
                children[i],
                children,
                markupTypes
              );
            }
          }
        });
        setItems([...items.concat(children as any[])]);
        setBigProcessing(false);
      });
    } else {
    }
  };
  const onAddTemplateRemoveFirstLevel = async (
    item: GROUP_OR_ITEM,
    template: ESTIMATE_TEMPLATE
  ) => {
    // setTemplateItem(item);
    const index = items.findIndex((m) => m.id === item.id);
    setBigProcessing(true);
    const res = await estimateTemplateDetails(template.id);
    if (res?.code === "200") {
      const children = remapGroupCode(
        orderByAsc(
          mappingEstimateTemplateGroupOrItemToArray(
            res?.data?.groups,
            res?.data?.items
          ),
          "level"
        )
      ).map(async (m: GROUP_OR_ITEM) => {
        if (m.isGroup === false && !m.catalog && m.product?.catalogAvailable) {
          const catalogues = (await catalogueByProduct(m.product?.id ?? 0))
            .data;
          const selectedCatalogue: CATALOGUE = catalogues.find(
            (m: CATALOGUE) => m.selected
          );
          if (selectedCatalogue) {
            m.catalog = selectedCatalogue;
            m.price = selectedCatalogue.price;
            m.total = m.quantity * selectedCatalogue.price;
          }
        }
        return m;
      });

      Promise.all(children.flat()).then((children) => {
        orderByAsc(children, "level").forEach((m, i) => {
          if (m.isGroup) {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                parentGroupCode: item.parentGroupCode,
                level: item.level,
                subOf: [item?.parentGroupCode ?? ""],
                sequence: item.sequence + m.sequence - 1,
              };
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: item.level + m.level,
                parentGroupCode: parent?.code,
                subOf:
                  parent?.subOf && parent.subOf.length > 0
                    ? parent.subOf.concat(parent.code ?? "")
                    : [parent?.code ?? ""],
              };
            }
          } else {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                level: item.level,
                sequence: item.sequence + m.sequence - 1,
                parentGroupCode: item.parentGroupCode,
              };
              children = updateIndividualItemMarkup(
                children[i],
                children,
                markupTypes
              );
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: item.level + m.level,
                parentGroupCode: parent?.code ?? "",
                groupCode: parent?.code,
                code: parent?.code,
              };
              children = updateIndividualItemMarkup(
                children[i],
                children,
                markupTypes
              );
            }
          }
        });
        setItems([...items.concat(children as any[])]);
        setBigProcessing(false);
      });
    } else {
    }
    //remove first index
    if (index > -1) {
      items.splice(index, 1);
    }
  };
  function onSelectTeamForAll(team: TEAM | null) {
    const _items = items;
    items.forEach((i, ind) => {
      if (
        _items[ind].product?.productType?.code === "svc" &&
        !_items[ind].isGroup
      ) {
        _items[ind].price =
          team?.price ?? i.catalog?.price ?? i.product?.price ?? i.price ?? 0;
        _items[ind].total = i.quantity * (team?.price ?? _items[ind].price);
      }
      _items[ind].team = team;
      _items[ind].teamId = team?.id || null;
    });
    resetMarkUp(_items);
    setTeam(team);
  }

  const RenderEstimateInformation = React.useMemo(() => {
    return (
      <EstimateInformation
        onModify={() => setModify(true)}
        contactRouteId={searchParams.get("contact")}
        estimateNo={estimateNo}
        taxRate={taxRate}
        onSetTaxRate={(tax) => setTaxRate(tax)}
        data={estimateData}
      />
    );
  }, [estimateData, taxRate, estimateNo]);

  const RenderProductDetails = React.useMemo(() => {
    return (
      <ProductDetails
        onGroupDescription={onChangeGroupDescription}
        team={team}
        onTeam={onSelectTeamForAll}
        onAddTemplate={onAddTemplateRemoveFirstLevel}
        onAddSubItem={onAddSubItem}
        onChangeGroupName={onChangeGroupName}
        onGroupTeam={onSelectGroupTeam}
        onDeleteItem={onDelete}
        onAddNewGroup={onAddNewGroup}
        onSetPrice={(value, group) => calculateDisperse(group, value, items)}
        onAddRow={onAddRow}
        updateItem={onUpdateItem}
        setItems={(data) => setItems(data)}
        items={items}
        data={estimateData}
        isPrinting={isPrinting}
      />
    );
  }, [items, estimateData, markupTypes, team, isPrinting]);

  const RenderExlusion = React.useMemo(() => {
    return (
      <ExclusionDetails
        exclusion={exclusion}
        setExclusion={(t) => setExclusion(t)}
        data={estimateData}
      />
    );
  }, [estimateData, exclusion]);

  const RenderTotalSummary = React.useMemo(() => {
    return <TotalSummary taxRate={taxRate} items={items} />;
  }, [estimateData, taxRate, items]);

  const RenderMarkup = React.useMemo(() => {
    return (
      <Markup
        markupTypes={markupTypes}
        onMarkup={(value, type, all, noModify) => {
          onMarkup(value, type, items, true, all);
        }}
        isDisabled={false}
        teams={teams}
        onCopyToAll={onCopyToAll}
        items={items}
      />
    );
  }, [markupTypes, teams, items]);
  const RenderPageFooter = React.useMemo(() => {
    return (
      <PageFooter
        printing={isPrinting}
        onSave={onSave}
        processing={processing}
        onClone={() => onClone()}
        estimateId={estimateData?.id}
        onPrint={() => {
          setPrinting(true);
          setTimeout(() => {
            handlePrint(null, () => contentToPrint.current);
          }, 500);
        }}
        taxRate={taxRate}
        exclusion={exclusion}
        items={items}
        goBack={() => {
          navigate("/main/estimate-and-invoices/estimate/estimate-list");
        }}
        isModify={isModify}
      />
    );
  }, [
    items,
    exclusion,
    taxRate,
    isModify,
    processing,
    estimateNo,
    isClone,
    isPrinting,
  ]);

  if (loading) {
    return <EstimateDetailsSkeleton />;
  }

  if (errorMessage) {
    return (
      <NotFound
        title={errorMessage}
        message={
          "We're sorry, the estimate that you are looking for is not found or you don't have the access right!"
        }
      />
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col ">
      {isClone ? <CloneBanner onCancel={() => onCancelClone()} /> : null}
      <PageHeader isModify={isModify} />
      <div
        ref={contentToPrint}
        id={"scroller"}
        className="h-full w-full overflow-auto overflow-x-hidden p-4 bg-white pb-10 pt-0"
      >
        {RenderEstimateInformation}
        {RenderProductDetails}
        <div className="flex">
          {RenderExlusion}
          {RenderTotalSummary}
        </div>
        {RenderMarkup}
        {isPrinting ? (
          <EstimateSummaryContent
            isChild
            items={items}
            exclusion={exclusion}
            taxRate={taxRate}
          />
        ) : null}
      </div>
      {RenderPageFooter}
      {bigProcessing ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
          <span className="text-white text-[40px]">Processing...</span>
        </div>
      ) : null}
    </div>
  );
}

export default inject("auth")(observer(EstimateDetails));
