interface Props {
  children: React.ReactNode;
  className?:string
}

export default function GlowingWrapper(props: Props) {
  return (
    <div className={`relative inline-flex group w-full`}>
      <div className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
      <div className={`relative inline-flex items-center justify-center  transition-all duration-200  rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900  ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
}
