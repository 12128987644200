import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  HiArrowRightCircle,
  HiMiniEllipsisVertical,
  HiQueueList,
  HiRectangleStack,
} from "react-icons/hi2";

interface Props {
  show?: boolean;
  onLevel: (lv: number) => void;
  levels: number[];
  onShowAll: () => void;
  onHideAll: () => void;
  onMoveTo: () => void;
  onCheckAll: () => void;
  onUnCheckAll: () => void;
}

function DropdownMenuRow(props: Props) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="cursor-pointer">
          <HiMiniEllipsisVertical className="text-muted-foreground" size={18} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {/* <DropdownMenuLabel>Activities</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => onScrollTo("top")}>
          Scroll To Top
          <DropdownMenuShortcut>
            <HiMiniBarsArrowUp size={16} />
          </DropdownMenuShortcut>
        </DropdownMenuItem> */}
        {/* <DropdownMenuSeparator /> */}

        <DropdownMenuGroup>
          {/* <DropdownMenuSub>
            <DropdownMenuSubTrigger>Levels</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {props.levels.map((lv) => (
                  <DropdownMenuItem onClick={() => props.onLevel(lv)} key={lv}>
                    Expand Level {lv}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub> */}

          <DropdownMenuItem onClick={() => props.onHideAll()}>
            Collapse All
            <DropdownMenuShortcut>
              <HiQueueList size={16} />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => props.onShowAll()}>
            Expand All
            <DropdownMenuShortcut>
              <HiRectangleStack size={16} />
            </DropdownMenuShortcut>
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => props.onMoveTo()}>
            Move To
            <DropdownMenuShortcut>
              <HiArrowRightCircle size={16} />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
          {/* <DropdownMenuSeparator /> */}
          {/* <DropdownMenuItem onClick={() => props.onCheckAll()}>
            Check All
            <DropdownMenuShortcut>
              <HiClipboardDocumentCheck size={16} />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => props.onUnCheckAll()}>
            Uncheck All
            <DropdownMenuShortcut>
              <HiClipboardDocument size={16} />
            </DropdownMenuShortcut>
          </DropdownMenuItem> */}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default DropdownMenuRow;
