"use client";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { cn } from "src/lib/utils";
import { Label } from "./ui/label";
import { HiCalendarDays } from "react-icons/hi2";
import { displayDate } from "src/utilities/formatter.utilities";
import { PopoverClose } from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";

interface Props {
  label: string;
  placeholder?: string;
  value: Date | null;
  maxDate?: Date;
  minDate?: Date;
  onDate?: (date: Date) => void;
  disabled?: boolean;
  auth?: AuthStore;
}

function DatePicker(props: Props) {
  const [value, setValue] = useState<Date | null>(null);
  const [open,setOpen] = useState(false);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);


  return (
    <div className="mt-4 w-full">
      <Label>{props.label}</Label>
      <Popover open = {open} onOpenChange={(v) => setOpen(v)}>
        <PopoverTrigger asChild>
          <Button
            disabled={!props.onDate || props.disabled}
            variant={"outline"}
            className={cn(
              `pl-3 mt-2 text-left font-normal w-full border border-gray-300 ${
                !value ? "text-muted-foreground" : ""
              }`
            )}
          >
            <span>
              {(value !== null && value.toString() === "MM / DD / YYYY") ||
              value?.toString() === "00-00-00"
                ? props.placeholder
                : value
                ? displayDate(value, props.auth?.dateFormat)
                : props.placeholder}
            </span>
            <HiCalendarDays className="ml-auto h-4 w-4 opacity-90" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
            <Calendar
              onDayClick={(date) => {
                setValue(date);
                props.onDate && props.onDate(date);
                setOpen(false)
              }}
              mode="single"
              disabled={(date) =>
                (props.minDate && date > props.minDate) ||
                (props.maxDate && props.maxDate < date) ||
                false
              }
              initialFocus
            />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default inject("auth")(observer(DatePicker));
