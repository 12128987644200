import { PhoneInput } from "react-international-phone";
import { Label } from "./ui/label";

interface InputPhoneProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  value: string | null;
  onChange: (t: string) => void;
  onBlur?: () => void;
  onEnter?: () => void;
}

export default function InputPhone(props: InputPhoneProps) {
  return (
    <div className={props.label ? "mt-4 w-full" : "w-full"}>
      {props.label && (
        <Label>
          {props.label}
          {props.required ? <span className="text-destructive">*</span> : null}
        </Label>
      )}

      <PhoneInput
        onChange={props.onChange}
        defaultCountry="us"
        inputProps={{
          className:
            "flex mt-2 h-9 w-full rounded-md border border-gray-300 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50 bg-white",
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            borderWidth: 0,
            backgroundColor: "transparent",
            alignItems: "start",
          },
          dropdownStyleProps: {
            className: "rounded",
          },
          className:
            "mr-2 mt-2 flex h-9 bg-white  rounded-md border border-gray-300 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50",
        }}
        className="mt-0"
        value={props.value ?? ""}
      />
    </div>
  );
}
