import NavigationBarItem, { INavigationBarItem } from "./NavigationBarItem";
import {  useState } from "react";
import { Separator } from "../ui/separator";
import images from "../../assets/images";
interface Props {
  routes: INavigationBarItem[];
  asChild?: boolean;
}

export default function NavigationBar(props: Props) {
  const [collapsedKey, setCollapsedKey] = useState("");

  return (
    <aside
      className={props.asChild ? "h-3/4 drop-shadow" : ` drop-shadow`}
      aria-label="Sidebar"
    >
      <div className="h-screen w-80 flex flex-col px-3 py-4 overflow-y-auto bg-primary">
        <img
          className="mb-4 rounded-full"
          src={images.logo_black}
          width={50}
          height={50}
          alt={""}
        />
        <Separator className={"opacity-5"} />
        <ul className="space-y-1 mt-4">
          {props.routes.map((route) => {
            return (
              <NavigationBarItem
                showKey={collapsedKey}
                onShow={(t) => t === collapsedKey ? setCollapsedKey("") : setCollapsedKey(t)}
                subRoutes={route.subRoutes}
                id={route.id}
                key={route.id}
                route={route.route}
                icon={route.icon}
                name={route.name}
              />
            );
          })}
        </ul>
        {props.asChild ? <Separator className="mt-4" /> : null}
        <div className="flex flex-1" />
        <span className="text-sm text-white/70 mt-4">
          © 2024 ANGKORSCAPE LLC.
        </span>
        <span className="text-sm text-white/70">All Rights Reserved.</span>
      </div>
    </aside>
  );
}
