import { IconType } from "react-icons";
import { Label } from "src/components/ui/label";

interface Props {
  icon: IconType;
  label: string;
  value: string;
  noBorder?: boolean;
  className?: string | null;
}

export default function ItemInfo(props: Props) {
  return (
    <div className={props.noBorder ? "flex py-4" : "flex border-b py-4"}>
      <div className={props.className ?? "flex w-44 min-w-44 items-center"}>
        <props.icon className="16" />
        <Label className="ml-2 font-light">{props.label}</Label>
      </div>
      <span className="ml-1 font-medium">{props.value}</span>
    </div>
  );
}
