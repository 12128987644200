import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "src/components/ui/tableEstimate";
import { COLUMNS, SPACE, SPACE_ROW } from "./ProductDetails";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import MainInput from "src/components/MainInput";
import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  getGroupCodeToDisplay,
  title,
} from "src/utilities/functions.utilities";
import { formatMoney, sum } from "src/utilities/formatter.utilities";
import { HiChatBubbleOvalLeft, HiTrash } from "react-icons/hi2";
import CollapsedButton from "./CollapsedButton";
import DropdownMenuRow from "./DropdownMenu";
import { TEAM } from "src/interfaces/team.interface";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@mui/material";
import AddButtonRow from "./AddButtonRow";
import CheckButton from "./CheckButton";
import { getSubGroups } from "./estimate.utility";

interface Props {
  item: GROUP_OR_ITEM;
  allItems: GROUP_OR_ITEM[];
  parentCode: string | null;
  isLast?: boolean;
  estimateId: number  | string | undefined;
  auth?: AuthStore;
}

function ProductDetailsGroupSkeleton(props: Props) {
  const span: any = useRef();
  const [width, setWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const [total, setTotal] = useState(0);
  const [team, setTeam] = useState<TEAM | null>(null);
  const [editingText, setEditing] = useState(false);
  const [openComment, setOpenComment] = useState(false);

  const [hover, setHover] = useState(false);
  const [checked, setCheck] = useState(false);

  useEffect(() => {
    const { team } = props.item;
    const groupChildrenCodes = getSubGroups(props.item, props.allItems)
      .map((m) => m.code)
      .concat(props.item.code);

    const allItemsOfGroupChildren = props.allItems.filter(
      (m) =>
        m.parentGroupCode &&
        !m.isGroup &&
        groupChildrenCodes.includes(m.parentGroupCode)
    );
    const _total = sum(allItemsOfGroupChildren, "total");
    const _disperse = sum(allItemsOfGroupChildren, "disperse");
    const _markup = sum(allItemsOfGroupChildren, "markup");
    setTotal(_total + _disperse + _markup);
  }, [props.item, props.allItems]);

  useEffect(() => {
    setTeam(props.item.team);
  }, [props.item.teamId]);

  useEffect(() => {
    setWidth(span.current.offsetWidth + 10);
  }, [props.item?.name]);

  const groupCode = React.useMemo(() => {
    const { sequence } = props.item;
    return getGroupCodeToDisplay(sequence, props.parentCode);
  }, [props.parentCode, props.item.sequence]);

  const renderTotal = React.useMemo(() => {
    return (
      <div className="w-52 ml-2">
        <MainInput
          prefix="$"
          extendClass={"bg-white font-bold"}
          value={formatMoney(total) ?? "0"}
        />
      </div>
    );
  }, [total, props.item]);

  const renderHeaderSpacing = React.useMemo(() => {
    if (props.item.level !== 1) return null;
    return (
      <TableRow>
        <TableCell
          className=" hover:bg-white bg-white"
          colSpan={COLUMNS.length}
        >
          <div className="w-full h-[1px] bg-primary drop-shadow-md" />
        </TableCell>
      </TableRow>
    );
  }, [props.item]);

  const renderDropDownMenu = React.useMemo(() => {
    return (
      <DropdownMenuRow
        onCheckAll={() => null}
        onUnCheckAll={() => null}
        onMoveTo={() => null}
        onLevel={() => null}
        onShowAll={() => null}
        onHideAll={() => null}
        levels={[]}
      />
    );
  }, []);

  const renderTree = React.useMemo(() => {
    return (
      <div
        style={{
          width:
            props.item.level === 1
              ? props.item.level * SPACE
              : props.item.level * SPACE_ROW - 7,
        }}
      ></div>
    );
  }, [props.item.level]);

  return (
    <>
      {renderHeaderSpacing}
      <TableRow
        className="cursor-pointer drop-shadow"
        style={{
          cursor: "grab",
        }}
      >
        <TableCell className="p-0 border-t" colSpan={COLUMNS.length}>
          <Table className="w-full">
            <TableBody>
              <TableRow
                onMouseLeave={() => setHover(false)}
                onMouseEnter={() => setHover(true)}
                className="bg-transparent relative bg-slate-100"
              >
                <TableCell
                  className="pt-2 pb-2 px-0"
                  colSpan={COLUMNS.length - 1}
                >
                  <div className="flex items-center">
                    <CheckButton checked={checked} onChange={() => null} />
                    {renderDropDownMenu}
                    {renderTree}
                    <CollapsedButton
                      rowId=""
                      onChange={() => setCollapsed(!collapsed)}
                      checked={collapsed}
                    />
                    <h2 className="mr-1">{groupCode}.</h2>
                    <div style={{ width: width }}>
                      <span
                        style={{
                          position: "absolute",
                          opacity: 0,
                          fontWeight: "700",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          bottom: 0,
                          left: -200,
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                        }}
                        id="hide"
                        ref={span}
                      >
                        {title(
                          props.item.name ?? "",
                          props.auth?.alphabetic ?? ""
                        )}
                      </span>
                      {editingText ? (
                        <MainInput
                          focus
                          extendClass={"bg-transparent font-bold"}
                          hideBorder
                          onChange={() => null}
                          value={props.item.name}
                        />
                      ) : (
                        <span
                          onClick={() => setEditing(true)}
                          className="font-semibold cursor-text"
                        >
                          {title(
                            props.item?.name ?? "",
                            props.auth?.alphabetic ?? ""
                          )}
                        </span>
                      )}
                    </div>
                    <AddButtonRow show={hover} onClick={() => null} />
                    {renderTotal}
                    <Tooltip
                      title={
                        props.estimateId
                          ? ""
                          : "Please save your estimate first."
                      }
                    >
                      <div
                        onClick={() =>
                          !props.estimateId ? null : setOpenComment(true)
                        }
                      >
                        <HiChatBubbleOvalLeft
                          className={`ml-2 hover:opacity-80 ${
                            !props.estimateId
                              ? "text-muted-foreground/30"
                              : props.item.hasComment
                              ? "text-secondary"
                              : "text-muted-foreground"
                          }`}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell className="p-2 justify-end flex">
                  <div
                    style={{
                      width: COLUMNS[COLUMNS.length - 1].width - 15,
                    }}
                    className={`flex items-center`}
                  >
                    <AutoCompleteWithLazyLoad
                      placeholder="Choose team"
                      onSelectedValue={(value) => {
                        setTeam(value);
                      }}
                      value={team}
                      path="team"
                    />
                    <HiTrash
                      onClick={() => null}
                      size={22}
                      className="ml-2 text-slate-500 mr-0 cursor-pointer"
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
}

export default inject("auth")(observer(ProductDetailsGroupSkeleton));
