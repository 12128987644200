import {
  CREATE_USER_PAYLOAD,
  UPDATE_USER_PAYLOAD,
  UPDATE_USER_ROLE,
  UPDATE_USER_WORKPLACE_PAYLOAD,
} from "src/interfaces/init.interface";
import { getAPI, postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";
import { TEAM } from "src/interfaces/team.interface";

export async function createUser(user: CREATE_USER_PAYLOAD) {
  let url = `${process.env.REACT_APP_MAIN_API}/user`;
  if (user?.external) {
    url = url + "/external-member";
  }
  const data = await postAPI(url, user);
  return data as RESPONSE;
}

export async function updateUser(
  user: UPDATE_USER_PAYLOAD | UPDATE_USER_ROLE | UPDATE_USER_WORKPLACE_PAYLOAD,
  path?: string
) {
  let url = `${process.env.REACT_APP_MAIN_API}/user/${user.id}` + (path ?? "");
  const data = await putAPI(url, user);
  return data as RESPONSE;
}

export async function updateDepartment(id: string, name: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/department/${id}/change-name`;
  const body = {
    name: name,
  };
  const data = await putAPI(url, body);
  return data as RESPONSE;
}

export async function updateTeam(id: string, name: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/team/${id}/change-name`;
  const body = {
    name: name,
  };
  const data = await putAPI(url, body);
  return data as RESPONSE;
}

export async function updateTeamPricing(team: TEAM, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/team/${id}`;
  const data = await putAPI(url, team);
  return data as RESPONSE;
}

export async function addDepartmentMembers(id: string, members: number[]) {
  let url = `${process.env.REACT_APP_MAIN_API}/department/${id}/add-memeber`;
  const data = await putAPI(url, members);
  return data as RESPONSE;
}

export async function addTeamMembers(id: string, members: number[]) {
  let url = `${process.env.REACT_APP_MAIN_API}/team/${id}/add-memeber`;
  const data = await putAPI(url, members);
  return data as RESPONSE;
}

export async function createDepartment(name: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/department`;
  const body = {
    name: name,
  };
  const data = await postAPI(url, body);
  return data as RESPONSE;
}

export async function createTeam(
  name: string,
  price: number,
  external: boolean
) {
  let url = `${process.env.REACT_APP_MAIN_API}/team`;
  const body: any = {
    name,
    enable: true,
    price: price,
    external,
  };
  const data = await postAPI(url, body);
  return data as RESPONSE;
}

export const mentionList = async (search: string) => {
  var url = `${
    process.env.REACT_APP_MAIN_API
  }/user/mention-search?page=${0}&length=${7}`;
  url = `${url}&search=${search ?? ""}`;
  const data = await getAPI(url);
  return data as RESPONSE;
};
