import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { HiXMark } from "react-icons/hi2";
import MainInput from "src/components/MainInput";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "src/components/ui/sheet";
import { AutoCompleteStatic } from "src/components/autoComplete/AutoCompleteStatic";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { FIELD_TYPE, UDF } from "src/interfaces/udf.interface";
import UDFStore from "src/stores/udf.store";
import UdfListTypeList from "../userDefinedTypeList/index";
import { Card } from "src/components/ui/card";
import UdfEnableButton from "../UdfEnableButton";

interface Props {
  udf?: UDFStore;
  auth?: AuthStore;
  entityCode: string;
  // this item used for delete on list
  item?: UDF;
  open: boolean;
  onOpen: (value: boolean) => void;
}

function UdfAdd(props: Props) {
  const [name, setName] = useState("");
  const [type, setType] = useState<FIELD_TYPE | null>(null);

  const { selectedUdf, updatingUdf: loadingUpdate } = props.udf ?? {};

  useEffect(() => {
    if (props.udf && selectedUdf) {
      const { name, fieldType } = selectedUdf;
      setName(name);
      setType(fieldType);
    }
    return () => {
      setName("");
    };
  }, [props.open]);

  const onSave = () => {
    if (props.udf) {
      const doc: UDF = {
        ...props.udf.selectedUdf,
        name: name,
      };
      props.udf.onSaveUdf(props.entityCode, doc, () => {
        if (selectedUdf?.fieldType?.list === true) return;
        props.onOpen(false);
      });
    }
  };

  return (
    <Sheet onOpenChange={props.onOpen} open={props.open}>
      <SheetContent className=" overflow-auto h-[99%] w-[40%]">
        <SheetHeader className="flex items-center flex-row justify-between">
          <SheetTitle>
            {selectedUdf?.id
              ? "Update contact user defined field"
              : "Create contact user defined field"}
          </SheetTitle>
          <SheetClose>
            <HiXMark size={22} />
          </SheetClose>
        </SheetHeader>
        <div className="my-5 gap-4 mb-10">
          <Card className="flex flex-1 p-4 pt-0 flex-col flex-grow-0">
            <AutoCompleteStatic
              required
              disabled={selectedUdf?.id !== ""}
              label="Field type"
              onSelectedValue={(v) => setType(v)}
              value={type}
              data={props.auth?.fieldTypes ?? []}
            />
            <MainInput
              required
              placeholder="Enter name"
              value={name}
              onChange={setName}
              label="Name"
            />
            <div className="mt-4">
              <UdfEnableButton item={props.item} label="Enable" />
            </div>
            <div className="flex items-end justify-end">
              <div className="w-40">
                <MainButton
                  disabled={name.trim() === selectedUdf?.name}
                  loading={loadingUpdate}
                  type="button"
                  label="Save"
                  onClick={onSave}
                />
              </div>
            </div>
          </Card>

          {selectedUdf?.fieldType?.list === true && selectedUdf?.id !== "" ? (
            <Card className="flex flex-1 p-4 pb-2 pt-0 mt-6 flex-col flex-grow-0">
              <UdfListTypeList />
            </Card>
          ) : null}
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default inject("auth", "udf")(observer(UdfAdd));
