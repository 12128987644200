import { arrayMoveImmutable } from "array-move";
import CrmStatusTabItem from "./CrmStatusTabItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddCrmStautsButton from "../CrmAddTabarButton";
import CRMStore from "src/stores/crm.store";
import { inject, observer } from "mobx-react";
import { CrmTabarSkeleton } from "../CrmTabarSkeleton";
import { CRM_TYPES } from "src/constants/crm.constants";

interface Props {
  crm?: CRMStore;
}

function CrmStatusTab(props: Props) {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = arrayMoveImmutable(
      props.crm?.statusList ?? [],
      result.source.index,
      result.destination.index
    );
    props.crm?.onCrmStatusReorder(items, CRM_TYPES.lead.id);
  };

  const { statusList, selectedStatus, processingStatusList } = props.crm ?? {};

  if (processingStatusList) {
    return <CrmTabarSkeleton />;
  }

  return (
    <div className="flex mx-2 items-center overflow-auto">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction="horizontal"
          droppableId={`OPTION_${selectedStatus?.id}`}
        >
          {(provided, snapshot) => {
            return (
              <div
                className="flex"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {statusList?.map((row, index) => {
                  const active = row.id === selectedStatus?.id;
                  const lastIndex = index === statusList.length - 1;
                  return (
                    <Draggable
                      key={row.id}
                      index={index}
                      draggableId={row.id ? row.id.toString() : ""}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={`draggble min-w-36 ${
                              lastIndex ? "" : "mr-2"
                            }`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <CrmStatusTabItem
                              active={active}
                              isLastIndex={lastIndex}
                              item={row}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <div>
        <AddCrmStautsButton />
      </div>
    </div>
  );
}

export default inject("crm")(observer(CrmStatusTab));
