import { arrayMoveImmutable } from "array-move";
import { makeAutoObservable, observable, action } from "mobx";
import { reorderSequence } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";
import { INavigationBarItem } from "src/components/navigationBar/NavigationBarItem";
import { UDF, UDF_LIST, getInitUdf } from "src/interfaces/udf.interface";
import { user_defined } from "src/routes/routeNav.config";
import {
  onCreateUdf,
  onCreateUdfList,
  onGetUdf,
  onUdfListReorder,
  onUdfReorder,
  onUpdateUdfEnable,
  onUpdateUdfName,
} from "src/services/udf.service";
import { orderByAsc } from "src/utilities/formatter.utilities";

export default class UDFStore {
  @observable udfs: UDF[] = [];
  @observable selectedUdf: UDF = getInitUdf;
  @observable loading: boolean = false;
  @observable updatingUdf: boolean = false;
  @observable updatingUdfList: boolean = false;
  @observable selectedTab: INavigationBarItem = user_defined[0];

  constructor() {
    makeAutoObservable(this);
  }

  @action onChangeTab = (tab: INavigationBarItem) => {
    this.selectedTab = tab;
  };

  @action onGetUdfs = (entityCode: string) => {
    this.loading = true;
    onGetUdf(entityCode).then((res) => {
      if (res !== null) {
        if (res.data.length === 0) {
          this.udfs = [];
          this.loading = false;
          return;
        }
        const results = orderByAsc(res?.data, "sequence");
        this.udfs = results;
      } else {
        this.udfs = [];
      }
      this.loading = false;
    });
  };

  @action onChangeUdf = (udf: UDF) => {
    if (udf.udfLists === undefined) {
      udf.udfLists = [];
    }
    this.selectedUdf = udf;
  };

  @action onDragUdf = (entityCode: string, result: any) => {
    if (!result.destination) return;
    const items = arrayMoveImmutable(
      this.udfs,
      result.source.index,
      result.destination.index
    );
    const reordered = reorderSequence(items);
    this.udfs = [...reordered];
    onUdfReorder(entityCode, reordered).then((res) => {
      if (res === null) return;
    });
  };

  @action onEnableUdf = (enable: boolean, onSuccess: () => void) => {
    onUpdateUdfEnable(this.selectedUdf?.id ?? "", !enable).then((res) => {
      if (res?.code === "200" && this.selectedUdf !== null) {
        const index = this.udfs.findIndex((m) => m.id === this.selectedUdf?.id);
        this.udfs[index].enable = !enable;
        this.selectedUdf.enable = !enable;
        onSuccess();
      } else {
        return;
      }
    });
  };

  @action onDeleteUdf = () => {
    const afterDelete = this.udfs.filter((m) => m.id !== this.selectedUdf?.id);
    this.udfs = [...afterDelete];
  };

  @action onSaveUdf = (entityCode: string, udf: UDF, onSuccess: () => void) => {
    this.updatingUdf = true;
    if (udf.id === "") {
      onCreateUdf(entityCode, udf).then((data) => {
        if (data !== null) {
          const result: UDF = {
            ...data,
            udfLists: [],
          };
          this.selectedUdf = result;
          this.udfs.push(result);
          this.updatingUdf = false;
          onSuccess();
        } else {
          this.updatingUdf = false;
        }
      });
    } else {
      onUpdateUdfName(udf.id, udf.name).then((res) => {
        if (res?.code === "200") {
          this.selectedUdf = udf;
          const index = this.udfs.findIndex((m) => m.id === udf.id);
          this.udfs[index] = { ...udf };
          this.updatingUdf = false;
          onSuccess();
        } else {
          this.updatingUdf = false;
        }
      });
    }
  };

  @action onAddUdfList = (udfList: UDF_LIST, onAdded: () => void) => {
    onCreateUdfList(this.selectedUdf.id, udfList).then((data) => {
      if (data !== null) {
        const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
        this.udfs[index].udfLists.push(data);
        this.selectedUdf.udfLists = [...this.udfs[index].udfLists];
        const finalResult = {
          ...this.selectedUdf,
        };
        this.selectedUdf = finalResult;
        onAdded();
      } else {
        return;
      }
    });
  };

  @action onDeleteUdfList = (udfList: UDF_LIST) => {
    const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
    const afterDelet = this.udfs[index].udfLists.filter(
      (m) => m.id !== udfList.id
    );
    this.udfs[index].udfLists = [...afterDelet];
    this.selectedUdf.udfLists = [...afterDelet];

    const finalResult = {
      ...this.selectedUdf,
      udfList: this.selectedUdf.udfLists,
    };
    this.selectedUdf = finalResult;
  };

  @action onDragUdfList = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = arrayMoveImmutable(
      this.selectedUdf.udfLists,
      result.source.index,
      result.destination.index
    );
    const reordered = reorderSequence(items);
    const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
    this.udfs[index].udfLists = [...reordered];
    this.selectedUdf.udfLists = [...reordered];
    onUdfListReorder(this.selectedUdf.id, reordered).then((res) => {
      if (res === null) return;
    });
  };

  @action onUpdateUdfListValue = (udfList: UDF_LIST) => {
    const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
    const udfListIndex = this.udfs[index].udfLists.findIndex(
      (m) => m.id === udfList.id
    );
    this.udfs[index].udfLists[udfListIndex] = udfList;
  };

  @action onUpdateUdfListColor = (udfList: UDF_LIST) => {
    const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
    const udfListIndex = this.udfs[index].udfLists.findIndex(
      (m) => m.id === udfList.id
    );
    this.udfs[index].udfLists[udfListIndex] = udfList;
  };

  @action onSetDefaultUdfList = (udfLists: UDF_LIST[]) => {
    const index = this.udfs.findIndex((m) => m.id === this.selectedUdf.id);
    this.udfs[index].udfLists = [...udfLists];
    this.selectedUdf.udfLists = [...udfLists];
    const finalResult = {
      ...this.selectedUdf,
      udfList: this.selectedUdf.udfLists,
    };
    this.selectedUdf = finalResult;
  };
}
