import dayjs from "dayjs";
import { AUTH } from "src/interfaces/auth.interface";

export function saveAuth(data: AUTH) {
  let authData  = {...data};
  const currentDate =  dayjs().unix();
  authData.tokenExpired = currentDate + (authData.tokenExpired/1000);
  authData.refreshTokenExpired = currentDate + (authData.refreshTokenExpired/1000);
  localStorage.setItem("auth", JSON.stringify(authData));
}

export function getAuth(){
  const result =  localStorage.getItem("auth")  ?? "";
  return result ? JSON.parse(result) as AUTH : null;
 }

export function getToken(){
    const result =  localStorage.getItem("auth") ?? "";
    const parsed = result ? JSON.parse(result) as AUTH : null;
    return parsed?.token;
}

export function removeAuth(){
  localStorage.removeItem("auth");
}

export function savePreviousPath(path:string){
  localStorage.setItem("previous_path", path);
}

export function getPreviousPath(){
  const result =  localStorage.getItem("path") ?? "";
  return result;
 }


 export function setStoredUniqueKey(key:string){
  localStorage.setItem("unique_key", key);
 }

 export function getStoredUniqueKey(){
   return localStorage.getItem("unique_key") ?? "";
 }

 export function removeStoredUniqueKey(){
  localStorage.removeItem("unique_key");
 }