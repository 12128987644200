import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { IoCalendarClearOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import NotFound from "src/app/notFound";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import CUSTOMER from "src/interfaces/customer.interface";
import CRMStore from "src/stores/crm.store";
import { dateToString } from "src/utilities/formatter.utilities";
import ItemInfo from "../ItemInfo";

interface Props {
  crm?: CRMStore;
}

function HistoryContact(props: Props) {
  const { id } = useParams();
  const [contactData, setContactData] = useState<CUSTOMER | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.crm?.contactDetails === null) {
      if (!id) {
        setLoading(false);
        return;
      }
      props.crm.onGetContactDetails(Number(id)).then((result) => {
        if (result?.data && result.code === "200") {
          setContactData(result?.data);
        } else {
          setErrorMessage(result?.message);
        }
        setLoading(false);
      });
    } else {
      setContactData(props.crm?.contactDetails || null);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <EstimateDetailsSkeleton />;
  }

  if (errorMessage) {
    return (
      <NotFound
        title={errorMessage}
        message={
          "We're sorry, the estimate that you are looking for is not found or you don't have the access right!"
        }
      />
    );
  }

  return (
    <div className="m-4 p-5 bg-white rounded-md flex">
      <div className="w-full">
        <div className="font-bold text-xl uppercase mb-6">History Info</div>
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Created Date"}
          value={
            contactData?.createdAt
              ? `${dateToString(contactData?.createdAt)}  by ${
                  contactData.displayName
                }`
              : "N/A"
          }
        />
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Contact creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
        />
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Lead Creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
        />
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Opportunity Creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
          noBorder
        />
      </div>
      <div className="w-full ml-8 pt-[46px]">
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Client Creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
        />
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Estimate Creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
        />
        <ItemInfo
          className={"flex w-52 min-w-52 items-center"}
          icon={IoCalendarClearOutline}
          label={"Proposal creation log"}
          value={
            contactData?.createdAt
              ? dateToString(contactData?.createdAt)
              : "N/A"
          }
          noBorder
        />
      </div>
    </div>
  );
}

export default inject("crm")(observer(HistoryContact));
