import { Label } from "src/components/ui/label";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import { META_DATA } from "src/interfaces/proposal.interface";
import { COMPONENT_TYPE } from "src/constants";
import { FILE } from "src/interfaces/auth.interface";
import UploadFileForProposal from "../UploadProposalFile";

interface Props {
  label: string;
  parent: META_DATA;
  child: META_DATA;
  images: FILE[];
  onChange: (t: any) => void;
}

 function OurTeamInputItem(props: Props){
  const isImage =
    props.child.type === COMPONENT_TYPE.image.key ||
    props.child.type === "imageAdd";

  const renderComponent = () => {
    if (isImage) {
      return (
        <UploadFileForProposal
          isOurTeam
          parent={props.parent}
          child={props.child}
          images={[
            { id: props.child.id, base64: null, url: props.child.value },
          ]}
        />
      );
    }
    return (
      <DynamicInput
        noLable
        noMarginTop
        meta={props.child}
        onChange={props.onChange}
      />
    );
  };

  return (
    <div className={`flex mb-4 ${isImage ? "items-start" : "items-center"}`}>
      <Label
        className={`font-semibold text-gray-500 mr-3 min-w-[90px] ${isImage ? "mt-6" : ""
          }`}
      >
        {props.label}
      </Label>
      <div className="w-[296px]">{renderComponent()}</div>
    </div>
  );
};


export default OurTeamInputItem;