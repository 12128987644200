import { useEffect, useState } from "react";
import { HiEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import MainButton from "src/components/MainButton";
import AddProposal from "../../proposal/proposalList/AddProposal";
import { AlertDialogue } from "src/components/AlertDialogue";
import { onGetValidateEstimateProposal } from "src/services/proposal.service";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";

interface Props {
  estimateId?: number;
  isModify: boolean;
  onSaveEstimate: (onSaved?: () => void) => void;
  savingEstimate: boolean;
}
export function PreviewButton(props: Props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isAlready, setIsAlready] = useState(false);
  const [proposalId, setProposalId] = useState(0);
  const [openCreateProposal, setOpenCreateProposal] = useState(false);
  const [withoutSave, setWithoutSave] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetValidateEstimateProposal(props.estimateId ?? 0).then((res) => {
      if (res?.data !== null) {
        setIsAlready(true);
        setProposalId(res?.data);
      } else {
        setIsAlready(false);
      }
      setLoading(false);
    });
  }, []);

  if (props.isModify === true) {
    return (
      <Dialog open={withoutSave} onOpenChange={setWithoutSave}>
        <DialogTrigger asChild>
          {
            <MainButton
              loading={loading || props.savingEstimate}
              disabled={!props.estimateId}
              variant={"secondary"}
              className="ml-2 mr-2 text-white"
              type="button"
              icon={HiEye}
              label="Preview"
            />
          }
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <div className="grid gap-4 py-4">
            <DialogHeader>
              <DialogTitle>Estimate Modified</DialogTitle>
              <DialogDescription>
                You have modified something without saving, would you like to
                save?
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter className="flex ">
            <DialogClose>
              <MainButton
                onClick={() => null}
                variant={"outline"}
                label="Cancel"
                type="button"
              />
            </DialogClose>
            <div className="flex">
              <MainButton
                loading={props.savingEstimate}
                onClick={() => {
                  props.onSaveEstimate(() => {
                    setWithoutSave(false);
                    if (isAlready === true) {
                      navigate(
                        `/proposal-details/${proposalId}/${false}/${
                          props.estimateId
                        }`
                      );
                    }
                  });
                }}
                variant={"default"}
                label={"Save"}
                type="button"
              />
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <AddProposal
        estimateId={props.estimateId ?? 0}
        onOpen={setOpenCreateProposal}
        open={openCreateProposal}
        onCreated={(proposalTemplate) => {
          setIsAlready(true);
          navigate(
            `/proposal-details/${proposalTemplate.id}/${false}/${
              props.estimateId
            }`
          );
        }}
      />
      {isAlready ? (
        <MainButton
          loading={loading}
          disabled={!props.estimateId}
          variant={"secondary"}
          className="ml-2 mr-2 text-white"
          type="button"
          icon={HiEye}
          onClick={() => {
            navigate(
              `/proposal-details/${proposalId}/${false}/${props.estimateId}`
            );
          }}
          label="Preview"
        />
      ) : (
        <AlertDialogue
          renderButton={
            <MainButton
              loading={loading}
              disabled={!props.estimateId}
              variant={"secondary"}
              className="ml-2 mr-2 text-white"
              type="button"
              icon={HiEye}
              label="Preview"
            />
          }
          title={"No proposal"}
          description={
            "This estimate is not proposal available yet please create proposal first before you are going to preview."
          }
          onContinue={() => {
            setOpenCreateProposal(true);
          }}
          continueLabel={"Create"}
        />
      )}
    </>
  );
}
