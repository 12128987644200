import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { COLORS } from "src/assets/colors";
import { Checkbox } from "src/components/CheckBox";
import { TableCell } from "src/components/ui/table";

interface Props {
  value: any;
  onSave: (v: any, onSuccess: () => void) => void;
}

export const ValueCheckBox = (props: Props) => {
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCheck(props.value);
  }, [props.value]);

  const onUpdate = (v: boolean) => {
    setLoading(true);
    props.onSave(v, () => {
      setCheck(v);
      setLoading(false);
    });
  };

  return (
    <TableCell className="border-r-[0.9px] p-0 px-[19px]">
      {loading ? (
        <Loader2 color={COLORS.primary} className="h-4 w-4 animate-spin" />
      ) : (
        <Checkbox
          value={check}
          onChange={(c) => {
            onUpdate(c);
          }}
        />
      )}
    </TableCell>
  );
};
