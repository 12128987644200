import { useEffect, useState } from "react";
import { FILE } from "src/interfaces/auth.interface";
import { useFilePicker } from "use-file-picker";
import { FileContent } from "use-file-picker/dist/interfaces";
import { ImageCropper } from "src/components/ImageCropper";
import MainButton from "src/components/MainButton";
import { HiMiniFolderOpen, HiMiniXMark, HiOutlinePhoto } from "react-icons/hi2";
import { IoCloudUploadOutline } from "react-icons/io5";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import { onUploadImageBySection } from "src/services/proposal.service";
import { META_DATA } from "src/interfaces/proposal.interface";
import { DeleteButton } from "src/components/DeleteButton";
import { MainImageViewer } from "src/components/MainImageViewer";

interface Props {
  proposal?: ProposalStore;
  parent: META_DATA;
  child: META_DATA;
  subChild?: META_DATA;
  images: FILE[];
  isReferenceImage?: boolean;
  isOurTeam?: boolean;
  isCover?: boolean;
  isProjectSchedule?: boolean;
}

function UploadProposalFile(props: Props) {
  const [uploadedFiles, setUploadedFiles] = useState<FILE[]>([]);
  const { openFilePicker, filesContent, loading, clear } = useFilePicker({
    accept: [".jpg", ".jpeg", ".png", ".gif"],
    multiple: false,
    readAs: "DataURL",
  });
  const [showCropImage, setShowCropImage] = useState<boolean>(false);
  const [imageCrop, setImageCrop] = useState<FileContent<string> | null>(null);
  const [uploading, setUploading] = useState(false);
  const [openViewImage, setOpenViewImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<FILE>(props.images[0]);

  useEffect(() => {
    setUploadedFiles(props.images);
  }, [props.images]);

  useEffect(() => {
    filesContent.map((value) => {
      setImageCrop(value);
      setShowCropImage(true);
    });
  }, [filesContent]);

  const uploadImage = async (base64: string) => {
    if (!props.proposal?.sectionDetail?.id) return;
    setUploading(true);
    var newImage = await onUploadImageBySection(
      props.proposal?.sectionDetail?.id,
      { id: imageCrop?.name, base64: base64 }
    );
    if (!newImage) {
      setUploading(false);
      setShowCropImage(false);
      return;
    }
    if (newImage.id) {
      if (props.isReferenceImage) {
        const childData4Add = {
          key: `image_${newImage.id}`,
          type: "image",
          label: "",
          sort: props.child.children.length + 1,
          value: `${newImage.id}`,
          children: [],
        };
        props.proposal.onUpdateReferenceImagePayload4UploadImage(
          props.parent,
          props.child,
          childData4Add,
          () => {
            props.proposal?.onUpdateSection(() => {
              setUploading(false);
              setShowCropImage(false);
            });
          }
        );
      } else if (props.isOurTeam) {
        const childData4Update = {
          ...props.child,
          type: "image",
          value: `${newImage.id}`,
        };

        props.proposal.onUpdateOurTeamImagePayload(
          props.parent,
          childData4Update,
          () => {
            props.proposal?.onUpdateSection(() => {
              setUploading(false);
              setShowCropImage(false);
            });
          }
        );
      } else if (props.isCover) {
        if (
          props.parent.children.length === 0 &&
          props.parent.type === "upload"
        ) {
          if (props.parent.key === "Upload LOGO") {
            const childData4Update = {
              key: "coporate_logo",
              value: `${newImage.id}`,
              label: "coporate_logo",
              type: "image",
              sort: 1,
              children: [],
            };
            props.proposal.onUploadPayloadCoverImage(
              props.parent,
              childData4Update,
              () => {
                props.proposal?.onUpdateSection(() => {
                  setUploading(false);
                  setShowCropImage(false);
                });
              }
            );
          } else {
            const childData4Update = {
              key: "bottom_banner",
              value: `${newImage.id}`,
              label: "coporate_image",
              type: "image",
              sort: 1,
              children: [],
            };
            props.proposal.onUploadPayloadCoverImage(
              props.parent,
              childData4Update,
              () => {
                props.proposal?.onUpdateSection(() => {
                  setUploading(false);
                  setShowCropImage(false);
                });
              }
            );
          }
        } else {
          const childData4Update = {
            ...props.child,
            type: "image",
            value: `${newImage.id}`,
          };
          props.proposal.onUploadPayloadCoverImage(
            props.parent,
            childData4Update,
            () => {
              props.proposal?.onUpdateSection(() => {
                setUploading(false);
                setShowCropImage(false);
              });
            }
          );
        }
      } else if (props.isProjectSchedule) {
        if (
          props.parent.children.length === 0 &&
          props.parent.type === "upload"
        ) {
          const childData4Update = {
            key: "image",
            value: `${newImage.id}`,
            label: "",
            type: "image",
            sort: 1,
            children: [],
          };
          props.proposal.onUploadPayloadCoverImage(
            props.parent,
            childData4Update,
            () => {
              props.proposal?.onUpdateSection(() => {
                setUploading(false);
                setShowCropImage(false);
              });
            }
          );
        } else {
          const childData4Update = {
            ...props.child,
            type: "image",
            value: `${newImage.id}`,
          };
          props.proposal.onUploadPayloadCoverImage(
            props.parent,
            childData4Update,
            () => {
              props.proposal?.onUpdateSection(() => {
                setUploading(false);
                setShowCropImage(false);
              });
            }
          );
        }
      } else {
        const childData4Update = {
          ...props.child,
          type: "image",
          value: `${newImage.id}`,
        };
        props.proposal.onUploadPayloadCoverImage(
          props.parent,
          childData4Update,
          () => {
            props.proposal?.onUpdateSection(() => {
              setUploading(false);
              setShowCropImage(false);
            });
          }
        );
      }
    }
  };

  const handleClickImage = (image: FILE) => {
    setSelectedImage(image);
    setOpenViewImage(true);
  };

  return (
    <>
      <MainImageViewer
        open={openViewImage}
        onClose={() => setOpenViewImage(false)}
        selectedImage={selectedImage}
        images={props.images}
      />
      <ImageCropper
        uploading={uploading}
        onUpload={uploadImage}
        onOpen={() => setShowCropImage(false)}
        image={imageCrop?.content ?? ""}
        open={showCropImage}
      />
      {props.isReferenceImage ? (
        <div>
          {props.images.length >= 4 ? (
            <div className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className={`flex justify-center w-full h-24 px-4 transition bg-red-50 border-[1.5px] border-red-500 border-dashed rounded-md appearance-none`}
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <div className="h-10 w-10 rounded-full bg-red-100 flex items-center justify-center">
                        <HiMiniXMark className="text-red-500" size={26} />
                      </div>
                      <div className="text-center">
                        <span className="font-bold text-red-500 text-[13px]">
                          Oops! Cannot upload more
                        </span>
                        <div className="text-red-500 font-light">
                          You can only upload 4 images in a row
                        </div>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => openFilePicker()}
              className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0"
            >
              <div className="relative w-full">
                <div className="items-center justify-center max-w-xl mx-auto">
                  <label
                    className={`flex justify-center w-full h-24 px-4 transition bg-white border-[1.5px] border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none`}
                    id="drop"
                  >
                    <span className="flex items-center space-x-2">
                      <HiMiniFolderOpen
                        className="mr-2 text-gray-500"
                        size={23}
                      />
                      <div className="text-center">
                        <span className="font-bold text-gray-400 text-[13px]">
                          Drop your image here
                        </span>
                        <div className="text-gray-500 font-light">
                          Supports JPG, JPEG, PNG and GIF
                        </div>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-2 flex-wrap">
            {uploadedFiles.map((m) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="relative cursor-pointer"
              >
                <img
                  onClick={() => {
                    handleClickImage(m);
                  }}
                  className=" h-28 w-28 object-cover rounded-lg"
                  src={m.url}
                  alt="Modern building architecture"
                />
                <DeleteButton
                  renderComponent={
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="absolute h-5 w-5 rounded-full bg-black bg-opacity-60 top-[-5px] right-[-3px] flex justify-center items-center cursor-pointer"
                    >
                      <HiMiniXMark className="text-white" size={16} />
                    </div>
                  }
                  isDeleteProposal
                  onAfterdeletedProposal={() => {
                    props.proposal?.onDeleteReferenceImage(
                      props.parent,
                      props.child,
                      m.id
                    );
                  }}
                  id={m.id ?? 0}
                  path={""}
                  deleted={() => {}}
                />
              </div>
            ))}
          </div>
        </div>
      ) : props.isOurTeam ? (
        <div>
          <div
            onClick={() => openFilePicker()}
            className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0"
          >
            <div className="relative w-full">
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className={`flex justify-center w-full h-20 px-4 transition bg-white border-[1.5px] border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none`}
                  id="drop"
                >
                  <span className="flex items-center space-x-2">
                    <HiMiniFolderOpen
                      className="mr-2 text-gray-500"
                      size={21}
                    />
                    <div className="text-center">
                      <span className="font-bold text-gray-400 text-[12px]">
                        Drop your image here
                      </span>
                      <div className="text-gray-500 font-light text-[12px]">
                        Supports JPG, JPEG, PNG and GIF
                      </div>
                    </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex gap-2 flex-wrap">
            {uploadedFiles.map((m) => {
              if (m.url === null) return null;
              return (
                <div
                  onClick={(e) => e.stopPropagation()}
                  key={m.id}
                  className="relative cursor-pointer"
                >
                  <img
                    onClick={() => handleClickImage(m)}
                    className="h-32 w-40 object-cover rounded-lg"
                    src={m.url}
                    loading="lazy"
                    alt="No image"
                  />
                  <DeleteButton
                    renderComponent={
                      <div className="absolute h-5 w-5 rounded-full bg-black bg-opacity-60 top-[-5px] right-[-3px] flex items-center justify-center cursor-pointer">
                        <HiMiniXMark className="text-white" size={16} />
                      </div>
                    }
                    isDeleteProposal
                    onAfterdeletedProposal={() => {
                      props.proposal?.onDeleteOurTeamImage(
                        props.parent,
                        props.child
                      );
                    }}
                    id={m.id ?? 0}
                    path={""}
                    deleted={() => {}}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : props.isCover || props.isProjectSchedule ? (
        <div>
          <div className="my-4 min-w-[30%]">
            {props.parent.children.length === 0 &&
            props.parent.type === "upload" ? (
              <div
                onClick={openFilePicker}
                className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0"
              >
                <div className="relative w-full">
                  <div className="items-center justify-center max-w-xl mx-auto">
                    <label
                      className="flex justify-center w-full h-28 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                      id="drop"
                    >
                      <span className="flex items-center space-x-2">
                        <IoCloudUploadOutline className="w-5 h-5 mr-3" />
                        <div>
                          <span className="font-medium text-gray-600 text-[14px]">
                            Drop your image here
                          </span>
                          <div className="text-gray-400 text-text-[8px]">
                            Supports JPG, JPEG, PNG and GIF
                          </div>
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {uploadedFiles.map((m) => {
                  if (m.url === null) return null;
                  return (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      key={m.id}
                      className="cursor-pointer relative h-64 w-full border-[1px] flex rounded-md"
                    >
                      <img
                        onClick={() => handleClickImage(m)}
                        className="h-64 w-full object-cover rounded-md"
                        src={m.url}
                        loading="lazy"
                        alt="No image"
                      />
                      <DeleteButton
                        renderComponent={
                          <div className="absolute h-5 w-5 rounded-full bg-black bg-opacity-60 top-[-5px] right-[-3px] flex items-center justify-center cursor-pointer">
                            <HiMiniXMark className="text-white" size={16} />
                          </div>
                        }
                        isDeleteProposal
                        onAfterdeletedProposal={() => {
                          props.proposal?.onDeleteCoverImage(
                            props.parent,
                            props.child
                          );
                        }}
                        id={m.id ?? 0}
                        path={""}
                        deleted={() => {}}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {uploadedFiles.length > 0 && uploadedFiles[0].url !== null ? (
            <MainButton
              onClick={openFilePicker}
              className="bg-muted"
              variant={"ghost"}
              label="Change Image"
              icon={HiOutlinePhoto}
              size={18}
            />
          ) : null}
        </div>
      ) : (
        <div>
          <div className="my-4 min-w-[30%]">
            {uploadedFiles.length === 0 || uploadedFiles[0].url === null ? (
              <div
                onClick={openFilePicker}
                className="flex-1 items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0"
              >
                <div className="relative w-full">
                  <div className="items-center justify-center max-w-xl mx-auto">
                    <label
                      className="flex justify-center w-full h-28 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                      id="drop"
                    >
                      <span className="flex items-center space-x-2">
                        <IoCloudUploadOutline className="w-5 h-5 mr-3" />
                        <div>
                          <span className="font-medium text-gray-600 text-[14px]">
                            Drop your image here
                          </span>
                          <div className="text-gray-400 text-text-[8px]">
                            Supports JPG, JPEG, PNG and GIF
                          </div>
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {uploadedFiles.map((m) => {
                  if (m.url === null) return null;
                  return (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      key={m.id}
                      className="cursor-pointer relative h-36 w-44 border-[1px] flex rounded-md"
                    >
                      <img
                        onClick={() => handleClickImage(m)}
                        className="h-36 w-44 object-cover rounded-md"
                        src={m.url}
                        loading="lazy"
                        alt="No image"
                      />
                      {/* <DeleteButton
                        renderComponent={
                          <div className="absolute h-5 w-5 rounded-full bg-black bg-opacity-60 top-[-5px] right-[-3px] flex items-center justify-center cursor-pointer">
                            <HiMiniXMark className="text-white" size={16} />
                          </div>
                        }
                        isDeleteProposal
                        onAfterdeletedProposal={() => { }}
                        id={m.id ?? 0}
                        path={""}
                        deleted={() => { }}
                      /> */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {uploadedFiles.length > 0 && uploadedFiles[0].url !== null ? (
            <MainButton
              onClick={openFilePicker}
              className="bg-muted"
              variant={"ghost"}
              label="Change Image"
              icon={HiOutlinePhoto}
              size={18}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

export default inject("proposal")(observer(UploadProposalFile));
