
export const FIELD_LIST_TYPES = {
    production_rate_unit: {
        key: "production-rate-unit",
        name: "Unit"
    }
}

export const PREPOSITIONS = [
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "nor",
    "yet",
    "so",
    "of",
    "in",
    "on",
    "at",
    "to",
    "for",
    "with",
    "by",
    "from",
    "up",
    "off",
    "into",
    "out",
    "down",
    "/",
    ":",
    "-"
]


export const ALPHABETIC_CHARACTERS = {
    normal: {
        key: "nl",
        name: "Normal Letter",
        value: "none",
    },
    capitalize: {
        key: "tc",
        name: "Title Letter",
        value: "capitalize",
    },
    uppercase: {
        key: "uc",
        name: "Uppercase Letter",
        value: "uppercase",
    },
    lowercase: {
        key: "lc",
        name: "Lowercase Letter",
        value: "lowercase"
    },
}

export const ALPHABETIC_CHARACTERS_ARRAY: any[] = Object.values(ALPHABETIC_CHARACTERS);

export const COMPONENT_TYPE = {
    text: {
        key: "text",
    },
    text_area: {
        key: "text_area",
    },
    rich_text: {
        key: "rich_text",
    },
    phone: {
        key: "phone",
    },
    date: {
        key: "date",
    },
    number: {
        key: "number",
    },
    checkbox: {
        key: "checkbox",
    },
    list: {
        key: "list",
    },
    image: {
        key: "image",
    }
};
