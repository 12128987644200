import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import { Alert, AlertTitle, AlertDescription } from "./ui/alert";
 

 
interface Props {
    title:string;
    message?:string;
    messages?:string[];
}

export function AlertDestructive(props:Props) {
  return (
    <Alert
     className="flex items-start"
     variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <div>
      <AlertTitle className="mt-1">{props.title}</AlertTitle>
      <AlertDescription>{props.message}</AlertDescription>
      {
        props.messages?.map(m => <AlertDescription key = {m}>{m}</AlertDescription>)
      }
      </div>
    </Alert>
  )
}