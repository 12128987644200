import { ContentSubTitle } from "../ContentSubTitle";
import { META_DATA } from "src/interfaces/proposal.interface";
import OurTeamInputItem from "./OurTeamInputItem";

interface Props {
  parent: META_DATA;
  onChnage: (child: META_DATA) => void;
  onAfterDelete: () => void;
}

export const OurTeamSection = (props: Props) => {
  return (
    <div className="mb-8">
      <ContentSubTitle
        title={props.parent.label}
        deleteId={props.parent.id}
        onAfterDelete={props.onAfterDelete}
      />
      <div className="flex">
        <div className="min-w-5 ml-5 h-16 border-l-2 border-b-2 rounded-bl-md" />
        <div className="rounded-sm bg-slate-50 border-[0.8px] p-4 pb-0 mt-3">
          {props.parent.children.map((child) => {
            return (
              <OurTeamInputItem
                key={child.id}
                label={child.label}
                parent={props.parent}
                child={child}
                images={[{ id: child.id, base64: null, url: child.value }]}
                onChange={(v) => {
                  const data = {
                    ...child,
                    value: v,
                  };
                  props.onChnage(data);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
