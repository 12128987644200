import { HiPlusCircle } from "react-icons/hi2";
import MainButton from "../../../components/MainButton";
import SearchInput from "../../../components/SearchInput";
import UpdateEntity from "../../../components/entity/UpdateEntity";

interface Props {
  onAdd?: () => void;
  onChange: (text: string) => void;
  entityCode?: string;
  valueSearch?: string;
}

export default function CrmTopTableHeader(props: Props) {
  return (
    <div className="w-full  h-12 flex justify-between">
      <div className="h-80 w-96">
        <SearchInput
          value={props.valueSearch}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </div>
      <div className="flex">
        {props.onAdd ? (
          <MainButton
            variant={"outline"}
            icon={HiPlusCircle}
            onClick={() => props.onAdd && props.onAdd()}
            type="button"
          />
        ) : null}
        {props.entityCode ? (
          <UpdateEntity entityCode={props.entityCode} />
        ) : null}
      </div>
    </div>
  );
}
