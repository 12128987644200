import { useEffect, useState } from "react";
import { updateUser } from "src/APIs/team.api";
import { AlertDestructive } from "src/components/AlertDestructive";
import { AutoCompleteWithAsync } from "src/components/autoComplete/AutoCompleteWithAsync";
import DatePicker from "src/components/DatePicker";
import InputPhone from "src/components/InputPhone";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import MainTextArea from "src/components/MainTextArea";
import {
  UPDATE_USER_WORKPLACE_PAYLOAD,
  USER,
} from "src/interfaces/init.interface";
import { DEPARTMENT, TEAM } from "src/interfaces/team.interface";
import {
  dateToString4API,
  phoneString,
} from "src/utilities/formatter.utilities";
import { isPhoneValid, nullString } from "src/utilities/functions.utilities";

interface Props {
  user: USER | null;
  updateSuccess: (doc: USER) => void;
}

export default function Workplace(props: Props) {
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [workAD, setWorkAD] = useState<Date | null>(null);
  const [selectedTeam, setTeam] = useState<TEAM | null>(null);
  const [selectedDepartment, setDeparmtnet] = useState<DEPARTMENT | null>(null);
  const [selectedManager, setManager] = useState<USER | null>(null);
  const [dob, setDOB] = useState<Date | null>(null);
  const [phone, setPhone] = useState("");
  const [isErrorPhone, setErrorPhone] = useState(false);
  const [address, setAddress] = useState("");
  const [isErrorAddress, setErrorAddress] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const {
      title,
      birthDate,
      joinedDate,
      phone,
      location,
      teamId,
      teamName,
      departmentId,
      departmentName,
      parentId,
      parentName,
    } = props.user || {};
    const manager: any = {
      id: parentId,
      fullName: parentName,
    };
    const team: any = {
      id: teamId,
      name: teamName,
    };
    const department: any = {
      id: departmentId,
      name: departmentName,
    };
    setTitle(title ?? "");
    if (joinedDate) {
      setWorkAD(joinedDate);
    } else {
      setWorkAD(null);
    }
    if (birthDate) {
      setDOB(birthDate);
    } else {
      setDOB(null);
    }
    setPhone(phone ?? "");
    setAddress(location ?? "");
    setDeparmtnet(department);
    setManager(manager);
    setTeam(team);

    return () => {
      setWorkAD(null);
      setDOB(null);
      setPhone("");
      setAddress("");
      setDeparmtnet(null);
      setManager(null);
      setTeam(null);
      setErrorAddress(false);
      // setErrorPhone(false);
    };
  }, [props.user]);

  function isValid() {
    if (phone.length > 0) {
      const isValidPhone = isPhoneValid(phone);
      setErrorPhone(!isValidPhone);
      if (!isValidPhone) setErrorMsg(["Phone number is invalid."]);
      return isValidPhone;
    } else {
      setErrorMsg([]);
      return true;
    }
  }

  const onSave = () => {
    if (isValid()) {
      setProcessing(true);
      const doc: UPDATE_USER_WORKPLACE_PAYLOAD = {
        id: props.user?.id,
        title: nullString(title ?? ""),
        phone: phoneString(phone ?? ""),
        location: nullString(address ?? ""),
        teamId: nullString(selectedTeam?.id ?? ""),
        parentId: selectedManager?.id ? selectedManager?.id : null,
        departmentId: nullString(selectedDepartment?.id ?? ""),
        birthDate: dob ? dateToString4API(dob) : null,
        joinedDate: workAD ? dateToString4API(workAD) : null,
        firstName: props.user?.firstName,
        lastName: props.user?.lastName,
      };
      updateUser(doc, "/edit-profile").then((doc) => {
        setProcessing(false);
        if (doc?.code !== "200") return;
        props.updateSuccess(doc?.data);
      });
    }
  };

  return (
    <div className="isolate bg-white px-6  lg:px-8">
      {errorMsg.length > 0 ? (
        <AlertDestructive messages={errorMsg} title="Missing Informations" />
      ) : null}
      <div className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <MainInput
              value={title}
              type="text"
              placeholder="Enter title"
              onChange={(text) => setTitle(text)}
              label="Title"
            />
          </div>
          <div className="sm:col-span-2">
            <DatePicker
              value={workAD}
              placeholder="Enter date"
              onDate={(date) => setWorkAD(date)}
              label="Work anniversary"
            />
          </div>
          <div className="sm:col-span-2">
            <AutoCompleteWithAsync
              path="team"
              value={selectedTeam?.name}
              placeholder="Choose team"
              onSelectedValue={(team) => setTeam(team)}
              label="Team"
            />
          </div>
          <div className="sm:col-span-2">
            <AutoCompleteWithAsync
              path="department"
              value={selectedDepartment?.name}
              placeholder="Choose department"
              onSelectedValue={(department) => setDeparmtnet(department)}
              label="Department"
            />
          </div>
          <div className="sm:col-span-2">
            <AutoCompleteWithAsync
              path="user"
              value={selectedManager?.fullName}
              placeholder="Choose manager"
              onSelectedValue={(m) => setManager(m)}
              label="Manager"
            />
          </div>
          <div className="sm:col-span-2">
            <DatePicker
              value={dob}
              placeholder="Choose date"
              onDate={(date) => setDOB(date)}
              label="Birthdate"
            />
          </div>
          <div className="sm:col-span-2">
            <InputPhone
              value={null}
              placeholder="Choose date"
              onChange={(text) => setPhone(text)}
              label="Phone number"
            />
          </div>
          <div className="sm:col-span-2">
            <MainTextArea
              value={address}
              placeholder="Enter address"
              onChange={(text) => setAddress(text)}
              label="Address"
            />
          </div>
        </div>

        <div className="mt-10">
          <MainButton
            loading={processing}
            className="text-white"
            type="button"
            onClick={() => onSave()}
            label="Save Changes"
          />
        </div>
      </div>
    </div>
  );
}
