import { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { numberOnly } from "src/utilities/functions.utilities";

interface Props {
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  onChange?: (t: string) => void;
  value: string | null;
  disabled?: boolean;
  hideBorder?: boolean;
  extendClass?: string;
  prefix?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  focus?: boolean;
  noMarginTop?: boolean;
  active?: boolean;
  onClick?: any;
  error?: boolean;
  disableEdit?: boolean;
  onEnter?: (text: string) => void;
  id?: string;
  isNumber?: boolean;
  maxLength?: number
}

export default function MainInput(props: Props) {
  const [isFocused, setFocus] = useState(false);
  const [text, setText] = useState("");
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value, isFocused]);

  useEffect(() => {
    if (isFocused) {
      ref?.current?.select();
      if(!props.focus) return;
      const timer =  setTimeout(() => {
        if (ref.current) {
          const length = ref.current.value.length;
          ref.current.focus();
          ref.current.setSelectionRange(length , length);
          ref.current.scrollBy({left:10000000})
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isFocused]);

  return (
    <div className={props.label ? "mt-4 w-full mb-1" : ""}>
      {props.label ? (
        <Label>
          {props.label}
          {props.required ? <span className="text-destructive">*</span> : null}
        </Label>
      ) : null}
      <Input
        ref={ref}
        onClick={props.onClick}
        autoFocus={props.focus}
        onBlur={(e) => {
          setFocus(false);
          props.onBlur && props.onBlur(e);
          props.onChange && props.onChange(text);
        }}
        onFocus={(e) => {
          setFocus(true);
        }}
        maxLength={props.maxLength}
        id={props.id}
        disabled={!props.onChange || props.disabled}
        value={
          isFocused
            ? text
            : props.prefix
            ? `${props.prefix}${text || ""}`
            : text || ""
        }
        onChange={(e) => {
          if (props.disableEdit) return;
          if (props.isNumber) {
            setText(numberOnly(e.target.value, true));
            return;
          }
          setText(e.target.value);
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            props.onChange && props.onChange(text);
            props.onEnter && props.onEnter(text);
          }
        }}
        type={props.type}
        className={`w-full ${props.noMarginTop ? "" : "mt-2"} ${
          props.active
            ? "border border-secondary"
            : props.hideBorder
            ? "border-transparent shadow-transparent"
            : ""
        } ring-0 outline-none bg-white `.concat(props.extendClass ?? "")}
        placeholder={props.placeholder ?? `Enter`}
      />
    </div>
  );
}
