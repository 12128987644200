import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { AlertDestructive } from "src/components/AlertDestructive";
import { AutoCompleteStatic } from "src/components/autoComplete/AutoCompleteStatic";
import { AutoCompleteWithAsync } from "src/components/autoComplete/AutoCompleteWithAsync";
import { Checkbox } from "src/components/CheckBox";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import MainTextArea from "src/components/MainTextArea";
import NumberInput from "src/components/NumberInput";
import TableStore from "src/components/tableComponents/TableStore";
import { Card } from "src/components/ui/card";
import { DrawerTitle } from "src/components/ui/drawer";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";
import { PRODUCT, PRODUCT_PAYLOAD } from "src/interfaces/product.interface";
import { DEPARTMENT_SELECT } from "src/interfaces/team.interface";
import AuthStore from "src/stores/auth.store";
import { nullString } from "src/utilities/functions.utilities";
import { ProductHistory } from "./ProductHistory";
import { createProduct, updateProduct } from "src/APIs/product.api";

interface Props {
  auth?: AuthStore;
  product: PRODUCT | null;
  open: boolean;
  updateProduct: (prd: PRODUCT) => void;
  table?: TableStore;
  addedProduct?:(prd:PRODUCT) => void
}
function AddProductForm(props: Props) {
  const { productTypes } = props.auth ?? {};
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState<PRODUCT_TYPE | null>(null);
  const [department, setDepartment] = useState<DEPARTMENT_SELECT | null>(null);

  const [description, setDescription] = useState("");
  const [enable, setEnable] = useState(true);
  const [taxIncluded, setTax] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  useEffect(() => {
    if (props.open && props.product) {
      const {
        name,
        price,
        productType,
        department,
        description,
        enable,
        taxIncluded,
      } = props.product ?? {};
      setName(name);
      setPrice(price.toString());
      setDepartment(department);
      setEnable(enable);
      setTax(taxIncluded);
      setType(productType);
      setDescription(description ?? "");
    }
    if (!props.open) {
      setName("");
      setPrice("");
      setType(null);
      setDepartment(null);
      setDescription("");
      setEnable(false);
      setTax(false);
    }
  }, [props.open, props.product]);

  const onSave = async () => {
    var message:string[] = [];
    if (!type) {
      message = message.concat("Please choose product type.");
    }
    if(!name){
      message = message.concat("Please enter product name.");
    }
    setErrorMessage(message);
    if(message.length > 0) return;
    setErrorMessage([]);
    setProcessing(true);
    const data: PRODUCT_PAYLOAD = {
      name,
      description: nullString(description ?? ""),
      price: Number(price),
      taxIncluded: taxIncluded,
      enable: enable,
      departmentId: department?.id ?? null,
    };
    if(!type) return;
    const res = props.product?.id
      ? await updateProduct(data, type, props.product.id)
      : await createProduct(data, type);
    if (res !== null) {
      if(props.addedProduct){
        props.addedProduct(res?.data);
        setProcessing(false);
        return;
      }
      if (props.product?.id) {
        props.table?.updateData(res.data);
      } else {
        props.table?.updateTable(res.data);
      }
      props.updateProduct(res.data);
    }
    setProcessing(false);
  };

  return (
    <Card className="flex flex-1 p-4 flex-col flex-grow-0">
      <div className="flex items-center">
        <DrawerTitle className="text-lg mb-2">General Information</DrawerTitle>
        {props.product?.id && props.open ? (
          <div>
            <ProductHistory product={props.product} />
          </div>
        ) : null}
      </div>
      {errorMessage.length > 0 ? (
        <AlertDestructive title="Missing Informations" messages={errorMessage} />
      ) : null}
      <div className="flex gap-4">
        <MainInput
          required
          value={name}
          placeholder="Enter product name"
          onChange={(text) => setName(text)}
          label="Product Name"
        />
        <AutoCompleteStatic
         disablePortal
          required
          placeholder="Choose product type"
          onSelectedValue={(value) => setType(value)}
          label="Product Type"
          value={type}
          data={productTypes ?? []}
        />
      </div>
      <div className="flex gap-4">
        <AutoCompleteWithAsync
          placeholder="Choose department"
          onSelectedValue={(value: DEPARTMENT_SELECT) => {
            setDepartment(value);
            setPrice(`${value?.price ?? 0}`);
          }}
          label="Department"
          value={department}
          path="department"
        />
        <NumberInput
          value={price}
          placeholder="Enter price"
          onChange={(text) => setPrice(text)}
          label="Price($)"
        />
      </div>
      <MainTextArea
        placeholder="Enter description"
        value={description}
        onChange={(text) => setDescription(text)}
        label="Description"
      />
      <div className="flex gap-4 mt-4">
        <Checkbox
          value={enable}
          label="Enable"
          onChange={() => setEnable(!enable)}
        />
        <Checkbox
          value={taxIncluded}
          label="Tax Included"
          onChange={() => setTax(!taxIncluded)}
        />
      </div>
      <div className="w-44 self-end mt-6">
        <MainButton
          loading={processing}
          type="submit"
          onClick={() => onSave()}
          label="Save"
        />
      </div>
    </Card>
  );
}

export default inject("auth", "table")(observer(AddProductForm));
