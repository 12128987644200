import { COMPONENT_TYPE } from "src/constants";
import { META_DATA } from "src/interfaces/proposal.interface";
import { ContentTitle } from "../ContentTitle";
import { DynamicInput } from "../dynamicComponent/DynamicInput";
import UploadFileForProposal from "../UploadProposalFile";

interface Props {
  parent: META_DATA;
  onChange: (child: META_DATA) => void;
}

export const CoverSection = (props: Props) => {
  return (
    <div className="mb-8">
      <ContentTitle title={props.parent?.label} />
      {props.parent.type === "upload" && props.parent.children.length === 0 ? (
        <UploadFileForProposal
          isCover
          key={props.parent.id}
          parent={props.parent}
          child={props.parent.children[0]}
          images={[]}
        />
      ) : null}
      {props.parent.children.map((child) => {
        if (child.type === COMPONENT_TYPE.image.key) {
          return (
            <UploadFileForProposal
              isCover
              key={child.id}
              parent={props.parent}
              child={child}
              images={[{ id: child.id, base64: null, url: child.value }]}
            />
          );
        }
        return (
          <DynamicInput
            key={child.id}
            onChange={(text) => {
              const data = {
                ...child,
                value: text,
              };
              props.onChange(data);
            }}
            meta={child}
          />
        );
      })}
    </div>
  );
};
