import { TableRow, TableCell } from "@mui/material";
import React from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import { displayDate, formatMoney } from "src/utilities/formatter.utilities";
import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";

interface Props {
  items: any[];
  name: string;
  isMain?: boolean;
  auth?: AuthStore;
}

export const COLUMNS: any[] = [
  {
    accessorKey: "productType",
    header: "Item Type",
    maxSize: 70,
  },
  {
    accessorKey: "qty",
    header: "Quantity",
    maxSize: 35,
  },
  {
    accessorKey: "total",
    header: "Total",
    maxSize: 70,
  },
  {
    accessorKey: "subtotalWithMarkup",
    header: "Total With Markup",
    maxSize: 120,
  },
  {
    accessorKey: "egp",
    header: "Estimate Profit",
    maxSize: 70,
  },
];


function SummaryRow(props: Props) {
  return (
    <React.Fragment>
      <TableRow
        style={{ backgroundColor: COLORS.white }}
      >
        <TableCell colSpan={COLUMNS.length} />
      </TableRow>
      <TableRow
        style={{ backgroundColor: props.isMain ? COLORS.primary : COLORS.secondary }}
      >
        <TableCell colSpan={COLUMNS.length}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ color: props.isMain ? COLORS.white : COLORS.text,   textTransform: "uppercase" }}>
              {props.name}
            </h4>
          </div>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: COLORS.input_background }}>
        {COLUMNS.map((column, index) => {
          return (
            <TableCell
              key={column.id}
              style={{
                backgroundColor: COLORS.background_primary,
                maxWidth: column.maxSize,
                textTransform: "uppercase"
              }}
            >
              {column.header}
            </TableCell>
          );
        })}
      </TableRow>
      {props.items.map((m, ind) => {
        const isBold = m.isBold;
        return (
          <TableRow
            style={{
              backgroundColor: isBold ? COLORS.background_primary : COLORS.white,
            }}
            key={m?.id}
          >
            {COLUMNS.map((cell: any, index) => {
              return (
                <TableCell
                  style={{
                    fontWeight: isBold ? "bolder" : "normal",
                    maxWidth: cell.maxSize,
                    paddingLeft: index === 0 ? 30 : 15,
                    paddingTop: LAYOUTS.padding,
                    paddingBottom: LAYOUTS.padding,
                  }}
                  key={cell.id}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {renderValue(cell.accessorKey, m, props.auth?.dateFormat)}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </React.Fragment>
  );
}

function renderValue(field: string, data: any, pattern?: string) {
  const fields = field.split(".");
  const value = fields.length > 1 ? fieldData(field, data) : data[field];
  if (
    field === "createdAt" ||
    field === "updatedAt" ||
    field === "joinedDate"
  ) {
    return displayDate(value, pattern);
  } else if (field === "profilePictureUrl") {
    return data;
  } else if (field === "enable") {
    return value ? "Active" : "Inactive";
  } else if (
    field === "cost" ||
    field === "price" ||
    field === "costAfterMarkup"
  ) {
    return `$${formatMoney(value)}`;
  } else if (field === "entityConfigurations") {
    const columns = value.map((m: any) => m.displayName).join(", ");
    return columns;
  } else if (
    field === "qty"
  ) {
    return value === "" ? "" : value ? value?.toString() : "0";
  } else {
    return value === "" ? "" : value ? value?.toString() : "$0.00";
  }
}

function fieldData(fields: string, data: any) {
  return fields.split(".").reduce((o, d) => o[d], data);
}

export default inject("auth")(observer(SummaryRow));


