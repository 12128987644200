import { CATALOGUE } from "src/interfaces/estimate.interface";
import { getAPI, patchAPI, postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";
import {
  CATALOGUE_PAYLOAD,
  PRODUCTION_RATE_PAYLOAD,
  PRODUCT_PAYLOAD,
} from "src/interfaces/product.interface";
import { PRODUCT_TYPE } from "src/interfaces/init.interface";

export async function catalogueByProduct(productId: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/product/${productId}/get-catalog`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function catalogueImage(catalog: CATALOGUE, id: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog/${catalog.id}/get-image/${id}`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function createProduct(
  product: PRODUCT_PAYLOAD,
  productType: PRODUCT_TYPE
) {
  const url = `${process.env.REACT_APP_MAIN_API}/product?type=${productType?.id}`;
  const data = await postAPI(url, product);
  return data as RESPONSE;
}

export async function updateProduct(
  product: PRODUCT_PAYLOAD,
  productType: PRODUCT_TYPE,
  id: number
) {
  const url = `${process.env.REACT_APP_MAIN_API}/product/${id}?type=${productType?.id}`;
  const data = await putAPI(url, product);
  return data as RESPONSE;
}

export async function productHistory(id: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/product/${id}/get-product-change`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function catalogueHistory(id: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog/${id}/get-catalog-change`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function createCatalogue(
  catalog: CATALOGUE_PAYLOAD,
  productId: number
) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog?product-id=${productId}`;
  const data = await postAPI(url, catalog);
  return data as RESPONSE;
}

export async function updateCatalogue(catalog: CATALOGUE_PAYLOAD, id: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog/${id}`;
  const data = await putAPI(url, catalog);
  return data as RESPONSE;
}

export async function createProductionRate(rate: PRODUCTION_RATE_PAYLOAD) {
  const url = `${process.env.REACT_APP_MAIN_API}/production-rate`;
  const data = await postAPI(url, rate);
  return data as RESPONSE;
}

export async function updateProductionRate(
  rate: PRODUCTION_RATE_PAYLOAD,
  id: number
) {
  const url = `${process.env.REACT_APP_MAIN_API}/production-rate/${id}`;
  const data = await putAPI(url, rate);
  return data as RESPONSE;
}

export async function updateDefaultProductionRate(id: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/production-rate/${id}/set-default`;
  const data = await patchAPI(url, null);
  return data as RESPONSE;
}

export async function updateDefaultCatalogue(id: number) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog/${id}/set-default`;
  const data = await patchAPI(url, null);
  return data as RESPONSE;
}

export async function updateEnableCatalog(id: number, value: boolean) {
  const url = `${process.env.REACT_APP_MAIN_API}/catalog/${id}/set-enable?enable=${value}`;
  const data = await putAPI(url, null);
  return data as RESPONSE;
}

export async function updateEnableProductionRate(id: number, value: boolean) {
  const url = `${process.env.REACT_APP_MAIN_API}/production-rate/${id}/set-enable?enable=${value}`;
  const data = await putAPI(url, null);
  return data as RESPONSE;
}
