import { useLayoutEffect, useRef, useState } from "react";
import MainTable from "src/components/tableComponents/MainTable";
import { CATALOGUE } from "src/interfaces/estimate.interface";
import { inject, observer } from "mobx-react";
import TableStore from "src/components/tableComponents/TableStore";
import AddProduct from "../productList/AddProduct";
import { CATALOG_ENTITY } from "src/constants/static_entity.constants";

interface Props {
  table?: TableStore;
}

function CatalogueListPage(props: Props) {
  const [openProduct, setOpenProduct] = useState(false);
  const [selected, setSelected] = useState<CATALOGUE | null>(null);
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        addLabel="Add"
        onAdd={() => setOpenProduct(true)}
        height={height}
        onClick={(row: CATALOGUE) => {
          setSelected(row);
          setOpenProduct(true);
        }}
        entityCode={CATALOG_ENTITY.code}
        path="catalog"
      />
      <AddProduct
        isFromChild
        selectedData={selected}
        product={null}
        onOpen={(value) => {
          setOpenProduct(value);
          if (!value) {
            setSelected(null);
          }
        }}
        open={openProduct}
      />
    </div>
  );
}

export default inject("table")(observer(CatalogueListPage));
