import axios, { AxiosError } from "axios";
import { RESPONSE, errorHandler } from "./error_handler";
import { getAuth } from "src/utilities/storage.utilities";
import { AUTH, FILE } from "src/interfaces/auth.interface";
import { base64ToBlob } from "base64-blob";
import { toast } from "react-toastify";
import { IoWarning } from "react-icons/io5";
import dayjs from "dayjs";
import { refreshToken } from "./auth.api";
import { getDeviceInfo } from "src/utilities/functions.utilities";
import { jsonToBase64 } from "src/utilities/mapping.utilities";

export async function postAPI(
  url: string,
  body: any,
  signal?: AbortSignal,
  noPopUp?: boolean
) {
  try {
    const res = await axios.post(url, JSON.stringify(body), {
      headers: await apiHeaders(),
      signal: signal,
    });
    if (!noPopUp) {
      successPopUp();
    }
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      let result: any = {};
      result["code"] = "503";
      result["message"] = "No internet connection!";
      toast("No internet connection!", {
        toastId: "no_internet_connection",
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        autoClose: false,
      });
      return result;
    }
    if (axios?.isCancel(error)) {
      console.error(">>>>>> REQUESE CANCELLED");
      return;
    }
    return errorHandler(error?.response?.data);
  }
}

export async function getAPI(url: string, signal?: AbortSignal) {
  const auth: AUTH | null = getAuth();
  if (!auth) return null;
  try {
    const res = await axios.get(url, {
      headers: await apiHeaders(),
      signal: signal,
    });
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      let result: any = {};
      result["code"] = "503";
      result["message"] = "No internet connection!";
      toast("No internet connection!", {
        toastId: "no_internet_connection",
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        autoClose: false,
      });
      return result;
    }
    if (axios?.isCancel(error)) {
      console.error(">>>>>> REQUESE CANCELLED");
      return;
    }
    return errorHandler(error?.response?.data || error?.response);
  }
}

export async function putAPI(
  url: string,
  body: any,
  signal?: AbortSignal,
  noPopUp?: boolean
) {
  try {
    const res = await axios.put(url, JSON.stringify(body), {
      headers: await apiHeaders(),
      signal: signal,
    });
    if (!noPopUp) {
      successPopUp();
    }
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      let result: any = {};
      result["code"] = "503";
      result["message"] = "No internet connection!";
      toast("No internet connection!", {
        toastId: "no_internet_connection",
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        autoClose: false,
      });
      return result;
    }
    if (axios?.isCancel(error)) {
      console.error(">>>>>> REQUESE CANCELLED");
      return;
    }
    return errorHandler(error?.response?.data);
  }
}

export async function patchAPI(url: string, body: any, signal?: AbortSignal) {
  try {
    const res = await axios.patch(url, body, {
      headers: await apiHeaders(),
      signal: signal,
    });
    successPopUp();
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      let result: any = {};
      result["code"] = "503";
      result["message"] = "No internet connection!";
      toast("No internet connection!", {
        toastId: "no_internet_connection",
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        autoClose: false,
      });
      return result;
    }
    if (axios?.isCancel(error)) {
      console.error(">>>>>> REQUESE CANCELLED");
      return;
    }
    return errorHandler(error?.response?.data);
  }
}

export async function deleteAPI(url: string, signal?: AbortSignal) {
  try {
    const res = await axios.delete(url, {
      headers: await apiHeaders(),
      signal: signal,
    });
    successPopUp();
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      let result: any = {};
      result["code"] = "503";
      result["message"] = "No internet connection!";
      toast("No internet connection!", {
        toastId: "no_internet_connection",
        type: "error",
        position: "top-center",
        hideProgressBar: false,
        autoClose: false,
      });
      return result;
    }
    if (axios?.isCancel(error)) {
      console.error(">>>>>> REQUESE CANCELLED");
      return;
    }
    return errorHandler(error?.response?.data);
  }
}

export async function postApiFormData(url: string, body: FILE) {
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("file", await base64ToBlob(body.base64 ?? ""), body.id);
    const res = await axios.post(url, bodyFormData, {
      headers: await apiFormDataHeaders(),
    });
    return res;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        // Request made but the server responded with an error
        throw error;
      } else if (error.request) {
        // Request made but no response is received from the server.
        toast("Unable to connect to server", {
          toastId: "postApiFormData",
          icon: IoWarning,
          type: "error",
          theme: "colored",
          position: "top-center",
          hideProgressBar: true,
        });
        throw "Unable to connect to server";
      } else {
        toast("Error setting up request", {
          toastId: "postApiFormData",
          icon: IoWarning,
          type: "error",
          position: "top-center",
          hideProgressBar: true,
        });
        throw "Error setting up request";
      }
    } else {
      toast("Error setting up request", {
        toastId: "postApiFormData",
        icon: IoWarning,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
      throw "Error setting up request";
    }
  }
}

async function apiFormDataHeaders() {
  const token = await getToken();
  if (!token) return;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  return headers;
}

export async function apiHeaders() {
  const token = await getToken();
  if (!token) return;
  const deviceInfo = getDeviceInfo();
  const info = {
    deviceId: deviceInfo.deviceID,
    deviceType: deviceInfo.deviceType,
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    deviceInfo: jsonToBase64(info),
  };
  return headers as any;
}

export async function apiHeadersLogout() {
  const auth = getAuth();
  const deviceInfo = getDeviceInfo();
  if (!auth?.token) return;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${auth?.token}`,
    deviceInfo: {
      deviceId: deviceInfo.deviceID,
      deviceType: deviceInfo.deviceType,
    },
  };
  return headers;
}

const successPopUp = () => {
  return toast("Success", {
    toastId: "success",
    position: "top-center",
    type: "success",
    hideProgressBar: true,
  });
};

const errorPopUp = () => {
  toast("Error", {
    toastId: "error",
    type: "error",
    position: "top-center",
    hideProgressBar: true,
  });
};

async function getToken() {
  const auth = getAuth();
  const currentDate = dayjs().unix();
  if (
    auth?.tokenExpired &&
    currentDate > auth?.tokenExpired &&
    auth?.refreshTokenExpired &&
    currentDate < auth?.refreshTokenExpired
  ) {
    return ((await refreshToken()) as any).token;
  }
  return auth?.token;
}

export async function getGeoapifyAddress(search: string, type?: string) {
  try {
    const apiKey = "930c2f4e849942d9a681774f23403046";
    var url = type
      ? `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&apiKey=${apiKey}&type=${type}`
      : `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&apiKey=${apiKey}`;
    const res = await axios.get(url);
    return res.data.features;
  } catch (error) {
    return;
  }
}
