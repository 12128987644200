import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { BiMessageRoundedAdd, BiMessageRoundedDots } from "react-icons/bi";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import RichTextEditorWithMention from "src/components/richTextWithMention";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { htmlToText } from "html-to-text";

interface Props {
  value: string;
  onChange: (text: string) => void;
}
export function GroupDescription(props: Props) {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(value);
  }, [props.value]);

  const renderDescription = (text: string) => {
    if (text.length === 0) return "";
    const texts = text.split(/\r|\r\n|\n/);
    return texts.map((m) => {
      return <p>{m.length === 0 ? " " : m}</p>;
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Tooltip title={renderDescription(htmlToText(props.value))}>
          <div>
            {value ? (
              <BiMessageRoundedDots
                className={`ml-2 hover:opacity-80 cursor-pointer text-secondary text-2xl`}
              />
            ) : (
              <BiMessageRoundedAdd
                className={`ml-2 hover:opacity-80 cursor-pointer text-2xl text-muted-foreground`}
              />
            )}
          </div>
        </Tooltip>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[560px]">
        <DialogHeader>
          <div className="flex justify-between items-center">
            <DialogTitle>Enter Description</DialogTitle>
            <CloseDrawerButton />
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-2 pt-0">
          <RichTextEditorWithMention
            isFocus
            description={props.value}
            isError
            onChange={(value) => setValue(value)}
          />
        </div>
        <DialogFooter className="flex">
          <DialogClose>
            <Button onClick={() => props.onChange(value)} type="submit">
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
