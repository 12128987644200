import { HTMLInputTypeAttribute } from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

interface Props {
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  onChange?: (t: string) => void;
  value: string | null;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  focus?: boolean;
  onClick?: any;
  onEnter?: () => void;
  id?: string;
  className?: string | undefined;
}

export default function MainInputNoState(props: Props) {
  return (
    <div className={props.label ? "mt-4 w-full mb-1" : ""}>
      {props.label ? (
        <Label>
          {props.label}
          {props.required ? <span className="text-destructive">*</span> : null}
        </Label>
      ) : null}
      <Input
        onClick={props.onClick}
        autoFocus={props.focus}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
        }}
        id={props.id}
        disabled={!props.onChange || props.disabled}
        value={props.value ?? ""}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            props.onEnter && props.onEnter();
          }
        }}
        type={props.type}
        className={`${props.label ? "mt-2" : ""} ${props.className}`}
        placeholder={props.placeholder ?? `Enter`}
      />
    </div>
  );
}
