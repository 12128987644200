import { Outlet } from "react-router-dom";
import UdfNavigationBar from "./UdfNavigationBar";

export default function UserDefinedLayout() {
  return (
    <div className="flex">
      <UdfNavigationBar />
      <div className="h-screen w-full bg-white">
        <Outlet />
      </div>
    </div>
  );
}
