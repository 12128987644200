import {
  HiMiniArrowRightCircle,
  HiOutlineXMark,
  HiTrash,
} from "react-icons/hi2";
import { AlertDialogue } from "src/components/AlertDialogue";
import MainButton from "src/components/MainButton";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";

interface Props {
  selectedItems: GROUP_OR_ITEM[];
  onCancel: () => void;
  onMove: () => void;
  onDelete: () => void;
}
export default function PopUpSelectedItems(props: Props) {
  return (
    <div
      className={`flex  sticky bg-white drop-shadow-lg bottom-0 mt-9 rounded-md transition-all overflow-hidden mx-20 justify-between z-50 ${
        props.selectedItems.length > 0 ? "h-14 border" : "h-0 border-0"
      }`}
    >
      <div className="bg-secondary p-2 text-white font-bold min-w-28 flex items-center justify-center text-2xl">
        {props.selectedItems.length}
      </div>
      <div className="flex justify-between items-center gap-2">
        <MainButton
          icon={HiMiniArrowRightCircle}
          label="Move Items"
          onClick={() => props.onMove()}
        />
        <AlertDialogue
          description="Are you sure to remove all these items?"
          title="Remove Items"
          onContinue={() => props.onDelete()}
          renderButton={
            <MainButton
              icon={HiTrash}
              variant={"destructive"}
              label="Remove Items"
              onClick={() => null}
            />
          }
          continueLabel={"Remove"}
          variant={"destructive"}
        />
      </div>
      <div className="flex justify-center items-center p-4">
      <div
       onClick={() => props.onCancel()}
       className="rounded-full bg-slate-100 p-2 cursor-pointer drop-shadow-sm w-10 h-10 flex justify-center items-center">
              <HiOutlineXMark size={26} />
            </div>
      </div>
    </div>
  );
}
