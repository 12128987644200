import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "src/components/ui/dialog";
import { DialogHeader, DialogFooter } from "src/components/ui/dialog";
import MainButton from "src/components/MainButton";
import { HiMiniClock } from "react-icons/hi2";
import { Button } from "src/components/ui/button";
import {  PRODUCT_HISTORY } from "src/interfaces/product.interface";
import { useEffect, useState } from "react";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "src/components/ui/table";
import { dateToString, formatMoney } from "src/utilities/formatter.utilities";
import { catalogueHistory, productHistory } from "src/APIs/product.api";
import { CATALOGUE } from "src/interfaces/estimate.interface";

interface Props {
  catalogue: CATALOGUE;
  asChild?: boolean;
  open?:boolean
  onOpen?:(value:boolean) => void;
}

export function CatalogueHistory(props: Props) {
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState<PRODUCT_HISTORY[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (open || props.open) {
      setLoading(true);
      props.catalogue?.id &&
        catalogueHistory(props.catalogue?.id).then((data) => {
          if (data.code === "200") {
            setHistory(data.data);
          }
          setLoading(false);
        });
    }
  }, [open,props.open]);
  return (
    <Dialog open = {props.asChild ? props.open : undefined} onOpenChange={() => setOpen(true)}>
      {props.asChild ? null : (
        <DialogTrigger asChild>
         { props.catalogue?.nameChange ? <MainButton
            variant={"ghost"}
            type="button"
            onClick={() => null}
            icon={HiMiniClock}
          /> : null}
        </DialogTrigger>
      )}
      <DialogContent className="sm:max-w-[550px]">
        <DialogHeader>
          <DialogTitle>Catalogue Change History</DialogTitle>
          <DialogDescription>
            Review change history of{" "}
            <span className="font-bold">{props.catalogue?.name}</span>.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4 w-[500px] h-96">
          {loading ? (
            <SmallListSkeleton />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Updated by</TableHead>
                  <TableHead>Updated At</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {history.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>${formatMoney(data.price)}</TableCell>
                    <TableCell>{data.updateBy}</TableCell>
                    <TableCell>{dateToString(data.updatedAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
        <DialogFooter>
          <DialogClose
           onClick={props.asChild ? () => props.onOpen && props.onOpen(false) : undefined }
           type="submit">
            <Button>Done</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
