import MainTextArea from "src/components/MainTextArea";
import { COMPONENT_TYPE } from "src/constants";
import { META_DATA } from "src/interfaces/proposal.interface";
import { TelephoneInput } from "src/components/TelephoneInput";
import DatePicker from "src/components/DatePicker";
import { Checkbox } from "src/components/CheckBox";
import { Label } from "src/components/ui/label";
import { dateToString } from "src/utilities/formatter.utilities";
import ProposalMainInput from "./ProposalMainInput";
import ProposalCurrencyInput from "./ProposalCurrencyInput";

interface Props {
  meta: META_DATA;
  onChange: (t: any) => void;
  noMarginTop?: boolean;
  noLable?: boolean;
  readonly?: boolean;
}

export const DynamicInput = (props: Props) => {
  const renderComponent = () => {
    const item = props.meta;
    if (item.type == COMPONENT_TYPE.text.key) {
      return (
        <ProposalMainInput
          disabled={props.readonly}
          noMarginTop={props.noMarginTop}
          placeholder="Enter text"
          label={props.noLable ? undefined : item.label}
          value={item.value}
          onChange={props.onChange}
        />
      );
    }
    if (item.type == COMPONENT_TYPE.text_area.key) {
      return (
        <MainTextArea
          disabled={props.readonly}
          placeholder="Enter text"
          label={item.label}
          value={item.value}
          onChange={props.onChange}
        />
      );
    }
    if (item.type == COMPONENT_TYPE.number.key) {
      return (
        <ProposalCurrencyInput
          isNumber
          prefix="$"
          disabled={props.readonly}
          placeholder="Enter number"
          label={item.label}
          value={item.value}
          onChange={props.onChange}
        />
      );
    }
    if (item.type == COMPONENT_TYPE.phone.key) {
      return (
        <TelephoneInput
          placeholder="Enter phone number"
          label={item.label}
          value={item.value}
          onChange={props.onChange}
        />
      );
    }
    if (item.type == COMPONENT_TYPE.date.key) {
      return (
        <DatePicker
          disabled={props.readonly}
          placeholder="Select date"
          label={item.label}
          value={item.value}
          onDate={(date) => {
            const value = dateToString(date);
            props.onChange(value);
          }}
        />
      );
    }
    if (item.type == COMPONENT_TYPE.checkbox.key) {
      return (
        <div className="flex items-center mt-6">
          <Checkbox value={item.value} onChange={props.onChange} />
          <Label className="ml-2 mb-1">{item.label}</Label>
        </div>
      );
    }
    return <div />;
  };

  return <div className="">{renderComponent()}</div>;
};
