import { HiPlusCircle } from "react-icons/hi2";

interface Props {
  show: boolean;
  onClick:() => void
}

function AddButtonRow(props: Props) {
  return (
    <div
      onClick={() => props.onClick()}
      className={`cursor-pointer z-50 ${
        props.show  ? "scale-1" : "scale-0"
      } transition-all`}
    >
      <HiPlusCircle className="text-secondary" size={18} />
    </div>
  );
}

export default AddButtonRow;
