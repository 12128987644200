import { ROLE } from "src/interfaces/init.interface";
import { postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";

export async function createRole(role: ROLE) {
  let url = `${process.env.REACT_APP_MAIN_API}/role`;
  const payLoad = {
    name: role.name,
    permissions: role.permissions.map((m) => m.id),
  };
  const data = await postAPI(url, payLoad);
  return data as RESPONSE;
}

export async function updateRole(role: ROLE) {
  let url = `${process.env.REACT_APP_MAIN_API}/role/${role?.id}`;
  const payLoad = {
    name: role.name,
    permissions: role.permissions.map((m) => m.id),
  };
  const data = await putAPI(url, payLoad);
  return data as RESPONSE;
}
