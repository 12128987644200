import { HiCog8Tooth, HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import images from "src/assets/images";
import { AlertDialogue } from "src/components/AlertDialogue";
import MainButton from "src/components/MainButton";
import EstimateSettingSheet from "./EstimateSettingSheet";

interface Props {
  isModify: boolean;
  disableSetting?: boolean;
}

export default function PageHeader(props: Props) {
  const navigate = useNavigate();
  return (
    <div
      id={"header"}
      className="flex flex-1 h-14 bg-white p-4 drop-shadow justify-between items-center"
    >
      <img alt="logo" className="h-9 w-30" src={images.LYNQiFY} />
      <div className="flex">
        {props.disableSetting ? null : (
          <EstimateSettingSheet
            renderButton={
              <MainButton
                variant={"link"}
                type={"button"}
                onClick={() => null}
                icon={HiCog8Tooth}
              />
            }
          />
        )}
        {props.isModify ? (
          <AlertDialogue
            variant="destructive"
            continueLabel="Leave"
            onContinue={() => {
              navigate(-1);
            }}
            title="Leaving Without Save"
            description="You have modified something without saving, would you like to leave?"
            renderButton={
              <MainButton
                variant={"link"}
                type={"button"}
                onClick={() => null}
                icon={HiXMark}
              />
            }
          />
        ) : (
          <MainButton
            variant={"link"}
            type={"button"}
            onClick={() => {
              navigate(-1);
            }}
            icon={HiXMark}
          />
        )}
      </div>
    </div>
  );
}
