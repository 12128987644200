import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import MemberTable from "./tables/MemberTable";
import { STEP } from "src/components/Stepper";
import React from "react";
import MemberStore from "./tables/MemberStore";
import { LargeListSkeleton } from "src/components/LargeListSkeleton";
import ManagerMember from "./manageMember/ManageMember";
import { USER } from "src/interfaces/init.interface";
import ProfilePopUp from "../../../components/ProfilePopUp";
import { staticList } from "src/APIs/table.api";
import { MEMBER_ENTITY } from "src/constants/static_entity.constants";

interface Props {
  type: string;
  member?: MemberStore;
}

const status_buttons: STEP[] = [
  {
    id: 1,
    name: "Active",
    sequence: 1,
  },
  {
    id: 2,
    name: "In-Active",
    sequence: 2,
  },
  {
    id: 3,
    name: "Show All",
    sequence: 3,
  },
];

function MemberListPage(props: Props) {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const [selectedStatus, setStatus] = useState(status_buttons[0]);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState<USER | null>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setLoading(true);
    staticList("user/view-detail").then((doc) => {
      if (doc.code !== "200") {
        setLoading(false);
        return;
      }
      props.member && props.member?.setData(doc?.data ?? []);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if(!openProfile) setSelectedMember(null);
  },[openProfile])

  const { data } = props.member ?? {};

  const getData = () => {
    if (props.type === "all-members") {
      return data;
    }
    if (props.type === "external-members") {
      return data?.filter((m) => m.external);
    }

    if (props.type === "internal-members") {
      return data?.filter((m) => !m.external);
    }
    return [];
  };

  const totalActive = React.useMemo(() => {
    return getData()?.filter((m) => m.enable).length;
  }, [getData()]);

  const totalInactive = React.useMemo(() => {
    return getData()?.filter((m) => !m.enable).length;
  }, [getData()]);

  if (loading) return <LargeListSkeleton />;

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MemberTable
        totalActive={totalActive ?? 0}
        totalInactive={totalInactive ?? 0}
        onHeaderButton={(status) => setStatus(status)}
        active={selectedStatus}
        headerButtons={status_buttons}
        data={
          getData()?.filter((m) =>
            selectedStatus.id === 1 ? m.enable : selectedStatus.id === 2 ? !m.enable : true
          ) ?? []
        }
        mainButtonLabel="Manage Member"
        onMainButton={() => setOpen(true)}
        height={height}
        onClick={(row: any) => {
          setOpenProfile(true);
          setSelectedMember(row)
        }}
        entityCode={MEMBER_ENTITY.code}
      />
      <ManagerMember
        type={props.type}
        open={open}
        onOpen={(value) => {
          setOpen(value);
        }}
      />
      <ProfilePopUp
        data={selectedMember}
        open={openProfile}
        onOpen={(v) => setOpenProfile(v)}
      />
    </div>
  );
}

export default inject("member")(observer(MemberListPage));
