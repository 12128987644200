import { user_defined } from "src/routes/routeNav.config";
import UdfNavigationBarItem from "./UdfNavigationBarItem";
import { inject, observer } from "mobx-react";
import UDFStore from "src/stores/udf.store";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  udf?: UDFStore;
}

function UdfNavigationBar(props: Props) {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  return (
    <div className="h-screen w-[28%] bg-muted drop-shadow p-4">
      {user_defined.map((item) => {
        const isActive = pathname.includes(item.route);
        return (
          <UdfNavigationBarItem
            key={item.id}
            isActive={isActive}
            onClick={
              props.udf?.loading || isActive
                ? undefined
                : () => {
                    props.udf?.onChangeTab(item);
                    navigate(`${item.route}/${item.showKey}/${item.name}`);
                  }
            }
            navigationBarItem={item}
          />
        );
      })}
    </div>
  );
}

export default inject("udf")(observer(UdfNavigationBar));
