import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "src/app/estimate/estimateList/estimateDetails/PageHeader";
import ProductDetails from "src/app/estimate/estimateList/estimateDetails/ProductDetails";
import { EstimateDetailsSkeleton } from "src/components/EstimateDetailsSkeleton";
import {
  GROUP_OR_ITEM,
  ESTIMATE_TEMPLATE,
  ESTIMATE_TEMPLATE_PAYLOAD,
  CATALOGUE,
} from "src/interfaces/estimate.interface";
import { v4 as uuidv4 } from "uuid";
import PageFooter from "./PageFooter";
import MainInput from "src/components/MainInput";
import {
  addNewRow,
  createItem,
  mappingEstimateTemplateGroupOrItemToArray,
  mappingToGroupPayload,
  mappingToItemPayload,
  onDeleteItem,
  remapGroupCode,
} from "src/app/estimate/estimateList/estimateDetails/estimate.utility";
import {
  createEstimateTemplate,
  estimateTemplateDetails,
  updateEstimateTemplate,
} from "src/APIs/estimate_details.api";
import { AlertDestructive } from "src/components/AlertDestructive";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { catalogueByProduct } from "src/APIs/product.api";

export default function CreatePresetGroup() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [items, setItems] = useState<GROUP_OR_ITEM[]>([]);
  const [data, setData] = useState<ESTIMATE_TEMPLATE | null>(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [progressing, setProgressing] = useState(false);
  const [error, setError] = useState(false);
  const [bigProcessing, setBigProcessing] = useState(false);
  const [selectedTemplateItem, setTemplateItem] =
    useState<GROUP_OR_ITEM | null>(null);

  useEffect(() => {
    const data: ESTIMATE_TEMPLATE = {
      id: uuidv4(),
      name: "",
      enable: false,
      groups: [],
      items: [],
    };
    const getData = async () => {
      if (!id) {
        setLoading(false);
        setData(data);
        return;
      }
      const res = await estimateTemplateDetails(id);
      setData(res?.data);
      setName(res?.data?.name);
      setLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!selectedTemplateItem) return;
    onAddRow(selectedTemplateItem);
    setTemplateItem(null);
  }, [items]);

  const onSave = async () => {
    setError(false);
    if (!name) {
      setError(true);
      return;
    }
    setProgressing(true);
    const resolvedGroups = items
      .filter((m) => m.isGroup)
      .map((m) => mappingToGroupPayload(m));
    const resolvedItems = items
      .filter((m) => !m.isGroup && m.product && m.quantity)
      .map((m) => mappingToItemPayload(m))
      .filter((m) => {
        const parent = resolvedGroups.find((p) => p.code === m.groupCode);
        if (!m.groupCode) return true;
        if (parent) return true;
        return false;
      });
    const doc: ESTIMATE_TEMPLATE_PAYLOAD = {
      id: id ?? "",
      groups: resolvedGroups,
      items: resolvedItems,
      name: name,
      enable: true,
    };
    if (id) {
      const res = await updateEstimateTemplate(doc, doc.id);
      setProgressing(false);
    } else {
      const res = await createEstimateTemplate(doc);
      setProgressing(false);
    }
  };

  function onAddRow(row: GROUP_OR_ITEM, _siblings?: GROUP_OR_ITEM[]) {
    const siblings =
      _siblings ||
      items.filter((i) => i.parentGroupCode === row.parentGroupCode);
    setItems(addNewRow(row, siblings, items));
  }

  function onAddNewGroup(name: string, item: GROUP_OR_ITEM) {
    const index = items.findIndex((m) => m.id === item.id);
    const parent = items.find((m) => m.code === item.parentGroupCode);
    let _item = createItem();
    _item.name = name || "Untitle";
    _item.isGroup = true;
    _item.id = item.id;
    _item.sequence = item.sequence;
    _item.parentGroupCode = item.parentGroupCode;
    _item.level = item.level;
    _item.code = uuidv4();
    _item.subOf =
      parent?.subOf && parent.subOf.length > 0
        ? parent.subOf.concat(parent.code ?? "")
        : [parent?.code ?? ""];
    _item.subOf = _item.subOf.filter((m) => m);
    items[index] = _item;
    setItems([...items]);
  }

  function onDelete(item: GROUP_OR_ITEM) {
    const remainingItems = onDeleteItem(item, items);
    if (remainingItems.length === 0) {
      setItems([]);
    } else {
      setItems(remainingItems);
    }
  }

  function onChangeGroupName(t: string, group: GROUP_OR_ITEM) {
    const index = items.findIndex((m) => m.id === group.id);
    items[index].name = t;
  }

  function onAddSubItem(item: GROUP_OR_ITEM | null) {
    const siblings = !item
      ? items.filter((m) => m.level === 1)
      : items.filter((m) => m.parentGroupCode === item.code);
    let _item = createItem();
    _item.parentGroupCode = item?.code || null;
    _item.level = item ? item?.level + 1 : 1;
    _item.sequence = siblings.length + 1;
    setItems(items.concat(_item));
  }

  const onAddTemplate = async (
    item: GROUP_OR_ITEM,
    template: ESTIMATE_TEMPLATE
  ) => {
    setTemplateItem(item);
    setBigProcessing(true);
    const res = await estimateTemplateDetails(template.id);
    if (res?.code === "200") {
      const index = items.findIndex((m) => m.id === item.id);
      const parent = items.find((m) => m.code === item.parentGroupCode);
      let _item = createItem();
      _item.name = template.name || "Untitle";
      _item.isGroup = true;
      _item.id = item.id;
      _item.sequence = item.sequence;
      _item.parentGroupCode = item.parentGroupCode;
      _item.level = item.level;
      _item.code = uuidv4();
      _item.subOf =
        parent?.subOf && parent.subOf.length > 0
          ? parent.subOf.concat(parent.code ?? "")
          : [parent?.code ?? ""];
      _item.subOf = _item.subOf.filter((m) => m);
      items[index] = _item;
      let children = remapGroupCode(
        orderByAsc(
          mappingEstimateTemplateGroupOrItemToArray(
            res?.data?.groups,
            res?.data?.items
          ),
          "level"
        )
      ).map(async (m: GROUP_OR_ITEM) => {
        if (m.isGroup === false && !m.catalog && m.product?.catalogAvailable) {
          const catalogues = (await catalogueByProduct(m.product?.id ?? 0))
            .data;
          const selectedCatalogue: CATALOGUE = catalogues.find(
            (m: CATALOGUE) => m.selected
          );
          if (selectedCatalogue) {
            m.catalog = selectedCatalogue;
            m.price = selectedCatalogue.price;
            m.total = m.quantity * selectedCatalogue.price;
          }
        }
        return m;
      });
      Promise.all(children.flat()).then((children) => {
        orderByAsc(children, "level").forEach((m, i) => {
          if (m.isGroup) {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                parentGroupCode: _item.code,
                level: _item.level + 1,
                subOf: _item.subOf?.concat(_item.code ?? ""),
              };
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: _item.level + m.level,
                parentGroupCode: parent?.code,
                subOf:
                  parent?.subOf && parent.subOf.length > 0
                    ? parent.subOf.concat(parent.code ?? "")
                    : [parent?.code ?? ""],
              };
            }
          } else {
            if (m.parentGroupCode === null) {
              children[i] = {
                ...m,
                parentGroupCode: _item.code,
                level: _item.level + 1,
              };
            } else {
              const parent = children.find(
                (ch) => ch.code === m.parentGroupCode
              );
              children[i] = {
                ...m,
                level: _item.level + m.level,
                parentGroupCode: parent?.code ?? "",
                groupCode: parent?.code,
                code: parent?.code,
              };
            }
          }
        });
        setItems([...items.concat(children as any[])]);
        setBigProcessing(false);
      });
    } else {
    }
  };

  const onUpdateItem = (item: GROUP_OR_ITEM, notModify?: boolean) => {
    const index = items.findIndex((m) => m.id === item.id);
    items[index] = item;
    const siblings = orderByAsc(
      items.filter((m) => m.parentGroupCode === item.parentGroupCode),
      "sequence"
    );
    const siblingIndex = siblings.findIndex((m) => m.id === item.id);
    const nextSibling = siblings[siblingIndex + 1];
    if (!nextSibling) {
      onAddRow(item, siblings);
    } else {
      setItems(items);
    }
  };

  if (loading) return <EstimateDetailsSkeleton />;
  return (
    <div className="h-screen w-screen flex flex-col ">
      <PageHeader disableSetting isModify={false} />
      <div className="h-full w-full overflow-auto p-4 bg-white pb-10 pt-0">
        {error ? (
          <div className="mt-6">
            <AlertDestructive
              message="Please enter your preset group name"
              title="Missing Preset Group Name"
            />
          </div>
        ) : null}
        <div className="w-96">
          <MainInput
            required
            value={name}
            placeholder="Enter preset group name"
            onBlur={(e) => setName(e.target.value)}
            label="Preset Group Name"
            onChange={() => null}
          />
        </div>
        <ProductDetails
          onGroupDescription={() => null}
          isTemplate
          team={null}
          onTeam={() => null}
          items={items}
          data={data}
          setItems={(items) => setItems(items)}
          updateItem={onUpdateItem}
          onAddRow={onAddRow}
          onSetPrice={() => null}
          onAddNewGroup={onAddNewGroup}
          onDeleteItem={onDelete}
          onGroupTeam={() => null}
          onChangeGroupName={onChangeGroupName}
          onAddSubItem={onAddSubItem}
          onAddTemplate={onAddTemplate}
        />
      </div>
      <PageFooter
        estimateId={id}
        isModify={false}
        processing={progressing}
        items={items}
        goBack={() => navigate(-1)}
        onSave={() => onSave()}
      />
      {bigProcessing ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
          <span className="text-white text-[40px]">Processing...</span>
        </div>
      ) : null}
    </div>
  );
}
