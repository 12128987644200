import { Button } from "../../../../../components/ui/button";
import { cn } from "src/lib/utils";

import { CATALOGUE } from "src/interfaces/estimate.interface";
import { useEffect, useRef, useState } from "react";
import { PRODUCT } from "src/interfaces/product.interface";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../../../../components/ui/drawer";
import MainButton from "../../../../../components/MainButton";
import { formatMoney } from "src/utilities/formatter.utilities";
import { HiCheckCircle, HiChevronDown, HiClock } from "react-icons/hi2";
import SearchInput from "../../../../../components/SearchInput";
import { staticSearch } from "src/utilities/mapping.utilities";
import { EstimateDetailsSkeleton } from "../../../../../components/EstimateDetailsSkeleton";
import Tooltip from "@mui/material/Tooltip";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import { catalogueByProduct } from "src/APIs/product.api";
import EmptyData from "src/components/emptyData";
import { CatalogueHistory } from "src/app/productAndConfigure/catalogueList/CatalogueHistory";
import CatalogueSelectorItem from "./CatalogueSelectorItem";
interface Props {
  disabled?: boolean;
  placeholder?: string;
  value: CATALOGUE | null;
  onSubmit: (data: CATALOGUE) => void;
  product: PRODUCT | null;
  hideBorder?: boolean;
  extendClass?: string;
  active: boolean;
}

export function CatalogueSelector(props: Props) {
  const [open, setOpen] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [showCatalogueHistory, setShowCatalogueHistory] = useState(false);

  let data = useRef<CATALOGUE[]>([]);
  const [catalogues, setCatalogues] = useState<CATALOGUE[]>([]);
  const [value, setValue] = useState<CATALOGUE | null>(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!props.product?.id || !open) return;
    setLoading(true);
    catalogueByProduct(props.product?.id).then((result) => {
      if (result.code !== "200") return;
      const _data = result.data;
      data.current = _data;
      setCatalogues(_data);
      setLoading(false);
    });
  }, [props.product, open]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (text) {
      setCatalogues(staticSearch(data.current, text) ?? []);
    } else {
      setCatalogues(data.current);
    }
  }, [text]);

  const onSubmit = () => {
    if (value) {
      props.onSubmit(value);
      setOpenDialogue(false);
    }
  };

  return (
    <Drawer
      modal={false}
      open={openDialogue}
      onOpenChange={() => {
        setOpen(true);
      }}
    >
      <DrawerTrigger asChild>
        <Button
          onClick={() => setOpenDialogue(true)}
          disabled={props.disabled}
          variant={"outline"}
          className={cn(
            `pl-3 text-left font-normal  bg-white w-full overflow-hidden ${
              props.active
                ? "border border-secondary "
                : props.hideBorder
                ? "border-transparent shadow-transparent"
                : "border border-gray-300"
            } flex justify-between items-start } ${
              props.extendClass || ""
            } disabled:border-none `
          )}
        >
          <Tooltip title={value?.name ?? ""}>
            <div className="flex flex-1 items-center">
              {value && value?.nameChange ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCatalogueHistory(true);
                  }}
                >
                  <HiClock
                    size={15}
                    className="mr-2 text-muted-foreground cursor-pointer"
                  />
                </div>
              ) : null}
              <span
                className={`${
                  !value?.name ? "text-muted-foreground truncate" : ""
                } w-[70%]`}
              >
                {value?.name || props.placeholder}
              </span>
            </div>
          </Tooltip>
          {props.disabled ? null : (
            <HiChevronDown className=" h-4 w-4 opacity-60" />
          )}
        </Button>
      </DrawerTrigger>
      <DrawerContent forceMount className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>Catalogues of {props.product?.name}</DrawerTitle>
            <DrawerDescription>Please choose your catalogue</DrawerDescription>
          </div>
          <div className="w-96">
            <SearchInput onChange={(e) => setText(e.target.value)} />
          </div>
          {value ? (
            <div className="w-32">
              <MainButton
                type="button"
                onClick={() => onSubmit()}
                label="Save Change"
              />
            </div>
          ) : null}
          <CloseDrawerButton onClick={() => setOpenDialogue(false)} />
        </DrawerHeader>
        {loading ? (
          <div className="h-full w-full">
            <EstimateDetailsSkeleton />
          </div>
        ) : catalogues.length === 0 ? (
          <EmptyData
            title="Catalogue Not Found!"
            message={`We're sorry, there's no catalogues for ${props.product?.name} to select.`}
          />
        ) : (
          <div className="h-full w-full overflow-auto">
            <div className="w-full">
              {catalogues.map((m) => {
                const active = m.id === value?.id;
                return (
                  <div
                    key={m.id}
                    onClick={() => setValue(m.id === value?.id ? null : m)}
                    className={`hover:bg-slate-200 p-4 rounded-xl bg-slate-100 m-4 drop-shadow cursor-pointer ${
                      active
                        ? "border-[4px] border-secondary bg-secondary/15 hover:bg-secondary/30"
                        : "border-[4px] border-transparent"
                    }`}
                  >
                    <div className="flex items-center">
                      <p className="text-lg font-bold mr-4">
                        {m.name} - ${formatMoney(m.price)}
                      </p>
                      {active ? (
                        <HiCheckCircle className="text-secondary" size={22} />
                      ) : null}
                    </div>
                    <CatalogueSelectorItem catalogue={m} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </DrawerContent>
      {value && value?.nameChange ? (
        <CatalogueHistory
          catalogue={value}
          asChild
          onOpen={() => setShowCatalogueHistory(false)}
          open={showCatalogueHistory}
        />
      ) : null}
    </Drawer>
  );
}
