"use client";
import { useEffect, useState } from "react";
import { Table } from "../../../components/ui/table";
import CrmTopTableHeader from "./CrmTopTableHeader";
import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import { onGetDefinedFieldsByTableId } from "src/services/crm.service";
import { orderByAsc } from "src/utilities/formatter.utilities";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { LargeListSkeleton } from "../../../components/LargeListSkeleton";
import AddButtonUserDefined from "src/app/admin/userDefined/UdfAddButton";
import CrmTableBody from "./tableBody";
import { CrmTableHeader } from "./tabeHeader";
import { FIELD_TYPE, UDF, getInitUdf } from "src/interfaces/udf.interface";
import { onCreateUdf } from "src/services/udf.service";
import { CRM_TYPES } from "src/constants/crm.constants";

interface Props {
  auth?: AuthStore;
  entityCode: string;
}

const CrmMainTable = (props: Props) => {
  const [columns, setColumns] = useState<ENTITY_CONFIG[]>([]);
  const [fetchingColumns, setFetchingColumns] = useState(true);
  const [addingUdf, setAddingUdf] = useState(false);

  useEffect(() => {
    onGetDefinedFieldsByTableId(props.entityCode).then((res) => {
      const udfs = res?.data as UDF[];
      const mapColumns = udfs.map((m) => {
        return udfToColumns(m);
      });
      const defaultEntities = props.auth?.entities.find(
        (m) => m.code === props.entityCode
      );
      defaultEntities?.entityConfigurations.map((m) => (m.visible = false));
      defaultEntities?.entityConfigurations.map((m) => (m.columnType = ""));
      const results = orderByAsc(
        defaultEntities?.entityConfigurations ?? [],
        "sequence"
      );
      setColumns(results.concat(mapColumns));
      setFetchingColumns(false);
    });
  }, []);

  const udfToColumns = (udf: UDF) => {
    const column: ENTITY_CONFIG = {
      id: udf.id ?? "",
      columnName: udf.name.toLowerCase(),
      displayName: udf.name,
      sequence: columns.length + 1,
      sortable: false,
      visible: true,
      columnType: udf.fieldType?.code,
      sortedColumnName: "",
    };
    return column;
  };

  const onUpdatedHeader = (c: ENTITY_CONFIG) => {
    const index = columns.findIndex((m) => m.id === c.id);
    columns[index].displayName = c.displayName;
    setColumns([...columns]);
  };

  const onDeleteHeader = (c: ENTITY_CONFIG) => {
    const results = columns.filter((m) => m.id !== c.id);
    setColumns(results);
  };

  const onAddHeader = (item: FIELD_TYPE) => {
    setAddingUdf(true);
    const udf: UDF = {
      ...getInitUdf,
      fieldType: item,
      name: item.name,
      sequence: columns.length,
    };
    onCreateUdf(props.entityCode, udf).then((data) => {
      if (data !== null) {
        const doc = udfToColumns(data);
        setColumns([...columns, doc]);
        setAddingUdf(false);
      } else {
        setAddingUdf(false);
      }
    });
  };

  if (fetchingColumns === true || columns.length === 0)
    return <LargeListSkeleton />;

  return (
    <div className="p-3 py-4">
      <CrmTopTableHeader
        onChange={(text) => {}}
        entityCode="lead"
        onAdd={() => {}}
      />
      <div
        className={`mt-1 border-[1px] rounded-t-md overflow-auto h-[470px] relative`}
      >
        <Table>
          <CrmTableHeader
            onDeleted={onDeleteHeader}
            onUpdated={onUpdatedHeader}
            columns={columns}
          />
          <CrmTableBody crmType={CRM_TYPES.lead} columns={columns} />
        </Table>
        <AddButtonUserDefined
          loading={addingUdf}
          onChange={onAddHeader}
          isCrm
        />
      </div>
    </div>
  );
};

export default inject("auth")(observer(CrmMainTable));
