import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "../../../../components/ui/drawer";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import ManageMemberList from "./ManageMemberList";
interface Props {
  open: boolean;
  onOpen: (value: boolean) => void;
  type: string;
}

function ManageMember(props: Props) {
  const renderTitle = () => {
    if(props.type === "all-members") return "All Members"
    if(props.type === "external-members") return "External Members"
    if(props.type === "internal-members") return "Internal Members"
  }
  return (
    <Drawer open={props.open}>
      <DrawerContent className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex items-center py-0 pt-2">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>{renderTitle()}</DrawerTitle>
            <DrawerDescription>Manage your member</DrawerDescription>
          </div>
          <div className="mb-2">
          <CloseDrawerButton onClick={() => props.onOpen(false)} />
          </div>
        </DrawerHeader>
        <div className="h-full w-full overflow-hidden bg-slate-50">
          <ManageMemberList type={props.type} />
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default ManageMember;
