import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/ui/sheet";
import EstimateSettingContent from "../../estimateSetting/EstimateSettingContent";


interface Props {
  renderButton: React.ReactNode;

}

function EstimateSettingSheet(props: Props) {

  return (
    <Sheet>
      <SheetTrigger asChild>{props.renderButton}</SheetTrigger>
      <SheetContent className="bg-white w-[40%]">
        <SheetHeader className="flex justify-between border-b pb-4">
          <SheetTitle>Setup Estimate</SheetTitle>
        </SheetHeader>
        <EstimateSettingContent />
      </SheetContent>
    </Sheet>
  );
}

export default EstimateSettingSheet;
