import dayjs from 'dayjs';
import * as htmlToImage from 'html-to-image';
import _ from 'lodash';
import { nullString } from './functions.utilities';

export function dateToString4API(date: Date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function dateToString(date: Date | null | undefined, pattern?: string) {
  if (!date) return 'N/A';
  return dayjs(date).format(pattern || 'MMM DD,YYYY');
}

export function stringDateToDate(date: string) {
  return dayjs(date).toDate();
}

export function dateToStringMMDD(date: Date) {
  return dayjs(date).format('MMMM DD');
}

export function stringDateMMMMDD(date: string) {
  const _date = dayjs(date).toDate();
  return dateToStringMMDD(dayjs(_date).toDate());
}

export function dateToStringMMDDYYYY(date: Date) {
  return dayjs(date).format('MMMM DD, YYYY');
}

export function stringDateMMMMDDYYYY(date: string) {
  if (!date) return '';
  const _date = dayjs(date).toDate();
  return dateToStringMMDD(dayjs(_date).toDate());
}

export function toNumber(value: any) {
  if (value === null || value === '' || value === undefined) {
    return 0;
  }
  if (Number.isNaN(Number(value))) return 0;
  return Number(value);
}

export function sum(data: Array<any>, field: string) {
  return data.reduce((a, b) => a + toNumber(b[field]), 0);
}

export function phoneOnly(txt: string) {
  if (txt.trim() === '') return '';
  const array = txt.split('');
  const number = '1234567890+ ';
  return array.filter((m) => number.includes(m)).join('');
}

export function formatMoney(amount: any, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i: any = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export function displayDate(date: string | Date | undefined, pattern?: string) {
  if (!date) return 'N/A';
  if (typeof date === 'string') {
    return dayjs(date, 'YYYY-MM-DD').format(pattern || 'MMM DD, YYYY');
  } else {
    return dayjs(date).format(pattern || 'ddd D MMM, YYYY');
  }
}

export function displayDateComment(date: string | Date | undefined) {
  if (!date) return 'N/A';
  if (typeof date === 'string') {
    const _date = dayjs(date, 'YYYY-MM-DD').format('dddd MM');
    const _hour = dayjs(date, 'YYYY-MM-DD:HH:mm').format('HH:mm');
    return `${_date} at ${_hour}`;
  } else {
    const _date = dayjs().format('ddd D MMM ');
    const _hour = dayjs(date).format('HH:mm');
    return `${_date} at ${_hour}`;
  }
}

export function nameChar(name: string) {
  const arr = name.split(' ');
  return arr.length >= 2 ? `${arr[0].charAt(0)}${arr[1]?.charAt(0)}` : arr[0].charAt(0);
}

export function removeComma(value: string) {
  return value.split(',').join('');
}

export function toTitleCase(str: string) {
  if (!str) return '';
  return str.toLowerCase().replace(/(?:^|[\s&()/])\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function orderByDesc(data: Array<any>, field: string) {
  return _.orderBy(data, [field], ['desc']);
}

export function orderByAsc(data: Array<any>, field: string) {
  return _.orderBy(data, [field], ['asc']);
}

export function phoneString(phone: string) {
  return nullString(
    phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''),
  );
}

export async function convertHTMLToImage(html: HTMLElement) {
  const dataUrl = await htmlToImage.toPng(html);
  const link = document.createElement('a');
  link.href = dataUrl;
  link.click();
  return link;
}
export function plainTextConverter(text: string) {
  let plainTextContent = text;
  plainTextContent = plainTextContent.replace(/<\/?ol>/gi, '\n#orderedlist#');
  plainTextContent = plainTextContent.replace(/<\/?ul>/gi, '\n#unorderedlist#');
  plainTextContent = plainTextContent.replace(/<li>/gi, '#listitem#');
  plainTextContent = plainTextContent.replace(/<\/li>/gi, '');
  plainTextContent = plainTextContent.replace(/<\/?p>/gi, '\n');
  plainTextContent = plainTextContent.replace(/<[^>]+>/g, '');

  function processOrderedList(part: string) {
    let olCounter = 1;
    return part
      .split('#listitem#')
      .map((item, index) => {
        if (index > 0) return `\t${olCounter++}. ${item.trim()}\n`;
        return item.trim() ? item : '';
      })
      .join('');
  }
  function processUnorderedList(part: string) {
    return part
      .split('#listitem#')
      .map((item, index) => {
        if (index > 0) return `\t• ${item.trim()}\n`;
        return item.trim() ? item : '';
      })
      .join('');
  }
  const sections = plainTextContent.split(/(#orderedlist#|#unorderedlist#)/);

  let processedContent = '';
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section === '#orderedlist#') {
      i++;
      if (i < sections.length) {
        processedContent += processOrderedList(sections[i]);
      }
    } else if (section === '#unorderedlist#') {
      i++;
      if (i < sections.length) {
        processedContent += processUnorderedList(sections[i]);
      }
    } else {
      processedContent += section;
    }
  }
  processedContent = processedContent.replace(/\n{2,}/g, '\n').trim();

  return processedContent;
}
