import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainTable from "src/components/tableComponents/MainTable";
import { PROPOSAL_LIST } from "src/constants/static_entity.constants";

function ProposalListPage() {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  // const [openAdd, setOpenAdd] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div ref={ref} className="overflow-hidden p-4 bg-white">
      <MainTable
        static
        height={height}
        entityCode={PROPOSAL_LIST.code}
        path="proposal/corporate/proposal"
        addLabel="Create Estimate"
        // onAdd={() => setOpenAdd(!openAdd)}
        onClick={(row: any) =>
          navigate(`/proposal-details/${row?.id}/${false}/${0}`)
        }
      />
      {/* <AddProposal
        estimateId={1}
        onOpen={(v) => setOpenAdd(v)}
        open={openAdd}
      /> */}
    </div>
  );
}

export default ProposalListPage;
