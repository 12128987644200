import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { COLORS } from "src/assets/colors";
import { renderColor } from "src/utilities/functions.utilities";
import { BiColorFill } from "react-icons/bi";
import { onUpdateUdfListColor } from "src/services/udf.service";
import { UDF_LIST } from "src/interfaces/udf.interface";
import { Loader2 } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import UDFStore from "src/stores/udf.store";

interface Props {
  auth?: AuthStore;
  udf?: UDFStore;
  udfList: UDF_LIST;
}

function UdfColorButton(props: Props) {
  const [Loading, setLoading] = useState(false);
  const [colorCode, setColorCode] = useState<string>("");
  const [openAdd, setOpenAdd] = useState(false);

  const onOpenAdd = () => setOpenAdd(!openAdd);

  const { colors } = props.auth ?? {};

  useEffect(() => {
    if (props.udfList) {
      setColorCode(props.udfList.backgroundColor);
    }
  }, [props.udfList.backgroundColor]);

  const onUpdate = (backgroundColor: string) => {
    setLoading(true);
    const doc: UDF_LIST = {
      ...props.udfList,
      backgroundColor: backgroundColor,
    };
    onUpdateUdfListColor(doc).then((res) => {
      if (res !== null) {
        props.udf?.onUpdateUdfListColor(doc);
        setLoading(false);
        onOpenAdd();
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            style={{
              background: renderColor(colorCode) || COLORS.background_primary,
            }}
            className={`h-8 w-8 rounded-md items-center justify-center flex cursor-pointer`}
          >
            {Loading ? (
              <Loader2 color={COLORS.white} className="h-4 w-4 animate-spin" />
            ) : (
              <BiColorFill color={COLORS.white} />
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-2 h-48">
          <div className="grid grid-cols-5">
            {colors?.map((item) => {
              return (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <DropdownMenuItem
                        onClick={() => {
                          setColorCode(item.code);
                          onUpdate(item.code);
                        }}
                      >
                        <div
                          style={{
                            background:
                              renderColor(item.code) ||
                              COLORS.background_primary,
                          }}
                          className={`h-10 w-10 rounded-md`}
                        />
                      </DropdownMenuItem>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{item.name}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
export default inject("auth", "udf")(observer(UdfColorButton));
