import { ProjectScheduleSection } from "./ProjectScheduleSection";
import MainButton from "src/components/MainButton";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import { AddDateButton } from "./AddDateButton";

interface Props {
  proposal?: ProposalStore;
}

function ContentProjectSchedule(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};

  return (
    <div>
      {sectionDetail?.meta.map((parent) => {
        if (parent.type === "section") {
          return (
            <div>
              <ProjectScheduleSection
                key={parent.id}
                parent={parent}
                onChange={(child) => {
                  props.proposal?.onUpdatePayload2Level(parent, child);
                }}
              />
              <div className="flex justify-end mb-8">
                <AddDateButton
                  onAdd={(title) => {
                    props.proposal?.onAddProjectScheduleDate(parent, title);
                  }}
                />
              </div>
            </div>
          );
        }
        return (
          <ProjectScheduleSection
            key={parent.id}
            parent={parent}
            onChange={(child) => {
              props.proposal?.onUpdatePayload2Level(parent, child);
            }}
          />
        );
      })}
      <MainButton
        disabled={props.proposal?.disableSave}
        onClick={onUpdateSection}
        loading={processingContentUpdate}
        label="Save"
        className="mt-8"
      />
    </div>
  );
}

export default inject("proposal")(observer(ContentProjectSchedule));
