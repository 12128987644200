import Bars3Icon from "@heroicons/react/20/solid/Bars3Icon";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "./ui/sheet";
import NavigationBar from "./navigationBar/NavigationBar";
import routeConfig from "../routes/route.config";

export function ModalNavigationBar() {
  return (
    <Sheet>
      <SheetTrigger className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white cursor-pointer hover:opacity-80" >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </SheetTrigger>
      <SheetContent side={"left"}>
        <NavigationBar asChild routes={routeConfig} />
      </SheetContent>
    </Sheet>
  );
}
