import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { COLORS } from "src/assets/colors";
import CrmRenderIcon from "src/app/crm/crmTableComponents/CrmRenderIcon";
import { renderColor } from "src/utilities/functions.utilities";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import UdfContactAdd from "./userDefinedPage/UdfAdd";
import { FIELD_TYPE, UDF, getInitUdf } from "src/interfaces/udf.interface";
import UDFStore from "src/stores/udf.store";
import { Loader2 } from "lucide-react";

interface Props {
  auth?: AuthStore;
  udf?: UDFStore;
  isCrm?: boolean;
  entityCode?: string;
  loading?: boolean;
  onChange?: (item: FIELD_TYPE) => void;
}

function UdfAddButton(props: Props) {
  const [openAdd, setOpenAdd] = useState(false);
  const onOpenAdd = () => setOpenAdd(!openAdd);

  const { fieldTypes } = props.auth ?? {};

  return (
    <>
      <UdfContactAdd
        entityCode={props.entityCode ?? "customer"}
        item={props.udf?.selectedUdf}
        open={openAdd}
        onOpen={onOpenAdd}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {props.isCrm ? (
            <div className="absolute bg-muted h-11 w-12 right-0 top-0 rounded-tr-md border-l-[1px] cursor-pointer flex items-center justify-center hover:bg-white">
              {props.loading === true ? (
                <Loader2
                  color={COLORS.primary}
                  className="h-4 w-4 animate-spin"
                />
              ) : (
                <HiOutlinePlusSmall size={28} />
              )}
            </div>
          ) : (
            <Button variant="outline" size="icon">
              <IoAdd className="h-5 w-5" />
            </Button>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-auto p-2">
          {props.isCrm ? null : (
            <DropdownMenuLabel>Essential</DropdownMenuLabel>
          )}
          {props.isCrm ? null : <DropdownMenuSeparator />}
          <DropdownMenuGroup>
            <div className="grid grid-rows-5 grid-flow-col gap-0">
              {fieldTypes?.map((item) => {
                return (
                  <DropdownMenuItem
                    key={item.id}
                    onClick={
                      props.isCrm
                        ? () => {
                            props.onChange && props.onChange(item);
                          }
                        : () => {
                            if (props.udf) {
                              const data: UDF = {
                                ...getInitUdf,
                                fieldType: item,
                                sequence: props.udf.udfs.length,
                              };
                              props.udf.onChangeUdf(data);
                              onOpenAdd();
                            }
                          }
                    }
                    className=" my-1"
                  >
                    <div
                      style={{
                        background:
                          renderColor(item.color) || COLORS.background_primary,
                      }}
                      className={`p-1 rounded-sm mr-2`}
                    >
                      <CrmRenderIcon
                        name={item.icon}
                        size={16}
                        color={COLORS.white}
                      />
                    </div>
                    <span>{item.name}</span>
                  </DropdownMenuItem>
                );
              })}
            </div>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
export default inject("auth", "udf")(observer(UdfAddButton));
