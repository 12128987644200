import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { onUpdateUdfListDefault } from "src/services/udf.service";
import { UDF_LIST } from "src/interfaces/udf.interface";
import UDFStore from "src/stores/udf.store";
import { IoRadioButtonOffSharp, IoRadioButtonOn } from "react-icons/io5";
import { Loader2 } from "lucide-react";
import { COLORS } from "src/assets/colors";

interface Props {
  udf?: UDFStore;
  udfList: UDF_LIST;
  onSuccessSetDefault: (list: UDF_LIST[]) => void;
}

function UdfSetDefaultButton(props: Props) {
  const [loading, setLoading] = useState(false);
  const [defaults, setDefault] = useState<boolean>(false);

  useEffect(() => {
    if (props.udfList) {
      setDefault(props.udfList.selected);
    }
  }, [props.udfList.selected]);

  const onSetDefault = () => {
    setLoading(true);
    onUpdateUdfListDefault(props.udfList).then((res) => {
      if (res !== null) {
        props.onSuccessSetDefault(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="bg-white rounded-full p-1">
            <Loader2 color={COLORS.primary} className="h-4 w-4 animate-spin" />
          </div>
        ) : (
          <div
            onClick={() => {
              onSetDefault();
            }}
            className="flex cursor-pointer bg-white rounded-full p-1"
          >
            {defaults ? <IoRadioButtonOn /> : <IoRadioButtonOffSharp />}
          </div>
        )}
      </div>
    </>
  );
}
export default inject("udf")(observer(UdfSetDefaultButton));
