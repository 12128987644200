import { useEffect, useState } from "react";
import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import { onGetDefaultTemplate } from "src/services/proposal.service";
import { ProposalTemplateItem } from "./ProposalTemplateItem";
import { Skeleton } from "src/components/ui/skeleton";
import { ContentTitle } from "../../proposalDetail/proposalContent/ContentTitle";

interface props {
  value: PROPOSAL_TEMPLATE | null;
  onChange: (v: PROPOSAL_TEMPLATE) => void;
}

export const DefaultTempate = (props: props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<PROPOSAL_TEMPLATE[]>([]);

  useEffect(() => {
    setLoading(true);
    onGetDefaultTemplate().then((data) => {
      if (data !== null) {
        setList(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <div className="mt-8">
      <ContentTitle title="Default Template" />
      {loading ? (
        <div className="mt-5 grid gap-x-8 gap-y-4 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-2">
          {[1, 2, 3, 4, 5, 6].map(() => {
            return (
              <Skeleton className="h-60 w-48 mr-3 rounded-sm cursor-pointer mb-4" />
            );
          })}
        </div>
      ) : (
        <div className="mt-5 grid gap-x-8 gap-y-4 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-2">
          {list.map((item, index) => {
            return (
              <ProposalTemplateItem
                key={item.id}
                onClick={() => props.onChange(item)}
                item={item}
                index={index}
                active={props.value === item}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
