import { Skeleton } from "./ui/skeleton";
 
export function EstimateDetailsSkeleton() {
  return (
    <div className="flex flex-1 flex-col justify-start gap-3 p-4">
      <Skeleton className="h-7 w-full rounded" />
      <Skeleton className="h-7 w-2/3 rounded" />
      <Skeleton className="h-7 w-1/3 rounded" />
      <Skeleton className="h-7 w-full rounded" />
      <div className="flex gap-3">
      <Skeleton className="w-full rounded h-36" />
      <Skeleton className="w-full rounded h-36" />
      </div>
      <Skeleton className="w-full rounded h-96" />
    </div>
  )
}