import { CATALOGUE } from "src/interfaces/estimate.interface";
import CatalogueImage from "../../../../../components/Image";
import { useEffect, useState } from "react";
import { onGetImages } from "src/services/auth.service";
import { FILE } from "src/interfaces/auth.interface";
import { MainImageViewer } from "src/components/MainImageViewer";

interface Props {
  catalogue: CATALOGUE;
}

export default function CatalogueSelectorItem(props: Props) {
  const [images, setImages] = useState<FILE[]>([]);
  const [openViewer,setOpenViewer] = useState(false);
  const [startIndex,setStartIndex] = useState(0);

  useEffect(() => {
    if (!props.catalogue?.id) return;
    onGetImages("catalog", props.catalogue?.id).then((res) => {
      setImages(res as any);
    });
  }, [props.catalogue]);
  return (
    <div 
    className="p-4 flex gap-4 flex-wrap">
      {images.map((m,i) => {
        return (
          <div
          onClick={(e) => {
            e.stopPropagation()
            setOpenViewer(true);
            setStartIndex(i)
          }}
            className={`cursor-pointer group relative flex h-24 w-24 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg`}
          >
            <CatalogueImage url={m.url ?? ""} />
          </div>
        );
      })}
      <MainImageViewer open={openViewer} onClose={() => setOpenViewer(false)} selectedImage={images[startIndex]} images={images}      />
    </div>
  );
}
