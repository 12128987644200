import { HiChevronDown, HiMiniPuzzlePiece, HiMiniTag, HiMiniWrenchScrewdriver, HiXMark } from "react-icons/hi2";
import { Label } from "../ui/label";
import { IconType } from "react-icons";
import { useEffect, useState } from "react";
import { formatMoney } from "src/utilities/formatter.utilities";
import MainButton from "../MainButton";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React from "react";
import ActivityIndicator from "../ActivityIndicator";
import { COLORS } from "src/assets/colors";
import { MAP_PROPERTY } from "./AutoCompleteAddress";

interface Props {
  data: any[];
  loading?: boolean;
  searching?: boolean;
  setText: (t: string) => void;
  sentryRef?: any;
  rootRef?: any;
  label?: string;
  icon?: IconType;
  placeholder?: string;
  onIconClick?: () => void;
  required?: boolean;
  onSelectedValue: (data: any, text?: string) => void;
  value: any;
  disabled?: boolean;
  renderOption?: (
    option: any,
    active: boolean,
    onSelectedValue: (data: any, text?: string) => void
  ) => React.ReactNode;
  onAdd?: () => void;
  hideBorder?: boolean;
  errorMessage?: string;
  leftIcon?: IconType;
  onOpenChange: (value: boolean) => void;
  isProduct?: boolean;
  onAddNewGroup?: () => void;
  active?: boolean;
  noClearButton?: boolean;
  isLocation?: boolean;
  isState?: boolean;
  enablePortal?: boolean;
}

const PRODUCT_BUTTONS = [
  {
    isButton: true,
  },
];

export function ProductTypeIcon(code:string){
  if(code === "svc"){
    return <HiMiniWrenchScrewdriver size={14} className="text-slate-500 mr-2 cursor-pointer"/>
  } else if(code === "mat") {
    return <HiMiniPuzzlePiece size={14} className="text-slate-500 mr-2 cursor-pointer"/>
  } else if (code === "dsf"){
    return <HiMiniTag size={14} className="text-slate-500 mr-2 cursor-pointer"/>
  }else {
    return <HiMiniWrenchScrewdriver size={14} className="text-slate-500 mr-2 cursor-pointer"/>
  }
}

export default function AutoCompleteView(props: Props) {
  const [value, setValue] = useState<any>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const renderName = (item: any) => {
    let name =
      item?.fullName ||
      item?.displayName ||
      item?.name ||
      item?.type ||
      item?.value ||
      item;
    return name;
  };

  const renderLocation = (item: MAP_PROPERTY) => {
    return props.isState ? item?.state : item?.formatted;
  };

  const renderProductButton = (isEmpty?: boolean) => {
    return (
      <div
        className={`${isEmpty ? "" : "p-4"} sticky top-[0px] bg-white flex `}>
        <div className="w-[50%]">
          <MainButton
            onClick={() => props.onAdd && props.onAdd()}
            label={"Add New Item"}
          />
        </div>
        <div className="p-1" />
        <div className="w-[50%]">
          <MainButton
            onClick={() => props.onAddNewGroup && props.onAddNewGroup()}
            label={"Add New Group"}
          />
        </div>
      </div>
    );
  };

  const renderOption = (
    _props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    selected: boolean,
    index: number
  ) => {
    const isSelected = renderName(option) === renderName(props.value)
    if (option.isButton) {
      return renderProductButton();
    }
    return (
      <li
        ref={props.sentryRef}
        {..._props}
        className={`flex justify-between flex-1 px-4 py-2 cursor-pointer items-start  ${
          isSelected ? "bg-secondary/20" : "bg-white hover:bg-slate-100"
        }`}
      >
        {props.isLocation ? renderLocation(option) : props.isProduct ? <div className="flex items-center">{ProductTypeIcon(option.productType?.code)}{renderName(option)}</div> : renderName(option)}
        {props.isProduct ? (
          option.taxIncluded === undefined ? (
            <span className="font-semibold text-secondary">
              {"Preset Group"}
            </span>
          ) : (
            <span className="font-semibold text-secondary">
              ${formatMoney(option.price)}
            </span>
          )
        ) : null}
      </li>
    );
  };
  const renderContent = () => {
    return (
      <>
        {props.label ? (
          <Label className="mb-2">
            {props.label}
            {props.required ? (
              <span className="text-destructive">*</span>
            ) : null}
          </Label>
        ) : null}
        <Tooltip title={renderName(props.value)}>
          <Autocomplete
            disablePortal={!props.enablePortal}
            freeSolo={props.isLocation && !props.isState}
            noOptionsText={
              props.isProduct ? renderProductButton(true) : undefined
            }
            isOptionEqualToValue={(o, v) => true}
            disabled={props.disabled}
            autoComplete={false}
            value={props.value || null}
            filterOptions={(m) => m}
            renderOption={(_props, option, { selected, index }) =>
              renderOption(_props, option, selected, index)
            }
            onInputChange={(e, newInputValue, reason) => {
              if (props.isLocation) {
                if (!open) return;
                if (
                  newInputValue === props.value?.properties?.address_line1 &&
                  newInputValue
                ) {
                  return;
                }
                if (
                  newInputValue.length > 0 &&
                  reason === "input" &&
                  !props.data.find(
                    (m) => m.properties?.address_line1 === newInputValue
                  )
                ) {
                  props.setText(newInputValue);
                }
              } else {
                if (newInputValue !== renderName(props.value))
                  props.setText(newInputValue);
              }
            }}
            onChange={(event, newValue) => {
              event.preventDefault();
              props.onSelectedValue(newValue);
              props.setText("");
            }}
            onOpen={() => {
              props.onOpenChange(true);
              setTimeout(() => {
                setOpen(true);
              }, 500);
            }}
            onClose={() => {
              setOpen(false);
            }}
            fullWidth
            style={{
              borderRadius: 100,
            }}
            componentsProps={{
              paper: {
                sx: {
                  minWidth: 300,
                  margin: 1,
                },
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "4px!important",
                borderRadius: "6px !important",
                marginTop: "7px !important",
                outline: "none !important",
                backgroundColor: COLORS.white,
                "&:hover": {
                  backgroundColor: "#f1f5f9",
                },
              },
              "& fieldSet": {
                borderColor: props.active
                  ? COLORS.secondary
                  : props.hideBorder
                  ? COLORS.white
                  : COLORS.border,
              },
              "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: props.active
                  ? COLORS.secondary
                  : props.hideBorder
                  ? COLORS.white
                  : COLORS.border,
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: props.active
                    ? COLORS.secondary
                    : props.hideBorder
                    ? COLORS.white
                    : COLORS.border,
                },
              "& .MuiAutocomplete-inputRoot":{
                flexWrap:"unset",
                
              }  
            }}
            size="small"
            options={
              props.isProduct ? PRODUCT_BUTTONS.concat(props.data) : props.data
            }
            getOptionLabel={(o) => renderName(o)}
            ListboxProps={{
              sx: { overscrollBehavior: "contain", padding: 0 },
              ref: props.rootRef,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={props.placeholder}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: props.leftIcon ? (
                    <props.leftIcon
                      onClick={(e) => {
                        props.onIconClick && props.onIconClick();
                      }}
                      className="text-slate-600 ml-2 w-4 h-4 cursor-pointer"
                    />
                  ) : null,
                  endAdornment: props.loading ? (
                    <ActivityIndicator />
                  ) : props.value && !props.noClearButton ? (
                   props.disabled ? null : <HiXMark
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onSelectedValue(null);
                      }}
                      className=" h-4 w-4 opacity-80 absolute right-2 cursor-pointer"
                    />
                  ) : props.isProduct && props.value?.productType ? ProductTypeIcon(props.value?.productType?.code) :  <HiChevronDown className=" h-4 w-4 opacity-80 absolute right-2 cursor-pointer" />,
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { padding: 3.6, display: "flex", paddingRight:20 },
                  className: `border-none focus:ring-0 ${
                    params.inputProps.className
                  } ${open ? "thisAutoComplete" : ""}`,
                }}
                onBlur={(e) => {
                  const text = e.target.value;
                  if (
                    props.isLocation &&
                    !props.isState &&
                    props.value?.properties?.address_line1 !== text
                  ) {
                    props.onSelectedValue(null, text);
                  }
                }}
              />
            )}
          />
        </Tooltip>
      </>
    );
    // return (
    //   <>
    //     {props.label ? (
    //       <Label className="mb-2">
    //         {props.label}
    //         {props.required ? (
    //           <span className="text-destructive">*</span>
    //         ) : null}
    //       </Label>
    //     ) : null}
    //     <Popover
    //       modal={true}
    //       onOpenChange={(value) => props.onOpenChange(value)}
    //     >
    //       <PopoverTrigger asChild>
    //         <Button
    //           onClick={() => null}
    //           disabled={props.disabled}
    //           variant={"outline"}
    //           className={cn(
    //             `pl-3 mt-2 text-left font-normal w-full pr-2 relative ${
    //               props.active
    //                 ? "border border-secondary"
    //                 : props.hideBorder
    //                 ? "border-transparent shadow-transparent"
    //                 : "border border-gray-300"
    //             } flex justify-between items-center overflow-hidden ${
    //               !value ? "text-muted-foreground" : ""
    //             }`
    //           )}
    //         >
    //           <Tooltip title={renderName(value)}>
    //             <div className="flex flex-1 items-center">
    //               {props.leftIcon ? (
    //                 <props.leftIcon
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     props.onIconClick && props.onIconClick();
    //                   }}
    //                   className="mr-2 h-4 w-4 opacity-90 text-muted-foreground"
    //                 />
    //               ) : props.icon ? (
    //                 <props.icon
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     props.onIconClick && props.onIconClick();
    //                   }}
    //                   className="mr-2 h-4 w-4 opacity-90 text-muted-foreground"
    //                 />
    //               ) : null}
    //               <span  className="truncate w-[70%]">
    //                 {renderName(value) || props.placeholder}
    //               </span>
    //             </div>
    //           </Tooltip>
    //         { props.loading ? <ActivityIndicator/> :  <HiChevronDown className=" h-4 w-4 opacity-80 absolute right-2" />}
    //         </Button>
    //       </PopoverTrigger>
    //       <PopoverContent className="w-auto p-0" align="end">
    //         <div className="w-80  p-4 overflow-hidden">
    //           <div className="flex flex-1 justify-between items-center">
    //             <SearchInput
    //               focus
    //               loading={props.searching}
    //               onChange={(event) => props.setText(event.target.value)}
    //             />
    //           </div>
    //           <Separator className="my-4" />
    //           {props.isProduct ? (
    //             <div className="mb-2">
    //               <MainButton
    //                 type="button"
    //                 onClick={() => props.onAdd && props.onAdd()}
    //                 label="New Item"
    //               />
    //             </div>
    //           ) : null}
    //           {props.isProduct ? (
    //             <div className="mb-4">
    //               <MainButton
    //                 type="button"
    //                 onClick={() => props.onAddNewGroup && props.onAddNewGroup()}
    //                 label="New Group"
    //               />
    //             </div>
    //           ) : null}
    //           <div
    //             ref={props.rootRef}
    //             className="w-full max-h-[19rem] overflow-auto"
    //           >
    //             {props.data.map((m: any) => {
    //               const isSelected = value
    //                 ? (value?.id && value?.id === m?.id) ||
    //                   (value?.code && value?.code === m?.code) ||
    //                   value === m?.value
    //                 : false;

    //               return props.renderOption ? (
    //                 <PopoverClose className="w-full text-left">
    //                   {props.renderOption(m, isSelected, props.onSelectedValue)}
    //                 </PopoverClose>
    //               ) : (
    //                 <PopoverClose
    //                   onClick={() => {
    //                     props.onSelectedValue(m);
    //                   }}
    //                   ref={props.sentryRef}
    //                   className="p-2 hover:bg-primary/5 rounded cursor-pointer flex items-center w-full"
    //                   key={m.id || m.code}
    //                 >
    //                   {props.icon ? (
    //                     <props.icon className="mr-2 h-6 w-6" />
    //                   ) : null}
    //                   <span className="text-left font-medium flex flex-1">
    //                     {renderName(m)}
    //                   </span>
    //                   {props.isProduct ? (
    //                     <span className="text-secondary mr-1">
    //                       {m.taxIncluded === undefined
    //                         ? "Preset Group"
    //                         : `$${formatMoney(m.price)}`}
    //                     </span>
    //                   ) : null}
    //                   {isSelected ? (
    //                     <HiCheckCircle className="text-primary" size={20} />
    //                   ) : (
    //                     <HiChevronRight />
    //                   )}
    //                 </PopoverClose>
    //               );
    //             })}

    //             {props.loading ? (
    //               <div
    //                 style={{ overscrollBehavior: "contain" }}
    //                 className="flex justify-center items-center"
    //               >
    //                 <SmallListSkeleton />
    //               </div>
    //             ) : null}
    //           </div>
    //         </div>
    //       </PopoverContent>
    //     </Popover>
    //   </>
    // );
  };

  if (!props.label) {
    return renderContent();
  }

  return <div className="mt-4 w-full">{renderContent()}</div>;
}
