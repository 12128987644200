import axios from "axios";
import { RESPONSE, errorHandler } from "./error_handler";
import {
  apiHeaders,
  apiHeadersLogout,
  deleteAPI,
  getAPI,
  postApiFormData,
  putAPI,
} from ".";
import { getDeviceInfo, resizeImage } from "src/utilities/functions.utilities";
import { AUTH, FILE } from "src/interfaces/auth.interface";
import { ENTITY } from "src/interfaces/init.interface";
import { getAuth, saveAuth } from "src/utilities/storage.utilities";

export async function login(username: String, password: String) {
  const deviceInfo = getDeviceInfo();
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_MAIN_API}/auth/token`,
      {
        username,
        password,
        deviceInfo: {
          deviceId: deviceInfo.deviceID,
          deviceType: deviceInfo.deviceType,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    res?.data?.code.toString();
    return res?.data as RESPONSE;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      return null;
    }
    return error?.response?.data as RESPONSE;
  }
}

export async function refreshToken() {
  const deviceInfo = getDeviceInfo();
  const auth: AUTH | null = getAuth();
  if (!auth) {
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_MAIN_API}/auth/token/refresh`,
      {
        refreshToken: auth.refreshToken,
        deviceInfo: {
          deviceId: deviceInfo.deviceID,
          deviceType: deviceInfo.deviceType,
        },
      },
    );
    const data = res?.data?.data as AUTH;
    saveAuth(data);
    return data;
  } catch (error: any) {
    if (!error.response && error.code === "ERR_NETWORK") {
      return null;
    }
    return errorHandler(error?.response?.data as RESPONSE, true);
  }
}

export async function logOut(callback: (res: RESPONSE) => void) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_MAIN_API}/auth/token/logout`,
      null,
      {
        headers: (await apiHeadersLogout()) as any,
      }
    );

    callback(res?.data);
    res?.data?.code.toString();
    return res?.data as RESPONSE;
  } catch (error: any) {
    return errorHandler(error?.response?.data as RESPONSE, true);
  }
}

export async function initData() {
  const url = `${process.env.REACT_APP_MAIN_API}/utility`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function estimateSetting() {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate-setting`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function getFieldListItems(type: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/field-list?type=${type}`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function uploadImage(path: string, image: FILE) {
  const img: any = await resizeImage(image.base64 ?? "");
  image.base64 = img;
  const url = `${process.env.REACT_APP_MAIN_API}/${path}`;
  const res = await postApiFormData(url, image);
  return res.data;
}

export async function deleteItem(id: number | string, path: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/${path}/${id}`;
  const data = await deleteAPI(url);
  return data as RESPONSE;
}

export async function image(path: string) {
  const url = `${process.env.REACT_APP_MAIN_API}/${path}`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function getImages(path: string, id: string | number) {
  const url = `${process.env.REACT_APP_MAIN_API}/${path}/${id}/get-image`;
  const data = await getAPI(url);
  return data as RESPONSE;
}

export async function updateEntity(doc: ENTITY) {
  const url = `${process.env.REACT_APP_MAIN_API}/entity/${doc.id}`;
  const data = await putAPI(url, doc);
  return data as RESPONSE;
}
