import { HiCog8Tooth, HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import images from "src/assets/images";
import MainButton from "src/components/MainButton";

export default function PageHeader() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-1 h-14 bg-white p-4 drop-shadow justify-between items-center mb-2">
      <img alt="logo" className="h-9 w-30" src={images.LYNQiFY} />
      <div className="flex">
        <MainButton
          variant={"link"}
          type={"button"}
          onClick={() => navigate(-1)}
          icon={HiXMark}
        />
      </div>
    </div>
  );
}
