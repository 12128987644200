import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ModalNavigationBar } from "./ModalNavigationBar";
import routeConfig from "../routes/route.config";
import { useLocation } from "react-router-dom";
import DropDownProfile from "./DropdownProfile";
export default function SideBar() {

  const pathname = useLocation().pathname;
  const routes = React.useMemo(() => {
    const parent = routeConfig.find((m) => pathname.includes(m.route));
    if (parent && parent.subRoutes && parent.subRoutes?.length > 0) {
      const route = parent.subRoutes.find((m) =>
        pathname.includes(m.route)
      );
      return [parent, route];
    } else {
      return [parent];
    }
  }, [pathname]);
  return (
    <div className="flex drop-shadow">
    <div className="h-14 bg-muted  flex flex-1 justify-between items-center px-2 border-b">
      <div className="flex lg:hidden">
        <ModalNavigationBar />
      </div>
        <div className="flex">
          {routes.map((route, index) => {
            const isParent = routes?.length > 0 && index === 0;
            return (
              <span
                key={uuidv4()}
                className={` pl-2 font-semibold text-primary  ${
                  isParent && routes.length > 1 ? "opacity-50" : ""
                }`}
              >
                {(!isParent ? "/ " : "") + route?.name}
              </span>
            );
          })}
        </div>
      <DropDownProfile />
    </div>
    </div>
  );
}
