import { HiMiniPlus } from "react-icons/hi2";
import { OurTeamSection } from "./OurTeamSection";
import ProposalStore from "src/stores/proposal.store";
import { inject, observer } from "mobx-react";
import MainButton from "src/components/MainButton";

interface Props {
  proposal?: ProposalStore;
}

function ContentOurTeam(props: Props) {
  const { sectionDetail, processingContentUpdate, onUpdateSection } =
    props.proposal ?? {};

  return (
    <div className="mb-16">
      {sectionDetail?.meta.map((parent) => {
        return (
          <OurTeamSection
            key={parent.id}
            parent={parent}
            onChnage={(child) => {
              props.proposal?.onUpdateOurTeamPayload(parent, child);
            }}
            onAfterDelete={() => {
              props.proposal?.onDeleteOurTeam(parent);
            }}
          />
        );
      })}
      <div className="flex justify-end mt-8">
        <div className="flex">
          <MainButton
            onClick={() => {
              props.proposal?.onAddOurTeam();
            }}
            variant={"outline"}
            loading={props.proposal?.processingContentUpdate}
            icon={HiMiniPlus}
            size={18}
            label="Add Member"
            className="flex"
          />
        </div>
      </div>
      <MainButton
        disabled={props.proposal?.disableSave}
        onClick={onUpdateSection}
        loading={processingContentUpdate}
        label="Save"
        className="mt-8"
      />
    </div>
  );
}

export default inject("proposal")(observer(ContentOurTeam));
