import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import MainInput from "../../../../components/MainInput";
import { TableHead } from "../../../../components/ui/table";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { HiOutlineTrash } from "react-icons/hi2";
import { DeleteButton } from "../../../../components/DeleteButton";
import { onUpdateUdfName } from "src/services/udf.service";
import { RESPONSE } from "src/APIs/error_handler";

interface Props {
  item: ENTITY_CONFIG;
  onUpdated: (v: ENTITY_CONFIG) => void;
  onDeleted: (v: ENTITY_CONFIG) => void;
  isLast: boolean;
}

const CrmTableHeaderItem = (props: Props) => {
  const [name, setName] = useState("");
  const [editing, setEditing] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const readOnly = props.item.visible === false;

  useEffect(() => {
    setName(props.item.displayName);
  }, [props.item.displayName]);

  const onUpdateName = (text: string) => {
    if (props.item.displayName === text) {
      setHover(false);
      setEditing(false);
      return;
    }
    setLoading(true);
    onUpdateUdfName(props.item.id.toString(), text).then((res) => {
      const data = {
        ...props.item,
        displayName: text,
      };
      props.onUpdated(data);
      setLoading(false);
      setHover(false);
      setEditing(false);
    });
  };

  return (
    <TableHead
      className={`min-w-[150px] pl-0 ${
        props.isLast ? " pr-[29px]" : "pr-0"
      } rounded-tl-md border-r-[1px]`}
    >
      {editing ? (
        <div className="flex relative">
          <MainInput
            onBlur={(e) => {
              console.log(e.target.value);
              onUpdateName(e.target.value);
            }}
            focus
            noMarginTop
            value={name}
            onChange={setName}
            onEnter={(text: string) => {
              onUpdateName(text);
            }}
          />
          {loading ? (
            <Loader2 className="h-[15px] w-[15px] animate-spin absolute right-2 top-[8px]" />
          ) : null}
        </div>
      ) : (
        <div
          onMouseOver={readOnly ? undefined : () => setHover(true)}
          onMouseLeave={readOnly ? undefined : () => setHover(false)}
          onClick={readOnly ? undefined : (e) => setEditing(true)}
          className={`flex justify-between ml-0 cursor-text rounded-sm px-2 py-2 ${
            readOnly ? "" : "hover:bg-white"
          }`}
        >
          {name}
          {hover ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DeleteButton
                renderComponent={
                  <HiOutlineTrash className="w-4 h-4 cursor-pointer" />
                }
                deleted={(result: RESPONSE) => {
                  props.onDeleted(props.item);
                }}
                id={props.item.id}
                path={"udf"}
              />
            </div>
          ) : null}
        </div>
      )}
    </TableHead>
  );
};

export default CrmTableHeaderItem;
