import { stringAvatar } from "src/utilities/functions.utilities";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { useState } from "react";
  
interface Props {
  name:string
  url?:string
}
export function ProfileAvatar(props:Props) {
    const [isError,setError] = useState(false);
    if(props.url && !isError) return <img
     onError={() => setError(true)}
     style={{height:35.59, width:35.59, borderRadius:100}} src={props.url} />
    return (
      <Avatar className="bg-primary">
        <AvatarFallback className="bg-primary font-bold text-white">{stringAvatar(props.name) ?? ""}</AvatarFallback>
      </Avatar>
    )
  }