import { Outlet } from "react-router-dom";
import SubSidebar from "src/components/subSidebar/SubSidebar";
import { proposal } from "src/routes/routeNav.config";

export default function ProposalLayout() {
  return (
    <div className="flex flex-1 flex-col">
      <SubSidebar routes={proposal} />
      <Outlet />
    </div>
  );
}
