interface Props {
    title:string
    key?:string
}

export default function TitleWithBox(props:Props){
    return <div id={props.key} className="flex bg-primary p-2 w-fit items-center rounded-sm">
        <div className="h-[22px] w-2 rounded bg-secondary mr-1" />
        <p className="text-title text-white uppercase font-bold">{props.title}</p>
    </div>
}