import { IoCheckbox, IoSquare } from "react-icons/io5";

interface Props {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}
export default function CheckButton(props: Props) {
  return (
    <div
      onClick={() => (props.disabled ? null : props.onChange())}
      className={` ml-1 cursor-pointer ${
        props.disabled ? "opacity-25 hover:opacity-25" : "hover:opacity-80"
      }`}
    >
      {props.checked ? (
        <IoCheckbox className="text-secondary cursor-pointer" size={22} />
      ) : (
        <IoSquare className="text-slate-200 cursor-pointer" size={22} />
      )}
    </div>
  );
}
