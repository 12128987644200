import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "src/components/ui/dialog";
import { DialogHeader, DialogFooter } from "src/components/ui/dialog";
import { HiCalculator } from "react-icons/hi2";
import { PRODUCT } from "src/interfaces/product.interface";
import { AutoCompleteWithAsync } from "src/components/autoComplete/AutoCompleteWithAsync";
import { useEffect, useState } from "react";
import { PRODUCTION_RATE } from "src/interfaces/estimate.interface";
import MainInput from "src/components/MainInput";
import { nullString, numberOnly } from "src/utilities/functions.utilities";
import MainButton from "src/components/MainButton";
import { Tooltip } from "@mui/material";

interface Props {
  product: PRODUCT | null;
  productionRate: PRODUCTION_RATE | null;
  qty: string;
  onSave: (prd: PRODUCTION_RATE | null, qty: string, minimum: string) => void;
  itemQty: string;
}

export function ProductionRate(props: Props) {
  const [productionRate, setProductionRate] = useState<PRODUCTION_RATE | null>(
    null
  );
  const [qty, setQty] = useState<string>("0");

  useEffect(() => {
    setProductionRate(props.productionRate);
    setQty(props.qty);
  }, [props.product, props.productionRate, props.qty]);

  function onSave() {
    if (productionRate?.rate && qty !== undefined) {
      const minimum = Math.ceil(Number(qty) / productionRate?.rate).toString();
      props.onSave(productionRate, qty, minimum);
    } else {
      props.onSave(null, "0", "0");
    }
  }

  const isWarning = () => {
    if (props.qty && props.productionRate && qty) {
      const minimum = Math.ceil(
        Number(qty) / props.productionRate?.rate
      ).toString();
      return Number(props.itemQty) < Number(minimum);
    }
    return false;
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Tooltip
          title={
            isWarning()
              ? "Your current quantity is smaller than the minimum quantity."
              : ""
          }
        >
          <div className="mx-1 cursor-pointer hover:opacity-90 mt-2">
            <HiCalculator
              className={
                isWarning() ? "text-secondary" : "text-muted-foreground"
              }
            />
          </div>
        </Tooltip>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <div className="flex flex-col gap-2 py-4">
          <DialogHeader>
            <DialogTitle>Production Rate</DialogTitle>
          </DialogHeader>
          <div className="flex gap-2">
            <AutoCompleteWithAsync
              placeholder="Choose type"
              label="Type"
              value={productionRate}
              onSelectedValue={(value) => setProductionRate(value)}
              path={`product/${props.product?.id}/get-production-rate`}
            />
            <MainInput
              disabled
              placeholder="Enter quantity"
              value={productionRate?.rate.toString() ?? "0"}
              onChange={() => null}
              label="Rate"
            />
          </div>
          <div className="flex gap-2">
            <MainInput
              placeholder="Enter quantity"
              value={qty}
              onChange={(t) => setQty(numberOnly(t))}
              label="Quantity"
            />
            <MainInput
              disabled
              placeholder="Enter quantity"
              value={
                productionRate && nullString(qty, true)
                  ? Math.ceil(Number(qty) / productionRate?.rate).toString()
                  : ""
              }
              onChange={() => null}
              label="Minimum Quantity"
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose>
            <MainButton
              onClick={onSave}
              label="Save changes"
              type="submit"
            />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
