import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import "clipboard-polyfill/overwrite-globals";
import { inject, observer } from "mobx-react";
import HTMLString from "react-html-string";
import { AiOutlineCopy } from "react-icons/ai";
import { toast } from "react-toastify";
import { COLORS } from "src/assets/colors";
import { LAYOUTS } from "src/assets/layouts";
import RichTextEditor from "src/components/richTextEditor";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import AuthStore from "src/stores/auth.store";
import {
  formatMoney,
  orderByAsc,
  plainTextConverter,
  sum,
} from "src/utilities/formatter.utilities";
import { title } from "src/utilities/functions.utilities";
import CollapsedButton from "../CollapsedButton";
import { getSubGroups } from "../estimate.utility";
import "./style.scss";

interface Props {
  items: GROUP_OR_ITEM[];
  exclusion: string;
  taxRate: number | null;
  auth?: AuthStore;
}

const components = {
  ul: (props: any) => (
    <ul
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  li: (props: any) => (
    <li
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  p: (props: any) => (
    <p
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  strong: (props: any) => (
    <strong
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  br: (props: any) => (
    <br
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  hr: (props: any) => (
    <hr
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  h1: (props: any) => (
    <h1
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  h2: (props: any) => (
    <h2
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  h3: (props: any) => (
    <h3
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  h4: (props: any) => (
    <h4
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  h5: (props: any) => (
    <h5
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
  b: (props: any) => (
    <b
      {...props}
      style={{
        userSelect: "text",
        cursor: "text",
      }}
    />
  ),
};

function SummarySnapshot(props: Props) {
  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);
  const [levels, setLevels] = useState<number[]>([]);
  const [selectedLevel, setLevel] = useState(1);
  const onCollapse = (id: string) => {
    const isExist = collapsedIds.includes(id);
    if (isExist) {
      setCollapsedIds([...collapsedIds.filter((m) => m !== id)]);
    } else {
      setCollapsedIds([...collapsedIds, id]);
    }
  };

  useEffect(() => {
    const groups = props.items.filter((m) => m.isGroup);
    const levels = orderByAsc(groups, "level").map((m) => m.level);
    const groupByLevel = levels.filter(function (item, pos) {
      return levels.indexOf(item) === pos;
    });
    setLevels(groupByLevel);
  }, [props.items]);

  const groups = orderByAsc(
    props.items
      .filter(
        (m) =>
          (m.level === 1 && m.isGroup) ||
          (m.parentGroupCode === null && m.product)
      )
      .map((gr) => {
        if (gr.isGroup) {
          const subGroups = getSubGroups(gr, props.items);
          const groupKeys = subGroups.map((m) => m.code).concat(gr.code);
          const allItems = props.items.filter(
            (m) =>
              !m.isGroup &&
              m.parentGroupCode &&
              groupKeys.includes(m.parentGroupCode)
          );
          const totalPrice = sum(allItems, "total");
          const totalMarkup = sum(allItems, "markup");
          const total = totalPrice + totalMarkup;
          return { group: gr, total: `$${formatMoney(total)}` };
        } else {
          gr.name = gr.description || "Untitle";
          return { group: gr, total: `$${formatMoney(gr.total)}` };
        }
      }),
    "group.sequence"
  );
  const total = formatMoney(
    sum(props.items, "total") +
      sum(props.items, "markup") +
      sum(props.items, "disperse")
  );

  let content = "<p>PRICINGS</p><br>";

  const drawGroup = (
    parentCode: string | null,
    value: any,
    i: number,
    isView?: boolean
  ) => {
    const children = props.items.filter(
      (ch) =>
        ch?.level !== 1 &&
        ch.parentGroupCode === value.group?.code &&
        ch.isGroup &&
        collapsedIds.includes(value.group.id)
    );

    const hasChildren =
      props.items.filter(
        (ch) =>
          ch?.level !== 1 &&
          ch.parentGroupCode === value.group?.code &&
          ch.isGroup
      ).length > 0;

    const groups = orderByAsc(
      children.map((gr) => {
        if (gr.isGroup) {
          const subGroups = getSubGroups(gr, props.items);
          const groupKeys = subGroups.map((m) => m.code).concat(gr.code);
          const allItems = props.items.filter(
            (m) =>
              !m.isGroup &&
              m.parentGroupCode &&
              groupKeys.includes(m.parentGroupCode)
          );
          const totalPrice = sum(allItems, "total");
          const totalMarkup = sum(allItems, "markup");
          const disperse = sum(allItems, "disperse");
          const total = totalPrice + totalMarkup + disperse;
          return { group: gr, total: `$${formatMoney(total)}` };
        } else {
          gr.name = gr.description ?? "Untitle";
          return { group: gr, total: `$${formatMoney(gr.total)}` };
        }
      }),
      "group.sequence"
    );
    const code = `${i + 1}.`;
    const mergedCode = value?.group?.level === 1 ? code : parentCode + code;
    const createSpace = () => {
      return Array.from({ length: value?.group?.level }, (v, k) => k + 1)
        .map((lv) => {
          return " ";
        })
        .join("");
    };
    if (isView && value.total !== "$0.00") {
      return (
        <div className="mb-1">
          <div className="flex">
            {<p>{value?.group?.level === 1 ? "" : createSpace()}</p>}
            {hasChildren ? (
              <CollapsedButton
                isSummary
                rowId={value?.group?.id}
                onChange={() => onCollapse(value.group.id)}
                checked={!collapsedIds.includes(value.group.id)}
              />
            ) : (
              <div className="w-[28px]" />
            )}
            <p>
              {mergedCode}{" "}
              {title(value?.group?.name, props.auth?.alphabetic ?? "none")}:{" "}
              {value.total}
            </p>
          </div>
          {groups.map(
            (value, index) => drawGroup(mergedCode, value, index, true) as any
          )}
        </div>
      );
    }
    if (value.total !== "$0.00") {
      content = content.concat(
        `<p>${
          value?.group?.level === 1 ? "" : createSpace()
        }${mergedCode} ${title(
          value?.group?.name,
          props.auth?.alphabetic ?? "none"
        )}:  ${value.total}</><br></p>`
      );
    }
    groups
      .filter((value) => value.total !== "$0.00")
      .forEach((value, index) => {
        drawGroup(mergedCode, value, index);
      });
  };

  const renderDescription = (text: string) => {
    if (text.length === 0) return "";
    const texts = text.split(/\r|\r\n|\n/);
    texts.forEach((m) => {
      content = content.concat(`<p>${m.length === 0 ? " " : m}</p>\n`);
    });
  };

  const renderDescriptionView = (text: string) => {
    if (text.length === 0) return "";
    let content = "";
    const texts = text.split(/\r|\r\n|\n/);
    texts.forEach((m) => {
      content = content.concat(`<p>${m.length === 0 ? " " : m}</p>`);
    });
    return content;
  };

  groups.forEach((value, index) => {
    drawGroup(`${index + 1}.`, value, index);
  });

  content = content.concat(`Total: $${total} + Tax `);
  content = content.concat("<br>EXCLUSION<br>");
  renderDescription(props.exclusion);
  async function handler() {
    const item = new ClipboardItem({
      "text/plain": new Blob([plainTextConverter(content)], {
        type: "text/plain",
      }),
    });
    navigator.clipboard.write([item]);
  }

  const renderLevelButton = (name: number) => {
    const isActive = name === selectedLevel;
    return (
      <div
        onClick={() => {
          setLevel(name);
          const levels = props.items
            .filter((m) => m.level < name)
            .map((m) => m.id ?? "");
          setCollapsedIds([...levels]);
        }}
        className={`mb-4 flex min-w-20 cursor-pointer items-center justify-center rounded-full border px-2 py-1 ${
          isActive ? "bg-primary font-bold text-white" : "bg-slate-100"
        }`}
      >
        {name.toString()}
      </div>
    );
  };

  return (
    <form
      style={{
        backgroundColor: COLORS.white,
        width: "55%",
        padding: LAYOUTS.padding_big,
        borderRadius: LAYOUTS.radius,
        boxShadow: COLORS.shadow,
        position: "relative",
      }}
    >
      <div id={"text"}>
        <div className="flex flex-wrap space-x-1 pr-4">
          {levels.map((m) => renderLevelButton(m))}
        </div>
        <p className="font-bold">PRICINGS</p>
        {groups.map((value, i) => drawGroup(null, value, i, true) as any)}
        <p className="mt-4 font-bold">Total: ${total} + Tax </p>
        <p className="mt-4 font-bold">EXCLUSION</p>
        <RichTextEditor value={props.exclusion} readonly={true} />
      </div>
      <IconButton
        onClick={async () => {
          await handler();
          toast("Copied to clipboard", {
            toastId: "success",
            type: "success",
            hideProgressBar: true,
            autoClose: 800,
            closeOnClick: false,
            closeButton: false,
            position: "top-center",
          });
        }}
        style={{ position: "absolute", right: 15, top: 15 }}
      >
        <AiOutlineCopy />
      </IconButton>
    </form>
  );
}

export default inject("auth")(observer(SummarySnapshot));
