import { RESPONSE } from "src/APIs/error_handler";
import {
  createUdf,
  createUdfLink,
  createUdfList,
  getUdf,
  getUdfList,
  udfListReorder,
  udfReorder,
  updateUdfEnable,
  updateUdfLink,
  updateUdfListColor,
  updateUdfListDefault,
  updateUdfListValue,
  updateUdfName,
} from "src/APIs/udf.api";
import { CRM_TYPE } from "src/interfaces/crm.interface";
import { UDF, UDF_LINK_CREATE, UDF_LIST } from "src/interfaces/udf.interface";

export async function onGetUdf(entityCode: string, isAll?: boolean) {
  const res = await getUdf(entityCode, isAll);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onGetUdfList(udfid: string) {
  const res = await getUdfList(udfid);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateUdfName(udfId: string, name: string) {
  try {
    const res = await updateUdfName(udfId, name);
    if (res.code === "200") {
      return res;
    }
  } catch (error) {
    return error as RESPONSE;
  }
}

export async function onUpdateUdfEnable(udfId: string, enable: boolean) {
  try {
    const res = await updateUdfEnable(udfId, enable);
    if (res.code === "200") {
      return res;
    }
  } catch (error) {
    return error as RESPONSE;
  }
}

export async function onCreateUdf(entityCode: string, udf: UDF) {
  const res = await createUdf(entityCode, udf);
  if (res.code === "200") {
    return res.data as UDF;
  } else {
    return null;
  }
}

export async function onUdfReorder(entityCode: string, udfs: UDF[]) {
  const res = await udfReorder(entityCode, udfs);
  if (res.code === "200") {
    return res as RESPONSE;
  } else {
    return null;
  }
}

export async function onCreateUdfList(udfId: string, udfList: UDF_LIST) {
  const res = await createUdfList(udfId, udfList);
  if (res.code === "200") {
    return res.data as UDF_LIST;
  } else {
    return null;
  }
}

export async function onUpdateUdfListValue(udfList: UDF_LIST) {
  const res = await updateUdfListValue(udfList);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUpdateUdfListColor(udfList: UDF_LIST) {
  const res = await updateUdfListColor(udfList);
  if (res.code === "200") {
    return res.data as UDF_LIST;
  } else {
    return null;
  }
}

export async function onUpdateUdfListDefault(udfList: UDF_LIST) {
  const res = await updateUdfListDefault(udfList);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onUdfListReorder(udfId: string, udfLists: UDF_LIST[]) {
  const res = await udfListReorder(udfId, udfLists);
  if (res.code === "200") {
    return res as RESPONSE;
  } else {
    return null;
  }
}

export async function onCreateUdfLink(
  crmType: CRM_TYPE,
  payload: UDF_LINK_CREATE
) {
  const res = await createUdfLink(crmType, payload);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}
export async function onUpdateUdfLink(id: String, value: String) {
  const res = await updateUdfLink(id, value);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}
