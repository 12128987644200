import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../../components/ui/sheet";
import { useEffect, useState } from "react";
import MainButton from "src/components/MainButton";
import { HiOutlineMap, HiOutlineUsers } from "react-icons/hi2";
import { nullString } from "src/utilities/functions.utilities";
import NameAndContact from "./NameAndContact";
import Address from "./Address";
import CUSTOMER, { ADDRESS } from "src/interfaces/customer.interface";
import { Checkbox } from "src/components/CheckBox";
import AssociatedContact from "./AssociatedContact";
import { phoneString } from "src/utilities/formatter.utilities";
import { onCreateContact } from "src/services/crm.service";
import TableStore from "src/components/tableComponents/TableStore";
import { inject, observer } from "mobx-react";
import { AlertDestructive } from "src/components/AlertDestructive";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import CRMStore from "src/stores/crm.store";
interface Props {
  title?: string;
  open: boolean;
  onOpen: (value: boolean) => void;
  table?: TableStore;
  crm?: CRMStore;
}

function AddContact(props: Props) {
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");

  const [displayName, setDisplayName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [fax, setFax] = useState("");
  const [website, setWebsite] = useState("");
  const [spouseInfo, setSpouseInfo] = useState("");
  const [sameAsBillingAddress, setSameAsBillingAddress] = useState(false);
  const [shippingAddress, setShippingAddress] = useState<ADDRESS | null>(null);
  const [assoAddress, setAssoAddress] = useState<ADDRESS | null>(null);

  const [sameAsMainAddress, setSameAsMainAddress] = useState(false);
  const [assoFullName, setAssoFullName] = useState("");
  const [assoRelationship, setAssoRelationship] = useState("");
  const [assoEmail, setAssoEmail] = useState("");
  const [assoPhone, setAssoPhone] = useState("");

  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [billingAddress, setBillingAddress] = useState<ADDRESS | null>(null);

  useEffect(() => {
    onDisplayName(title, firstName, middleName, lastName, suffix);
  }, [title, firstName, middleName, lastName, suffix]);

  useEffect(() => {
    if (props.crm?.contactDetails) {
      const contact = props.crm.contactDetails;
      setTitle(contact.title ?? "");
      setFirstName(contact.firstName ?? "");
      setMiddleName(contact.middleName ?? "");
      setSuffix(contact.suffix ?? "");
      setDisplayName(contact.displayName ?? "");
      setCompanyName(contact.companyName ?? "");
      setEmail(contact.email ?? "");
      setPhone(contact.telephone1 ?? "");
      setMobilePhone(contact.telephone2 ?? "");
      setFax(contact.fax ?? "");
      setWebsite(contact.website ?? "");
      setSpouseInfo(contact.spouse ?? "");
      setSameAsBillingAddress(contact.sameBillingShippingAddress);
      setBillingAddress(contact.billingAddress);
      setShippingAddress(contact.shippingAddress);
    } else {
      setTitle("");
      setFirstName("");
      setMiddleName("");
      setSuffix("");
      setDisplayName("");
      setCompanyName("");
      setEmail("");
      setPhone("");
      setMobilePhone("");
      setFax("");
      setWebsite("");
      setSpouseInfo("");
      setBillingAddress(null);
      setShippingAddress(null);
      setSameAsBillingAddress(false);
    }
  }, [props.open, props.crm?.contactDetails]);

  function isValid() {
    let msg: string[] = [];
    const errorDisplayName = nullString(displayName) === null;
    if (errorDisplayName) {
      msg = msg.concat("Please enter your display name. ");
    }
    const errorBillingAddress = billingAddress === null;
    if (errorBillingAddress) {
      msg = msg.concat("Please enter your billing address. ");
    }
    const errorShippingAddress = shippingAddress === null;
    if (errorShippingAddress) {
      msg = msg.concat("Please enter your shipping address. ");
    }

    return msg;
  }

  function onCreate() {
    const validMessage = isValid();
    if (validMessage.length > 0) {
      setErrorMsg(validMessage);
      return;
    }
    setProcessing(true);
    const contact = props.crm?.contactDetails;
    const doc: CUSTOMER = {
      id: contact?.id,
      title: nullString(title),
      firstName: nullString(firstName),
      middleName: nullString(middleName),
      lastName: nullString(lastName),
      suffix: nullString(suffix),
      displayName: nullString(displayName),
      companyName: nullString(companyName),
      gender: null,
      telephone1: phoneString(phone),
      telephone2: phoneString(mobilePhone),
      email: nullString(email),
      fax: nullString(fax),
      website: nullString(website),
      note: null,
      spouse: nullString(spouseInfo),
      billingAddress: billingAddress,
      sameBillingShippingAddress: sameAsBillingAddress,
      shippingAddress: shippingAddress,
    };

    if (contact?.id) {
      props.crm?.onUpdateContact(doc).then((res) => {
        if (!res?.data) {
          setProcessing(false);
          return;
        }
        props.onOpen(false);
        setProcessing(false);
        setErrorMsg([]);
        props.table?.updateData(res?.data);
      });
    } else {
      onCreateContact(doc).then((res) => {
        if (!res?.data) {
          setProcessing(false);
          return;
        }
        props.onOpen(false);
        setProcessing(false);
        props.table?.updateTable(res?.data);
        setErrorMsg([]);
      });
    }
  }

  const onDisplayName = (
    title?: string,
    fName?: string,
    mName?: string,
    lName?: string,
    subffix?: string
  ) => {
    let name = "";
    if (title) {
      name = `${title}`;
    }
    if (fName) {
      name = `${name} ${fName} `;
    }
    if (mName) {
      name = `${name} ${mName} `;
    }
    if (lName) {
      name = `${name} ${lName} `;
    }
    if (subffix) {
      name = `${name} ${subffix}`;
    }
    setDisplayName(name);
  };

  return (
    <Sheet open={props.open}>
      <SheetContent className="h-[99%] overflow-auto w-[50%]">
        <SheetHeader className="flex border-b mb-6 flex-row items-center justify-between pb-2">
          <SheetTitle className="mt-2">
            {props.title ?? "Add New Contact"}
          </SheetTitle>
          <CloseDrawerButton onClick={() => props.onOpen(false)} />
        </SheetHeader>
        <div className="h-full w-full">
          {errorMsg.length > 0 ? (
            <div className="bg-white m-4">
              <AlertDestructive
                messages={errorMsg}
                title="Missing Informations"
              />
            </div>
          ) : null}
          <div className="w-full h-full flex gap-4 flex-col">
            <NameAndContact
              title={title}
              firstName={firstName}
              middleName={middleName}
              lastName={lastName}
              suffix={suffix}
              displayName={displayName}
              companyName={companyName}
              email={email}
              phone={phone}
              mobilePhone={mobilePhone}
              website={website}
              fax={fax}
              spouse={spouseInfo}
              onFirstName={(t) => setFirstName(t)}
              onLastName={(t) => setLastName(t)}
              onMiddleName={(t) => setMiddleName(t)}
              onSuffix={(t) => setSuffix(t)}
              onCompanyName={(t) => setCompanyName(t)}
              onDisplayName={(t) => setDisplayName(t)}
              onEmail={(t) => setEmail(t)}
              onFax={(t) => setFax(t)}
              onMobilePhone={(t) => setMobilePhone(t)}
              onPhone={(t) => setPhone(t)}
              onSpouse={(t) => setSpouseInfo(t)}
              onTitle={(t) => setTitle(t)}
              onWebsite={(t) => setWebsite(t)}
            />
            <div className="flex items-center mb-1 font-semibold mt-6">
              <HiOutlineMap className="mr-2" size={18} />
              <span className="">Address</span>
            </div>
            <div className="bg-muted p-4 rounded-md">
              <Address
                onChange={(value) => setBillingAddress(value)}
                value={billingAddress}
                label={"Billing Address"}
              />
              <div className="mb-6" />
              <Address
                sameAsBillingAddress={sameAsBillingAddress}
                onSameAS={(v) => setSameAsBillingAddress(v)}
                otherValue={billingAddress}
                onChange={(value) => setShippingAddress(value)}
                hasSameAs
                value={shippingAddress}
                label={"Shipping Address"}
              />
            </div>
            <div className="flex items-center mb-1 font-semibold  mt-6">
              <HiOutlineUsers className="mr-2" size={18} />
              <span className="font-semibold">Associated</span>
            </div>
            <div className="bg-muted p-4 rounded-md">
              <AssociatedContact />
              <div className="mb-6" />
              <Address
                hasSameAs
                label={"Address"}
                sameAsLabel="Same as main address"
                value={assoAddress}
                otherValue={billingAddress}
                sameAsBillingAddress={sameAsMainAddress}
                onSameAS={(v) => setSameAsMainAddress(v)}
                onChange={(value) => setAssoAddress(value)}
              />
              <div className="flex items-center gap-4 mt-4">
                <Checkbox
                  onChange={(v) => null}
                  value={false}
                  label="Option to have"
                />
                <Checkbox
                  onChange={(v) => null}
                  value={false}
                  label="Option to check"
                />
              </div>
            </div>
            <div className=" pb-12 flex self-end gap-4">
              <div className="w-32">
                <MainButton
                  variant={"outline"}
                  type="button"
                  label="Cancel"
                  onClick={() => props.onOpen(false)}
                />
              </div>
              <div className="w-32">
                <MainButton
                  loading={processing}
                  type="button"
                  label="Save"
                  onClick={() => onCreate()}
                />
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default inject("table", "crm")(observer(AddContact));
