import "./styles.scss";
import {
  FaBold,
  FaItalic,
  FaStrikethrough,
  FaReply,
  FaUnderline,
  FaListOl,
  FaListUl,
  FaParagraph,
  FaTable,
  FaLink,
  FaAlignLeft,
  FaAlignRight,
  FaAlignCenter,
  FaRulerHorizontal,
} from "react-icons/fa";
import { MdHorizontalRule, MdNoteAdd } from "react-icons/md";

import { LAYOUTS } from "src/assets/layouts";
import { COLORS } from "src/assets/colors";
import SketchColor from "../SketchColor";
export default function MenuBar({ editor, onAttachFile }: any) {
  if (!editor) {
    return null;
  }
  const isActiveBold = editor.isActive("bold");
  const isActiveItalic = editor.isActive("italic");
  const isActiveUnderline = editor.isActive("underline");
  const isActiveStrike = editor.isActive("strike");
  const isActiveBulletList = editor.isActive("bulletList");
  const isActiveOrderList = editor.isActive("orderedList");
  const isActiveUndo = !editor.can().chain().focus().undo().run();

  const alignCenter = editor.isActive({ textAlign: "center" });
  const alignLeft = editor.isActive({ textAlign: "left" });
  const alignRight = editor.isActive({ textAlign: "right" });
  const alignJustify = editor.isActive({ textAlign: "justify" });

  const setLink = () => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);
    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  };

  return (
    <div
      style={{
        display: "flex",
        paddingTop: LAYOUTS.padding,
        paddingBottom: LAYOUTS.padding,
        justifyContent: "flex-start",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: COLORS.text,
      }}
    >
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveBold ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().setParagraph().run()}
      >
        <FaParagraph />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveBold ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <FaBold />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveItalic ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <FaItalic />
      </button>

      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveUnderline ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <FaUnderline />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveStrike ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <FaStrikethrough />
      </button>
      <div
        style={{
          marginLeft: LAYOUTS.margin,
          marginTop: 6,
          color: isActiveBulletList ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
      >
        <SketchColor
          onChanged={(color: any) =>
            editor.chain().focus().setColor(color).run()
          }
        />
      </div>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: isActiveBulletList ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FaListUl />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,
          paddingRight: 3,
          color: isActiveOrderList ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FaListOl />
      </button>

      <button
        style={{
          marginLeft: LAYOUTS.margin,
          paddingRight: 3,
          color: isActiveOrderList ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
      >
        <FaTable />
      </button>

      <button
        style={{
          marginLeft: LAYOUTS.margin,
          paddingRight: 3,
          color: isActiveOrderList ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => setLink()}
      >
        <FaLink />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: alignLeft ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
      >
        <FaAlignLeft />
      </button>
      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: alignRight ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
      >
        <FaAlignRight />
      </button>

      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: alignCenter ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
      >
        <FaAlignCenter />
      </button>

      <button
        style={{
          marginLeft: LAYOUTS.margin,

          color: alignCenter ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <MdHorizontalRule size={22} />
      </button>
     
      <div style={{ flex: 1 }} />
      {onAttachFile ? (
        <button
          style={{
            marginLeft: LAYOUTS.margin,

            color: isActiveOrderList ? COLORS.primary : COLORS.text,
            cursor: "pointer",
          }}
          onClick={() => onAttachFile()}
        >
          <MdNoteAdd size={22} />
        </button>
      ) : null}
      <button
        style={{
          marginRight: LAYOUTS.margin,

          color: isActiveUndo ? COLORS.primary : COLORS.text,
          cursor: "pointer",
        }}
        onClick={() => editor.chain().focus().undo().run()}
      >
        <FaReply />
      </button>
    </div>
  );
}
