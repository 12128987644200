import { Label } from "src/components/ui/label";
import AddButtonUserDefined from "../UdfAddButton";
import UdfContactList from "./UdfList";
import { useParams } from "react-router-dom";

export default function UdfPage() {
  const { entityCode, title } = useParams();

  return (
    <div className="w-full">
      <div className="flex items-center p-6">
        <Label className="text-2xl font-bold mr-3">
          {title === ":title" ? "Contact" : title}
        </Label>
        <AddButtonUserDefined
          entityCode={entityCode === ":entityCode" ? "customer" : entityCode}
        />
      </div>
      <UdfContactList
        entityCode={
          entityCode === ":entityCode" ? "customer" : entityCode ?? "customer"
        }
      />
    </div>
  );
}
