import { useEffect, useLayoutEffect, useRef, useState } from "react";
import AddProposalTemplate from "./AddProposalTemplate";
import { useNavigate } from "react-router-dom";
import { CurrentTempate } from "./AddProposalTemplate/CurrentTemplate";
import CrmTopTableHeader from "src/app/crm/crmTableComponents/CrmTopTableHeader";
import { PROPOSAL_TEMPLATE } from "src/interfaces/proposal.interface";
import { onGetTemplateList } from "src/services/proposal.service";
import { TempateList } from "./TemplateList";
import { log } from "console";

function ProposalTemplatePage() {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<PROPOSAL_TEMPLATE[]>([]);

  useEffect(() => {
    setLoading(true);
    onGetTemplateList().then((data) => {
      if (data !== null) {
        setList(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const getFilteredList = () => {
    return list.filter((s) => s.name.includes(searchText));
  };

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  console.log(height);

  return (
    <>
      <div className="bg-white p-4 w-full">
        <CrmTopTableHeader
          valueSearch={searchText}
          onChange={setSearchText}
          onAdd={() => setOpenAdd(!openAdd)}
        />
        <div
          ref={ref}
          style={{ height: height - 150 }}
          className="overflow-auto"
        >
          <TempateList
            list={getFilteredList()}
            loading={loading}
            onClick={(item) => {
              navigate(`/proposal-details/${item.id}/${true}/${0}`);
            }}
          />
        </div>
      </div>
      <AddProposalTemplate
        open={openAdd}
        onOpen={(v) => setOpenAdd(v)}
        onCreated={(data) => {
          setList([...list, data]);
        }}
      />
    </>
  );
}

export default ProposalTemplatePage;
