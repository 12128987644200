import { UdfSkeletonList } from "../UdfSkeletonList";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { inject, observer } from "mobx-react";
import UDFStore from "src/stores/udf.store";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import EmptyData from "src/components/emptyData";
import UdfContactItem from "./UdfItem";

interface Props {
  entityCode: string;
  udf?: UDFStore;
}

function UdfList(props: Props) {
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight - 120);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (props.udf) {
      props.udf.onGetUdfs(props.entityCode);
    }
  }, [props.udf?.selectedTab]);

  const { loading, udfs } = props.udf ?? {};

  const onDragEnd = async (result: any) => {
    props.udf?.onDragUdf(props.entityCode, result);
  };

  if (loading === true) {
    return (
      <div className="p-6 pt-0">
        <UdfSkeletonList />
      </div>
    );
  }

  if (udfs?.length === 0) {
    return <EmptyData />;
  }

  return (
    <div
      ref={ref}
      style={{ height: height }}
      className="overflow-auto p-6 pt-0"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`OPTION_${uuidv4()}`}>
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {udfs?.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      index={index}
                      draggableId={item.id.toString()}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className="draggble"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <UdfContactItem
                              key={item.id}
                              entityCode={props.entityCode}
                              item={item}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default inject("udf")(observer(UdfList));
