import { useCallback, useEffect, useRef, useState } from "react";
import TitleWithBox from "src/components/TitleWithBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/tableEstimate";
import ProductDetailsRow from "./ProductDetailsRow";
import {
  ESTIMATE_DETAILS,
  ESTIMATE_TEMPLATE,
  GROUP_OR_ITEM,
} from "src/interfaces/estimate.interface";
import React from "react";

// @ts-ignore
import { orderByAsc } from "src/utilities/formatter.utilities";
import ColumnResizer from "src/components/ColumnResizer";
import {
  addNewRow,
  createItem,
  getSubGroups,
  mappingGroupOrItemToArray,
  onDeleteItem,
  onDrag,
  reorderSequence,
  updateChildrenLevel,
} from "./estimate.utility";
import { ProductArrangement } from "./ProductArrangement";
import { onScrollTo } from "src/utilities/functions.utilities";
import { TEAM } from "src/interfaces/team.interface";
import ProductDetailsGroup from "./ProductDetailsGroup";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import ProductDetailsGroupSkeleton from "./ProductDetailsGroupSkeleton";
import { inject, observer } from "mobx-react";
import AuthStore from "src/stores/auth.store";
import PopUpSelectedItems from "./PopUpSelectedItems";

export interface ESTIMATE_COLUMN {
  accessorKey: string;
  header: string;
  width: number;
}

export const COLUMNS: ESTIMATE_COLUMN[] = [
  {
    accessorKey: "item",
    header: "Item",
    width: 450,
  },
  {
    accessorKey: "catalogue",
    header: "Catalogue",
    width: 200,
  },
  {
    accessorKey: "description",
    header: "Description",
    width: 150,
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    width: 150,
  },
  {
    accessorKey: "price",
    header: "Cost",
    width: 140,
  },
  {
    accessorKey: "taxIncluded",
    header: "Tax",
    width: 140,
  },
  {
    accessorKey: "total",
    header: "Total Cost",
    width: 140,
  },
  {
    accessorKey: "totalAfterMarkup",
    header: "Total After Markup",
    width: 200,
  },
  {
    accessorKey: "team",
    header: "Team",
    width: 250,
  },
];

export const COLUMNS_TEMPLATE: ESTIMATE_COLUMN[] = [
  {
    accessorKey: "item",
    header: "Item",
    width: 450,
  },
  {
    accessorKey: "catalogue",
    header: "Catalogue",
    width: 200,
  },
  {
    accessorKey: "description",
    header: "Description",
    width: 150,
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    width: 150,
  },
  {
    accessorKey: "price",
    header: "Cost",
    width: 140,
  },
  {
    accessorKey: "taxIncluded",
    header: "Tax",
    width: 140,
  },
  {
    accessorKey: "total",
    header: "Total Cost",
    width: 140,
  },
];

interface Props {
  team: TEAM | null;
  onTeam: (team: TEAM) => void;
  items: GROUP_OR_ITEM[];
  data: ESTIMATE_DETAILS | ESTIMATE_TEMPLATE | null;
  setItems: (items: GROUP_OR_ITEM[]) => void;
  updateItem: (item: GROUP_OR_ITEM, isUpdateMarkup?:boolean) => void;
  onAddRow: (row: GROUP_OR_ITEM) => void;
  onSetPrice: (value: number, group: GROUP_OR_ITEM) => void;
  onAddNewGroup: (name: string, item: GROUP_OR_ITEM) => void;
  onDeleteItem: (item: GROUP_OR_ITEM) => void;
  onGroupTeam: (group: GROUP_OR_ITEM, team: TEAM) => void;
  onChangeGroupName: (t: string, group: GROUP_OR_ITEM) => void;
  onAddSubItem: (item: GROUP_OR_ITEM | null) => void;
  onAddTemplate: (item: GROUP_OR_ITEM, template: ESTIMATE_TEMPLATE) => void;
  isTemplate?: boolean;
  isPrinting?: boolean;
  auth?: AuthStore;
  onGroupDescription:(text:string,group:GROUP_OR_ITEM) => void
}

export const SPACE = 5;
export const SPACE_ROW = 30;

function ProductDetails(props: Props) {
  const tableRef = useRef<any>(null);
  const resizerRef = useRef(null) as any;
  const levelsRef = useRef([]) as any;

  const [openMove, setOpenMove] = useState<boolean>(false);
  const [collapsedId, setCollapsedId] = useState<string[]>([]);

  const [ready, setReady] = useState(false);
  const [checkItems, setcheckItems] = useState<GROUP_OR_ITEM[]>([]);
  const [activeId, setActiveId] = useState("");
  const [tbHeight, setTbHeight] = useState<number>(0);
  const [alphabetic, setAlphabetic] = useState("");

  const enableResize = () => {
    const options: any = {
      resizeMode: "overflow",
      disabledColumns: [8],
      liveDrag: true,
      serialize: true,
      headerOnly: true,
      onDrag: () => onEndResizeColumn(),
    };
    resizerRef.current = new ColumnResizer(tableRef.current, options);
  };

  useEffect(() => {
    setAlphabetic(props.auth?.alphabetic ?? "");
  }, [props.auth?.alphabetic]);

  useEffect(() => {
    if (tableRef.current === null) return;
    enableResize();
  }, [tableRef]);

  useEffect(() => {
    const items = props.data?.items ?? [];
    const groups = props.data?.groups ?? [];
    const data = mappingGroupOrItemToArray(groups, items);
    props.setItems(data);
    setTimeout(() => {
      setReady(true);
    }, 1000);
  }, [props.data]);

  useEffect(() => {
    const groups = props.items.filter((m) => m.isGroup);
    const levels = groups.map((m) => m.level);
    const groupByLevel = levels.filter(function (item, pos) {
      return levels.indexOf(item) === pos;
    });
    levelsRef.current = groupByLevel;
  }, [props.items]);

  useEffect(() => {
    if (!ready) return;
    const siblings = props.items.filter(
      (m) => m.level === 1 || !m.parentGroupCode
    );
    if (siblings.filter((m) => !m.product && !m.isGroup).length === 0) {
      props.onAddSubItem(null);
    }
  }, [props.items, ready]);

  useEffect(() => {
    onEndResizeColumn();
    Array.from(document.getElementsByClassName("grip-resizable")).forEach(
      (m: any) => {
        m.style.height = `${0}px`;
      }
    );
    const table = document.getElementById(
      props.isTemplate ? "mainTable_template" : "mainTable_3"
    );
    if (!table) return;
    setTimeout(() => {
      Array.from(document.getElementsByClassName("grip-resizable")).forEach(
        (m: any) => {
          m.style.height = `${table?.offsetHeight}px`;
        }
      );
    }, 500);
  }, [props.items, collapsedId]);

  function onEndResizeColumn() {
    (props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).forEach((m) => {
      const element = document.getElementById(m.accessorKey);
      const divs = Array.from(document.getElementsByTagName("div")).filter(
        (d) => d.id.includes(m.accessorKey)
      );
      divs.forEach((ele) => {
        if (ele.id.includes("clone")) {
          ele.style.width = `${element?.clientWidth ?? 0}px`;
          ele.style.minWidth = `${element?.clientWidth ?? 0}px`;
        } else {
          ele.style.width = `${(element?.clientWidth ?? 0) - 10}px`;
          ele.style.minWidth = `${(element?.clientWidth ?? 0) - 10}px`;
        }
      });
      const cells = Array.from(document.getElementsByTagName("td")).filter(
        (a) => a.id.includes(m.accessorKey)
      );
      cells.forEach((ele) => {
        ele.style.width = `${element?.clientWidth}px`;
        ele.style.minWidth = `${m.width}px`;
      });
    });
  }

  function onDragEnd(result: any, siblings: GROUP_OR_ITEM[]) {
    const items = onDrag(result, siblings, props.items);
    props.setItems(items);
  }

  function onShowAll() {
    setCollapsedId([]);
    onScrollTo("top");
  }

  function onHideAll() {
    const groupids = props.items.filter(m => m.isGroup).map(m => m.id ?? "");
    setCollapsedId(groupids);
    onScrollTo("top");
  }

  function onShowByLevel(lv: number) {
     const groupidsToExpand = props.items.filter(m => m.isGroup && m.level <= lv).map(m => m.id);
     setCollapsedId([...collapsedId.filter(m => !groupidsToExpand.includes(m ?? ""))])
  }

  function onMoveTo(item: GROUP_OR_ITEM) {
    if (checkItems.find((m) => m.id === item.id)) {
      setOpenMove(true);
    } else {
      if (item.isGroup) {
        const subGroups = getSubGroups(item, props.items).concat(item);
        const groupCodes = subGroups.map((m) => m.code);
        const items = props.items.filter(
          (m) => groupCodes.includes(m.parentGroupCode) && !m.isGroup
        );
        const allItems = [...subGroups, ...items];
        setcheckItems([...checkItems, ...allItems]);
      } else {
        setcheckItems([...checkItems, item]);
      }
      setOpenMove(true);
    }
  }

  function onMoveCheckItems(toGroup: GROUP_OR_ITEM) {
    checkItems.forEach((it) => {
      const hasParent = checkItems.find(
        (m) => m.code && m.code === it.parentGroupCode
      );
      if (hasParent) return;
      onMoveToGroupAfterSelected(it, toGroup);
      setcheckItems([]);
    });
  }

  function onMoveToGroupAfterSelected(from: GROUP_OR_ITEM, to: GROUP_OR_ITEM) {
    const previousParentCode = from.parentGroupCode;
    let _items = props.items;
    let row = createItem();
    row.parentGroupCode = to.code;
    row.sequence = 0;
    row.team = to.team;
    row.level = to.level + 1;
    row.subOf = to.subOf;
    row.code = from.code;
    if (to.code) {
      row.subOf = row.subOf?.concat(to.code);
    }
    const itemsAfterRemoved = onDeleteItem(from, _items, true);
    if (from.isGroup) {
      const itemsWithNewLevels = updateChildrenLevel(row, itemsAfterRemoved);
      const siblings = itemsWithNewLevels.filter(
        (i) => i.parentGroupCode === row.parentGroupCode
      );
      const itemsAfterAdded = addNewRow(
        row,
        siblings,
        itemsWithNewLevels,
        from
      );
      props.setItems(itemsAfterAdded);
    } else {
      const siblings = _items.filter(
        (i) => i.parentGroupCode === row.parentGroupCode
      );
      const itemsAfterAdded = addNewRow(row, siblings, _items, from);
      const rowSiblings = itemsAfterAdded.filter(
        (m) => m.parentGroupCode === previousParentCode
      );
      const orderedSequences = reorderSequence(rowSiblings);
      orderedSequences.forEach((m) => {
        const index = itemsAfterAdded.findIndex((i) => i.id === m.id);
        itemsAfterAdded[index].sequence = m.sequence;
      });
      props.setItems(itemsAfterAdded);
    }
    setOpenMove(false);
  }

  const groupsOrItemsLevel1: GROUP_OR_ITEM[] = React.useMemo(() => {
    return orderByAsc(
      props.items.filter((m) => m.level === 1 || !m.parentGroupCode),
      "sequence"
    );
  }, [props.items]);

  const onUpdateItem = (item: GROUP_OR_ITEM,isUpdateMarkup?:boolean) => {
    props.updateItem(item,isUpdateMarkup);
  };

  const onCheck = useCallback(
    (row: GROUP_OR_ITEM) => {
      if (checkItems.find((m) => m.id === row?.id)) {
        if (row.isGroup) {
          const subGroups = getSubGroups(row, props.items).concat(row);
          const subGroupsId = subGroups.map((m) => m.id);
          const subGroupsCode = subGroups.map((m) => m.code);
          const itemsId = props.items.filter((m) =>
            subGroupsCode.includes(m.parentGroupCode)
          );
          setcheckItems(
            checkItems.filter(
              (it) =>
                !subGroupsId.find((m) => m === it.id) &&
                !itemsId.find((m) => m?.id === it?.id)
            )
          );
        } else {
          setcheckItems(checkItems.filter((id) => id?.id !== row.id));
        }
      } else {
        if (row.isGroup) {
          const subGroups = getSubGroups(row, props.items).concat(row);
          const subGroupsId = subGroups;
          const subGroupsCode = subGroups.map((m) => m.code);
          const itemsId = props.items.filter((m) =>
            subGroupsCode.includes(m.parentGroupCode)
          );
          const itms = [...subGroupsId, ...itemsId];
          setcheckItems([...checkItems, ...itms]);
        } else {
          setcheckItems(checkItems.concat(row));
        }
      }
    },
    [props.items, checkItems]
  );

  function onCollapse(id: string) {
    if (collapsedId.includes(id)) {
      setCollapsedId(collapsedId.filter((m) => m !== id));
    } else {
      setCollapsedId([...collapsedId, id]);
    }
  }

  function onDeleteSelectedItems() {
    checkItems.forEach((it) => {
      const hasParent = checkItems.find(
        (m) => m.code && m.code === it.parentGroupCode
      );
      if (hasParent) return;
      props.onDeleteItem(it);
    });
  }

  useEffect(() => {
    var timer: any = null;
    const element = window.document.getElementById("scroller");
    const tableHeader: any = document.getElementById("tableHeader");
    const cloneHeader: any = document.getElementById("cloneHeader");
    const tableContainer: any = document.getElementById("tableContainer");
    const header = document.getElementById("header");
    const headerY =
      (tableHeader?.getBoundingClientRect().y ?? 0) -
      (header?.offsetHeight ?? 0);
    const tbH = tableHeader?.clientHeight ?? 0;
    setTbHeight(tbH);
    element?.addEventListener("scroll", (e) => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      const top = element.scrollTop - headerY;
      tableHeader.style.top = `${tbHeight}px`;
      cloneHeader.scrollLeft = tableContainer.scrollLeft;
      if (top > 0) {
        cloneHeader.style.visibility = "unset";
        tableHeader.style.position = `fixed`;
        tableHeader.style.zIndex = 999;
      } else {
        tableHeader.style.position = `fixed`;
        tableHeader.style.zIndex = 999;
        cloneHeader.style.visibility = "hidden";
      }
      timer = setTimeout(function () {
        const top = element.scrollTop - headerY;
        if (top > 0) {
          tableHeader.style.top = `${top}px`;
          cloneHeader.style.visibility = "hidden";
        }
      }, 150);
    });
  }, []);

  const lastEmptyItem = React.useMemo(() => {
    return groupsOrItemsLevel1[groupsOrItemsLevel1.length - 1];
  }, [groupsOrItemsLevel1]);

  return (
    <div className="mt-14 rounded-md relative">
      <div  id={"top"} />
      <div className="flex justify-between items-center mb-[-20px]">
        <TitleWithBox title={"Product Details and Charges"} />
        {props.isTemplate ? null : (
          <div
            style={{
              width: props.isTemplate
                ? COLUMNS_TEMPLATE[COLUMNS_TEMPLATE.length - 1].width
                : COLUMNS[COLUMNS.length - 1].width,
            }}
          >
            <AutoCompleteWithLazyLoad
              placeholder="Choose team"
              path={"team"}
              onSelectedValue={(value) => props.onTeam(value)}
              value={props.team}
            />
          </div>
        )}
      </div>
      <div
        style={{ visibility: "hidden" }}
        id={"cloneHeader"}
        className="sticky z-50 top-0 overflow-x-hidden border-b"
      >
        <TableHeader className=" bg-white drop-shadow w-full">
          <TableRow className=" hover:bg-white bg-white">
            {(props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).map((m, i) => (
              <TableHead
                id={`${m.accessorKey}_th_clone`}
                className="text-primary p-0"
                key={m.accessorKey}
              >
                <div
                  id={`${m.accessorKey}_clone`}
                  style={{
                    minWidth: props.isTemplate
                      ? COLUMNS_TEMPLATE[i].width
                      : COLUMNS[i].width,
                    maxWidth: props.isTemplate
                      ? COLUMNS_TEMPLATE[i].width
                      : COLUMNS[i].width,
                  }}
                  className="flex items-center"
                >
                  <div
                    className="flex items-center"
                    style={{
                      minWidth: props.isTemplate
                        ? COLUMNS_TEMPLATE[i].width
                        : COLUMNS[i].width,
                      maxWidth: props.isTemplate
                        ? COLUMNS_TEMPLATE[i].width
                        : COLUMNS[i].width,
                    }}
                  >
                    {i === 0 ? null : (
                      <div className="mr-2 w-[2px] h-[18px] rounded bg-slate-400 hover:opacity-90" />
                    )}
                    <span className="uppercase text-center w-full text-sm">
                      {m.header}
                    </span>
                  </div>
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
      </div>
      <div id="tableContainer" className="overflow-x-auto overflow-y-hidden">
        <Table
          id={props.isTemplate ? "mainTable_template" : "mainTable_3"}
          ref={tableRef}
          className="mt-6 drop-shadow bg-white relative"
        >
          <TableHeader
            id={"tableHeader"}
            className="fixed z-10 bg-white drop-shadow w-full"
          >
            <TableRow className=" hover:bg-white bg-white">
              {(props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).map((m, i) => (
                <TableHead
                  id={`${m.accessorKey}`}
                  className="text-primary p-0"
                  key={m.accessorKey}
                >
                  <div
                    style={{
                      minWidth: props.isTemplate
                        ? COLUMNS_TEMPLATE[i].width
                        : COLUMNS[i].width,
                      maxWidth: props.isTemplate
                        ? COLUMNS_TEMPLATE[i].width
                        : COLUMNS[i].width,
                    }}
                    className="flex items-center"
                  >
                    {i === 0 ? null : (
                      <div className="mr-2 w-[2px] h-[18px] rounded bg-slate-400 hover:opacity-90" />
                    )}
                    <span className="uppercase text-center w-full text-sm">
                      {m.header}
                    </span>
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <div
            style={{
              height: tbHeight,
            }}
            id="fake"
          />
          <DragDropContext
            onBeforeCapture={() => {
              let element: any = document.getElementById("tableContainer");
              element.style.overflowX = "hidden";
            }}
            onDragEnd={(result) => {
              onDragEnd(result, groupsOrItemsLevel1);
              let element: any = document.getElementById("tableContainer");
              element.style.overflowX = "unset";
            }}
          >
            <Droppable
              renderClone={(provided, snapshot, rubric) => {
                const row = props.items.find(
                  (m) =>
                    m.id === provided.draggableProps["data-rbd-draggable-id"]
                );
                return (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {row?.isGroup ? (
                      <ProductDetailsGroupSkeleton
                        allItems={props.items}
                        item={row}
                        parentCode={null}
                        estimateId={props?.data?.id}
                      />
                    ) : (
                      <ProductDetailsRow
                        isTemplate={props.isTemplate}
                        siblingsLevel={[]}
                        activeId={activeId}
                        onActive={(id) => setActiveId(id)}
                        onCheckAll={() => setcheckItems(props.items)}
                        onUnCheckAll={() => setcheckItems([])}
                        onCheck={(id) => onCheck(id)}
                        checkItems={checkItems}
                        isLastEmpty={false}
                        estimateId={props.data?.id}
                        key={row?.id}
                        onAddTemplate={props.onAddTemplate}
                        onAddNewGroup={props.onAddNewGroup}
                        updateItem={onUpdateItem}
                        onMoveTo={onMoveTo}
                        onDelete={props.onDeleteItem}
                        levels={levelsRef.current}
                        onHideAll={onHideAll}
                        onShowAll={onShowAll}
                        onShowByLevel={onShowByLevel}
                        onAdd={props.onAddRow}
                        item={row ?? ({} as any)}
                        parentCode={null}
                      />
                    )}
                  </div>
                );
              }}
              droppableId={"DROP_ID_GOES_HERE"}
            >
              {(provided, snapshot) => {
                return (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {groupsOrItemsLevel1.map((row, index) => {
                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id ?? ""}
                          index={index}
                        >
                          {(provided) => {
                            if (row.isGroup)
                              return (
                                <ProductDetailsGroup
                                onGroupDescription={props.onGroupDescription}
                                  alphabetic={alphabetic}
                                  isPrinting={props.isPrinting}
                                  isTemplate={props.isTemplate}
                                  siblingsLevel={[]}
                                  onCollapse={onCollapse}
                                  collapsedId={collapsedId}
                                  activeId={activeId}
                                  onActive={(id) => setActiveId(id)}
                                  onCheckAll={() => setcheckItems(props.items)}
                                  onUnCheckAll={() => setcheckItems([])}
                                  onCheck={(id) => onCheck(id)}
                                  checkItems={checkItems}
                                  estimateId={props.data?.id}
                                  key={row.id}
                                  onAddTemplate={props.onAddTemplate}
                                  onAddSubItem={props.onAddSubItem}
                                  onChangeGroupName={props.onChangeGroupName}
                                  onTeam={props.onGroupTeam}
                                  onAddNewGroup={props.onAddNewGroup}
                                  onSetPrice={props.onSetPrice}
                                  updateItem={onUpdateItem}
                                  onMoveTo={onMoveTo}
                                  onDelete={props.onDeleteItem}
                                  levels={levelsRef.current}
                                  onHideAll={onHideAll}
                                  onShowAll={onShowAll}
                                  onShowByLevel={onShowByLevel}
                                  onDragEnd={onDragEnd}
                                  onAdd={props.onAddRow}
                                  parentCode={null}
                                  allItems={props.items}
                                  item={row}
                                  provided={provided}
                                />
                              );
                            return (
                              <ProductDetailsRow
                                isPrinting={props.isPrinting}
                                isTemplate={props.isTemplate}
                                siblingsLevel={[]}
                                activeId={activeId}
                                onActive={(id) => setActiveId(id)}
                                onCheckAll={() => setcheckItems(props.items)}
                                onUnCheckAll={() => setcheckItems([])}
                                onCheck={(id) => onCheck(id)}
                                checkItems={checkItems}
                                isLastEmpty={lastEmptyItem?.id === row.id}
                                estimateId={props.data?.id}
                                key={row.id}
                                onAddTemplate={props.onAddTemplate}
                                onAddNewGroup={props.onAddNewGroup}
                                updateItem={onUpdateItem}
                                onMoveTo={onMoveTo}
                                onDelete={props.onDeleteItem}
                                levels={levelsRef.current}
                                onHideAll={onHideAll}
                                onShowAll={onShowAll}
                                onShowByLevel={onShowByLevel}
                                onAdd={props.onAddRow}
                                item={row}
                                parentCode={null}
                                provided={provided}
                              />
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
      </div>
      <ProductArrangement
        checkItems={checkItems}
        onSave={onMoveCheckItems}
        items={props.items}
        onClose={() => {
          setOpenMove(false);
        }}
        open={openMove}
      />
      <PopUpSelectedItems
        onDelete={() => onDeleteSelectedItems()}
        selectedItems={checkItems}
        onCancel={() => setcheckItems([])}
        onMove={() => setOpenMove(true)}
      />
    </div>
  );
}

export default inject("auth")(observer(ProductDetails));
