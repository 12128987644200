import TableStore from "src/components/tableComponents/TableStore";
import AuthStore from "./auth.store";
import MemberStore from "src/app/team/member/tables/MemberStore";
import TeamStore from "src/app/team/team/TeamStore";
import CRMStore from "./crm.store";
import ProposalStore from "./proposal.store";
import UDFStore from "./udf.store";

function connectedStores() {
  return {
    auth: new AuthStore(),
    table: new TableStore(),
    member: new MemberStore(),
    team: new TeamStore(),
    crm: new CRMStore(),
    proposal: new ProposalStore(),
    udf: new UDFStore(),
  };
}
export default connectedStores;
