import { HTMLInputTypeAttribute, useEffect, useState } from "react";
import MainInput from "./MainInput";
import { formatMoney } from "src/utilities/formatter.utilities";
import {
  isValidMaxNumber,
  numberOnly,
} from "src/utilities/functions.utilities";
import { calculate } from "src/utilities/mapping.utilities";
import AlertTextField from "./AlertTextField";

interface Props {
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  onChange?: (t: string) => void;
  value: string | null;
  disabled?: boolean;
  hideBorder?: boolean;
  extendClass?: string;
  prefix?: string;
  default?: number;
  active?: boolean;
  id?: string;
}

export default function NumberInput(props: Props) {
  const [text, settext] = useState(props.value);
  const [errorLength, setErrorLength] = useState(false);
  useEffect(() => {
    settext(formatMoney(props.value) ?? "");
  }, [props.value]);

  useEffect(() => {
    setTimeout(() => {
      setErrorLength(false);
    }, 5000);
  }, [errorLength, props.active]);
  return (
    <>
      <MainInput
        {...props}
        value={text}
        id={`${props.id}_editor_number`}
        isNumber
        active={props.active}
        onChange={(e) => {
          const isValid = isValidMaxNumber(calculate(e) || "");
          setErrorLength(!isValid);
          if (!isValid) {
            settext(numberOnly(props.value || ""));
            return;
          }
          if (e === "0" && props.default) {
            settext(props.default?.toString());
            e = props.default.toString();
          } else {
            const result = calculate(e || "");
            settext(result);
          }
          const result = calculate(e || "");
          props.onChange && props.onChange(numberOnly(result));
        }}
      />
      <AlertTextField
        message="Your amount is too large to enter."
        show={errorLength}
        parentId={`${props.id}_editor_number`}
      />
    </>
  );
}
