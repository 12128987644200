import { Separator } from "src/components/ui/separator";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import { formatMoney } from "src/utilities/formatter.utilities";
import { getTotal } from "./estimate.utility";

interface Props {
  items: GROUP_OR_ITEM[];
  taxRate: number;
}
export default function TotalSummary(props: Props) {
  const summary = getTotal(props.taxRate,props.items);
  return (
    <div className="bg-white p-4 rounded-md ml-4 mt-28 w-[400px] drop-shadow">
      <div className="flex  justify-between mb-1 uppercase">
        <span>Subtotal</span>
        <span>${formatMoney(summary.subTotla)}</span>
      </div>
      <Separator />
      <div className="flex  justify-between mb-1 uppercase mt-4">
        <span>Subtotal with markup</span>
        <span>${formatMoney(summary.totalSummary)}</span>
      </div>
      <Separator />
      <div className="flex  justify-between mb-1 uppercase mt-4">
        <span>Taxable Amount</span>
        <span>${formatMoney(summary.taxableAmount)}</span>
      </div>
      <Separator />
      <div className="flex  justify-between mb-1 uppercase  mt-4">
        <span>Tax</span>
        <span>{(summary.tax)}</span>
      </div>
      <Separator />
      <div className="flex  justify-between mb-1 uppercase font-bold mt-4 text-2xl">
        <span>Total</span>
        <span>${formatMoney(summary.total)}</span>
      </div>
    </div>
  );
}
