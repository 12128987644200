import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { HiOutlineCheckCircle, HiPencilSquare } from "react-icons/hi2";
import { IoCamera } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { COLORS } from "src/assets/colors";
import { ImageCropper } from "src/components/ImageCropper";
import { Avatar, AvatarFallback } from "src/components/ui/avatar";
import { DialogHeader } from "src/components/ui/dialog";
import { USER } from "src/interfaces/init.interface";
import { onUploadImage } from "src/services/auth.service";
import AuthStore from "src/stores/auth.store";
import {  nameChar } from "src/utilities/formatter.utilities";
import { styled } from "styled-components";
import { useFilePicker } from "use-file-picker";
import { FileContent } from "use-file-picker/dist/interfaces";

interface Props {
  user: USER | null;
  auth?: AuthStore;
}

function AccountHeader(props: Props) {
  const navigate = useNavigate();
  const date = useRef(Date.now());
  const { openFilePicker, filesContent } = useFilePicker({
    multiple: false,
    readAs: "DataURL",
  });
  const { firstName, lastName, external } = props.user ?? {};
  const [imageCrop, setImageCrop] = useState<FileContent<string> | null>(null);
  const [showCropImage, setShowCropImage] = useState<boolean>(false);
  const [processing, setProcessing] = useState(false);
  const [profileImg, setProfilImg] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.user?.profilePictureUrl)
      setProfilImg(props.user?.profilePictureUrl);
  }, [props.user]);

  useEffect(() => {
    filesContent.map((value) => {
      setImageCrop(value);
      setShowCropImage(true);
    });
  }, [filesContent]);

  const uploadImage = async (base64: string) => {
    setProcessing(true);
    var newImage = await onUploadImage(
      `/user/${props.user?.id}/upload-profile`,
      {
        id: imageCrop?.name,
        base64: base64,
      }
    );
    if (!newImage) {
      setProcessing(false);
      setShowCropImage(false);
      return;
    }
    setShowCropImage(false);
  };

  const fullName = `${firstName} ${lastName}`;
  return (
    <div className="relative h-40 w-full">
      <ImageCropper
        onUpload={uploadImage}
        uploading={processing}
        onOpen={() => setShowCropImage(!showCropImage)}
        open={showCropImage}
        image={imageCrop?.content ?? ""}
      />
      <div className="flex items-center absolute left-4 right-0 top-[30px]">
        <div className="w-28 h-28 drop-shadow-md rounded-full bg-white p-1">
          {profileImg && !error ? (
            <img
              onError={() => setError(true)}
              className="w-full h-full"
              style={{
                borderRadius: 100,
                position: "relative",
                boxShadow: " rgba(0, 0, 0, 0.09) 0px 3px 12px",
              }}
              src={`${profileImg}?${date.current}`}
            />
          ) : (
            <Avatar className="w-full h-full">
              <AvatarFallback style={{ fontSize: 22, fontWeight: "bold" }}>
                {nameChar(fullName ?? "")}
              </AvatarFallback>
            </Avatar>
          )}
          <CameraContainer onClick={() => openFilePicker()}>
            <IoCamera size={23} color={COLORS.white} />
          </CameraContainer>
        </div>
        <div className="flex p-4 pl-4 flex-col justify-center items-center">
          <div className="flex items-start flex-col justify-start">
            <span className="font-bold text-2xl text-white">{fullName}</span>
            {external ? null : (
              <div className="flex items-start gap-1">
                <HiOutlineCheckCircle className="text-secondary text-xl" />
                <span className="text-secondary">Internal Member</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="h-32 bg-gradient-to-tl from-indigo-900 to-blue-900 rounded-md p-2 pr-1">
        <DialogHeader>
          <div className="flex flex-1 items-center">
            <div className="flex-1 flex flex-col"></div>
            <div
              onClick={() => navigate(`/member-details/${props.user?.id}`)}
              className="mr-2 bg-transparent text-white/50 hover:opacity-80 hover:text-white"
            >
              <HiPencilSquare className="cursor-pointer" size={26} />
            </div>
          </div>
        </DialogHeader>
      </div>
    </div>
  );
}

export default inject("auth")(observer(AccountHeader));

const CameraContainer = styled.div`
  height: 7rem;
  width: 7rem;
  border-radius: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  &:hover {
    opacity: 1;
    transition: all 500ms;
    cursor: pointer;
  }
`;
