import {
  Dialog,
  DialogContent,
} from "src/components/ui/dialog";
import { USER } from "src/interfaces/init.interface";
import AccountHeader from "../app/team/member/AccountHeader";
import {
  HiOutlineAcademicCap,
  HiOutlineBeaker,
  HiOutlineBuildingOffice,
  HiOutlineCake,
  HiOutlineCalendarDays,
  HiOutlineEnvelope,
  HiOutlineMap,
  HiOutlinePhone,
  HiOutlineUser,
} from "react-icons/hi2";
import { IconType } from "react-icons";
import AuthStore from "src/stores/auth.store";
import { inject, observer } from "mobx-react";
import { dateToString } from "src/utilities/formatter.utilities";
import { useEffect, useState } from "react";
import { SmallListSkeleton } from "src/components/SmallListSkeleton";
import { details } from "src/APIs/table.api";

interface Props {
  open: boolean;
  onOpen: (value: boolean) => void;
  data: USER | string | null;
  auth?: AuthStore;
}

function ProfilePopUp(props: Props) {
  const [user, setUser] = useState<USER | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    fullName,
    email,
    phone,
    joinedDate,
    location,
    birthDate,
    title,
    teamName,
    departmentName,
    firstName,lastName
  } = user ?? {};
  useEffect(() => {
    if(!props.open) {
      setUser(null);
      return;
    }
    setLoading(true);
   
    details("user", typeof props.data === "string" ? props.data : props.data?.id).then((doc) => {
      if (doc.code !== "200") {
        setLoading(false);
        return;
      } else {
        setUser(doc?.data);
        setLoading(false);
      }
    });
  }, [props.data, props.open]);
  const renderContent = (
    label: string,
    data: string | undefined | null,
    Icon: IconType,
    noBorder?:boolean
  ) => {
    return (
      <div className={ noBorder ? "flex justify-between pb-2" : "flex justify-between border-b pb-2"}>
        <div className="flex items-center">
          <Icon className="mr-2" />
          <p className="text-gray-500">{label}</p>
        </div>
        <p className="font-normal">{data ?? "N/A"}</p>
      </div>
    );
  };

  return (
    <Dialog onOpenChange={(v) => props.onOpen(v)} open={props.open}>
      <DialogContent className="sm:max-w-[425px] p-4 z-[9999999]">
        <div className="grid gap-4 py-0">
          <AccountHeader user={typeof props.data === "string" ? user : props.data} />
          {loading ? (
             <div className="flex flex-1 p-2 flex-col gap-4">
           <SmallListSkeleton />
              </div>
          ) : (
            <div className="flex flex-1 p-2 flex-col gap-4">
              {renderContent("Full Name", `${firstName} ${lastName}`, HiOutlineUser)}
              {renderContent("Email", email, HiOutlineEnvelope)}
              {renderContent("Phone", phone, HiOutlinePhone)}
              {renderContent(
                "Work Anniversary",
                dateToString(joinedDate, props.auth?.dateFormat),
                HiOutlineCalendarDays
              )}
              {renderContent("Location", location, HiOutlineMap)}
              {renderContent(
                "Birthday",
                dateToString(birthDate, props.auth?.dateFormat),
                HiOutlineCake
              )}
              {renderContent("Title", title, HiOutlineAcademicCap)}
              {renderContent("Team", teamName, HiOutlineBuildingOffice)}
              {renderContent("Department", departmentName, HiOutlineBeaker, true)}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default inject("auth")(observer(ProfilePopUp));
