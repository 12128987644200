import { IconType } from "react-icons";
import { useEffect, useState } from "react";
import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import configurations from "src/configuration";
import AutoCompleteView from "./AutoCompleteView";
import { ESTIMATE_TEMPLATE } from "src/interfaces/estimate.interface";
import AddProduct from "src/app/productAndConfigure/productList/AddProduct";
import paginationAutoComplete from "src/APIs/auto_complete.api";
import { paginationList } from "src/APIs/table.api";

interface Props {
  icon?: IconType;
  label?: string;
  placeholder?: string;
  onIconClick?: () => void;
  required?: boolean;
  onSelectedValue: (value: any) => void;
  value: any;
  disabled?: boolean;
  hideBorder?: boolean;
  leftIcon?: IconType;
  isProduct?: boolean;
  onAddNewGroup?: (name: string) => void;
  active?: boolean;
  loading?:boolean;
  enablePortal?:boolean;
}

export function AutoCompleteProduct(props: Props) {
  const [hasOpened, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState([]);
  const [text, setText] = React.useState("");
  const [timeoutRef, setTimeoutRef] = React.useState<any>(null);
  const [ready, setReady] = useState(false);

  const [templates, setTemplates] = useState<ESTIMATE_TEMPLATE[]>([]);
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  const [page, setPage] = React.useState(0);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    disabled: !hasOpened,
    onLoadMore: () => fetchData(),
  });

  useEffect(() => {
    if (hasOpened && data.length === 0) {
      fetchData();
      fetchTemplates();
    }
  }, [hasOpened]);

  useEffect(() => {
    if (!ready) return;
    setSearching(true);
    const controller = new AbortController();
    const signal = controller.signal;
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    fetchTemplates();
    const timeOut = setTimeout(async () => {
      const result = await paginationAutoComplete(
        "product",
        0,
        configurations.LENGTH,
        text,
        signal
      );
      const content = result?.data?.content || result?.data;
      if (!content) {
        setSearching(false);
        return;
      }
      setData(content);
      setPage(0);
      setHasNextPage(content.length >= configurations.LENGTH);
      setSearching(false);
    }, 200);
    setTimeoutRef(timeOut);
    return () => {
      controller.abort();
    };
  }, [text]);

  async function fetchData() {
    if (loading) return;
    setLoading(true);
    const result = await paginationAutoComplete(
      "product",
      page,
      configurations.LENGTH,
      text
    );
    const content = result?.data?.content || result?.data;
    if (!content) {
      setSearching(false);
      return;
    }
    setData(data.concat(content));
    setLoading(false);
    setReady(true);
    if (content.length >= configurations.LENGTH) {
      setPage(page + 1);
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }
  }

  async function fetchTemplates() {
    setLoadingTemplate(true);
    paginationList("estimate-template",0,100,text).then((res) => {
      if (res?.code !== "200") {
        setLoadingTemplate(false);
        return;
      } else {
        setTemplates(res?.data?.content);
        setLoadingTemplate(false);
      }
    });
  }

  return (
    <>
      <AddProduct
        addedProduct={props.onSelectedValue}
        product={null}
        open={openAddProduct}
        onOpen={(v) => setOpenAddProduct(v)}
      />
      <AutoCompleteView
        enablePortal = {props.enablePortal}
        noClearButton
        active={props.active}
        onAddNewGroup={() => props.onAddNewGroup && props.onAddNewGroup(text)}
        isProduct={props.isProduct}
        onIconClick={props.onIconClick}
        leftIcon={props.leftIcon}
        onAdd={() => setOpenAddProduct(true)}
        hideBorder={props.hideBorder}
        disabled={props.disabled}
        value={props.value}
        onSelectedValue={props.onSelectedValue}
        required={props.required}
        onOpenChange={(v) => setOpen(v)}
        data={templates.concat(data)}
        loading={loading || props.loading}
        searching={searching}
        setText={(t) => setText(t)}
        sentryRef={sentryRef}
        rootRef={rootRef}
        label={props.label}
        placeholder={props.placeholder}
        icon={props.icon}
      />
    </>
  );
}
