import { ENTITY, USER } from "src/interfaces/init.interface";
import { getStatus, getStatusColor, mappingEntity } from "src/utilities/mapping.utilities";
import { Checkbox } from "../ui/checkbox";
import _ from "lodash";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { dateToString, formatMoney } from "src/utilities/formatter.utilities";
import { PhoneNumber } from "./PhoneNumber";
import AuthStore from "src/stores/auth.store";
import { ProfileAvatar } from "../ProfileAvatar";
import { ColumnSizingState } from "@tanstack/react-table";
import { COLUMN_TYPES } from "src/constants/table.constants";

interface Props {
  entity: ENTITY;
  auth?: AuthStore;
}

export function saveSize(entityCode: string, columnSizing: ColumnSizingState) {
  localStorage.setItem(entityCode, JSON.stringify(columnSizing));
}

export function getSize(entityCode: string) {
  const data = localStorage.getItem(entityCode);
  return data ? JSON.parse(data) : null;
}

export default function RenderColumnRow(props: Props) {
  const { entity } = props;

  return mappingEntity(entity.entityConfigurations).map((m) => {
    const column = entity.entityConfigurations?.find(
      (c) => c.columnName === m.accessorKey
    );
    const columnType = column?.columnType;
    if (m.accessorKey === "unit")
      return {
        ...m,
        Cell: ({ cell }: any) => {
          const data = cell.row.original;
          const unitName = props.auth?.unitList.find((m) => m.id === data.unit);
          return <div>{unitName?.value ?? "N/A"}</div>;
        },
      };
    if (m.accessorKey === "fullName") {
      const column = {
        ...m,
        Cell: ({ cell }: any) => {
          const data = cell.row.original as USER;
          return (
            <div className="flex items-center">
              <ProfileAvatar
                url={data.profilePictureUrl}
                name={data.fullName ?? ""}
              />
              <span className="ml-4">{data.fullName ?? ""}</span>
              {data.external ? (
                <span className="text-secondary ml-2 text-xs font-semibold">
                  ({"External"})
                </span>
              ) : data.owner ? (
                <span className="text-primary ml-2 text-xs font-semibold">
                  ({"Owner"})
                </span>
              ) : null}
            </div>
          );
        },
      };
      return column;
    }
    return {
      ...m,
      enableSorting: column?.sortable,
      Cell: ({ cell }: any) => {
        const data = cell.row.original;
        const value = _.get(data, m.accessorKey ?? "");
        if (columnType === COLUMN_TYPES.checkbox) {
          return (
            <Checkbox
              style={{ width: 18, height: 18 }}
              checked={value === 1 || value === true}
            />
          );
        }

        if (columnType === COLUMN_TYPES.telephone) {
          return value ?  <PhoneNumber value={value} /> : <div>N/A</div>;
        }

        if (columnType === COLUMN_TYPES.date) {
          return (
            <div>
              {value ? dateToString(value, props.auth?.dateFormat) : "N/A"}
            </div>
          );
        }

        if (columnType === COLUMN_TYPES.email) {
          return (
            <div className="flex items-center justify-center">
              <HiOutlineEnvelope size={18} className="mr-2" /> {value || "N/A"}
            </div>
          );
        }

        if (columnType === COLUMN_TYPES.number) {
          return <div>{value ? formatMoney(value) : "N/A"}</div>;
        }
        if (columnType === COLUMN_TYPES.currency) {
          return <div>${value ? formatMoney(value) : "0"}</div>;
        }
        if(columnType === COLUMN_TYPES.dropdown){
          return <div style={{color:getStatusColor(value)}} className="rounded-md w-full text-center font-semibold bg-slate-100 p-[3px]">{getStatus(value)}</div>
        }
        return <div>{value ?? "N/A"}</div>;
      },
    };
  });
}

