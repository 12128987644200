import { IconButton, ListItemIcon, MenuItem } from "@mui/material";
import React from "react";
import {} from "react-icons/io5";
import Menu from "@mui/material/Menu";
import { LAYOUTS } from "src/assets/layouts";
import { HiEllipsisVertical, HiPencil, HiTrash } from "react-icons/hi2";

interface Props {
  onDelete: () => void;
  onEdit: () => void;
}

function CommentAction(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <React.Fragment>
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={handleClick}
        style={{ marginLeft: LAYOUTS.margin }}
      >
        <HiEllipsisVertical size={22} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(100%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem style={{ fontSize: 14 }} onClick={props.onEdit}>
          <ListItemIcon>
            <HiPencil size={16} />
          </ListItemIcon>
          Edit Comment
        </MenuItem>
        <MenuItem style={{ fontSize: 14 }} onClick={props.onDelete}>
          <ListItemIcon>
            <HiTrash size={16} />
          </ListItemIcon>
          Delete Comment
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default CommentAction;
