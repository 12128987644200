import MainInput from "src/components/MainInput";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { ROLE } from "src/interfaces/init.interface";

interface Props {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  role: ROLE | null;

  onFirstName: (t: string) => void;
  onLastName: (t: string) => void;
  onUserName: (t: string) => void;
  onEmail: (t: string) => void;
  onPassword: (t: string) => void;
  onRole: (role: ROLE) => void;
  external: boolean;
}

export default function Account(props: Props) {
  return (
    <div className="flex  flex-col">
      <div className="flex flex-1 gap-4">
        <MainInput
          required
          placeholder="Enter first name"
          value={props.firstName}
          onChange={props.onFirstName}
          label="First Name"
        />
        <MainInput
          required
          placeholder="Enter last name"
          value={props.lastName}
          onChange={props.onLastName}
          label="Last Name"
        />
      </div>
      <div className="flex flex-1 gap-4">
        <MainInput
          required
          type="email"
          placeholder="Enter email"
          value={props.email}
          onChange={props.onEmail}
          label="Email"
        />
        <MainInput
          required
          type="text"
          placeholder="Enter username"
          value={props.username}
          onChange={props.onUserName}
          label="Username"
        />
      </div>
      {props.external ? null : (
        <div className="flex flex-1 gap-4">
          <MainInput
            required
            type="password"
            placeholder="Enter password"
            value={props.password}
            onChange={props.onPassword}
            label="Enter password"
          />
          <AutoCompleteWithLazyLoad
            required
            placeholder="Choose role"
            onSelectedValue={(value) => props.onRole(value)}
            value={props.role}
            path="role"
            label="Role"
          />
        </div>
      )}
    </div>
  );
}
