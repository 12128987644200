import { Dialog, DialogContent, DialogTitle } from "src/components/ui/dialog";
import { DialogFooter } from "src/components/ui/dialog";
import MainButton from "src/components/MainButton";
import MainInput from "src/components/MainInput";
import { useEffect, useState } from "react";
import { TEAM } from "src/interfaces/team.interface";
import { numberOnly } from "src/utilities/functions.utilities";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import { updateTeamPricing } from "src/APIs/team.api";
import { AlertDestructive } from "src/components/AlertDestructive";
import TableStore from "src/components/tableComponents/TableStore";
import { inject, observer } from "mobx-react";
import { Checkbox } from "src/components/CheckBox";

interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  item: TEAM | null;
  table?: TableStore;
}

function EditPricingDialog(props: Props) {
  const [value, setValue] = useState<string>("0");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [enable, setEnable] = useState(false);
  useEffect(() => {
    if (props.open) {
      setValue(props.item?.price?.toString() ?? "");
      setEnable(props.item?.enable ?? false);
    } else {
      setValue("0");
      setError(false);
      setProcessing(false);
    }
  }, [props.open]);

  const onSave = () => {
    setError(false);
    if (!value) {
      setError(true);
      return;
    }
    setProcessing(true);
    let data: any = {
      id: props.item?.id,
      price: Number(value),
      enable: enable,
      external: props.item?.external,
    };
    updateTeamPricing(data, data.id).then((doc) => {
      if (doc.code === "200") {
        props.table?.updateData(data);
        props.onClose(false);
        setProcessing(false);
      }
    });
  };

  return (
    <Dialog onOpenChange={(v) => props.onClose(v)} open={props.open}>
      <DialogContent className="sm:max-w-[425px]">
        <div className="flex items-center mb-[-12px]">
          <DialogTitle className="flex flex-1">
            {props.item ? props.item.name : "Edit Pricing"}
          </DialogTitle>
          <CloseDrawerButton />
        </div>
        {error ? (
          <div className="mt-2">
            <AlertDestructive
              message="Please enter your labor pricing."
              title="No Pricing"
            />
          </div>
        ) : null}
        <MainInput
          extendClass="mt-0"
          onChange={(v) => setValue(numberOnly(v))}
          value={value}
          prefix="$"
          label="Price"
        />
        <Checkbox
          label="Enable"
          onChange={(v) => setEnable(v)}
          value={enable}
        />
        <DialogFooter className="mt-6">
          <MainButton
            loading={processing}
            onClick={onSave}
            label="Save Change"
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default inject("table")(observer(EditPricingDialog));
