import { useState } from "react";
import { HiTrash } from "react-icons/hi2";
import { RESPONSE } from "src/APIs/error_handler";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { onDeleteItem } from "src/services/auth.service";
import MainButton from "./MainButton";
import { deleteSectionMetaData } from "src/APIs/proposal.api";

interface Props {
  title?: string;
  message?: string;
  deleted: (result: RESPONSE) => void;
  id: number | string;
  path: string;
  renderComponent?: React.ReactNode;
  isDeleteProposal?: boolean;
  onAfterdeletedProposal?: () => void;
}

export function DeleteButton(props: Props) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);

  async function onDelete() {
    if (props.isDeleteProposal) {
      setProgress(true);
      const result = await deleteSectionMetaData(props.id);
      if (result === false) {
        setProgress(false);
        return;
      }
      props.onAfterdeletedProposal && props.onAfterdeletedProposal();
      setProgress(false);
      setOpen(false);
      return;
    }
    setProgress(true);
    const result = await onDeleteItem(props.path, props.id);
    if (!result) {
      setProgress(false);
      return;
    }
    props.deleted(result);
    setProgress(false);
    setOpen(false);
  }
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        if (!open) {
          setOpen(true);
        }
      }}
    >
      <DialogTrigger asChild>
        {!props.renderComponent ? (
          <Button onClick={(e) => e.stopPropagation()} variant={"ghost"}>
            <HiTrash className="mb-2" size={18} />
          </Button>
        ) : (
          props.renderComponent
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{props.title ?? "Delete Item"}</DialogTitle>
          <DialogDescription>
            {props.message ?? "Are you sure to delete this item?"}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="justify-end">
          <DialogClose asChild>
            <Button
              onClick={() => setOpen(false)}
              type="button"
              variant={"outline"}
            >
              Close
            </Button>
          </DialogClose>
          <DialogClose>
            <MainButton
              loading={progress}
              label="Delete"
              onClick={() => onDelete()}
              type="button"
              variant={"destructive"}
            />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
