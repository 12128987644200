import { toast } from "react-toastify";
import {
  removeAuth,
  removeStoredUniqueKey,
} from "src/utilities/storage.utilities";

export interface RESPONSE {
  timestamp: EpochTimeStamp;
  code: "200" | "500" | "401" | "404" | "503";
  message: string;
  data: any | null;
  status: number;
}

export function errorHandler(result: RESPONSE, noPopUp?: boolean) {
  if (result?.status) {
    result.code = result?.status.toString() as any;
  }
  if (result.code.toString() === "401") {
    !noPopUp &&
      toast("Unauthenticated.", {
        toastId: result.code,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
    removeAuth();
    removeStoredUniqueKey();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } else if (result.code.toString() === "404") {
    !noPopUp &&
      toast("Not Found!.", {
        toastId: result.code,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
  } else if (result.code.toString() === "500") {
    !noPopUp &&
      toast(result?.message, {
        toastId: result.code,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
  } else if (result.code.toString() === "422") {
    !noPopUp &&
      toast(result?.message, {
        toastId: result.code,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
  } else {
    !noPopUp &&
      toast(result?.message, {
        toastId: result.code,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
      });
  }
  if (result.code) {
    result.code = result.code.toString() as any;
  }
  return result;
}
