import {
  ESTIMATE_PAYLOAD,
  ESTIMATE_SETTING,
  ESTIMATE_TEMPLATE_PAYLOAD,
} from "src/interfaces/estimate.interface";
import { getAPI, postAPI, putAPI } from ".";
import CUSTOMER from "src/interfaces/customer.interface";
import { RESPONSE } from "./error_handler";

export const estimateDetails = async (id: number) => {
  var url = `${process.env.REACT_APP_MAIN_API}/estimate/${id}`;
  const data = await getAPI(url);
  return data;
};

export const estimateTemplateDetails = async (id: string) => {
  var url = `${process.env.REACT_APP_MAIN_API}/estimate-template/${id}`;
  const data = await getAPI(url);
  return data;
};

export async function taxRate(
  countryCode: string,
  stateCode: string,
  postalCode: string,
  signal: AbortSignal
) {
  const url = `${process.env.REACT_APP_MAIN_API}/tax-rate?country-code=${countryCode}&state-code=${stateCode}&zip-code=${postalCode}`;
  const data = await getAPI(url, signal);
  return data;
}

export async function createEstimate(
  payload: ESTIMATE_PAYLOAD,
  customer: CUSTOMER
) {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate?customer-id=${customer.id}`;
  const data = await postAPI(url, payload);
  return data as RESPONSE;
}

export async function createEstimateTemplate(
  payload: ESTIMATE_TEMPLATE_PAYLOAD
) {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate-template`;
  const data = await postAPI(url, payload);
  return data as RESPONSE;
}

export async function updateEstimate(
  payload: ESTIMATE_PAYLOAD,
  estimateId: number,
  customer: CUSTOMER
) {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate/${estimateId}?customer-id=${customer?.id}`;
  const data = await putAPI(url, payload);
  return data as RESPONSE;
}

export async function updateEstimateTemplate(
  payload: ESTIMATE_TEMPLATE_PAYLOAD,
  estimateId: string
) {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate-template/${estimateId}`;
  const data = await putAPI(url, payload);
  return data as RESPONSE;
}

export async function updateEstimateSetting(payload: ESTIMATE_SETTING) {
  const url = `${process.env.REACT_APP_MAIN_API}/estimate-setting`;
  const data = await putAPI(url, payload);
  return data as RESPONSE;
}

export async function getComment(
  isGroup: boolean,
  estimateId: number | string,
  id: string
) {
  const url = !isGroup
    ? `${process.env.REACT_APP_MAIN_API}/estimate/${estimateId}/show-comment-by-item?item-id=${id}`
    : `${process.env.REACT_APP_MAIN_API}/estimate/${estimateId}/show-comment-by-group?group-id=${id}`;
  const data = await getAPI(url);
  return data;
}

