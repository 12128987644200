import { IconType } from "react-icons";
import { useEffect, useState } from "react";

import React from "react";
import { HiCheckCircle, HiChevronRight } from "react-icons/hi2";
import AutoCompleteView from "./AutoCompleteView";
import { getGeoapifyAddress } from "src/APIs";

interface Props {
  label: string;
  icon?: IconType;
  placeholder?: string;
  onIconClick?: () => void;
  required?: boolean;
  onSelectedValue: (line: string, data: MAP_PROPERTY | null) => void;
  value: string | null;
  disabled?: boolean;
  type?: "state";
  big?: boolean;
}

export default function AutoCompleteAddress(props: Props) {
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = React.useState(false);
  const [data, setData] = useState<MAP_PROPERTY[]>([]);
  const [text, setText] = React.useState("");
  const [selectedMap, setMap] = useState<MAP_PROPERTY | null>(null);
  useEffect(() => {
    setText(props.value ?? "");
  }, [props.value, open]);

  useEffect(() => {
    if (!open) return;
    if (text === selectedMap?.address_line1 || text === selectedMap?.state)
      return;
    setSearching(true);
    getGeoapifyAddress(
      text ? text || "USA" : props.type === "state" ? "New York" : "USA",
      props.type
    ).then((data: MAP[]) => {
      const mappingData = data.map((m) => getProperty(m));
      setData([...mappingData]);
      setSearching(false);
    });
  }, [open, text]);
  return (
    <AutoCompleteView
      isState = {props.type === "state"}
      isLocation
      loading = {searching}
      onOpenChange={(v) => setOpen(v)}
      disabled={props.disabled}
      value={props.value}
      onSelectedValue={(data:MAP_PROPERTY,text) => props.onSelectedValue(text ?? data?.address_line1,data)}
      required={props.required}
      data={data}
      setText={(t) => setText(t)}
      label={props.label}
      placeholder={props.placeholder}
      renderOption={(option, active, onSelectedValue) => {
        return (
          <div
            onClick={() => onSelectedValue(option)}
            className="p-2 hover:bg-primary/5 rounded cursor-pointer flex items-center"
            key={option.code}
          >
            <img
              className="mr-4"
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code}.png`}
              alt=""
            />
            <span className="font-medium flex flex-1">{option.name}</span>
            {active ? <HiCheckCircle size={20} /> : <HiChevronRight />}
          </div>
        );
      }}
    />
  );
}

export interface MAP {
  properties: MAP_PROPERTY;
}

export interface MAP_PROPERTY {
  address_line1: string;
  address_line2: string;
  category: string;
  country: string;
  country_code: string;
  formatted: string;
  lat: number;
  lon: number;
  name: string;
  result_type: string;
  place_id: string;
  state: string;
  state_code: string;
  postcode: string;
  city: string;
}

const getProperty = (data: MAP) => {
  return data.properties;
};
