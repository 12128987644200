export const COLORS = {
  primary: "hsl(215 72% 21%)",
  secondary: "#e09902",
  secondary_transparent: "#e4af005f",

  orange: "#F78662",
  primary_transparent: "#1D439622",
  orange_transparent: "#F7856226",
  blue_transparent: "#2A58B42F",
  blue: "#2A58B4",
  blue_light: "#5399b5",

  red: "#F45250",
  red_transparent: "#F453501E",
  text: "#333333",
  sub_text: "rgba(0, 0, 0, 0.54)",
  inactive: "#6E6E73",
  white: "#ffffff",
  table_header: "#FAFBFF",
  background_primary: "#F5F5F7",
  white_text: "#d0d1d8",
  white_text_transparent: "#d0d1d88d",
  white_text_transparent_v3: "#d0d1d833",
  white_text_transparent_v2: "#d0d1d8a",
  border: "hsl(214 32% 91%)",
  opacity: 0.4,
  input_background: "#f4f5f6",
  shadow: "1px 1px 5px 1px #9E9E9E35",
  shadow_line: " 0px 0px 15px 4px #0b935d45",
  shadow_bottom: "0px 4px 5px #9E9E9E35",
  shadow_child: "1px 1px 0px 1px #9E9E9E35",
  disabled: "#6b6b6a40",
  transparent: "transparent",
  input_border: "rgba(0, 0, 0, 0.3)",
  placeholder: "#64748B",
};
