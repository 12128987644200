import { Skeleton } from "src/components/ui/skeleton";

interface Props {
  isStage?: boolean;
}

export function CrmTabarSkeleton(props: Props) {
  return (
    <div className="overflow-auto">
      <div className={`flex mx-2 ${props.isStage ? "mb-3" : ""}`}>
        {[1, 2, 3].map((item) => {
          return (
            <Skeleton
              key={item}
              className={`h-12 ${
                props.isStage ? "w-60" : "w-full"
              } rounded-sm cursor-pointer mr-2`}
            />
          );
        })}
      </div>
    </div>
  );
}
