import { HiDocumentChartBar } from "react-icons/hi2";
import MainButton from "src/components/MainButton";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../../../../components/ui/drawer";
import CloseDrawerButton from "src/components/CloseDrawerButton";
import TitleWithBox from "src/components/TitleWithBox";
import SummaryTable from "./SummaryTable/SummaryTable";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import SummarySnapshot from "./SummarySnapshot";
import React from "react";
import EstimateSummaryContent from "./EstimateSummaryContent";
interface Props {
  disabled?: boolean;
  extendClass?: string;
  items: GROUP_OR_ITEM[];
  taxRate: number;
  exclusion: string;
}

function EstimateSummary(props: Props) {
  return (
    <Drawer>
      <DrawerTrigger>
        <MainButton
          className=" text-white bg-green-700 mt-[2px]"
          type="button"
          icon={HiDocumentChartBar}
          onClick={() => null}
          label="Summary"
        />
      </DrawerTrigger>
      <DrawerContent className=" overflow-hidden h-[99%]">
        <DrawerHeader className="flex items-center">
          <div className="flex flex-1 flex-col">
            <DrawerTitle>Estimate Summary</DrawerTitle>
            <DrawerDescription>
              Preview summary of your estimate details.
            </DrawerDescription>
          </div>
          <CloseDrawerButton />
        </DrawerHeader>
       <EstimateSummaryContent items={props.items} exclusion={props.exclusion} taxRate={props.taxRate} />
      </DrawerContent>
    </Drawer>
  );
}

export default EstimateSummary;
