export interface FIELD_TYPE {
  id: number;
  code: string;
  name: string;
  list: boolean;
  regExpression: any;
  pattern: any;
  icon: string;
  width: number;
  color: string;
  sequence: number;
  enable: boolean;
}

export interface UDF {
  id: string;
  fieldType: FIELD_TYPE | null;
  entity: string;
  name: string;
  sequence: number;
  udfLists: UDF_LIST[];
  enable: boolean;
  deleted: boolean;
}

export const getInitUdf: UDF = {
  id: "",
  fieldType: null,
  entity: "",
  name: "",
  sequence: 0,
  udfLists: [],
  enable: true,
  deleted: false,
};

export interface UDF_LIST {
  id?: string;
  value: string;
  backgroundColor: string;
  selected: boolean;
  sequence: number;
}

export interface UDF_LINK_CREATE {
  entityId: number;
  userDefinedFieldId: string;
  value: string;
}

export interface UDF_LINK {
  id: string;
  userDefinedField: UDF;
  value: string;
}
