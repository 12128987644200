import React, { useEffect, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { AutoCompleteWithLazyLoad } from "src/components/autoComplete/AutoCompleteWithLazyLoad";
import { Checkbox } from "src/components/CheckBox";
import { TableCell, TableRow } from "src/components/ui/tableEstimate";
import {
  CATALOGUE,
  COMMENT,
  ESTIMATE_TEMPLATE,
  GROUP_OR_ITEM,
  PRODUCTION_RATE,
} from "src/interfaces/estimate.interface";
import {
  getGroupCodeToDisplay,
  numberOnly,
  updateObjArray,
} from "src/utilities/functions.utilities";
import { COLUMNS, COLUMNS_TEMPLATE, SPACE_ROW } from "./ProductDetails";
import { HiClock, HiTrash } from "react-icons/hi2";
import { PRODUCT } from "src/interfaces/product.interface";
import DropdownMenuRow from "./DropdownMenu";
import AddButtonRow from "./AddButtonRow";
import { TEAM } from "src/interfaces/team.interface";
import NumberInput from "src/components/NumberInput";
import { ProductionRate } from "./ProductionRate";
import { AutoCompleteProduct } from "src/components/autoComplete/AutoCompleteProduct";
import CommentMui from "./CommentMui";
import { Tooltip, Typography } from "@mui/material";
import { ProductHistory } from "src/app/productAndConfigure/productList/ProductHistory";
import CheckButton from "./CheckButton";
import { COLORS } from "src/assets/colors";
import EstimateTableCell from "./EstimateTableCell";
import Tree from "./Tree";
import { useInView } from "react-intersection-observer";
import mergeRefs from "merge-refs";
import MainTextArea from "src/components/MainTextAreaEstimate";
import { catalogueByProduct } from "src/APIs/product.api";
import { CatalogueSelector } from "./catalogueSelectorDialogue/CatalogueSelector";
interface Props {
  estimateId: number | string | undefined;
  provided?: DraggableProvided;
  parentCode: string | null;
  item: GROUP_OR_ITEM;
  onAdd: (item: GROUP_OR_ITEM) => void;
  levels: number[];
  onHideAll: () => void;
  onShowAll: () => void;
  onShowByLevel: (lv: number) => void;
  onDelete: (item: GROUP_OR_ITEM) => void;
  onMoveTo: (item: GROUP_OR_ITEM) => void;

  updateItem: (item: GROUP_OR_ITEM, isUpdateMarkup?: boolean) => void;
  onAddNewGroup: (name: string, item: GROUP_OR_ITEM) => void;
  onAddTemplate: (row: GROUP_OR_ITEM, presetGroup: ESTIMATE_TEMPLATE) => void;
  isLast?: boolean;
  isLastEmpty: boolean;
  onCheck: (row: GROUP_OR_ITEM) => void;
  checkItems: GROUP_OR_ITEM[];
  onCheckAll: () => void;
  onUnCheckAll: () => void;
  activeId: string;
  onActive: (id: string) => void;
  siblingsLevel: number[];
  isTemplate?: boolean;
  isPrinting?: boolean;
}

export default function ProductDetailsRow(props: Props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [openComment, setOpenComment] = useState(false);
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<PRODUCT | null>(null);
  const [catalogue, setCatalogue] = useState<CATALOGUE | null>(null);
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("1");
  const [cost, setCost] = useState<string>("0");
  const [tax, setTax] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<string>("0");
  const [team, setTeam] = useState<TEAM | null>(null);
  const [productionRate, setProductionRate] = useState<PRODUCTION_RATE | null>(
    null
  );
  const [productionRateQty, setProductionRateQty] = useState<string>("");
  const [checked, setCheck] = useState(false);
  const [disabledCheck, setDisableCheck] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);

  const groupCode = React.useMemo(() => {
    const { sequence } = props.item ?? {};
    return getGroupCodeToDisplay(sequence || 0, props.parentCode);
  }, [props.parentCode, props.item.sequence]);

  const isEmpty = React.useMemo(() => {
    return props.item.isNew && !product?.id;
  }, [product]);

  useEffect(() => {
    setActive(props.activeId === props.item.id);
  }, [props.activeId]);

  useEffect(() => {
    setProduct(props.item.product);
    setCatalogue(props.item.catalog);
    setDescription(props.item.description ?? "");
    setQuantity(props.item?.quantity?.toString() ?? "1");
    setCost(props.item.price?.toString());
    setTax(props.item.taxIncluded);
    setTotalCost((props.item.price * props.item.quantity)?.toString());
    setProductionRate(props.item.productionRate);
    setProductionRateQty(props.item.productionRateQuantity?.toString());
  }, [props.item]);

  useEffect(() => {
    setTeam(props.item.team);
  }, [props.item.team]);

  useEffect(() => {
    setCheck(
      props.checkItems.find((m) => m.id === props.item.id) ? true : false
    );
    if (props.item.level !== 1) {
      setDisableCheck(
        props.checkItems.find((m) => m.code === props.item.parentGroupCode)
          ? true
          : false
      );
    }
  }, [props.checkItems]);

  async function onProduct(product: PRODUCT | null) {
    if (product?.id === props.item.product?.id) return;
    setLoadingProduct(true);
    if (product?.taxIncluded === undefined && product !== null) {
      props.onAddTemplate(props.item, product as any);
      return;
    }
    let item = props.item;
    item.product = product;
    item.productId = product?.id || null;
    item.catalog = null;
    item.catalogId = null;
    item.price = product?.price ?? 0;
    if (Number(quantity) === 0) {
      setQuantity("1");
      item.quantity = 1;
    }
    item.total = (product?.price ?? 0) * item.quantity;
    item.description = product?.description ?? "";
    item.taxIncluded = product?.taxIncluded || false;
    setProduct(product);
    setDescription(item.description ?? "");
    setTax(item.taxIncluded);

    const catalogues = product?.catalogAvailable
      ? (await catalogueByProduct(product?.id ?? 0)).data
      : [];
    const selectedCatalogue = catalogues?.find((m: CATALOGUE) => m.selected);
    if (selectedCatalogue) {
      setCatalogue(selectedCatalogue);
      item.total = (selectedCatalogue?.price ?? 0) * item.quantity;
      item.price = selectedCatalogue?.price ?? 0;
      item.catalog = selectedCatalogue;
      item.catalogId = selectedCatalogue?.id;
      setCost(item.price?.toString());
      setTotalCost(item.total?.toString());
    } else {
      setCatalogue(null);
    }

    setTotalCost(item.total?.toString() ?? "0");
    setLoadingProduct(false);
    props.updateItem(item);
  }

  function onCatalogue(catalogue: CATALOGUE) {
    let item = props.item;
    item.catalog = catalogue;
    item.catalogId = catalogue.id || null;
    item.price = catalogue.price;
    item.total = catalogue.price * Number(quantity || 1);
    setCatalogue(catalogue);
    setCost(item.price?.toString());
    setTotalCost(item.total?.toString());
    props.updateItem(item);
  }

  function onDescription(text: string) {
    if (text === description) return;
    setDescription(text);
    let item = props.item;
    item.description = text;
    props.updateItem(item);
  }

  function onQuantity(quantity: string, _item?: GROUP_OR_ITEM) {
    let item = _item || props.item;
    if (Number(quantity) === item?.quantity) return;
    item.quantity = Number(quantity) || 1;
    item.total = item.price * Number(quantity || 1);
    setQuantity(quantity);
    setTotalCost(item.total?.toString());
    props.updateItem(item);
  }

  function onCost(cost: string) {
    let item = props.item;
    if (Number(cost) === item?.price) return;
    item.price = Number(cost);
    item.total = Number(cost) * Number(quantity || 1);
    setTotalCost(item.total?.toString());
    setCost(cost);
    props.updateItem(item);
  }

  function onTotalAfterMarkup(cost: string) {
    let item = props.item;
    const markup = Number(cost) - Number(totalCost);
    if (markup === item?.markup) return;
    item.markup = markup;
    props.updateItem(item, true);
  }

  function onTax(tax: boolean) {
    let item = props.item;
    item.taxIncluded = tax;
    setTax(tax);
    props.updateItem(item);
  }

  function onTotalCost(total: string) {
    let item = props.item;
    if (Number(total) === item?.total) return;
    item.total = Number(total);
    item.price = item.total / (item.quantity || 1);
    setCost(item.price?.toString());
    setTotalCost(item.total?.toString());
    props.updateItem(item);
  }

  function onTeam(teamParams: TEAM | null) {
    let item = props.item;
    if (team?.id === teamParams?.id) return;
    item.team = teamParams;
    item.teamId = teamParams?.id;
    if (item.product?.productType?.code === "svc" && item.isGroup === false) {
      item.price =
        teamParams?.price ??
        item.catalog?.price ??
        item.product?.price ??
        item.price;
      item.total = item.quantity * (teamParams?.price ?? item.price);
    }
    setTeam(teamParams);
    props.updateItem(item);
  }

  function onProductionRate(
    prd: PRODUCTION_RATE | null,
    qty: string,
    minimum: string
  ) {
    let item = props.item;
    item.productionRate = prd;
    item.productionRateId = prd?.id || null;
    item.productionRateQuantity = Number(qty);
    setProductionRate(productionRate);
    setProductionRateQty(qty);
    onQuantity(minimum, item);
  }

  function onComment(comment: COMMENT) {
    let item = props.item;
    if (props.item?.comment?.find((m) => m.id === comment.id)) {
      item = {
        ...props.item,
        comment: updateObjArray(item.comment, comment),
        editedComments: updateObjArray(item.editedComments, comment),
      };
      props.updateItem(item);
      return;
    }
    item = {
      ...props.item,
      hasComment: true,
      newComments: updateObjArray(item.newComments, comment),
    };
    props.updateItem(item);
  }

  function onDeleteComment(comment: COMMENT) {
    let item = props.item;
    if (props.item?.comment?.find((m) => m.id === comment.id)) {
      item = {
        ...props.item,
        comment: item.comment.filter((m) => m.id !== comment.id),
        editedComments: item.editedComments.filter((m) => m.id !== comment.id),
        deletedComments: item.deletedComments.concat(comment.id ?? ""),
      };
      item.hasComment = !(
        item?.comment?.length === 0 && item?.newComments?.length === 0
      );
      props.updateItem(item);
      return;
    }
    item = {
      ...props.item,
      newComments: item.newComments.filter((m) => m.id !== comment.id),
    };
    item.hasComment = !(
      item?.comment?.length === 0 && item?.newComments?.length === 0
    );
    props.updateItem(item);
  }

  function autoUpdateComment(listComment: COMMENT[]) {
    let item: GROUP_OR_ITEM = {
      ...props.item,
      comment: listComment,
    };
    props.updateItem(item);
  }

  const renderDropDownMenu = React.useMemo(() => {
    return (
      <DropdownMenuRow
        onCheckAll={props.onCheckAll}
        onUnCheckAll={props.onUnCheckAll}
        onMoveTo={() => props.onMoveTo(props.item)}
        onLevel={(lv) => props.onShowByLevel(lv)}
        onShowAll={() => props.onShowAll()}
        onHideAll={() => props.onHideAll()}
        levels={props.levels}
      />
    );
  }, [props.levels, props.checkItems]);

  const renderTotalAfterMarkup = () => {
    const value =
      Number(totalCost) +
      Number(props.item.markup) +
      Number(props.item.disperse);
    return (
      <div
        style={{
          width: COLUMNS[7]?.width,
          minWidth: COLUMNS[7]?.width,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <NumberInput
          id={`${props.item?.id ?? ""}_value`}
          prefix="$"
          active={active}
          default={1}
          hideBorder={!hover}
          value={value.toString()}
          onChange={(text) => onTotalAfterMarkup(text)}
        />
      </div>
    );
  };

  const renderTeam = React.useMemo(() => {
    return (
      <AutoCompleteWithLazyLoad
        enablePortal
        active={active}
        hideBorder={!hover}
        placeholder="Choose team"
        path={"team"}
        onSelectedValue={(value) => onTeam(value)}
        value={team?.name ?? null}
      />
    );
  }, [team, active]);

  const renderTree = () => {
    return (
      <Tree
        rowId={`${props.item.id}_row`}
        hover={hover}
        isItem
        level={props.item.level}
        siblingsLevel={props.siblingsLevel}
        isLast={props.isLast ?? false}
      />
    );
  };

  const renderHeaderSpacing = React.useMemo(() => {
    if (props.item.level !== 1) return null;
    return (
      <TableRow>
        <TableCell
          className=" hover:bg-white bg-slate-50"
          colSpan={(props.isTemplate ? COLUMNS_TEMPLATE : COLUMNS).length}
        >
          {/* <div className="w-full h-[1px] bg-primary drop-shadow-md" /> */}
        </TableCell>
      </TableRow>
    );
  }, [props.item]);

  const whiteSpaceWidth = React.useMemo(() => {
    return SPACE_ROW * props.item.level - 1 + SPACE_ROW + 4;
  }, [props.item.level]);

  if (!inView && !props.isPrinting && !props.isLastEmpty && !active)
    return (
      <>
        {props.item.level === 1 ? renderHeaderSpacing : null}
        {props.item.level === 1 ? null : (
          <div
            id={`${props.item.id}_fake`}
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              height: 2,
              background: `linear-gradient(to right, #ffffff ${whiteSpaceWidth}px,  ${COLORS.border} 0%)`,
            }}
          />
        )}
        <TableRow
          style={{
            ...props?.provided?.draggableProps.style,
            cursor: "grab",
            position: "relative",
          }}
          {...props?.provided?.draggableProps}
          {...props?.provided?.dragHandleProps}
          ref={mergeRefs(props.provided?.innerRef, ref) as any}
          className="relative"
        >
          <TableCell
            colSpan={
              props.isTemplate ? COLUMNS_TEMPLATE.length : COLUMNS.length
            }
            className="bg-white"
          >
            <div
              style={{
                width: (props.isTemplate ? COLUMNS_TEMPLATE[0] : COLUMNS[0])
                  .width,
              }}
              className="flex h-full items-center pr-2 overflow-hidden"
            >
              {renderTree()}
              <div className="opacity-0">
                <AutoCompleteProduct
                  enablePortal
                  disabled={false}
                  loading={false}
                  active={false}
                  onAddNewGroup={(name) => null}
                  placeholder=""
                  hideBorder={false}
                  onSelectedValue={(value) => null}
                  value={null}
                />
              </div>
              <div
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  opacity: 0,
                }}
              >
                {!product ? null : (
                  <MainTextArea
                    id={props.item.id ?? ""}
                    placeholder=""
                    active={active}
                    hideBorder={!hover}
                    value={description}
                    onChange={(text) => onDescription(text)}
                  />
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
        <div
          id={`${props.item.id}_fake`}
          style={{
            left: 0,
            right: 0,
            position: "absolute",
            height: 2,
            background: `linear-gradient(to right, #ffffff ${whiteSpaceWidth}px,  ${COLORS.border} 0%)`,
          }}
        />
      </>
    );

  const renderDescription = (text: string) => {
    if (text.length === 0) return "";
    const texts = text.split(/\r|\r\n|\n/);
    return texts.map((m) => {
      return <p>{m.length === 0 ? " " : m}</p>;
    });
  };

  return (
    <>
      {props.item.level === 1 ? renderHeaderSpacing : null}
      {props.item.level === 1 ? null : (
        <div
          id={`${props.item.id}_fake`}
          style={{
            left: 0,
            right: 0,
            position: "absolute",
            height: 2,
            background: `linear-gradient(to right, #ffffff ${whiteSpaceWidth}px,  ${COLORS.border} 0%)`,
          }}
        />
      )}
      <TableRow
        id={`${props.item.id}_row`}
        className={`cursor-pointer draggable ${
          active ? "bg-secondary/10" : "bg-white"
        }`}
        style={{
          ...props?.provided?.draggableProps.style,
          cursor: "grab",
          position: "relative",
        }}
        {...props?.provided?.draggableProps}
        {...props?.provided?.dragHandleProps}
        ref={mergeRefs(props.provided?.innerRef, ref) as any}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        onClick={(e) => {
          e.stopPropagation();
          props.onActive(props.item.id ?? "");
          const autocompletes =
            document.getElementsByClassName("thisAutoComplete");
          Array.from(autocompletes).forEach((e: any) => e.blur());
        }}
      >
        <EstimateTableCell
          firstCell
          itemId={props.item.id ?? ""}
          id={(props.isTemplate ? COLUMNS_TEMPLATE[0] : COLUMNS[0]).accessorKey}
        >
          <div
            id={`${props.item.id}_${
              (props.isTemplate ? COLUMNS_TEMPLATE[0] : COLUMNS[0]).accessorKey
            }_div`}
            style={{
              width: (props.isTemplate ? COLUMNS_TEMPLATE[0] : COLUMNS[0])
                .width,
            }}
            className="flex h-full items-center pr-2 overflow-hidden"
          >
            <div className={`items-center flex`}>
              {renderDropDownMenu}
              <CheckButton
                disabled={disabledCheck}
                checked={checked}
                onChange={() => props.onCheck(props.item)}
              />
              {renderTree()}
              <AddButtonRow
                show={hover}
                onClick={() => props.onAdd(props.item)}
              />
              <h2 className="mr-2 ml-2 mt-1 ">{groupCode}.</h2>
            </div>
            <AutoCompleteProduct
              enablePortal
              disabled={loadingProduct}
              loading={loadingProduct}
              active={active}
              onAddNewGroup={(name) => props.onAddNewGroup(name, props.item)}
              isProduct
              leftIcon={product?.nameChange ? HiClock : undefined}
              onIconClick={() => setOpen(true)}
              placeholder="Choose product"
              hideBorder={!hover}
              onSelectedValue={(value) => onProduct(value)}
              value={product}
            />
            {/* {props.isTemplate ? null : (
              <Tooltip
                title={
                  props.estimateId ? "" : "Please save your estimate first."
                }
              >
                <div
                  onClick={() =>
                    !props.estimateId ? null : setOpenComment(true)
                  }
                >
                  <HiChatBubbleOvalLeft
                    className={`ml-2 hover:opacity-80 cursor-pointer mt-2 ${
                      !props.estimateId
                        ? "text-muted-foreground/30"
                        : props.item.hasComment
                        ? "text-secondary"
                        : "text-muted-foreground"
                    }`}
                  />
                </div>
              </Tooltip>
            )} */}
          </div>
        </EstimateTableCell>
        <EstimateTableCell
          secondCell
          itemId={props.item.id ?? ""}
          id={(props.isTemplate ? COLUMNS_TEMPLATE[1] : COLUMNS[1]).accessorKey}
        >
          <div
            id={`${props.item.id}_${
              (props.isTemplate ? COLUMNS_TEMPLATE[1] : COLUMNS[1]).accessorKey
            }_div`}
            className="flex h-full items-center pr-2"
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingTop: 6,
              width: (props.isTemplate ? COLUMNS_TEMPLATE[1] : COLUMNS[1])
                .width,
            }}
          >
            {!product || !product?.catalogAvailable ? null : (
              <CatalogueSelector
                active={active}
                disabled={!product?.catalogAvailable}
                placeholder={
                  !product?.catalogAvailable ? "" : "Choose catalogue"
                }
                hideBorder={!hover}
                value={catalogue}
                onSubmit={(cata) => onCatalogue(cata)}
                product={product}
              />
            )}
          </div>
        </EstimateTableCell>
        <EstimateTableCell
          itemId={props.item.id ?? ""}
          id={(props.isTemplate ? COLUMNS_TEMPLATE[2] : COLUMNS[2]).accessorKey}
        >
          <Tooltip title={renderDescription(description)}>
            <div
              style={{
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {!product ? null : (
                <MainTextArea
                  id={props.item.id ?? ""}
                  placeholder=""
                  active={active}
                  hideBorder={!hover}
                  value={description}
                  onChange={(text) => onDescription(text)}
                />
              )}
            </div>
          </Tooltip>
        </EstimateTableCell>
        <EstimateTableCell
          itemId={props.item.id ?? ""}
          id={COLUMNS[3].accessorKey}
        >
          <div
            className="flex items-center"
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {!product ? null : (
              <NumberInput
                id={`${props.item?.id ?? ""}_qty`}
                active={active}
                default={1}
                hideBorder={!hover}
                value={quantity}
                onChange={(text) => onQuantity(text)}
              />
            )}
            {product?.productionRateAvailable ? (
              <ProductionRate
                itemQty={quantity}
                onSave={onProductionRate}
                qty={productionRateQty}
                productionRate={productionRate}
                product={product}
              />
            ) : null}
          </div>
        </EstimateTableCell>
        <EstimateTableCell
          itemId={props.item.id ?? ""}
          id={COLUMNS[4].accessorKey}
        >
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {!product ? null : (
              <NumberInput
                id={`${props.item?.id ?? ""}_cost`}
                active={active}
                prefix="$"
                hideBorder={!hover}
                value={cost}
                onChange={(text) => onCost(numberOnly(text))}
              />
            )}
          </div>
        </EstimateTableCell>
        <EstimateTableCell
          itemId={props.item.id ?? ""}
          id={COLUMNS[5].accessorKey}
        >
          <div
            style={{
              opacity: isEmpty ? 0.3 : 1,
            }}
            className="h-full flex items-center justify-center pt-2"
          >
            {!product ? null : (
              <Checkbox
                active={active}
                onChange={(tax) => onTax(tax)}
                label={""}
                value={tax}
              />
            )}
          </div>
        </EstimateTableCell>
        <EstimateTableCell
          itemId={props.item.id ?? ""}
          id={COLUMNS[6].accessorKey}
        >
          <div
            style={{
              paddingLeft: 5,
              paddingRight: props.isTemplate ? 0 : 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {!product ? null : (
              <NumberInput
                id={`${props.item?.id ?? ""}_total_cost`}
                active={active}
                prefix="$"
                hideBorder={!hover}
                value={totalCost}
                onChange={(text) => onTotalCost(text)}
              />
            )}
            {!props.isTemplate ? null : (
              <div className="ml-2 mr-2">
                {props.isLastEmpty ? null : !product ? (
                  <div className="flex justify-end items-end">
                    <HiTrash
                      onClick={() =>
                        props.isLastEmpty ? null : props.onDelete(props.item)
                      }
                      size={15}
                      className={` text-slate-500 cursor-pointer ${
                        props.isLastEmpty ? "opacity-35" : ""
                      }`}
                    />
                  </div>
                ) : (
                  <HiTrash
                    onClick={() =>
                      props.isLastEmpty ? null : props.onDelete(props.item)
                    }
                    size={15}
                    className={` text-slate-500 cursor-pointer ${
                      props.isLastEmpty ? "opacity-35" : ""
                    }`}
                  />
                )}
              </div>
            )}
          </div>
        </EstimateTableCell>
        {props.isTemplate ? null : (
          <EstimateTableCell
            itemId={props.item.id ?? ""}
            id={COLUMNS[7].accessorKey}
          >
            {!product ? null : renderTotalAfterMarkup()}
          </EstimateTableCell>
        )}
        {props.isTemplate ? null : (
          <EstimateTableCell
            itemId={props.item.id ?? ""}
            id={COLUMNS[8].accessorKey}
          >
            <div style={{}} className="flex items-center pl-2">
              {!product ? null : renderTeam}
              <Tooltip
                title={
                  props.isLastEmpty
                    ? "Can't be deleted. An empty item is required!"
                    : ""
                }
              >
                <div className="ml-2 mr-2 flex flex-1">
                  {props.isLastEmpty ? null : !product ? (
                    <div className="relative w-full">
                      <HiTrash
                        onClick={() =>
                          props.isLastEmpty ? null : props.onDelete(props.item)
                        }
                        size={15}
                        className={`absolute top-[-4px] right-0 text-slate-500 cursor-pointer ${
                          props.isLastEmpty ? "opacity-35" : ""
                        }`}
                      />
                    </div>
                  ) : (
                    <HiTrash
                      onClick={() =>
                        props.isLastEmpty ? null : props.onDelete(props.item)
                      }
                      size={15}
                      className={` text-slate-500 cursor-pointer ${
                        props.isLastEmpty ? "opacity-35" : ""
                      }`}
                    />
                  )}
                </div>
              </Tooltip>
            </div>
          </EstimateTableCell>
        )}
      </TableRow>
      <div
        id={`${props.item.id}_fake`}
        style={{
          left: 0,
          right: 0,
          position: "absolute",
          height: 2,
          background: `linear-gradient(to right, #ffffff ${whiteSpaceWidth}px,  ${COLORS.border} 0%)`,
        }}
      />
      {open && product && product.nameChange ? (
        <ProductHistory
          asChild
          open={open}
          onOpen={(value) => setOpen(value)}
          product={product}
        />
      ) : null}
      {openComment ? (
        <CommentMui
          autoUpdateComment={autoUpdateComment}
          estimateId={props.estimateId}
          onComment={(comment) => onComment(comment)}
          onDeleteCommment={(comment) => onDeleteComment(comment)}
          open={openComment}
          onClose={() => setOpenComment(false)}
          item={props.item}
        />
      ) : null}
    </>
  );
}
