import { getAPI } from ".";
import { RESPONSE } from "./error_handler";

export default async function paginationAutoComplete(
  path: string,
  page: number,
  length: number,
  search: string,
  signal?: AbortSignal
) {
  var _url = `${process.env.REACT_APP_MAIN_API}/${path}?page=${page}&length=${length}`;
  if (search) {
    _url = `${_url}&search=${search}`;
  }
  const data = await getAPI(_url, signal);
  return data as RESPONSE;
}
