import { uploadImage } from "src/APIs/auth.api";
import {
  getPdfBySection,
  getSection,
  updateSection,
  getSectionList,
  getHtml,
  createSection,
  createTemplate,
  createProposal,
  getDefaultTemplate,
  getTemplateList,
  checkB4CreateProposal,
} from "src/APIs/proposal.api";
import { FILE } from "src/interfaces/auth.interface";
import { PROPOSAL_TEMPLATE, SECTION } from "src/interfaces/proposal.interface";

export async function onGetSection(
  corporateId: number,
  sectionId: number,
  isTemplate?: boolean
) {
  const res = await getSection(corporateId, sectionId, isTemplate);
  if (!res?.data.id) {
    return null;
  } else {
    return res?.data as SECTION;
  }
}

export async function onGetPdfBySection(sectionId: number) {
  const res = await getPdfBySection(sectionId);
  if (!res?.data) {
    return null;
  } else {
    return res;
  }
}

export async function onUpdateSection(
  corporateId: number,
  section: SECTION,
  isTemplate?: boolean
) {
  const res = await updateSection(corporateId, section, isTemplate);
  if (res?.data?.id) {
    return res?.data as SECTION;
  } else {
    return null;
  }
}

export async function onUploadImageBySection(sectionId: number, image: FILE) {
  const res = await uploadImage(
    `proposal-template/section/${sectionId}/upload`,
    image
  );
  if (res.data.id) return res.data;
  return null;
}

export async function onGetSectionList(
  corporateId: number,
  isTemplate?: boolean
) {
  const res = await getSectionList(corporateId, isTemplate);
  if (res.code === "200") {
    return res?.data as PROPOSAL_TEMPLATE;
  } else {
    return null;
  }
}

export async function onGetHtml(
  corporateId: number,
  sectionId: number,
  isTemplate?: boolean
) {
  const res = await getHtml(corporateId, sectionId, isTemplate);
  if (res.code === "200") {
    return res;
  } else {
    return null;
  }
}

export async function onCreateSection(
  corporateId: number,
  isTemplate: boolean,
  sectionName: string
) {
  const res = await createSection(corporateId, isTemplate, sectionName);
  if (res.code === "200") {
    return res?.data as SECTION;
  } else {
    return null;
  }
}

export async function onCreateTemplateByDefaultTemplate(
  defaultTemplateId: number,
  templateName: string
) {
  const res = await createTemplate(defaultTemplateId, templateName);
  if (res !== null) {
    return res?.data as PROPOSAL_TEMPLATE;
  } else {
    return null;
  }
}

export async function onCreateProposalByTemplate(
  templateId: number,
  proposalName: string,
  estimateId: number
) {
  const res = await createProposal(templateId, proposalName, estimateId);
  if (res !== null) {
    return res?.data as PROPOSAL_TEMPLATE;
  } else {
    return null;
  }
}

export async function onGetDefaultTemplate() {
  const res = await getDefaultTemplate();
  if (res !== null) {
    return res?.data as PROPOSAL_TEMPLATE[];
  } else {
    return null;
  }
}

export async function onGetTemplateList() {
  const res = await getTemplateList();
  if (res !== null) {
    return res?.data as PROPOSAL_TEMPLATE[];
  } else {
    return null;
  }
}

export const onGetValidateEstimateProposal = async (estimateId: number) => {
  const res = await checkB4CreateProposal(estimateId);
  return res;
};
