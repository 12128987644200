import CUSTOMER, {
  DEAL_TYPE,
  LEAD,
  LEAD_GROUP,
  LEAD_SOURCE,
  LEAD_STAGE,
  LEAD_STATUS,
} from "src/interfaces/customer.interface";
import { getAPI, postAPI, putAPI } from ".";
import { RESPONSE } from "./error_handler";
import { CRM_TYPES } from "src/constants/crm.constants";
import {
  DEFINED_FIELD,
  DEFINED_FIELD_LIST,
  DEFINED_FIELD_REORDER,
  CRM_SETUP,
  CRM_TYPE,
} from "src/interfaces/crm.interface";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { FIELD_LIST_ITEM } from "src/interfaces/product.interface";

export async function createContact(contact: CUSTOMER) {
  let url = `${process.env.REACT_APP_MAIN_API}/customer`;
  try {
    const res = await postAPI(url, contact);
    return res as RESPONSE;
  } catch (error: any) {
    return error as RESPONSE;
  }
}

export async function updateContact(contact: CUSTOMER) {
  let url = `${process.env.REACT_APP_MAIN_API}/customer/${contact?.id}`;
  const res = await putAPI(url, contact);
  return res as RESPONSE;
}

export const contactDetails = async (id: number) => {
  let url = `${process.env.REACT_APP_MAIN_API}/customer/${id}`;
  const res = await getAPI(url);
  return res as RESPONSE;
};

export async function getLeadList(
  type: string,
  statusId: string,
  stageId: string,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}?status-id=${statusId}&stage-id=${stageId}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  if (dateFrom && dateTo) {
    url = `${url}&date-from=${dateFrom}&date-to=${dateTo}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getOpportunityList(
  statusId: number,
  stageId: number,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  let url = `${process.env.REACT_APP_MAIN_API}/opportunity?status-id=${statusId}&stage-id=${stageId}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  if (dateFrom && dateTo) {
    url = `${url}&date-from=${dateFrom}&date-to=${dateTo}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getClientList(
  statusId: number,
  stageId: number,
  page: number,
  length: number,
  search?: string,
  sort?: string,
  desc?: boolean,
  dateFrom?: Date | null,
  dateTo?: Date | null
) {
  let url = `${process.env.REACT_APP_MAIN_API}/client?status-id=${statusId}&stage-id=${stageId}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  if (dateFrom && dateTo) {
    url = `${url}&date-from=${dateFrom}&date-to=${dateTo}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getStatus(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getStatusWithSummary(type: string) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}/summary`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getGroup(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-group?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getStage(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getSource(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-source?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getDealType(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-deal-type?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getCrmSetup(type: string, setupType: CRM_SETUP) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${setupType.key}?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getCrmDetailById(id: number, type: string) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}/${id}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getSourceList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  let url = isAll
    ? `${process.env.REACT_APP_MAIN_API}/lead-source/all?type=${type}&page=${page}&length=${length}`
    : `${process.env.REACT_APP_MAIN_API}/lead-source?type=${type}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getStageList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  let url = isAll
    ? `${process.env.REACT_APP_MAIN_API}/lead-stage/all?type=${type}&page=${page}&length=${length}`
    : `${process.env.REACT_APP_MAIN_API}/lead-stage?type=${type}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getStatusList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  let url = isAll
    ? `${process.env.REACT_APP_MAIN_API}/lead-status/all?type=${type}&page=${page}&length=${length}`
    : `${process.env.REACT_APP_MAIN_API}/lead-status?type=${type}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getGroupsList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  let url = isAll
    ? `${process.env.REACT_APP_MAIN_API}/lead-group/all?type=${type}&page=${page}&length=${length}`
    : `${process.env.REACT_APP_MAIN_API}/lead-group?type=${type}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getDealTypeList(
  page: number,
  length: number,
  type: string,
  search?: string,
  sort?: string,
  desc?: boolean,
  isAll?: boolean
) {
  let url = isAll
    ? `${process.env.REACT_APP_MAIN_API}/lead-deal-type/all?type=${type}&page=${page}&length=${length}`
    : `${process.env.REACT_APP_MAIN_API}/lead-deal-type?type=${type}&page=${page}&length=${length}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (sort && desc !== undefined) {
    url = `${url}&order-by=${sort}&ascending=${!desc}`;
  }
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function createCustomer(customer: CUSTOMER) {
  let url = `${process.env.REACT_APP_MAIN_API}/customer`;
  const res = await postAPI(url, customer);
  return res as RESPONSE;
}

export async function setStageDefault(stage: LEAD_STAGE) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage/${stage.id}/set-default`;
  const res = await putAPI(url, stage);
  return res as RESPONSE;
}

export async function setCrmSetupOnFly(
  doc: LEAD_GROUP | LEAD_SOURCE | DEAL_TYPE,
  leadId: number,
  type: string,
  setupType: CRM_SETUP
) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}/${leadId}/set-${setupType.key}?${setupType.key}-id=${doc?.id}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function setColorOnFly(
  id: string,
  type: CRM_SETUP,
  color: string
) {
  color = color.replaceAll("#", "");
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${type.key}/${id}/set-color?color=${color}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function setNameOnFly(id: string, type: CRM_SETUP, color: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${type.key}/${id}/set-name?name=${color}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function setCrmDefault(type: CRM_SETUP, id: number) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${type.key}/${id}/set-default`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function createCrm(lead: LEAD, type: string) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}`;
  const res = await postAPI(url, lead);
  return res as RESPONSE;
}

export async function createSource(leadSource: LEAD_SOURCE, type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-source/${type}`;
  const res = await postAPI(url, leadSource);
  return res as RESPONSE;
}

export async function createCrmSetup(
  doc: LEAD_SOURCE | LEAD_GROUP | DEAL_TYPE,
  setupType: CRM_SETUP,
  type: string
) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${setupType.key}/${type}`;
  const res = await postAPI(url, doc);
  return res as RESPONSE;
}

export async function createStage(v: LEAD_STAGE) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage`;
  const res = await postAPI(url, v);
  return res as RESPONSE;
}

export async function createStatus(doc: LEAD_STATUS, type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status/${type}`;
  const res = await postAPI(url, doc);
  return res as RESPONSE;
}

export async function createGroup(doc: LEAD_GROUP, type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-group/${type}`;
  const res = await postAPI(url, doc);
  return res as RESPONSE;
}

export async function createDealType(v: DEAL_TYPE, type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-deal-type/${type}`;
  const res = await postAPI(url, v);
  return res as RESPONSE;
}

export async function createCRMSetup(
  v: DEAL_TYPE,
  type: string,
  setupType: CRM_SETUP
) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-${setupType.key}/${type}`;
  const res = await postAPI(url, v);
  return res as RESPONSE;
}

export async function updateDealType(v: DEAL_TYPE, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-deal-type/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function updateCustomer(customer: CUSTOMER, id: number) {
  let url = `${process.env.REACT_APP_MAIN_API}/customer/${id}`;
  const res = await putAPI(url, customer);
  return res as RESPONSE;
}

export async function crmUpdate(v: LEAD, id: number, type: string) {
  if (type === CRM_TYPES.lead.key) type = "prospect";
  let url = `${process.env.REACT_APP_MAIN_API}/${type}/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function updateSource(v: LEAD_SOURCE, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-source/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function updateStage(v: LEAD_STAGE, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function updateStatus(v: LEAD_STATUS, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function updateStatusName(status: LEAD_STATUS) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status/${status.id}/set-name?name=${status.name}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function updateStageName(stage: LEAD_STAGE) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage/${stage.id}/set-name?name=${stage.name}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function enableStatus(enable: boolean, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status/${id}/set-enable?enable=${enable}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function enableStage(enable: boolean, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage/${id}/set-enable?enable=${enable}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}

export async function updateGroup(v: LEAD_GROUP, id: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-group/${id}`;
  const res = await putAPI(url, v);
  return res as RESPONSE;
}

export async function getEstimateByCustomer(customerId: number) {
  let url = `${process.env.REACT_APP_MAIN_API}/customer/${customerId}/get-estimate`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function crmStatusReorder(doc: LEAD_STATUS[], type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-status/re-order?type=${type}`;
  const payLoad: any[] = doc.map((s) => ({ id: s.id, sequence: s.sequence }));
  const res = await putAPI(url, payLoad);
  return res as RESPONSE;
}

export async function crmStageReorder(doc: LEAD_STAGE[], statusId: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/lead-stage/re-order?status-id=${statusId}`;
  const payLoad: any[] = doc.map((s) => ({ id: s.id, sequence: s.sequence }));
  const res = await putAPI(url, payLoad);
  return res as RESPONSE;
}

export async function getDefinedFieldsByTableId(tableId: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf?entity=${tableId}&enable=true`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function fieldListShow(type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/field-list?type=${type}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function getDefinedFieldsList(definedFieldId: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/show-by-udf?udf-id=${definedFieldId}`;
  const res = await getAPI(url);
  return res as RESPONSE;
}

export async function createDefinedField(
  doc: DEFINED_FIELD,
  crmType: CRM_TYPE
) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf/${crmType.key}`;
  const res = await postAPI(url, doc);
  return res as RESPONSE;
}

export async function definedFieldUpdateName(id: string, name: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf/${id}/set-name?name=${name}`;
  const data = await putAPI(url, null);
  return data as RESPONSE;
}

export async function definedFieldEnable(data: DEFINED_FIELD) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf/${data.id}/set-enable?enable=${data.enable}`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function definedFieldReorder(
  crmType: CRM_TYPE,
  data: DEFINED_FIELD_REORDER[]
) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf/re-order?entity=${crmType.key}`;
  const res = await putAPI(url, data);
  return res?.data;
}

export async function definedFieldListReorder(
  definedFieldId: string,
  data: DEFINED_FIELD_REORDER[]
) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/re-order?udf-id=${definedFieldId}`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function defineFieldListUpdateName(data: DEFINED_FIELD_LIST) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/${data.id}/set-value?value=${data.value}`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function defineFieldListUpdateColor(data: DEFINED_FIELD_LIST) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/${data.id}/set-color?background-color=${data.backgroundColor}`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function defineFieldListSetDefault(data: DEFINED_FIELD_LIST) {
  let url = `${process.env.REACT_APP_MAIN_API}/udf-list/${data.id}/set-default`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function fieldListSetDefault(data: FIELD_LIST_ITEM) {
  let url = `${process.env.REACT_APP_MAIN_API}/field-list/${data.id}/set-default`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function fieldListReorder(
  type: string,
  data: DEFINED_FIELD_REORDER[]
) {
  let url = `${process.env.REACT_APP_MAIN_API}/field-list/re-order?type=${type}`;
  const res = await putAPI(url, data);
  return res as RESPONSE;
}

export async function fieldListCreate(data: FIELD_LIST_ITEM, type: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/field-list/${type}`;
  const res = await postAPI(url, data);
  return res as RESPONSE;
}

export async function fieldListUpdate(id: string, value: string) {
  let url = `${process.env.REACT_APP_MAIN_API}/field-list/${id}/set-value?value=${value}`;
  const res = await putAPI(url, null);
  return res as RESPONSE;
}
