import { META_DATA } from "src/interfaces/proposal.interface";
import { ContentSubTitle } from "../ContentSubTitle";
import { DynamicInput } from "../dynamicComponent/DynamicInput";

interface Props {
  parent: META_DATA;
  onChnage: (child: META_DATA) => void;
  onAfterDelete: (data: META_DATA) => void;
}

export const FinancialTermSection = (props: Props) => {
  return (
    <div className="mt-8">
      <ContentSubTitle
        onAfterDelete={() => {
          props.onAfterDelete(props.parent);
        }}
        deleteId={props.parent.id}
        title={`${props.parent.label}`}
      />
      <div className="rounded-sm bg-gray-50 border-[0.8px] p-4 pt-0 mt-3">
        {props.parent.children.map((child) => {
          return (
            <DynamicInput
              key={child.id}
              meta={child}
              onChange={(v) => {
                const data = {
                  ...child,
                  value: v,
                };
                props.onChnage(data);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
