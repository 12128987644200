import { blobToBase64 } from "base64-blob";
import { isValidPhoneNumber } from "libphonenumber-js";
import _ from "lodash";
import { Area } from "react-easy-crop/types";
import { ALPHABETIC_CHARACTERS, PREPOSITIONS } from "src/constants";
import { GROUP_OR_ITEM } from "src/interfaces/estimate.interface";
import platform from "platform";
import { getStoredUniqueKey, setStoredUniqueKey } from "./storage.utilities";
import { v4 as uuidv4 } from "uuid";
import { DEVICE_TYPES } from "src/constants/deviceTypes.constants";

export const isPhoneValid = (phone: string) => {
  const isValid = isValidPhoneNumber(phone, "US");
  if (isValid === true) {
    return true;
  }
  return false;
};

export function nullString(data: string | null, notAllowZero?: boolean) {
  if (notAllowZero && Number(data) === 0) {
    return null;
  }
  if (data === null || data === undefined) return null;
  return data.trim() === "" ? null : data;
}

export function getGroupCodeToDisplay(
  sequence: number,
  parentCode: string | null
) {
  if (parentCode) {
    return `${parentCode}.${sequence}`;
  } else {
    return sequence.toString();
  }
}

export function sortByMultiFields(
  data: any[],
  fields: string[],
  iterates: "asc"[] | "desc"[]
) {
  return _.sortBy(data, fields, iterates);
}

export function displayImageBase64(base64: string, mimeType: string) {
  return `data:${mimeType};base64,${base64}`;
}

export function numberOnly(txt: string, allowCalculate?: boolean) {
  if (txt.trim() === "") return "";
  const array = txt.split("");
  let number = "1234567890.-";
  if (allowCalculate) {
    number = number.concat("+-*/");
  }
  return array.filter((m) => number.includes(m)).join("");
}

export function isValidMaxNumber(number: string) {
  const _numberArr = number.replaceAll(",", "").split(".");
  if (_numberArr[0] && _numberArr[0].length > 17) {
    return false;
  }
  return true;
}

export function updateObjArray(data: Array<any>, newItem: any) {
  let value: any[] = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter((m) => m.id === newItem.id);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj) => obj.id === newItem.id);
      value[index] = newItem;
    } else {
      value.push(newItem);
    }
  } else {
    value.push(newItem);
  }
  return value;
}

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  callback: (base64: string) => void
) {
  const image: any = await createImage(imageSrc);
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
  canvas.width = safeArea;
  canvas.height = safeArea;
  ctx?.translate(safeArea / 2, safeArea / 2);
  // ctx?.rotate(getRadianAngle(rotation));
  ctx?.translate(-safeArea / 2, -safeArea / 2);
  ctx?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data: any = ctx?.getImageData(0, 0, safeArea, safeArea);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx?.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );
  canvas.toBlob(async (blob: any) => {
    callback((await blobToBase64(blob)) as string);
  });
}

export function resizeImage(
  base64Str: string,
  maxWidth = 800,
  maxHeight = 600
) {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}

export function stringAvatar(name: string) {
  if (!name) return "";
  return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
}

export const onScrollTo = (key: string) => {
  const section: any = document.querySelector(`#${key}`);
  section.scrollIntoView({ block: "start", height: -200 });
};

function toTitleCase(str: string) {
  if (!str) return "";
  return str.toLowerCase().replace(/(?:^|[\s&()/:-])\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function title(text: string, alphabetic: string) {
  if (alphabetic === ALPHABETIC_CHARACTERS.lowercase.key) {
    return text ? text.toLocaleLowerCase() : "";
  }
  if (alphabetic === ALPHABETIC_CHARACTERS.uppercase.key) {
    return text ? text.toUpperCase() : "";
  }
  if (alphabetic === ALPHABETIC_CHARACTERS.capitalize.key) {
    const values = toTitleCase(text).split(" ");
    const mapped = values.map((m, index) => {
      if (PREPOSITIONS.includes(m.toLowerCase()) && index !== 0) {
        return m.toLowerCase();
      }
      return m;
    });
    return mapped.join(" ");
  }
  return text;
}

export function getAlphabetic(code: string) {
  if (code === "lc") return ALPHABETIC_CHARACTERS.lowercase.value;
  if (code === "org") return ALPHABETIC_CHARACTERS.normal.value;
  if (code === "tc") return ALPHABETIC_CHARACTERS.capitalize.value;
  if (code === "uc") return ALPHABETIC_CHARACTERS.uppercase.value;
  return "none";
}

export function getAlphabeticObj(code: string) {
  if (code === "lc") return ALPHABETIC_CHARACTERS.lowercase;
  if (code === "org") return ALPHABETIC_CHARACTERS.normal;
  if (code === "tc") return ALPHABETIC_CHARACTERS.capitalize;
  if (code === "uc") return ALPHABETIC_CHARACTERS.uppercase;
  return null;
}

export function toAlphabeticCode(alp: any) {
  if (ALPHABETIC_CHARACTERS.lowercase.key === alp.code) return "lc";
  if (ALPHABETIC_CHARACTERS.normal.key === alp.code) return "org";
  if (ALPHABETIC_CHARACTERS.capitalize.key === alp.code) return "tc";
  if (ALPHABETIC_CHARACTERS.uppercase.key === alp.code) return "uc";
  return "";
}

export function pageShowing(size: number, currentPage: number) {
  if (currentPage === 1) {
    return 1;
  }
  return size * currentPage + 1 - size;
}

export function pageTo(
  size: number,
  currentPage: number,
  totalElement: number
) {
  const result = size * currentPage;
  if (result >= totalElement) {
    return totalElement;
  }
  return result;
}

export function onOpenEmail(email: string | null | undefined) {
  window.location.href = `mailto:${email ?? "example@email.com"}`;
}

export function onOpenLocation(latLong: string | null | undefined) {
  window.open(`https://maps.google.com?q=${latLong}`);
}

export function onOpenPhoneCall(phoneNumber: string | null | undefined) {
  window.location.href = `tel:${phoneNumber}`;
}

export function nextSibling(siblings: GROUP_OR_ITEM[], index: number) {
  const next = siblings[index + 1]?.level;
  return next || null;
}

export function checkVisible(elm: HTMLElement | null) {
  if (!elm) return false;
  var rect = elm?.getBoundingClientRect();
  var viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export function renderColor(color?: string | null) {
  if (!color) return "#ffffff";
  return color.includes("#") ? color : `#${color}`;
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return DEVICE_TYPES.DEVICE_TYPE_ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return DEVICE_TYPES.DEVICE_TYPE_ANDROID;
  }

  return DEVICE_TYPES.DEVICE_TYPE_WEB;
}

export function getDeviceInfo() {
  const deviceType = getMobileOperatingSystem();
  const browserName = platform.name?.toLocaleUpperCase();
  const uniqueKey = getStoredUniqueKey() || uuidv4();
  const deviceID = `${deviceType}-${browserName}-${uniqueKey}`;
  setStoredUniqueKey(uniqueKey);
  return { deviceType, browserName, uniqueKey, deviceID };
}


export function getContentWidth(element:any) {
  var styles = window.getComputedStyle(element);
  return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
}


export function measureInputTextWidth(inputId:string) {
  var input:any = document.getElementById(inputId);
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  if(context === null || input === null) return;
  context.font = window.getComputedStyle(input).font;
  var metrics = context.measureText(input.value ?? "");
  return metrics.width;
}