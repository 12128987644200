import { makeAutoObservable, observable, action } from "mobx";
import { META_DATA, SECTION } from "src/interfaces/proposal.interface";
import {
  onCreateSection,
  onGetSection,
  onGetSectionList,
  onGetHtml,
  onGetPdfBySection,
  onUpdateSection,
} from "src/services/proposal.service";
import { orderByAsc } from "src/utilities/formatter.utilities";
import { getDayLabel } from "src/utilities/mapping.utilities";

export default class ProposalStore {
  @observable pdfBase64: string = "";
  @observable rawHtml: any = null;
  @observable errorPreview: boolean = true;
  @observable section: SECTION | null = null;
  @observable sectionList: SECTION[] = [];
  @observable sectionDetail: SECTION | null = null;
  @observable sectionPayload: SECTION | null = null;
  @observable processingPreview: boolean = true;
  @observable processingContent: boolean = true;
  @observable processingContentUpdate: boolean = false;
  @observable processingSection: boolean = true;
  @observable processingCreateSection: boolean = false;
  @observable disableSave: boolean = true;
  @observable corporateId: number = 0;
  @observable isTemplate: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action onInitCorporateId = (corporateId: number, isTemplate: boolean) => {
    this.corporateId = corporateId;
    this.isTemplate = isTemplate;
  };

  @action onGetSectionList = (corporateId: number, isTemplate?: boolean) => {
    this.processingSection = true;
    onGetSectionList(corporateId, isTemplate).then((data) => {
      if (data !== null) {
        if (data.sections) {
          this.sectionList = orderByAsc(data.sections, "sort");
          this.section = this.sectionList[0];
          this.processingSection = false;
        }
      } else {
        this.sectionList = [];
        this.section = null;
        this.processingSection = false;
      }
    });
  };

  @action onChangeSection = (section: SECTION) => {
    this.section = section;
    this.disableSave = true;
  };

  @action onCreateSection = (
    proposalId: number,
    name: string,
    onSuccess: () => void
  ) => {
    this.processingCreateSection = true;
    onCreateSection(proposalId, this.isTemplate, name)
      .then((data) => {
        if (data !== null) {
          this.section = data;
          this.sectionList.push(data);
          this.sectionList = orderByAsc(this.sectionList, "sort");
          this.processingCreateSection = false;
          onSuccess();
        } else {
          this.processingCreateSection = false;
        }
      })
      .catch(() => {
        this.processingCreateSection = false;
      })
      .finally(() => {
        this.processingCreateSection = false;
      });
  };

  @action onGetSection = (
    corporateId: number,
    sectionId: number,
    isTemplate?: boolean
  ) => {
    this.processingContent = true;
    this.sectionDetail = null;
    onGetSection(corporateId, sectionId, isTemplate).then((data) => {
      if (data === null) {
        this.sectionDetail = null;
        this.processingContent = false;
      } else {
        this.sectionPayload = { ...data };
        this.sectionDetail = { ...data };
        if (data.template === "our-team") {
          const fakeImage: META_DATA = {
            key: "image",
            value: null,
            label: "Upload Image",
            type: "image",
            sort: 4,
            children: [],
          };
          this.sectionDetail.meta.forEach((item) => {
            const index = this.sectionDetail?.meta.findIndex(
              (m) => m.id === item.id
            );
            if (index !== -1) {
              if (this.sectionDetail?.meta[index ?? 0].children.length === 3) {
                this.sectionDetail?.meta[index ?? 0].children.push(fakeImage);
              }
            }
          });
          this.sectionPayload.meta.forEach((item) => {
            const index = this.sectionPayload?.meta.findIndex(
              (m) => m.id === item.id
            );
            if (index !== -1) {
              if (this.sectionPayload?.meta[index ?? 0].children.length === 3) {
                this.sectionPayload?.meta[index ?? 0].children.push(fakeImage);
              }
            }
          });
        }
        if (data.template === "project-schedule") {
          const fakeImage: META_DATA = {
            key: "image",
            value: null,
            label: "Upload Image",
            type: "image",
            sort: 4,
            children: [],
          };
          this.sectionDetail.meta.forEach((item) => {
            const index = this.sectionDetail?.meta.findIndex(
              (m) => m.id === item.id
            );
            if (index !== -1) {
              if (this.sectionDetail?.meta[index ?? 0].children.length === 3) {
                this.sectionDetail?.meta[index ?? 0].children.push(fakeImage);
              }
            }
          });
          this.sectionPayload.meta.forEach((item) => {
            const index = this.sectionPayload?.meta.findIndex(
              (m) => m.id === item.id
            );
            if (index !== -1) {
              if (this.sectionPayload?.meta[index ?? 0].children.length === 3) {
                this.sectionPayload?.meta[index ?? 0].children.push(fakeImage);
              }
            }
          });
        }
        this.sectionDetail.meta = orderByAsc(this.sectionDetail.meta, "sort");
        this.sectionDetail.meta.map((m) => orderByAsc(m.children, "sort"));
        this.sectionPayload.meta = orderByAsc(this.sectionPayload.meta, "sort");
        this.processingContent = false;
      }
    });
  };

  @action onGetPdfBySection = (sectionId: number) => {
    this.processingPreview = true;
    this.errorPreview = false;
    onGetPdfBySection(sectionId).then((data) => {
      if (!data) {
        this.processingPreview = false;
        this.errorPreview = true;
        this.pdfBase64 = "";
      } else {
        this.pdfBase64 = data?.data;
        this.processingPreview = false;
        this.errorPreview = false;
      }
    });
  };

  @action onGetHtml = (
    corporateId: number,
    sectionId: number,
    isTemplate?: boolean
  ) => {
    this.processingPreview = true;
    onGetHtml(corporateId, sectionId, isTemplate).then((res) => {
      if (res === null) {
        this.processingPreview = false;
        this.rawHtml = null;
      } else {
        this.rawHtml = res.data;
        this.processingPreview = false;
      }
    });
  };

  @action onUpdateSection = (onSuccess?: () => void) => {
    this.processingContentUpdate = true;
    this.processingPreview = true;
    if (this.sectionPayload !== null) {
      onUpdateSection(this.corporateId, this.sectionPayload, this.isTemplate)
        .then((data) => {
          if (data !== null) {
            this.sectionPayload = data;
            this.sectionDetail = data;
            this.rawHtml = data.pdf;

            this.sectionDetail.meta = orderByAsc(data.meta, "sort");
            this.sectionDetail.meta.map((m) => orderByAsc(m.children, "sort"));

            this.processingContentUpdate = false;
            this.processingPreview = false;
            this.disableSave = true;
            onSuccess && onSuccess();
          } else {
            this.processingContentUpdate = false;
            this.processingPreview = false;
          }
        })
        .catch(() => {
          this.processingContentUpdate = false;
          this.processingPreview = false;
          return;
        })
        .finally(() => {
          this.processingContentUpdate = false;
          this.processingPreview = false;
          return;
        });
    }
  };

  @action onUpdatePayload2Level = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      const parentIndex = this.sectionPayload.meta.findIndex(
        (m) => m.id === parent.id
      );

      if (child.id === undefined) {
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);
        this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;
      } else {
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;
      }

      this.disableSave = false;

      if (child.type === "image") {
        const images = this.sectionPayload.meta.filter(
          (m) => m.id === parent.id
        );
        this.sectionPayload.meta = images;
        onSuccess && onSuccess();
        return;
      }

      const childNoImage = this.sectionPayload.meta[
        parentIndex
      ].children.filter((m) => m.type !== "image");
      this.sectionPayload.meta[parentIndex].children = childNoImage;

      const parentNoImage = this.sectionPayload.meta.filter(
        (m) => m.type !== "upload"
      );
      this.sectionPayload.meta = parentNoImage;
      onSuccess && onSuccess();
    }
  };

  // USED FOR COVER ONLY
  @action onDeleteCoverImage = (parent: META_DATA, child: META_DATA) => {
    if (this.sectionDetail !== null && this.sectionPayload !== null) {
      const parentIndex = this.sectionDetail.meta.findIndex(
        (m) => m.id === parent.id
      );
      const imagesAfterDelete = this.sectionDetail.meta[
        parentIndex
      ].children.filter((m) => m.id !== child.id);

      this.sectionDetail.meta[parentIndex].children = imagesAfterDelete;
      this.sectionPayload.meta[parentIndex].children = imagesAfterDelete;

      const finalResult = {
        ...this.sectionDetail,
        meta: this.sectionDetail.meta,
      };
      this.sectionDetail = finalResult;
      this.sectionPayload = finalResult;
      this.onGetHtml(this.corporateId, this.sectionDetail.id, this.isTemplate);
    }
  };
  @action onUploadPayloadCoverImage = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      const parentIndex = this.sectionPayload.meta.findIndex(
        (m) => m.id === parent.id
      );
      if (child.id !== undefined) {
        const childIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children[childIndex] = child;
      } else {
        this.sectionPayload.meta[parentIndex].children.push(child);
      }

      const finalResult = this.sectionPayload.meta.filter(
        (m) => m.id === parent.id
      );
      this.sectionPayload.meta = finalResult;
      onSuccess && onSuccess();
    }
  };

  // USED FOR FINANCIAL TERM ONLY
  @action onDeleteFinancialTerm = (data: META_DATA) => {
    if (this.sectionDetail !== null && this.sectionPayload !== null) {
      const results = this.sectionDetail.meta.filter((m) => m.id !== data.id);
      this.sectionDetail.meta = results;
      this.sectionPayload.meta = results;
      this.onGetHtml(this.corporateId, this.sectionDetail.id, this.isTemplate);
    }
  };
  @action onAddFinancialTerm = () => {
    if (this.sectionPayload !== null && this.sectionDetail !== null) {
      const newData: META_DATA = {
        key: `financial_term_${this.sectionPayload.meta.length + 1}`,
        type: "section",
        value: "",
        label: `${this.sectionPayload.meta.length + 1}. Financial Term`,
        sort: this.sectionPayload.meta.length + 1,
        children: [
          {
            key: "Milestone Name",
            type: "text",
            value: "",
            label: "Milestone Name",
            sort: 1,
            children: [],
          },
          {
            key: "Amount",
            type: "number",
            value: "",
            label: "Amount",
            sort: 2,
            children: [],
          },
        ],
      };
      this.sectionPayload.meta.push(newData);
      this.sectionDetail.meta.push(newData);
      this.disableSave = false;
    }
  };

  // USED FOR PROJECT SCHEDULE ONLY
  @action onAddProjectScheduleDate = (parent: META_DATA, title: string) => {
    if (this.sectionPayload !== null && this.sectionDetail !== null) {
      this.sectionPayload.pdf = null;
      const parentIndex = this.sectionPayload.meta.findIndex(
        (m) => m.id === parent.id
      );
      const newData: META_DATA = {
        key: title.toLowerCase().replaceAll(" ", "_"),
        type: "date",
        value: new Date(),
        label: title,
        sort: this.sectionPayload.meta[parentIndex].children.length + 1,
        children: [],
      };

      this.sectionDetail.meta[parentIndex].children.push(newData);
      this.sectionDetail.meta = [...this.sectionDetail.meta];

      this.sectionPayload.meta[parentIndex].children.push(newData);
      const parentNoImage = this.sectionPayload.meta.filter(
        (m) => m.type !== "upload"
      );
      this.sectionPayload.meta = parentNoImage;
    }
  };

  // USED FOR REFERENCE IMAGE ONLY
  @action onUpdateReferenceImagePayload4UploadImage = (
    parent: META_DATA,
    child: META_DATA,
    subChild: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      if (parent.id !== undefined && child.id !== undefined) {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.id === parent.id
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);

        this.sectionPayload.meta[parentIndex].children[
          childIdIndex
        ].children.push(subChild);
        const image4Upload = this.sectionPayload.meta[parentIndex].children[
          childIdIndex
        ].children.filter((m) => m.id === undefined);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].children =
          image4Upload;
      } else {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.key === parent.key
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);

        this.sectionPayload.meta[parentIndex].children[
          childIdIndex
        ].children.push(subChild);
        const image4Upload = this.sectionPayload.meta[parentIndex].children[
          childIdIndex
        ].children.filter((m) => m.id === undefined);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].children =
          image4Upload;
      }

      const results = this.sectionPayload.meta.filter(
        (m) => m.id === parent.id
      );
      this.sectionPayload.meta = results;
      onSuccess && onSuccess();
    }
  };
  @action onUpdateReferenceImagePayload = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      if (parent.id !== undefined && child.id !== undefined) {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.id === parent.id
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].value =
          child.value;
      } else {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.key === parent.key
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].value =
          child.value;
      }

      this.sectionPayload.meta.forEach((item) => {
        if (item.id !== undefined) {
          const index = this.sectionPayload?.meta.findIndex(
            (m) => m.id === item.id
          );
          if (index !== -1) {
            const results = this.sectionPayload?.meta[
              index ?? 0
            ].children.filter((m) => m.type !== "upload");
            if (this.sectionPayload?.meta && results) {
              this.sectionPayload.meta[index ?? 0].children = results;
            }
          }
        }
      });

      this.disableSave = false;
      onSuccess && onSuccess();
    }
  };
  @action onAddReferenceImage = () => {
    if (this.sectionPayload !== null && this.sectionDetail !== null) {
      const newData: META_DATA = {
        key: `${getDayLabel(
          this.sectionPayload.meta.length + 1
        )}_reference_images`,
        value: null,
        label: `${getDayLabel(
          this.sectionPayload.meta.length + 1
        )} Row Reference Images`,
        type: "section",
        sort: this.sectionPayload.meta.length + 1,
        children: [
          {
            key: "image_title",
            value: "Untitle",
            label: "Title",
            type: "text",
            sort: 1,
            children: [],
          },
          {
            key: "description",
            value: "",
            label: "Description",
            type: "text_area",
            sort: 2,
            children: [],
          },
          {
            key: "upload_image",
            value: null,
            label: "upload_image",
            type: "upload",
            sort: 3,
            children: [],
          },
        ],
      };

      this.sectionPayload.meta.push(newData);
      this.sectionDetail.meta.push(newData);
      this.sectionPayload.pdf = null;

      this.sectionPayload.meta.forEach((item) => {
        if (item.id !== undefined) {
          const index = this.sectionPayload?.meta.findIndex(
            (m) => m.id === item.id
          );
          if (index !== -1) {
            const results = this.sectionPayload?.meta[
              index ?? 0
            ].children.filter((m) => m.type !== "upload");
            if (this.sectionPayload?.meta && results) {
              this.sectionPayload.meta[index ?? 0].children = results;
            }
          }
        }
      });
    }
  };
  @action onDeleteReferenceImage = (
    parent: META_DATA,
    child: META_DATA,
    subChildId: any
  ) => {
    if (this.sectionDetail !== null && this.sectionPayload !== null) {
      const parentIndex = this.sectionDetail.meta.findIndex(
        (m) => m.id === parent.id
      );
      const childIndex = this.sectionDetail.meta[
        parentIndex
      ].children.findIndex((m) => m.id === child.id);

      const sectionImagesAfterDelete = this.sectionDetail.meta[
        parentIndex
      ].children[childIndex].children.filter((m) => m.id !== subChildId);
      const payloadImagesAfterDelete = this.sectionPayload.meta[
        parentIndex
      ].children[childIndex].children.filter((m) => m.id !== subChildId);

      this.sectionDetail.meta[parentIndex].children[childIndex].children =
        sectionImagesAfterDelete;
      this.sectionPayload.meta[parentIndex].children[childIndex].children =
        payloadImagesAfterDelete;

      this.sectionDetail = { ...this.sectionDetail };
      this.sectionPayload = { ...this.sectionPayload };
      this.onGetHtml(this.corporateId, this.sectionDetail.id, this.isTemplate);
    }
  };

  // USED FOR OUR-TEAM ONLY
  @action onUpdateOurTeamPayload = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;

      if (parent.id !== undefined && child.id !== undefined) {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.id === parent.id
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].value =
          child.value;
      } else {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.key === parent.key
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);
        this.sectionPayload.meta[parentIndex].children[childIdIndex].value =
          child.value;
      }

      this.sectionPayload.meta.forEach((item) => {
        if (item.id !== undefined) {
          const index = this.sectionPayload?.meta.findIndex(
            (m) => m.id === item.id
          );
          if (index !== -1) {
            const results = this.sectionPayload?.meta[
              index ?? 0
            ].children.filter((m) => m.type !== "image");
            if (this.sectionPayload?.meta && results) {
              this.sectionPayload.meta[index ?? 0].children = results;
            }
          }
        }
      });

      this.disableSave = false;
      onSuccess && onSuccess();
    }
  };
  @action onUpdateOurTeamImagePayload = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;

      if (parent.id !== undefined && child.id !== undefined) {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.id === parent.id
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;

        const childData4Update = this.sectionPayload.meta[
          parentIndex
        ].children.filter((m) => m.id === child.id);
        this.sectionPayload.meta[parentIndex].children = childData4Update;

        const parentData4Update = this.sectionPayload.meta.filter(
          (m) => m.id === parent.id
        );
        this.sectionPayload.meta = parentData4Update;
      } else if (child.id === undefined) {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.id === parent.id
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);
        this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;

        const parentData4Update = this.sectionPayload.meta.filter(
          (m) => m.id === parent.id
        );
        this.sectionPayload.meta = parentData4Update;
      } else {
        const parentIndex = this.sectionPayload.meta.findIndex(
          (m) => m.key === parent.key
        );
        const childIdIndex = this.sectionPayload.meta[
          parentIndex
        ].children.findIndex((m) => m.key === child.key);
        this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;

        const parentData4Update = this.sectionPayload.meta.filter(
          (m) => m.key === parent.key
        );
        this.sectionPayload.meta = parentData4Update;
      }

      this.disableSave = false;
      onSuccess && onSuccess();
    }
  };
  @action onAddOurTeam = () => {
    if (this.sectionPayload !== null && this.sectionDetail !== null) {
      this.sectionPayload.pdf = null;
      const newData: META_DATA = {
        key: `${this.sectionPayload.meta.length + 1}_our_team`,
        value: null,
        label: `${this.sectionPayload.meta.length + 1}. Team Member`,
        type: "section",
        sort: this.sectionPayload.meta.length + 1,
        children: [
          {
            key: "name",
            value: "",
            label: "Name",
            type: "text",
            sort: 1,
            children: [],
          },
          {
            key: "position",
            value: "",
            label: "Position",
            type: "text",
            sort: 2,
            children: [],
          },
          {
            key: "email",
            value: "",
            label: "Email",
            type: "text",
            sort: 3,
            children: [],
          },
          {
            key: "image",
            value: null,
            label: "Upload Image",
            type: "imageAdd",
            sort: 4,
            children: [],
          },
        ],
      };
      this.sectionPayload.meta.push(newData);
      this.sectionDetail.meta.push(newData);

      this.sectionPayload.meta.forEach((item) => {
        if (item.id !== undefined) {
          const index = this.sectionPayload?.meta.findIndex(
            (m) => m.id === item.id
          );
          if (index !== -1) {
            const results = this.sectionPayload?.meta[
              index ?? 0
            ].children.filter((m) => m.type !== "image");
            if (this.sectionPayload?.meta && results) {
              this.sectionPayload.meta[index ?? 0].children = results;
            }
          }
        }
      });
    }
  };
  @action onDeleteOurTeam = (parent: META_DATA) => {
    if (this.sectionDetail !== null && this.sectionPayload !== null) {
      const contentAfterDetele = this.sectionDetail.meta.filter(
        (m) => m.id !== parent.id
      );
      const payloadAfterDetele = this.sectionPayload.meta.filter(
        (m) => m.id !== parent.id
      );
      this.sectionDetail.meta = contentAfterDetele;
      this.sectionPayload.meta = payloadAfterDetele;
      this.onGetHtml(this.corporateId, this.sectionDetail.id, this.isTemplate);
    }
  };
  @action onDeleteOurTeamImage = (parent: META_DATA, child: META_DATA) => {
    if (this.sectionDetail !== null && this.sectionPayload !== null) {
      const parentIndex = this.sectionDetail.meta.findIndex(
        (m) => m.id === parent.id
      );
      const imagesAfterDelete = this.sectionDetail.meta[
        parentIndex
      ].children.filter((m) => m.id !== child.id);
      this.sectionDetail.meta[parentIndex].children = imagesAfterDelete;
      this.sectionPayload.meta[parentIndex].children = imagesAfterDelete;

      const fakeImage: META_DATA = {
        key: "image",
        value: null,
        label: "Upload Image",
        type: "imageAdd",
        sort: 4,
        children: [],
      };
      this.sectionDetail.meta[parentIndex].children.push(fakeImage);
      this.sectionPayload.meta[parentIndex].children.push(fakeImage);

      const finalResult = {
        ...this.sectionDetail,
        meta: this.sectionDetail.meta,
      };
      this.sectionDetail = finalResult;
      this.sectionPayload = finalResult;
      this.onGetHtml(this.corporateId, this.sectionDetail.id, this.isTemplate);
    }
  };

  // USED FOR PROPOSAL SIGN-OFF ONLY
  @action onUpdateProposalSignOffPayload = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      const parentIndex = this.sectionPayload.meta.findIndex(
        (m) => m.id === parent.id
      );
      const childIdIndex = this.sectionPayload.meta[
        parentIndex
      ].children.findIndex((m) => m.id === child.id);
      this.sectionPayload.meta[parentIndex].children[childIdIndex] = child;

      if (child.type === "image") {
        const results = this.sectionPayload.meta.filter(
          (m) => m.id === parent.id
        );
        this.sectionPayload.meta = results;
      } else {
        this.sectionPayload.meta.forEach((item) => {
          if (item.id !== undefined) {
            const index = this.sectionPayload?.meta.findIndex(
              (m) => m.id === item.id
            );
            if (index !== -1) {
              const results = this.sectionPayload?.meta[
                index ?? 0
              ].children.filter((m) => m.type !== "image");
              if (this.sectionPayload?.meta && results) {
                this.sectionPayload.meta[index ?? 0].children = results;
              }
            }
          }
        });
      }

      this.disableSave = false;
      onSuccess && onSuccess();
    }
  };
  @action onUpdateProposalSignOff4ImagePayload = (
    parent: META_DATA,
    child: META_DATA,
    onSuccess?: () => void
  ) => {
    if (this.sectionPayload !== null) {
      this.sectionPayload.pdf = null;
      const parentIndex = this.sectionPayload.meta.findIndex(
        (m) => m.id === parent.id
      );

      this.sectionPayload.meta[parentIndex].children = [];
      this.sectionPayload.meta[parentIndex].children.push(child);

      const results = this.sectionPayload.meta.filter(
        (m) => m.id === parent.id
      );
      this.sectionPayload.meta = results;

      this.disableSave = false;
      onSuccess && onSuccess();
    }
  };
}
