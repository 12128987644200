export const LAYOUTS = {
  padding: "10px",
  padding_small: "6px",
  padding_big: "24px",
  margin: "12px",
  margin_small: "6px",
  margin_big: "24px",
  margin_icon: "6px",
  radius: "5px",
  super_radius: "100px",
  icon_size: "18px",
  icon_size_big: "28px",
  full_width: "100vw",
  full_height: "100vh",
};
