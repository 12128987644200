export const COLUMN_TYPES = {
    list:"list",
    text:"text",
    people:"people",
    dropdown:"dropdown",
    date:"date",
    number:"number",
    file:"file",
    checkbox:"checkbox",
    progress:"progress",
    timeline:"timeline",
    email:"email",
    telephone:"telephone",
    currency:"currency"
}