import {
  IoCalculatorOutline,
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";
import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";
import { displayDateComment } from "src/utilities/formatter.utilities";
import CUSTOMER from "src/interfaces/customer.interface";
import {
  onOpenEmail,
  onOpenLocation,
  onOpenPhoneCall,
} from "src/utilities/functions.utilities";
import { useEffect, useState } from "react";
import CRMStore from "src/stores/crm.store";
import { inject, observer } from "mobx-react";

interface Props {
  crm?: CRMStore;
}

function ContactDetailSubHeader(props: Props) {
  const [contactData, setContactData] = useState<CUSTOMER | null>(null);

  useEffect(() => {
    if (props.crm?.contactDetails) {
      setContactData(props.crm.contactDetails);
    }
  }, [props.crm?.contactDetails]);

  return (
    <div className="flex">
      <div className=" w-full rounded-md bg-white m-4 p-6">
        <div className="flex items-center">
          <Label className="text-2xl font-semibold uppercase">
            {contactData?.displayName}
          </Label>
          {contactData?.email ? (
            <div className="flex items-center">
              <Button
                onClick={() => {
                  onOpenEmail(contactData?.email);
                }}
                className="ml-7"
                variant="link"
                size="icon"
              >
                <IoMailOutline size={18} />
              </Button>
              <Label className="text-base font-medium ml-1">
                {contactData?.email}
              </Label>
            </div>
          ) : null}

          {contactData?.telephone1 ? (
            <div className="flex items-center">
              <Button
                onClick={() => {
                  onOpenPhoneCall(contactData?.telephone1);
                }}
                className="ml-7"
                variant="link"
                size="icon"
              >
                <IoCallOutline size={18} />
              </Button>
              <Label className="text-base font-medium ml-1">
                {contactData?.telephone1}
              </Label>
            </div>
          ) : null}

          {contactData?.telephone2 ? (
            <div className="flex items-center">
              <Button
                onClick={() => {
                  onOpenPhoneCall(contactData?.telephone2);
                }}
                className="ml-7"
                variant="link"
                size="icon"
              >
                <IoCalculatorOutline size={18} />
              </Button>
              <Label className="text-base font-medium ml-1">
                {contactData.telephone2}
              </Label>
            </div>
          ) : null}
        </div>

        {contactData?.billingAddress?.fullAddress ? (
          <div>
            <div className="mt-3 flex items-center">
              <Button
                onClick={() => {
                  onOpenLocation(contactData?.billingAddress?.latLong);
                }}
                variant="link"
                size="icon"
              >
                <IoLocationOutline size={18} />
              </Button>
              <Label className="font-light">Address</Label>
            </div>
            <Label className="text-base font-medium">
              {contactData?.billingAddress?.fullAddress}
            </Label>
          </div>
        ) : null}

        <div className="mt-6 flex">
          <div>
            <Label className="font-light">Spouse info</Label>
            <div className="text-base font-semibold">
              {contactData?.spouse ?? "N/A"}
            </div>
          </div>
          <div className="ml-9">
            <Label className="font-light flex-col">Creation Log </Label>
            <div className="text-base font-semibold">
              {contactData?.createdAt
                ? displayDateComment(contactData?.createdAt)
                : "N/A"}
            </div>
          </div>
          <div className="ml-9">
            <Label className="font-light">Total Invoice</Label>
            <div className="text-base font-semibold">{0}</div>
          </div>
        </div>
      </div>
      <div className="w-96 rounded-md bg-white m-4 ml-0 p-6 justify-around">
        <Label className="uppercase font-bold">Summary</Label>
        <div className="flex items-center mt-4">
          <div className="h-6 w-1.5 bg-orange-500"></div>
          <Label className="text-3xl uppercase font-bold ml-2">USD 0.00</Label>
        </div>
        <Label className=" text-sm font-light ml-4">Open Balance</Label>

        <div className="flex items-center mt-4">
          <div className="h-6 w-1.5 bg-red-500"></div>
          <Label className="text-3xl uppercase font-bold ml-2">USD 0.00</Label>
        </div>
        <Label className=" text-sm font-light ml-4">Overdue Payment</Label>
      </div>
    </div>
  );
}

export default inject("crm")(observer(ContactDetailSubHeader));
