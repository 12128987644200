import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import ProposalStore from "src/stores/proposal.store";
import AddTemplateButton from "./AddSectionButton";
import { ProposalSectionItem } from "./ProposalSectionItem";
import { ProposalSectionSkeleton } from "./ProposalSectionSkeleton";

interface Props {
  id: number;
  isTemplate?: boolean;
  proposal?: ProposalStore;
}

function ProposalSection(props: Props) {
  useEffect(() => {
    if (!props.proposal) return;
    props.proposal.onInitCorporateId(props.id, props.isTemplate ?? false);
    props.proposal.onGetSectionList(props.id, props.isTemplate);
  }, []);

  const { processingSection, section, sectionList, processingPreview } =
    props.proposal ?? {};

  return (
    <div className="w-[225px]">
      <div className="m-4 flex h-12 flex-1 items-center justify-center rounded-sm bg-secondary font-semibold text-white">
        <HiOutlineSquare3Stack3D className="mr-1 size-5" />
        TEMPLATES
      </div>
      {processingSection ? (
        <ProposalSectionSkeleton />
      ) : (
        <div className="h-screen overflow-auto">
          <div className="m-4 mr-3 mb-40">
            {sectionList?.map((item, index) => {
              return (
                <ProposalSectionItem
                  id={props.id}
                  isTemplate={props.isTemplate}
                  key={item.id}
                  index={index}
                  onClick={
                    processingPreview
                      ? undefined
                      : () => props.proposal?.onChangeSection(item)
                  }
                  active={item.id === section?.id}
                  item={item}
                  rawHTMLUpdate={props.proposal?.rawHtml}
                  rawHTMLUpdateProcessing={
                    props.proposal?.processingPreview ?? false
                  }
                />
              );
            })}
            <AddTemplateButton proposalId={props.id} />
          </div>
        </div>
      )}
    </div>
  );
}

export default inject("proposal")(observer(ProposalSection));
