import { makeAutoObservable, observable, action } from "mobx";
import { contactDetails, updateContact } from "src/APIs/crm.api";
import { RESPONSE } from "src/APIs/error_handler";
import { reorderSequence } from "src/app/estimate/estimateList/estimateDetails/estimate.utility";
import { CRM_SETUP, CRM_TYPE } from "src/interfaces/crm.interface";
import CUSTOMER, {
  DEAL_TYPE,
  LEAD,
  LEAD_GROUP,
  LEAD_SOURCE,
  LEAD_STAGE,
  LEAD_STATUS,
} from "src/interfaces/customer.interface";
import {
  onCreateCRMSetup,
  onCreateCrm,
  onCreateDealType,
  onCreateGroup,
  onCreateSource,
  onCreateStage,
  onCreateStatus,
  onCrmStageReorder,
  onCrmStatusReorder,
  onCrmUpdate,
  onEnableStage,
  onEnableStatus,
  onGetClientList,
  onGetDealTypeList,
  onGetGroupsList,
  onGetLeadList,
  onGetSourceList,
  onGetStage,
  onGetStageList,
  onGetStatus,
  onGetStatusList,
  onGetStatusWithSummary,
  onSetColorOnFly,
  onSetCrmDefault,
  onSetCrmSetupOnFly,
  onSetNameOnFly,
  onSetStageDefault,
  onUpdateDealType,
  onUpdateGroup,
  onUpdateSource,
  onUpdateStage,
  onUpdateStageName,
  onUpdateStatus,
  onUpdateStatusName,
} from "src/services/crm.service";
import { orderByAsc } from "src/utilities/formatter.utilities";

export default class CRMStore {
  @observable contactDetails: CUSTOMER | null = null;
  @observable processing: boolean = false;
  @observable data: Array<LEAD> = [];
  @observable totalPages: number = 0;
  @observable totalRows: number = 0;
  @observable page: number = 1;
  @observable crmSetupList:
    | LEAD_STATUS[]
    | LEAD_GROUP[]
    | LEAD_SOURCE[]
    | DEAL_TYPE[] = [];

  @observable statusList: LEAD_STATUS[] = [];
  @observable selectedStatus: LEAD_STATUS | null = null;
  @observable processingStatusList: boolean = true;
  @observable processingUpdateStatus: boolean = false;
  @observable processingAddCrmTab: boolean = false;

  @observable selectedStage: LEAD_STAGE | null = null;
  @observable processingUpdateStage: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action onGetContactDetails = async (id: number, callback?: () => void) => {
    try {
      const result = await contactDetails(id);
      this.contactDetails = result.data;
      callback && callback();
      return result as RESPONSE;
    } catch (error) {
      return error as RESPONSE;
    }
  };

  @action onUpdateContact = async (contact: CUSTOMER) => {
    const res = await updateContact(contact);
    if (res.code === "200") {
      if (res.data) {
        this.contactDetails = res.data;
      }
      return res;
    } else {
      return null;
    }
  };

  @action async onGetStatus(
    type: string,
    callback: (status: LEAD_STATUS[]) => void
  ) {
    const res = await onGetStatus(type);
    if (res !== null) {
      callback(orderByAsc(res.data, "sequence"));
    }
  }

  @action async onGetStatusWithSummary(type: string) {
    this.processingStatusList = true;
    const res = await onGetStatusWithSummary(type);
    if (res !== null) {
      if (res.data.length > 0) {
        const data = orderByAsc(res.data, "sequence");
        this.statusList = [...data];
        this.selectedStatus = data[0];
        if (data[0].stages.length > 0) {
          this.selectedStage = data[0].stages[0];
        }
      } else {
        this.statusList = [];
        this.selectedStatus = null;
        this.selectedStage = null;
      }
      this.processingStatusList = false;
    } else {
      this.statusList = [];
      this.selectedStatus = null;
      this.selectedStage = null;
      this.processingStatusList = false;
    }
  }

  @action async onChangeStatus(status: LEAD_STATUS) {
    this.selectedStatus = status;
    if (status.stages) {
      this.selectedStage = status.stages[0];
    }
  }

  @action async onChangeStage(crmType: CRM_TYPE, stage: LEAD_STAGE) {
    this.selectedStage = stage;
    this.onGetList(crmType, this.selectedStatus?.id, stage.id);
  }

  @action async onGetStage(
    type: string,
    callback: (status: LEAD_STATUS[]) => void
  ) {
    const res = await onGetStage(type);
    if (res?.data !== null) {
      callback(orderByAsc(res?.data, "sequence"));
    }
  }

  @action onGetList = async (
    type: CRM_TYPE,
    statusId?: string,
    stageId?: string,
    page?: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean,
    dateFrom?: Date | null,
    dateTo?: Date | null
  ) => {
    if (!stageId || !statusId) return;
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetLeadList(
      type.key,
      statusId,
      stageId,
      this.page - 1,
      size || 15,
      search,
      sort,
      desc,
      dateFrom,
      dateTo
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.data = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onGetClientList = async (
    statusId?: number,
    stageId?: number,
    page?: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean,
    dateFrom?: Date | null,
    dateTo?: Date | null
  ) => {
    if (!stageId || !statusId) return;
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    setTimeout(async () => {
      const res = await onGetClientList(
        statusId,
        stageId,
        this.page - 1,
        size || 15,
        search,
        sort,
        desc,
        dateFrom,
        dateTo
      );
      if (res !== null) {
        this.totalRows = res.data.totalElements;
        this.totalPages = res.data.totalPages;
        this.data = res.data.content;
        this.processing = false;
      } else {
        this.processing = false;
      }
    }, 1000);
  };

  @action onGetSourceList = async (
    type: CRM_TYPE,
    page: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean
  ) => {
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetSourceList(
      this.page - 1,
      size || 15,
      type.id,
      search,
      sort,
      desc,
      true
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.crmSetupList = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onGetStageList = async (
    type: CRM_TYPE,
    page: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean
  ) => {
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetStageList(
      this.page - 1,
      size || 15,
      type.id,
      search,
      sort,
      desc,
      true
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.crmSetupList = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onGetStatusList = async (
    type: CRM_TYPE,
    page: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean
  ) => {
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetStatusList(
      this.page - 1,
      size || 15,
      type?.id,
      search,
      sort,
      desc,
      true
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.crmSetupList = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onGetGroupList = async (
    type: CRM_TYPE,
    page: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean
  ) => {
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetGroupsList(
      this.page - 1,
      size || 15,
      type.id,
      search,
      sort,
      desc,
      true
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.crmSetupList = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onGetDealTypeList = async (
    type: CRM_TYPE,
    page: number,
    search?: string,
    size?: number,
    sort?: string,
    desc?: boolean
  ) => {
    if (this.processing) return;
    this.processing = true;
    if (page) {
      this.page = page;
    } else {
      this.page = 1;
    }
    const res = await onGetDealTypeList(
      this.page - 1,
      size || 15,
      type.id,
      search,
      sort,
      desc,
      true
    );
    if (res !== null) {
      this.totalRows = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.crmSetupList = res.data.content;
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onCreateCrm = async (
    v: LEAD,
    type: CRM_TYPE,
    status: LEAD_STATUS,
    stage: LEAD_STAGE,
    callback: (v: LEAD) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCreateCrm(v, type.key);
    if (res !== null) {
      if (v.statusId === status.id && stage.id === v.stageId) {
        this.data = [res.data, ...this.data];
      }
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onCreateSource = async (
    v: LEAD_SOURCE,
    type: CRM_TYPE,
    callback: (customer: LEAD_SOURCE[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCreateSource(v, type.key);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onCreateStage = async (stage: LEAD_STAGE, callback: () => void) => {
    if (this.processingAddCrmTab) return;
    this.processingAddCrmTab = true;
    const res = await onCreateStage(stage);
    if (res !== null) {
      if (this.selectedStatus?.stages) {
        // const stageIndex = this.selectedStatus?.stages.findIndex((m) => m.id === stage.id);
        const _stages = orderByAsc(
          res.data.filter((row: any) => row.enable),
          "sequence"
        ) as LEAD_STAGE[];
        const _results = orderByAsc(_stages, "sequence") as LEAD_STAGE[];
        this.selectedStatus.stages = _results;
        this.selectedStage = _stages[_stages.length - 1];
      } else {
        this.selectedStage = null;
      }
      callback();
      this.processingAddCrmTab = false;
    } else {
      this.selectedStage = null;
      this.processingAddCrmTab = false;
    }
  };

  @action onCreateStatus = async (
    status: LEAD_STATUS,
    type: CRM_TYPE,
    callback: () => void
  ) => {
    if (this.processingAddCrmTab) return;
    this.processingAddCrmTab = true;
    const res = await onCreateStatus(status, type.key);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      if (res.data.length > 0) {
        const statuses = res.data.filter((m: any) => m.enable);
        this.selectedStatus = statuses[statuses.length - 1];
        this.statusList = [...statuses];
        this.selectedStage = null;
      } else {
        this.selectedStatus = null;
        this.statusList = [];
      }
      callback();
      this.processingAddCrmTab = false;
    } else {
      this.processingAddCrmTab = false;
    }
  };

  @action onCreateGroup = async (
    v: LEAD_GROUP,
    type: CRM_TYPE,
    callback: (customer: LEAD_GROUP[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCreateGroup(v, type.key);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onCreateDealType = async (
    v: LEAD_GROUP,
    type: CRM_TYPE,
    callback: (customer: DEAL_TYPE[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCreateDealType(v, type.key);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onCreateCrmSetup = async (
    v: LEAD_GROUP,
    type: CRM_TYPE,
    setupType: CRM_SETUP,
    callback: (customer: DEAL_TYPE[] | LEAD_GROUP[] | LEAD_SOURCE[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCreateCRMSetup(v, type.key, setupType);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateCrm = async (
    v: LEAD,
    id: number,
    type: CRM_TYPE,
    callback: (doc?: LEAD) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onCrmUpdate(v, id, type.key);
    if (res !== null) {
      const removedData = this.data.filter((c) => c.id !== id);
      this.data = [res.data, ...removedData];
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateSource = async (
    v: LEAD_SOURCE,
    id: string,
    callback: (doc?: LEAD_SOURCE) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onUpdateSource(v, id);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateStage = async (
    v: LEAD_STAGE,
    id: string,
    callback: (doc?: LEAD_STAGE[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onUpdateStage(v, id);
    if (res !== null) {
      const statusIndex = this.crmSetupList.findIndex(
        (m) => m?.id === v?.leadStatusId
      );
      this.crmSetupList[statusIndex].stages = res.data;
      this.crmSetupList = [...this.crmSetupList];
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onSetStageDefault = async (
    v: LEAD_STAGE,
    statusId: string,
    callback: (doc?: LEAD_STAGE[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onSetStageDefault(v);
    if (res !== null) {
      const statusIndex = this.crmSetupList.findIndex((m) => m.id === statusId);
      this.crmSetupList[statusIndex].stages = res.data;
      this.crmSetupList = [...this.crmSetupList];
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateStatus = async (
    v: LEAD_STATUS,
    id: string,
    callback: (doc?: LEAD_STATUS[]) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onUpdateStatus(v, id);
    if (res !== null) {
      this.crmSetupList = res.data;
      callback(res.data);
      this.processing = false;
    }
  };

  @action onUpdateStatusName = async (
    status: LEAD_STATUS,
    callback: () => void
  ) => {
    if (this.processingUpdateStatus) return;
    this.processingUpdateStatus = true;
    const res = await onUpdateStatusName(status);
    if (res !== null) {
      if (this.statusList.length > 0) {
        const index = this.statusList.findIndex(
          (m) => m.id === this.selectedStatus?.id
        );
        this.statusList[index].name = status.name;
        this.statusList = [...this.statusList];
        callback();
        this.processingUpdateStatus = false;
      } else {
        this.selectedStatus = null;
        this.statusList = [];
        callback();
        this.processingUpdateStatus = false;
      }
    } else {
      this.processingUpdateStatus = false;
    }
  };

  @action onUpdateStageName = async (
    stage: LEAD_STAGE,
    callback: () => void
  ) => {
    if (this.processingUpdateStage) return;
    this.processingUpdateStage = true;
    const res = await onUpdateStageName(stage);
    if (res !== null) {
      if (this.selectedStatus?.stages) {
        const stageIndex = this.selectedStatus.stages?.findIndex(
          (m) => m.id === stage.id
        );
        this.selectedStatus.stages[stageIndex] = stage;
        this.selectedStage = stage;
      }
      this.processingUpdateStage = false;
      callback();
    } else {
      this.selectedStage = null;
      this.processingUpdateStage = false;
    }
  };

  @action onEnableStage = async (
    enable: boolean,
    id: string,
    callback: () => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onEnableStage(enable, id);
    if (res !== null) {
      callback();
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateGroup = async (
    v: LEAD_GROUP,
    id: string,
    callback: (doc?: LEAD_GROUP) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onUpdateGroup(v, id);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateDealType = async (
    v: LEAD_GROUP,
    id: string,
    callback: (doc?: LEAD_GROUP) => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onUpdateDealType(v, id);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback(res.data);
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onSetDefault = async (
    type: CRM_SETUP,
    id: number,
    callback: () => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onSetCrmDefault(type, id);
    if (res !== null) {
      this.crmSetupList = orderByAsc(res.data, "sequence");
      callback();
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onEnableStatus = async (
    enable: boolean,
    id: string,
    callback: () => void
  ) => {
    if (this.processing) return;
    this.processing = true;
    const res = await onEnableStatus(enable, id);
    if (res !== null) {
      const index = this.crmSetupList.findIndex((m) => m.id === id);
      this.crmSetupList[index].enable = enable;
      callback();
      this.processing = false;
    } else {
      this.processing = false;
    }
  };

  @action onUpdateCustomerCrm(id: number, customer: CUSTOMER) {
    const crmIndex = this.data.findIndex((m) => m.id === id);
    this.data[crmIndex].customer = customer;
    this.data = [...this.data];
  }

  @action onCrmStatusReorder = async (
    statusies: LEAD_STATUS[],
    type: string
  ) => {
    const reordered = reorderSequence(statusies);
    this.statusList = [...reordered];
    const res = await onCrmStatusReorder(statusies, type);
    if (res === null) return;
  };

  @action onCrmStageReorder = async (stages: LEAD_STAGE[]) => {
    if (this.selectedStatus !== null) {
      const reordered = reorderSequence(stages);
      this.selectedStatus.stages = [...reordered];
      this.selectedStatus = { ...this.selectedStatus };
      const res = await onCrmStageReorder(
        stages,
        this.selectedStatus?.id ?? "0"
      );
      if (res === null) return;
    }
  };

  @action
  async onSetCrmOnFly(
    lead: LEAD,
    group: LEAD_GROUP,
    type: CRM_TYPE,
    setupType: CRM_SETUP,
    callback: () => void
  ) {
    const res = await onSetCrmSetupOnFly(
      group,
      lead.id ?? 0,
      type?.key,
      setupType
    );
    if (res !== null) {
      callback();
    } else {
      return;
    }
  }

  @action
  async onSetCRMSetupColorOnFly(
    group: LEAD_GROUP,
    type: CRM_SETUP,
    color: string,
    callback: () => void
  ) {
    const res = await onSetColorOnFly(group?.id ?? "", type, color);
    if (res !== null) {
      callback();
    } else {
      return;
    }
  }

  @action
  async onSetCRMSetupNameOnFly(
    group: LEAD_GROUP,
    type: CRM_SETUP,
    name: string,
    callback: () => void
  ) {
    const res = await onSetNameOnFly(group?.id ?? "", type, name);
    if (res !== null) {
      callback();
    } else {
      return;
    }
  }

  @action onClear = () => {
    this.contactDetails = null;
  };
}
