"use client";
import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { inject, observer } from "mobx-react";
import TableHeader from "./TableHeader";
import AuthStore from "src/stores/auth.store";

import { ENTITY_CONFIG } from "src/interfaces/init.interface";
import TableStore from "./TableStore";
import { LargeListSkeleton } from "../LargeListSkeleton";
import { COLORS } from "src/assets/colors";
import { Pagination, PaginationItem } from "@mui/material";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { pageShowing, pageTo } from "src/utilities/functions.utilities";
import { LAYOUTS } from "src/assets/layouts";
import _ from "lodash";
import RenderColumnRow, { getSize, saveSize } from "./RenderColumnRow";
import { ColumnSizingState } from "@tanstack/react-table";
import { paginationList, staticList } from "src/APIs/table.api";

interface Props {
  entityCode:string;
  path: string;
  auth?: AuthStore;
  onClick: (row: any) => void;
  height: number;
  entityConfigs?: ENTITY_CONFIG[];
  defaultSort?: string;
  static?: boolean;
  onAdd?: () => void;
  addLabel?: string;
  table?: TableStore;
  useShowAll?: boolean;
}

const MainTable = (props: Props) => {
  const { entities } = props.auth ?? {};
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [loading, setLoading] = useState(true);
  const [timeoutRef, setTimeoutRef] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 15,
    pageIndex: 1,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([
    { id: props.defaultSort ?? "", desc: false },
  ]);
  const [ready, setReady] = useState(false);
  const { pageSize, pageIndex } = pagination;
  const [totalElement, settotalElement] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (Object.getOwnPropertyNames(columnSizing).length !== 0) {
      saveSize(props.entityCode ?? "", columnSizing);
    } else {
      const size = getSize(props.entityCode ?? "");
      if (!size) return;
      setColumnSizing(size);
    }
  }, [columnSizing]);

  useEffect(() => {
    if (ready && props.static) {
      props.table?.searchData(search);
      return;
    }
    setLoading(true);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const timeOut = setTimeout(async () => {
      const sort = sorting[0];
      const res: any = props.static
        ? await staticList(props.path)
        : await paginationList(
            showAll ? props.path + "/all" : props.path,
            search ? 0 : pageIndex - 1,
            pageSize,
            search,
            sort?.id,
            !sort?.desc ?? null
          );
      if (res?.code !== "200") {
        setLoading(false);
        return;
      }
      const data = res?.data?.content || res?.data || [];
      props.table?.setData(data);
      if (!props.static) {
        setPageCount(res?.data?.totalPages);
        settotalElement(res?.data?.totalElements);
      }
      setReady(true);
      setLoading(false);
    }, 300);
    setTimeoutRef(timeOut);
  }, [pageIndex, pageSize, search, sorting, showAll]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    const entity = entities?.find((m) => m.code === props.entityCode);
    if (!entity) return [];
    return RenderColumnRow({ auth: props.auth, entity: entity });
  }, [entities]);

  const table = useMaterialReactTable({
    onColumnSizingChange: setColumnSizing,
    state: {
      pagination,
      rowSelection,
      sorting,
      isLoading: loading,
      showLoadingOverlay: false,
      columnSizing: columnSizing,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: pageCount,
    rowCount: totalElement,
    columns,
    data: props.table?.data ?? [],
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 250, //default size is usually 180
    },
    muiTableContainerProps: {
      sx: {
        height: props.static ? props.height - 142 : props.height - 195,
        borderWidth: 1,
        borderRadius: LAYOUTS.radius,
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#f8fafc",
      },
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: "#f8fafc",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRightWidth: 1,
        borderRightColor: COLORS.border,
      },
    },
    enableSorting: !props.static,
    enableBottomToolbar: !props.static,
    enablePagination: !props.static,
    manualSorting: true,
    enableMultiSort: false,
    enableColumnActions: false,
    manualPagination: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    enableStickyHeader: true,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: "pointer",
      },
      onClick: (event) => {
        props.onClick(row.original);
      },
    }),
    renderBottomToolbar: ({ table }) => {
      const pageCount = table.getPageCount();
      if (loading && !ready) return <div className="h-12" />;
      return (
        <div className="flex justify-center items-center p-2 ml-4">
          <Pagination
            size="medium"
            showLastButton
            showFirstButton
            shape={"rounded"}
            count={pageCount}
            page={pageIndex}
            onChange={(e, p) => {
              if (p === pageIndex) return;
              setPagination({ pageIndex: p, pageSize });
            }}
            renderItem={(item) => (
              <PaginationItem
                className="cursor-pointer"
                slots={{ previous: IoArrowBack, next: IoArrowForward }}
                {...item}
              />
            )}
          />
          <p className="w-96">
            Showing {pageShowing(pageSize, pageIndex) ?? "-"} to{" "}
            {pageTo(pageSize, pageIndex, totalElement) ?? "-"} of{" "}
            {totalElement ?? "-"} elements
          </p>
        </div>
      );
    },
    renderTopToolbar: () => {
      return (
        <TableHeader
          showAll={showAll}
          onShowActive={props.useShowAll ? () => setShowAll(false) : undefined}
          onShowAll={props.useShowAll ? () => setShowAll(true) : undefined}
          addLabel={props.addLabel}
          onAdd={props.onAdd}
          onChange={(e) => setSearch(e)}
          entityCode={props.entityCode}
        />
      );
    },
  });
  if (columns.length === 0) return <LargeListSkeleton />;
  return <MaterialReactTable table={table} />;
};

export default inject("auth", "table")(observer(MainTable));
